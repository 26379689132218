import apiHttpClient from "libs/api-http-client";
import ContributionTransaction, {
  ContributionTransactionInput,
  ContributionTransactionsQuery,
} from "types/contribution-transaction";
import { PaginatedEntityList } from "types/pagination";

export const addContributionTransactionAPI = (
  fundId: number,
  contribution: ContributionTransactionInput
): Promise<unknown> =>
  apiHttpClient.post(`funds/${fundId}/contribution-transactions`, contribution);

export const updateContributionTransactionAPI = (
  fundId: number,
  contributionId: number,
  contribution: ContributionTransaction
): Promise<unknown> =>
  apiHttpClient.patch(
    `funds/${fundId}/contribution-transactions/${contributionId}`,
    contribution
  );

export const cancelContributionTransactionAPI = (
  fundId: number,
  contributionId: number
): Promise<unknown> =>
  apiHttpClient.patch(
    `/funds/${fundId}/contribution-transactions/${contributionId}/cancel`
  );

export const getContributionTransactionsAPI = (
  query: ContributionTransactionsQuery
): Promise<PaginatedEntityList<ContributionTransaction>> =>
  apiHttpClient.get(`funds/${query.fundId}/contribution-transactions`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      year: query.year,
      status: query.status,
      orderBy: query.orderBy,
    },
  });
export const getContributionTransactionAPI = (
  fundId: number,
  contributionId: number
): Promise<ContributionTransaction> =>
  apiHttpClient.get(
    `/funds/${fundId}/contribution-transactions/${contributionId}`
  );
