import apiHttpClient from "libs/api-http-client";
import {
  INVESTMENT_FORMAT_ENUM,
  INVESTMENT_RISK_TYPE_ENUM,
  Investment,
} from "types/Investment";
import PaginatedEntityList from "types/PaginatedEntityList";
import { InvestmentRequestInput } from "types/investment-request";
export interface GetInvestmentsQuery {
  searchTerm?: string;
  offset?: number;
  limit?: number;
  filter?: {
    tickers?: string[];
    riskTypes?: INVESTMENT_RISK_TYPE_ENUM[];
    formats?: INVESTMENT_FORMAT_ENUM[];
    isActive?: boolean;
    isAFP?: boolean;
    isLiquid?: boolean;
    isListed?: boolean;
  };
  orderBy?: {
    createdAt?: "ASC" | "DESC";
    updatedAt?: "ASC" | "DESC";
  };
}

export const getInvestmentsAPI = (
  query: GetInvestmentsQuery
): Promise<PaginatedEntityList<Investment>> =>
  apiHttpClient.get(`/Investments`, {
    params: query,
  });

export const getInvestmentAPI = (ticker: string): Promise<Investment> =>
  apiHttpClient.get(`/investments/${ticker}`);

export const makeInvestmentRequestAPI = (
  ticker: string,
  data: InvestmentRequestInput
): Promise<unknown> =>
  apiHttpClient.post(`/investments/${ticker}/requests`, data);
