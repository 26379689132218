import React from "react";
import { Box, Button, Card, CardMedia, Container, Grid, Typography } from "@mui/material";
import home from '../../assets/home.png';
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import image5 from '../../assets/image5.png'
import Layout from "../../components/layout/layout";




export const Home = () => {

  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box >
          <Card sx={{ position: 'relative' }}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              height="auto"
              image={home}
              title="Contemplative Reptile"
            />
            <Grid sx={{
              position: 'absolute',
              top: '20%',
              width: '100%',
              p: { md: 6 },
              pr: { md: 0 },
            }} container>
              <Grid item md={6}>

                <Typography variant="h2" color={"white"} gutterBottom>
                  Optimise your donations and grants while doubling your impact.
                </Typography>
                <Typography variant="bodyCopyBold" color={"white"} >
                  Neta Foundation is the first Donation Advisor Fund Sponsor allowing you to invest in impactful illiquid investments.
                </Typography>
                <Box sx={{
                  my: 4
                }}>
                  <Button variant="contained" color='primary' size='large' onClick={() => handleOpen(true)}>Join Neta</Button>
                </Box></Grid></Grid>

          </Card>
          <Box sx={{ width: '100%', backgroundColor: 'white' }}>
            <Container >
              <Box sx={{ py: 5, width: '100%' }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={5}
                >
                  <Grid item md={12} sx={{ textAlign: "center", my: 2 }}>
                    <Typography variant="h3" gutterBottom>
                      How it works?
                    </Typography>
                    <Typography variant="bodyCopyRegular">
                      Lorem ipsum dolor sit amet consectetur. Vel nisi nunc imperdiet quis amet purus tempor amet.
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image1}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Add money to the Fund
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      Transfers cash, securities, or other financial instruments, to your Donor-Advised Fund. This transfer will be considered as a donation, tax-deductible in the year it is made.
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Receive tax benefits
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      Once the assets are in the fund, receive an immediate tax deduction for the full market value of the assets contributed. This is especially advantageous in order to itemize deductions on tax returns. </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image2}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image3}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Recommend for Grants
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      Although the fund relinquishes legal control over the assets, it retains advisory privileges. This means you can recommend specific charitable organizations or causes to receive grants from the fund. Neta has the final say, but we typically always honor the donor's preferences - unless funds are unavailable.               </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Invest for growth
                    </Typography>
                    <Typography variant="bodyCopyRegular">
                      The contributed assets are invested and have the potential to grow over time. This allows you to potentially increase the amount your fund can give to charitable causes in the future.                 </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image4}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={5}>
                    <Card >
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="auto"
                        image={image5}
                        title="Contemplative Reptile"
                      />
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      GrantVest, investments for impact
                    </Typography>
                    <Typography variant="bodyCopyRegular" paragraph>
                      The particularity of Neta is that we allow you to buy and grow assets of illiquid investments, supporting projects of impact. You can therefore double the impact you will have with your fund.                              </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>
      )}



    </Layout>);
}
