import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as DeleteIcon } from "./../../../assets/Icons/Delete.svg";
import { ReactComponent as EditIcon } from "./../../../assets/Icons/edit.svg";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { ChangeUserRolePagePath } from "constants/pages";
import { DeleteUserFromFund } from "./delete-user-from-fund";
import { FundUsersRoles } from "types/FundUsersRoles";
interface RolesSettingsMenuProps {
  user: FundUsersRoles;
  refetch: () => void;
}
export const RolesSettingsMenu: React.FC<RolesSettingsMenuProps> = ({
  user,
  refetch,
}) => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openEditPage = () => {
    const url = ChangeUserRolePagePath.replace(":id", user.id.toString());
    navigate(url);
  };

  return (
    <>
      <DeleteUserFromFund
        open={openCancelDialog}
        user={user}
        onClose={() => {
          setOpenCancelDialog(false);
          refetch();
        }}
      />
      <IconButton
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVertIcon sx={{ color: "neutral.light" }} />
      </IconButton>
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiMenu-paper": { px: 1 },
          }}
        >
          <MenuItem className="contribution-dropdown" onClick={openEditPage}>
            <EditIcon /> Change role
          </MenuItem>
          <MenuItem
            className="contribution-dropdown"
            onClick={() => {
              setOpenCancelDialog(true);
              handleClose();
            }}
          >
            <DeleteIcon /> Delete
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
