import { Box, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { GRANT_REQUEST_TRANSACTION_TYPE_ENUM } from "types/grant-request";
import { INVESTMENT_REQUEST_INTEREST_ENUM } from "types/investment-request";

interface GrantSuccessProps {
  amount: string;
  charityName: string;
  transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM;
  interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM | null;
}

export const GrantSuccess: FC<GrantSuccessProps> = ({
  charityName,
  amount,
  transactionType,
  interestedIn
}) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box mx={2} pt={3}>
          <Typography variant="h3">Thank you</Typography>
        </Box>
        <Box m={2}>
          <Typography sx={{ mt: 2 }} variant="subtitle3">
            {
              transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && interestedIn === INVESTMENT_REQUEST_INTEREST_ENUM.UNITS && <>
                We have received your recommendation to grant the amount obtained by selling {amount} to {charityName}.
              </>
            }
            {
              transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && interestedIn === INVESTMENT_REQUEST_INTEREST_ENUM.AMOUNT && <>
                We have received your request to grant {amount} to{" "}
                {charityName}.
              </>
            }
            {
              transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH && <>
                We have received your request to grant {amount} to{" "}
                {charityName}.
              </>
            }

          </Typography>
          <br />
          <Typography sx={{ mt: 2 }} variant="bodyCopyRegular">
            Our team will review and, once approved, process it in accordance with
            your recommendations.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
