export const HomePage = "/";
export const SignUpPage = "/signup/:id";
export const UserSettingsPage = "/user/settings";
export const SignUpByInvitePage = "/signup-invite/:id/:email/:fund/:role";

// GrantVestments Pages
export const GrantVestmentsHomePage = "/grantvestments";
export const GrantVestmentsSearchPage = "/grantvestments/search";
export const GrantVestmentPage = "/grantvestments/:id";
export const GrantVestmentRequestPageUrl =
  "/grant-vestments/:grantVestmentId/request";
export const GrantVestmentRequestDetailPageUrl = "/grant-vestments/request/:id";
export const MyGrantVestmentRequestPage = "/my-grant-vestments";
export const MyGrantVestmentDetailsPage = "/my-grant-vestments/:id";

// Fund Settings Pages
export const AddFundPage = "/user/create-fund";
export const MyFundsPageUrl = "/my-funds";
export const FundSettingsPage = "/fund-settings";
export const EntitySettingsPage = "/fund-settings/entity";
export const InterestsSettingsPage = "/fund-settings/interests";
export const EmailSettingsPage = "/fund-settings/email-settings";
// my cash
export const MyCashPageUrl = "/my-cash";
// Successors
export const SuccessionPlanPage = "/fund-settings/succession";
export const AddSuccessorPage = "/fund-settings/succession/create";
export const EditSuccessorPage = "/fund-settings/succession/edit";

// roles and permissions
export const RolesPermissionsPagePath = "/fund-settings/roles";
export const InviteUserPagePath = "/fund-settings/roles/invite";
export const ChangeUserRolePagePath = "/fund-settings/roles/edit/:id";

export const ContributionReceiptsPage = "/fund-settings/contribution-receipts";
export const ContributionTransactionDetailPageUrl =
  "/fund-settings/contribution-receipts/:id";
// Fund
export const FundDashboardPage = "/fund-account";
export const AddMoneyPage = "/add-money";
export const EditContributionReceiptsPage =
  "/fund-settings/contribution-receipts/edit/:id";

// Charities
export const CharitiesPagePath = "/charities";
export const CharitiesSearchPagePath = "/charities/search";
export const CharityGrantRequestPagePath = "/charities/grant";
export const MyGrantsRequestsPagePath = "/my-grants";
export const MyGrantsRequestEditPagePath = "/my-grants/edit/:id";
export const MyGrantsRequestDetailPagePath = "/my-grants/:id";
export const MyGrantsTransactionDetailPagePath = "/my-grants/request/:id";

// Investments
export const InvestmentsHomePagePath = "/investments";

// Investments request
export const InvestmentsBuyRequestPagePath = "/investments/buy";
export const InvestmentsSellRequestPagePath = "/investments/sell";
export const MyInvestmentsRequestsPage = "/my-investments";
export const MyInvestmentsRequestDetailPage = "/my-investments/:ticker";
export const MyInvestmentsRequestTransactionDetailPage =
  "/my-investments/request/:id";
export const MyDividendRequestDetailPage = "/my-dividends/:id";

// Fee request
export const MyFeeDetailPage = "/my-fees/:id";

// dividend request
export const MyDividendDistributionDetailPage = "/my-dividend/:id";

// Public pages
export const publicPages = [
  HomePage,
  SignUpPage,
  GrantVestmentsHomePage,
  GrantVestmentsSearchPage,
  GrantVestmentPage,
];
