import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { SUCCESSOR_TYPE, Successor, SuccessorCharity, SuccessorIndividual } from 'types/successor';
interface SuccsessAlertProps {
    successor?: Successor;
    last: boolean
    open: boolean;
    cancel: () => void;
    remove: () => void;
}
export const RemoveSuccessor: React.FC<SuccsessAlertProps> = ({
    successor, last, open, cancel, remove
}) => {
    const getName = () => {
        if (successor) {
            return (successor.successorType === SUCCESSOR_TYPE.CHARITY)?
            (successor.successorInfo as  SuccessorCharity).name :
            (successor.successorInfo as  SuccessorIndividual).firstName 
        }
        return ''
    };
    const getTitle = () => {
        return (last) ? "Remove from this successor?" : `Are you sure you want to remove ${getName()}?`
    }
    const getContent = () => {
        return (last) ?
            `${getName()} is the only listed successor on your plan. If you remove them, until you name other successors, you agree that upon termination of this fund all of its assets will revert to Neta’s Discretionary Fund` :
            `By removing ${getName()} from the list, you will also erase their information.`
    }

    return (

        <Dialog sx={{ m: 2 }} fullWidth={true}
            maxWidth={'sm'} open={open} onClose={cancel}>

            <DialogTitle sx={{ my: 2, pl: 4 }}> <Typography sx={{ color: "neutral" }} variant='subtitle1'>{getTitle()}</Typography> </DialogTitle>
            <DialogContent sx={{ pl: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='bodyCopyRegular'>
                            {getContent()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button variant="text" color='primary' size='large' onClick={cancel} >
                                Cancel
                            </Button>
                            <Button variant="contained" color='primary' size='large' onClick={remove}  >
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>


        </Dialog>


    )
}
