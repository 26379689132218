import React, { useState } from "react";
import {
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import { ReactComponent as DeleteIcon } from './../../assets/Icons/Delete.svg';
import { ReactComponent as EditIcon } from './../../assets/Icons/edit.svg'

import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props {
    index: number
    openEditPage: (index: number) => void;
    deleteInvite: (index: number) => void;
}
export const InvitationSettingsMenu: React.FC<
    Props
> = ({ index, openEditPage, deleteInvite }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEdit = () => {
        handleClose();
        openEditPage(index)
    }
    const handleDelete = () => {
        handleClose();
        deleteInvite(index)
    }

    return (
        <>
            <IconButton
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <MoreVertIcon sx={{ color: 'neutral.light' }} />
            </IconButton>
            {anchorEl && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        "& .MuiMenu-paper": { px: 1 },
                    }}
                >

                    <MenuItem
                        className="contribution-dropdown"
                        onClick={handleEdit}
                    >
                        <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem
                        className="contribution-dropdown"
                        onClick={handleDelete}
                    >
                        <DeleteIcon /> Delete
                    </MenuItem>
                </Menu>
            )}

        </>
    );
};
