import awsExports from "../aws-exports";
import axios, { AxiosResponse } from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const apiHttpClient = axios.create({
  baseURL: awsExports.endpoints[0].endpoint,
});

apiHttpClient.interceptors.request.use(async (reqConfig) => {
  const { idToken } =
    (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
  reqConfig.headers.Authorization = `Bearer ${idToken?.toString() as string}`;
  return reqConfig;
});

apiHttpClient.interceptors.response.use((res: AxiosResponse) => res.data);

export default apiHttpClient;
