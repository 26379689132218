import { USER_ROLE } from "./account";
export enum FUND_USERS_ROLES_TYPE_ENUM {
  EXISTED = "existed",
  INVITED = "invited",
}
export interface FundUsersRoles {
  id: number;
  fundId: number;
  user: string;
  email: string;
  type: FUND_USERS_ROLES_TYPE_ENUM;
  role: USER_ROLE;
  createdAt: Date;
  expiredAt: Date;
}
