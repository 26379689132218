import React from 'react';
import { Button, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';


export const BackToPreviousButton = () => {
    const navigate = useNavigate();
    return (

        <Button onClick={() => navigate(-1)} className='footer' variant='text' sx={{
            color: "neutral.main", "&:hover": {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            }
        }} startIcon={<NavigateNextIcon />}>
            <Typography variant='bodyCopyBold' >
                Back to previous</Typography>
        </Button>


    )
}
