import { Grid, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  stepsTitles: string[];
  activeStep: number;
}

export const CustomStepper: FC<Props> = ({
  activeStep = 0,
  stepsTitles: titles,
}) => {
  return (
    <Grid container sx={{ my: 5 }}>
      <Grid item xs={12} sm={10} md={10}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {titles.map((title, index) => (
            <Step>
              <StepLabel>
                <Typography variant="smallCopy">STEP {index + 1}</Typography>
                <br />
                <Typography variant="subtitle3">{title}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};
