import apiHttpClient from "libs/api-http-client";

import PaginatedEntityList from "types/PaginatedEntityList";
import { FundInvestment } from "types/fund-investment";
import {
  INVESTMENT_REQUEST_STATUS_ENUM,
  InvestmentRequest,
} from "types/investment-request";

export interface GetInvestmentRequestsQuery {
  searchTerm?: string;
  offset?: number;
  limit?: number;
  filter?: {
    ticker?: string;
    status?: INVESTMENT_REQUEST_STATUS_ENUM;
    fundId?: number;
  };
  orderBy?: {
    createdAt?: "ASC" | "DESC";
    updatedAt?: "ASC" | "DESC";
  };
}

export const getInvestmentRequestsAPI = (
  query: GetInvestmentRequestsQuery
): Promise<PaginatedEntityList<InvestmentRequest>> =>
  apiHttpClient.get(`/investments-requests`, {
    params: query,
  });

export interface GetFundInvestmentQuery {
  offset?: number;
  limit?: number;
}

export const getFundInvestmentRequestsAPI = (
  fundId: number,
  query: GetFundInvestmentQuery
): Promise<PaginatedEntityList<FundInvestment>> =>
  apiHttpClient.get(`/funds/${fundId}/investments`, {
    params: query,
  });
export const getFundInvestmentAPI = (
  fundId: number,
  ticker: string
): Promise<FundInvestment> =>
  apiHttpClient.get(`/funds/${fundId}/investments/${ticker}`);

export const getFundInvestmentRequestAPI = (
  investmentRequestId: number
): Promise<InvestmentRequest> =>
  apiHttpClient.get(`/investments-request/${investmentRequestId}`);

export const getFundInvestmentTotalAPI = (
  fundId: number,
  ticker?: string
): Promise<{
  totalAmount: number;
}> =>
  apiHttpClient.get(`/funds/${fundId}/total-fund-investments`, {
    params: {
      ticker: ticker,
    },
  });
