import { getGrantVestmentAPI, getGrantVestmentRequestAPI, getGrantVestmentsAPI, getGrantVestmentsQuery, makeGrantVestmentRequestAPI } from "api/grant-vestment.api";
import { useInfiniteQuery, useMutation, useQuery } from "react-query"
import { GrantVestment, GrantVestmentRequest } from "types/grant-vestment"
import { PaginatedEntityList } from "types/pagination";

const GRANT_VESTMENT_RQ_KEY = 'grant-vestments';

export const useGetGrantVestmentQuery = ({ grantVestmentId }: { grantVestmentId: number }) => {
  return useQuery<GrantVestment>({
    queryKey: [GRANT_VESTMENT_RQ_KEY, grantVestmentId],
    enabled: grantVestmentId !== 0,
    queryFn: () => getGrantVestmentAPI(grantVestmentId),
  })
}
export const useGetGrantVestmentsQuery = (query: getGrantVestmentsQuery) => {
  return useQuery<PaginatedEntityList<GrantVestment>>({
    queryKey: [GRANT_VESTMENT_RQ_KEY, query],
    queryFn: () => getGrantVestmentsAPI(query),
  })
}
export const useGetGrantVestmentsQueryWithPagination = (query: getGrantVestmentsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<GrantVestment>>({
    queryKey: [GRANT_VESTMENT_RQ_KEY, query],
    queryFn: ({ pageParam = 0 }) => getGrantVestmentsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useMakeGrantVestmentRequestMutation = (grantVestmentId: number) => {
  return useMutation<unknown, unknown, {
    requestedAmount: number;
    fundId: number;
  }>({
    mutationKey: [GRANT_VESTMENT_RQ_KEY, grantVestmentId],
    mutationFn: (data) => makeGrantVestmentRequestAPI(grantVestmentId, data),
  })
}

export const useGetGrantVestmentRequestQuery = (grantVestmentRequestId: number) => {
  return useQuery<GrantVestmentRequest>({
    queryKey: [GRANT_VESTMENT_RQ_KEY, grantVestmentRequestId],
    enabled: grantVestmentRequestId !== 0,
    queryFn: () => getGrantVestmentRequestAPI(grantVestmentRequestId),
  })
}
