import { useQuery } from "react-query";
import apiHttpClient from "libs/api-http-client";

export function useLookup(setData: (data: any) => void) {
  const getLookupFn = async () => {
    const response = await apiHttpClient.get("/funds/lookups");
    return response;
  };
  return useQuery({
    queryKey: "getLookup",
    queryFn: getLookupFn,
    onSuccess: (data) => setData(data),
  });
}
