import React from "react";
import { Box, Grid } from "@mui/material";
import logo from "../../../assets/logo-black/logo-s.svg";
import Contact from "./contact";
import Links from "./links";
import Copyright from "./copyright";

export const Footer: React.FC = () => {
  return (
    <Box sx={{ p: 5, backgroundColor: "warm.main" }}>
      <Grid justifyContent={"space-between"} container flexDirection={"row"}>
        <Grid item xs={12} sm={4}>
          <Box component="img" alt="Logo" src={logo} />
        </Grid>

        <Grid item xs={12} sm={2.5} alignSelf={"flex-end"} paddingLeft={3}>
          <Contact />
        </Grid>
      </Grid>
      <Grid
        sx={{ mt: 5 }}
        justifyContent="flex-end"
        alignItems="center"
        container
      >
        <Grid item xs={12} sm={12}>
          <Links />
        </Grid>
      </Grid>
      <Grid
        sx={{ mt: 2, textAlign: "center" }}
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item xs={12} sm={12}>
          <Copyright />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
