import React, { useContext, useEffect } from 'react';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import Layout from 'components/layout/layout';
import { useNavigate } from 'react-router-dom';
import { Account, Entity, USER_ROLE } from 'types/account';
import { AuthContext } from 'providers/userProvider';
import { FundSettingsPage } from 'constants/pages';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { EntityInformation } from 'components/fund-information/entity-information';
import { useLookup } from 'api/lookupService';
import { UserError } from 'types/user';
import { USER_ERROR } from 'constants/user';
import { SaveButton } from 'components/shared/save-button';
import { AuthData } from 'types/auth';
import { useUpdateFundAccountMutation } from 'hooks/use-services/fund.service';


export const EntitySettings = () => {
    const navigate = useNavigate()
    const { userData, selectedAccount, setSelectedAccount, refresh, setRefresh, userRole } = useContext<AuthData>(AuthContext)
    const [entity, setEntity] = React.useState<Entity>(
        {
            entityName: "",
            zip: "",
            city: "",
            state: "",
            firstAddressLine: "",
            secondAddressLine: "",
            type: "",
            role: "",
        }

    )
    const [error, setError] = React.useState<UserError>(USER_ERROR)
    const [types, setTypes] = React.useState([]);
    const setLookupData = (data: any) => {
        setTypes(data.types)
    }
    useLookup(setLookupData)
    const {
        mutate: updateFundAccount
    } = useUpdateFundAccountMutation(selectedAccount?.fundAccountId || 0);
    useEffect(() => {
        if (selectedAccount?.entityInformation) {
            setEntity(selectedAccount?.entityInformation)
        }
    }, [selectedAccount, userData?.user])

    const handleChange = (key: string, value: any) => {
        setError({ ...error, [key]: false })
        setEntity({
            ...entity,
            [key]: value
        })
    }

    const handleInvalid = (event: any) => {
        setError({
            ...error,
            [event.target.name]: true
        })
    }
    const submit = (event: any) => {
        event.preventDefault()
        updateFundAccount({ entityInformation: entity }, {
            onSuccess: (newData) => {
                setSelectedAccount(newData as Account)
                setRefresh(!refresh)
                navigate(FundSettingsPage)
            }
        })
    }
    return (
        <Layout>
            {() => (
                <>
                    <Box sx={{ my: 4, width: '100%' }}>
                        <React.Fragment>

                            <Container sx={{ mt: 3, width: '100%' }}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link underline="hover" color="inherit" onClick={() => navigate(FundSettingsPage)}>
                                        <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Fund settings</Typography>
                                    </Link>
                                    <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Entity information</Typography>
                                </Breadcrumbs>
                                <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant='h3'>
                                            Entity Information
                                        </Typography>

                                    </Grid>
                                </Grid>
                                <Box
                                    component="form"
                                    autoComplete="off"
                                    onSubmit={submit}
                                >
                                    <EntityInformation role={userRole!} settings={true} entity={entity} types={types} error={error} handleEntityChange={handleChange} handleInvalid={handleInvalid} ></EntityInformation>
                                    {
                                        userRole === USER_ROLE.ADMIN &&
                                        <SaveButton reset={() => navigate(FundSettingsPage)} secondaryButton='Cancel' />

                                    }
                                </Box>

                            </Container>

                        </React.Fragment>

                    </Box>
                </>)}
        </Layout>



    )
}
