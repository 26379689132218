import React, { useState } from "react";
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText
} from "@mui/material";
import { ReactComponent as EditIcon } from './../../assets/Icons/edit.svg';
import { ReactComponent as ErrorIcon } from './../../assets/Icons/error.svg';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContributionReport } from "types/contribution-transaction";

interface DonorInformationProps {
    contributionReport: ContributionReport,
    handleChange: (key: string, value: any) => void;
    newDonorDisabled: boolean;
}
export const DonorInformation: React.FC<
    DonorInformationProps
> = ({ contributionReport, handleChange, newDonorDisabled }) => {
    const [report, setReport] = useState<ContributionReport>({
        name: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        donorEmail: ''
    })
    const [error, setError] = React.useState({
        name: false,
        addressLine1: false,
        city: false,
        state: false,
        zip: false,
        donorEmail: false
    })
    const [open, setOpen] = useState<boolean>(false)
    const handleReportChange = (key: string, value: string) => {
        setError({
            ...error,
            [key]: false
        })
        setReport({
            ...report,
            [key]: value
        })
    };
    const handleError = (key: string) => {
        setError({
            ...error,
            [key]: true
        })
    };
    const onSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        handleChange('contributionReport', report)
        setOpen(false)
    }
    return (
        <>
            <Dialog sx={{ m: 2 }} fullWidth={true}
                maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>

                <DialogTitle sx={{ my: 2, pl: 4 }}> <Typography sx={{ color: "neutral" }} variant='subtitle1'> Enter donor information for the<br></br>
                    contribution receipt</Typography> </DialogTitle>
                <DialogContent sx={{ pl: 4 }}>
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={onSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>

                                <FormControl error={error.name} fullWidth>
                                    <InputLabel shrink={false}>Name</InputLabel>
                                    <OutlinedInput name="name"
                                        value={report.name}
                                        onChange={(event) => handleReportChange("name", event.target.value)}
                                        onInvalid={(event) => handleError('name')}
                                        type="text" required />
                                    {error.name && <FormHelperText ><ErrorIcon />This field is required</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>

                                <FormControl error={error.donorEmail} fullWidth>
                                    <InputLabel shrink={false}>Email*</InputLabel>
                                    <OutlinedInput name="donorEmail"
                                        value={report.donorEmail}
                                        onChange={(event) => handleReportChange("donorEmail", event.target.value)}
                                        onInvalid={(event) => handleError('donorEmail')}
                                        type="text" required />
                                    {error.donorEmail && <FormHelperText ><ErrorIcon />This field is required</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>

                                <FormControl error={error.addressLine1} fullWidth>
                                    <InputLabel shrink={false}>Address line 1*</InputLabel>
                                    <OutlinedInput name="addressLine1"
                                        value={report.addressLine1}
                                        onChange={(event) => handleReportChange("addressLine1", event.target.value)}
                                        onInvalid={(event) => handleError('addressLine1')}
                                        type="text" required />
                                    {error.addressLine1 && <FormHelperText ><ErrorIcon />This field is required</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>

                                <FormControl fullWidth>
                                    <InputLabel shrink={false}>Address line 2</InputLabel>
                                    <OutlinedInput name="addressLine2"
                                        value={report.addressLine2}
                                        onChange={(event) => handleReportChange("addressLine2", event.target.value)}
                                        type="text"
                                    />
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={error.city} fullWidth>
                                    <InputLabel shrink={false}>City*</InputLabel>
                                    <OutlinedInput name="city"
                                        value={report.city}
                                        onChange={(event) => handleReportChange("city", event.target.value)}
                                        onInvalid={(event) => handleError('city')}
                                        type="text" required />
                                    {error.city && <FormHelperText ><ErrorIcon />This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={error.state} fullWidth>
                                    <InputLabel shrink={false}>State*</InputLabel>
                                    <OutlinedInput name="state"
                                        value={report.state}
                                        onChange={(event) => handleReportChange("state", event.target.value)}
                                        onInvalid={(event) => handleError('state')}
                                        type="text" required />
                                    {error.state && <FormHelperText ><ErrorIcon />This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={error.zip} fullWidth>
                                    <InputLabel shrink={false}>ZIP* </InputLabel>
                                    <OutlinedInput name="zip"
                                        value={report.zip}
                                        onChange={(event) => handleReportChange("zip", event.target.value)}
                                        onInvalid={(event) => handleError('zip')}
                                        type="text" required />
                                    {error.zip && <FormHelperText ><ErrorIcon />This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button variant="text" color='primary' size='large' onClick={() => setOpen(false)} >
                                        Discard
                                    </Button>
                                    <Button variant="contained" color='primary' size='large' type="submit"   >
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </DialogContent>


            </Dialog>
            <Accordion defaultExpanded>
                <AccordionSummary tabIndex={-1}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="donor-info"
                    id="donor-info"
                >
                    <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
                        Donor information for contribution receipt
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid sx={{ my: 1 }} container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle3" sx={{ color: `neutral.main` }}>
                                {contributionReport?.name}
                            </Typography>
                            <Typography variant='bodyCopyRegular' sx={{ color: `neutral.main` }} paragraph>
                                {contributionReport?.addressLine1},<br></br>
                                {
                                    (contributionReport?.addressLine2) && <>{contributionReport?.addressLine2} <br></br></>
                                }

                                {contributionReport?.city}, {contributionReport?.state}, {contributionReport?.zip}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Button className='underline' onClick={() => setOpen(true)} startIcon={<EditIcon />} variant='text' color="primary" disabled={newDonorDisabled} > Different donor? Click here </Button>
                        </Grid>



                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
