import {
  GetFundInvestmentQuery,
  GetInvestmentRequestsQuery,
  getFundInvestmentAPI,
  getFundInvestmentRequestAPI,
  getFundInvestmentRequestsAPI,
  getFundInvestmentTotalAPI,
  getInvestmentRequestsAPI,
} from "api/investmentRequest.api";
import { useInfiniteQuery, useQuery } from "react-query";
import PaginatedEntityList from "types/PaginatedEntityList";
import { FundInvestment } from "types/fund-investment";
import { InvestmentRequest } from "types/investment-request";

const INVESTMENT_RQ_KEY = "/investments-requests";
export const useGetInvestmentRequestsQuery = (
  query: GetInvestmentRequestsQuery
) => {
  return useInfiniteQuery<PaginatedEntityList<InvestmentRequest>>({
    queryKey: [INVESTMENT_RQ_KEY, query],
    queryFn: ({ pageParam = 0 }) =>
      getInvestmentRequestsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.nextOffset;
    },
  });
};

export const useGetFundInvestmentRequestsQuery = (
  fundId: number,
  query: GetFundInvestmentQuery
) => {
  return useInfiniteQuery<PaginatedEntityList<FundInvestment>>({
    enabled: fundId !== 0,
    queryKey: [INVESTMENT_RQ_KEY, query, fundId],
    queryFn: ({ pageParam = 0 }) =>
      getFundInvestmentRequestsAPI(fundId, { ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.nextOffset;
    },
  });
};
export const useGetFundInvestmentQuery = (fundId: number, ticker: string) => {
  return useQuery<FundInvestment>({
    queryKey: [INVESTMENT_RQ_KEY, ticker, fundId],
    enabled: fundId !== 0 && ticker !== null,
    queryFn: () => getFundInvestmentAPI(fundId, ticker),
  })
}
export const useGetFundInvestmentRequestQuery = (transactionId: number) => {
  return useQuery<InvestmentRequest>({
    queryKey: [INVESTMENT_RQ_KEY, transactionId],
    enabled: transactionId !== 0,
    queryFn: () => getFundInvestmentRequestAPI(transactionId),
  })
}
export const useGetFundInvestmentTotalQuery = ({ fundId, ticker }: {
  fundId: number,
  ticker?: string
}) => {
  return useQuery<{
    totalAmount: number;
  }>({
    queryKey: [INVESTMENT_RQ_KEY, fundId],
    enabled: fundId !== 0,
    queryFn: () => getFundInvestmentTotalAPI(fundId, ticker),
  })
}
