import { neutral } from "style/color";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { FC } from "react";
import { USER_ROLE } from "types/account";
interface RolesRadioGroupProps {
    userInvite: any;
    handleChange: (key: string, value: any) => void;
}
export const RolesRadioGroup: FC<RolesRadioGroupProps> = ({ userInvite, handleChange }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant='subtitle3' >Assign role</Typography></Grid>
            <Grid item xs={12}>
                <FormControl>
                    <RadioGroup
                        name="row-radio-buttons-group"
                        value={userInvite.role}
                        onChange={(event) => handleChange("role", event.target.value)}
                    >
                        <FormControlLabel sx={{
                            mb: 1,
                            alignItems: 'flex-start',
                            '& span.MuiRadio-root': {
                                paddingTop: 0
                            }
                        }} value={USER_ROLE.ADMIN} control={<Radio />} label={
                            <><Typography variant='bodyCopyBold' >Admin</Typography><br></br>
                                <Typography sx={{ color: neutral[600] }} variant='bodyCopyRegular' >Has full access to the fund and can invite or remove others or modify the succession plan</Typography>
                            </>} />
                        <FormControlLabel sx={{
                            mb: 1, alignItems: 'flex-start',
                            '& span.MuiRadio-root': {
                                paddingTop: 0
                            }
                        }} value={USER_ROLE.ADVISOR} control={<Radio />} label={
                            <><Typography variant='bodyCopyBold' >Advisor</Typography><br></br>
                                <Typography sx={{ color: neutral[600] }} variant='bodyCopyRegular' >
                                    Can recommend transactions for the fund</Typography>

                            </>}
                        />
                        <FormControlLabel sx={{
                            alignItems: 'flex-start',
                            '& span.MuiRadio-root': {
                                paddingTop: 0
                            }
                        }} value={USER_ROLE.VIEWER} control={<Radio />} label={
                            <><Typography variant='bodyCopyBold' >Viewer</Typography><br></br>
                                <Typography sx={{ color: neutral[600] }} variant='bodyCopyRegular' >
                                    Can view all information but cannot perform any action</Typography>

                            </>
                        }
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </>
    );
}
