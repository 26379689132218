import React, { useContext, useEffect } from "react";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SvgIcon,
} from "@mui/material";

import { primary } from "../../style/color";

import { ReactComponent as DashboardIcon } from "./../../assets/Icons/Dashboard.svg";
import { ReactComponent as CashIcon } from "./../../assets/Icons/Cash.svg";
import { ReactComponent as InvestmentsIcon } from "./../../assets/Icons/fluctuations.svg";
import { ReactComponent as GrantVestmentsIcon } from "./../../assets/Icons/Gantvestments.svg";
import { ReactComponent as GrantIcon } from "./../../assets/Icons/Grant.svg";
import { ReactComponent as SettingsIcon } from "./../../assets/Icons/settings.svg";
import { ReactComponent as ChevronLeftIcon } from "./../../assets/Icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "./../../assets/Icons/chevron-right.svg";
import { ReactComponent as ChevronDownIcon } from "./../../assets/Icons/chevron-down.svg";

import { AuthContext } from "providers/userProvider";
import { Account } from "types/account";
import { golbalTheme } from "style/theme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FundDashboardPage,
  FundSettingsPage,
  MyCashPageUrl,
  MyGrantVestmentRequestPage,
  MyGrantsRequestsPagePath,
  MyInvestmentsRequestsPage,
} from "constants/pages";
import { AuthData } from "types/auth";
import { FundAccountMember } from "types/fund-account-member";

interface SidebarProps {
  route: string;
}
export const Sidebar: React.FC<SidebarProps> = ({ route }) => {
  const menuItems = [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      page: FundDashboardPage,
      active: "/fund-account",
    },
    {
      title: "My Cash",
      icon: <CashIcon />,
      page: MyCashPageUrl,
      active: MyCashPageUrl,
    },
    {
      title: "My Investments",
      icon: <InvestmentsIcon />,
      page: MyInvestmentsRequestsPage,
      active: MyInvestmentsRequestsPage,
    },
    {
      title: "My GrantVestments",
      icon: <GrantVestmentsIcon />,
      page: MyGrantVestmentRequestPage,
      active: MyGrantVestmentRequestPage,
    },
    {
      title: "My Grants",
      page: MyGrantsRequestsPagePath,
      active: MyGrantsRequestsPagePath,
      icon: <GrantIcon />,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, selectedAccount, setSelectedAccount, setUserRole } =
    useContext<AuthData>(AuthContext);
  const [fundAccountMembers, setFundAccounts] = React.useState<
    FundAccountMember[]
  >([]);
  const [account, setAccount] = React.useState<number>(-1);
  const [name, setName] = React.useState<string>("");
  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    if (userData?.user && selectedAccount) {
      setFundAccounts(userData?.user?.fundAccounts!);
      setAccount(selectedAccount?.fundAccountId!);
      setName(getName(selectedAccount));
    }
  }, [userData?.user, selectedAccount]);
  const handleChange = (event: any) => {
    event.preventDefault();
    setChecked((prev) => {
      return !prev;
    });
  };
  const handleAccountChange = (event: SelectChangeEvent<number>) => {
    event.preventDefault();
    const accountMembers = fundAccountMembers.find(
      (value) => value.fund.fundAccountId === event.target.value
    );
    localStorage.setItem(
      "selectedAccount",
      accountMembers?.fund.fundAccountId?.toString()!
    );
    setSelectedAccount(accountMembers?.fund!);
    setUserRole(accountMembers?.role!)
  };
  const getName = (selectedAccount: Account) => {
    if (selectedAccount) {
      return (selectedAccount as Account)?.fundAccountName
        ?.split(" ")
        .reduce((accumulator, char) => accumulator + char[0], "");
    }
    return "";
  };
  const isActive = (value: string) => {
    return location.pathname.includes(value);
  };
  const isDisabled = () => {
    return location.pathname.includes("add-money");
  };
  const goTo = (link: string) => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <Box>
      {route === "authenticated" && (
        <Collapse
          className="sidebar"
          sx={{
            flex: 1,
            pr: checked ? 3 : 0,
            pt: 2,
            "& .MuiCollapse-wrapperInner ": {
              width: "-webkit-fill-available",
            },
          }}
          orientation="horizontal"
          in={checked}
          collapsedSize={85}
        >
          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  {checked ? (
                    <FormControl size="small" fullWidth>
                      <Select
                        disabled={isDisabled()}
                        fullWidth
                        name="funds"
                        id="funds"
                        value={account}
                        IconComponent={(props) => (
                          <SvgIcon {...props}>
                            <ChevronDownIcon />
                          </SvgIcon>
                        )}
                        sx={{
                          ...golbalTheme.typography.bodyCopyBold,
                          color: "white",
                          backgroundColor: primary[500],
                          "& .Mui-disabled": {
                            backgroundColor: primary[500],
                          },
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "white",
                          },
                        }}
                        onChange={(event) => {
                          handleAccountChange(event);
                        }}
                        readOnly={fundAccountMembers.length === 1}
                      >
                        {fundAccountMembers.map((account, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={account.fund.fundAccountId}
                            >
                              {account.fund.fundAccountName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <OutlinedInput
                      size="small"
                      onClick={(event) => handleChange(event)}
                      readOnly
                      fullWidth
                      name="funds"
                      id="funds"
                      value={name}
                      inputProps={{ style: { textAlign: "center" } }}
                      sx={{
                        ...golbalTheme.typography.bodyCopyBold,
                        color: "white",
                        backgroundColor: primary[500],
                        "& fieldset": {
                          border: "0px",
                        },
                        "&:hover fieldset": {
                          border: "0px",
                        },
                        "&.Mui-focused fieldset": {
                          border: "0px",
                        },
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              {menuItems.map((item, index) => {
                return (
                  <ListItem
                    className={isActive(item?.active!) ? "active" : ""}
                    key={index}
                    sx={{ my: 1 }}
                    onClick={() => goTo(item?.page!)}
                    disablePadding
                  >
                    <ListItemButton sx={{ justifyContent: "center" }}>
                      <ListItemIcon
                        sx={{ minWidth: checked ? "56px" : "auto" }}
                      >
                        <SvgIcon>{item.icon}</SvgIcon>
                      </ListItemIcon>
                      {checked && (
                        <ListItemText
                          sx={{
                            m: 0,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "pre-wrap",
                          }}
                          primary={item.title}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Divider variant="middle" sx={{ backgroundColor: primary[100] }} />
            <List>
              <ListItem
                onClick={() => navigate(FundSettingsPage)}
                className={isActive("fund-settings") ? "active" : ""}
                sx={{ my: 1 }}
                disablePadding
              >
                <ListItemButton sx={{ justifyContent: "center" }}>
                  <ListItemIcon sx={{ minWidth: checked ? "56px" : "auto" }}>
                    <SvgIcon>
                      <SettingsIcon />
                    </SvgIcon>
                  </ListItemIcon>
                  {checked && (
                    <ListItemText
                      sx={{
                        m: 0,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        whiteSpace: "pre-wrap",
                      }}
                      primary={"Fund settings "}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </List>
            <IconButton
              sx={{ float: "inline-end", m: 1 }}
              onClick={(event) => handleChange(event)}
            >
              <SvgIcon>
                {checked ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </SvgIcon>
            </IconButton>
          </Box>
        </Collapse>
      )}
    </Box>
  );
};
