import { getFundDividendAPI, GetFundDividend } from "api/fund-dividend.api";
import { useQuery } from "react-query";
import { FundDividendDistribution } from "types/fund-dividend-distribution";

const FUND_FEE_KEY = "fund-dividend";
export const useGetFundDividendQuery = (query: GetFundDividend) => {
  return useQuery<FundDividendDistribution>({
    queryKey: [FUND_FEE_KEY, query.dividendId],
    enabled: query.dividendId !== 0,
    queryFn: () => getFundDividendAPI(query),
  });
};
