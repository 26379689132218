import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  SvgIcon,
  Typography,
} from "@mui/material";
import { warm, neutral, primary } from "style/color";
import { ReactComponent as ClockIcon } from "./../../assets/Icons/clock.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { moneyFormat } from "utils/money-format";
import { DIVIDEND_DISTRIBUTION_TYPE } from "types/fund-dividend-distribution";
interface TransactionDetailsCardProps {
  amount: number;
  quantity?: string;
  ein?: string;
  title: string;
  type: string;
  status: string;
  avatar: React.ReactNode;
  children: React.ReactNode;
  dividendType?: DIVIDEND_DISTRIBUTION_TYPE;
  statusCard?: {
    icon: React.ReactNode;
    title: string;
    text: string;
  };
}
export const TransactionDetailsCard: React.FC<TransactionDetailsCardProps> = ({
  amount,
  quantity,
  title,
  type,
  status,
  avatar,
  children,
  statusCard,
  ein,
  dividendType,
}) => {
  return (
    <Card sx={{ minWidth: 275, p: 2 }}>
      <CardHeader
        avatar={
          !["PENDING", "REJECTED", "CANCELED"].includes(status) ? (
            <Avatar
              sx={{
                bgcolor: primary[100],
                width: 56,
                height: 56,
              }}
              aria-label="recipe"
            >
              <SvgIcon className="avatar"> {avatar} </SvgIcon>
            </Avatar>
          ) : (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                >
                  {status === "PENDING" ? (
                    <ClockIcon />
                  ) : (
                    <HighlightOffIcon sx={{ backgroundColor: primary[500] }} />
                  )}
                </Avatar>
              }
            >
              <Avatar
                sx={{
                  bgcolor: primary[100],
                  width: 56,
                  height: 56,
                }}
                aria-label="recipe"
              >
                <SvgIcon className="avatar"> {avatar} </SvgIcon>
              </Avatar>
            </Badge>
          )
        }
      />

      <CardContent sx={{ pt: 0 }}>
        <Typography
          className="title"
          variant="smallCopy"
          sx={{ color: neutral[900] }}
        >
          {type}
        </Typography>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          {ein && (
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle3" sx={{ color: neutral[600] }}>
                {ein}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <Typography variant="h2" sx={{ color: neutral[900] }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              {quantity ? (
                <Box>
                  <Typography
                    className="title"
                    variant="smallCopy"
                    sx={{ color: neutral[900] }}
                  >
                    Quantity
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h1"
                      sx={{
                        color: neutral[900],
                        textDecoration:
                          status === "REJECTED" || status === "CANCELED"
                            ? "line-through"
                            : "none",
                      }}
                    >
                      {quantity}
                    </Typography>
                    {status !== "PENDING" && (
                      <Box sx={{ ml: 3 }}>
                        <Typography
                          className="title"
                          variant="smallCopy"
                          sx={{ color: neutral[600] }}
                        >
                          Liquidation value
                        </Typography>
                        <Typography
                          variant="subtitle3"
                          sx={{ color: neutral[600] }}
                          paragraph
                        >
                          ${moneyFormat(amount)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Typography
                    className="title"
                    variant="smallCopy"
                    sx={{ color: neutral[900] }}
                  >
                    {dividendType &&
                    dividendType === DIVIDEND_DISTRIBUTION_TYPE.UNIT
                      ? `Units`
                      : `Amount`}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: neutral[900],
                      textDecoration:
                        status === "REJECTED" || status === "CANCELED"
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {" "}
                    {amount > 0
                      ? `${
                          dividendType &&
                          dividendType === DIVIDEND_DISTRIBUTION_TYPE.UNIT
                            ? ``
                            : `$`
                        }${moneyFormat(amount)}`
                      : `-$${moneyFormat(amount * -1)}`}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            sx={{
              mt: 4,
              paddingTop: "32px!important",
              borderTop: "solid 2px",
              borderTopColor: warm[500],
            }}
            item
            xs={12}
            sm={12}
          ></Grid>
          {statusCard && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography
                  className="title"
                  variant="subtitle2"
                  sx={{ color: neutral[900] }}
                >
                  STATUS
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  sx={{
                    backgroundColor: warm[200],
                    padding: 3,
                    borderRadius: "4px",
                    mb: 3,
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={1}>
                    {statusCard.icon}
                  </Grid>

                  <Grid item xs={10}>
                    <Typography
                      variant="subtitle3"
                      sx={{ color: primary[500] }}
                    >
                      {statusCard.title}
                    </Typography>
                    <Typography
                      variant="smallCopyBold"
                      sx={{ color: neutral[600], mb: 0 }}
                      paragraph
                    >
                      {statusCard.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12}>
            <Typography
              className="title"
              variant="subtitle2"
              sx={{ color: neutral[900] }}
            >
              Details
            </Typography>
          </Grid>
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};
