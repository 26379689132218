import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, ListItemText, OutlinedInput, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { RolesRadioGroup } from 'components/roles-permissions/roles';
import { USER_ROLE } from 'types/account';
import { neutral } from 'style/color';
import { InvitationSettingsMenu } from './invite-settings-menu';

interface Props {
    invitations: {
        email: string, role: USER_ROLE
    }[];
    handleAccountChange: (key: string, value: any) => void;

}
export const InviteAdvisors: React.FC<Props> = ({ invitations, handleAccountChange }) => {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(false)
    const [index, setIndex] = useState(-1)
    const [userInvite, setUserInvite] = useState({
        email: '',
        role: USER_ROLE.ADMIN
    })
    const handleChange = (key: string, value: string) => {
        if (key === 'email') {
            setError(false)
        }
        setUserInvite({
            ...userInvite,
            [key]: value
        })
    }
    const onClose = () => {
        setUserInvite({
            email: '',
            role: USER_ROLE.ADMIN
        })
        setIndex(-1)
        setOpen(false)
    }
    const openEditPage = (index: number) => {
        const invite = invitations[index]
        setIndex(index)
        setUserInvite(invite)
        setOpen(true)
    }
    const onSubmit = () => {
        let newInvitations = invitations;
        if (index !== -1) {
            newInvitations[index] = userInvite
        }
        else {
            newInvitations.push(userInvite)

        }
        handleAccountChange('invitations', newInvitations)

        onClose()
    }
    const deleteInvite = (index: number) => {
        let newInvitations = invitations;
        newInvitations.splice(index)
        handleAccountChange('invitations', newInvitations)

        setOpen(false)
    }

    return (
        <Accordion defaultExpanded>
            <AccordionSummary tabIndex={-1}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="advisors"
                id="advisors"
            >
                <Typography sx={{ color: `neutral.main` }} variant="subtitle1">Invite other advisors  <Typography sx={{ color: `neutral.lighter`, mx: 3 }} variant="bodyCopyBold">Optional </Typography></Typography>
            </AccordionSummary>
            <AccordionDetails >
                <Dialog sx={{ m: 2 }} fullWidth={true}
                    maxWidth={'sm'} open={open} onClose={onClose}>

                    <DialogTitle sx={{ mt: 3, pl: 4 }}>
                        <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                            Invite other advisors
                            <IconButton
                                onClick={onClose}
                                sx={{
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    }
                                }}
                            > <CloseIcon />
                            </IconButton>

                        </Typography>
                        <Typography sx={{ color: `neutral.dark` }} variant='bodyCopyRegular'>
                            The advisor below will receive an email invitation to fill out his information                </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ pl: 4, mb: 3 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={9}>
                                <FormControl error={error} fullWidth>
                                    <InputLabel shrink={false}>Email </InputLabel>
                                    <OutlinedInput name="email"
                                        value={userInvite.email}
                                        onChange={(event) => handleChange("email", event.target.value)}
                                        onInvalid={() => setError(true)}
                                        type="text" required />
                                    {error && <FormHelperText color={'red'}>Email is required</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <RolesRadioGroup userInvite={userInvite} handleChange={handleChange} />
                            <Grid item xs={12} sm={12} textAlign={'end'}>
                                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose} >
                                    Cancel
                                </Button>
                                <Button variant="contained" color='primary' size='large' onClick={onSubmit} >
                                    Send invite
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Typography sx={{ color: `neutral.dark` }} variant='bodyCopyRegular'>
                    Invite other people to be advisors to the fund and set their permission. You can add or modify accesses later from your fund settings.
                </Typography>
                <Grid sx={{ mt: 2 }} container >
                    {
                        invitations.length !== 0 &&
                        <Grid item xs={12} sx={{ mb: 2 }} >
                            <List className='successor' sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {invitations.map((invite, index) => (
                                    < > <ListItem
                                        sx={{ display: '-webkit-inline-box' }}
                                        key={index}
                                    >
                                        <Grid alignItems='center' container>
                                            <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ListItemText sx={{ width: 'auto', flex: 'none' }} primary={<Typography sx={{ color: `neutral.dark` }} variant='bodyCopyBold' gutterBottom>{invite.email}</Typography>} />
                                            </Grid>
                                            <Grid item xs={12} sm={5} >
                                                <Typography sx={{ color: neutral[900] }} variant='bodyCopyRegular' >{invite.role} </Typography>

                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign={'end'}>
                                                <InvitationSettingsMenu index={index} deleteInvite={deleteInvite} openEditPage={openEditPage} />
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                        {
                                            index !== invitations.length - 1 && <Divider key={index + 100} />
                                        }

                                    </>
                                ))}
                            </List>
                        </Grid>
                    }

                    <Grid item xs={12} >
                        <Button startIcon={<AddIcon />} variant="outlined" color='primary' size='large' onClick={() => setOpen(true)}>
                            Send invite</Button>
                    </Grid>

                </Grid>

            </AccordionDetails>
        </Accordion>




    )
}
