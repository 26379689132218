import React from "react";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Button
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
import { GRANT_REQUEST_STATUS_ENUM, GRANT_REQUEST_TYPE_ENUM, GrantRequest, RecurrentSchedule, getExecutionDate, getFrequency } from "types/grant-request";
import { useUpdateGrantRequestStatusMutation } from "hooks/use-services/grant-request.service";
import { neutral } from "style/color";
import { einFormat } from "types/charity";
interface CancelGrantProps {
    grantRequest: GrantRequest,
    open: boolean;
    onClose: (redirect: boolean) => void;

}
export const CancelGrant: React.FC<
    CancelGrantProps
> = ({ open, onClose, grantRequest }) => {
    const { mutate: mutateGrantRequestStatus } = useUpdateGrantRequestStatusMutation((grantRequest?.grantRequestId) ? grantRequest?.grantRequestId : 0);

    const submit = () => {
        mutateGrantRequestStatus({
            status: GRANT_REQUEST_STATUS_ENUM.CANCELED
        }, {
            onSuccess: () => onClose(true),
        });

    }
    return (
        <>
            {
                grantRequest &&
                <Dialog sx={{ m: 2 }} fullWidth={true}
                    maxWidth={'md'} open={open} onClose={onClose}>

                    <DialogTitle sx={{ my: 2, pl: 4 }}>
                        <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                            Are you sure to want to cancel this {
                                (grantRequest.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT && 'recurring')
                            } grant?
                            <IconButton
                                onClick={() => onClose(false)}
                                sx={{
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    }
                                }}
                            > <CloseIcon />
                            </IconButton>

                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ pl: 4 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: neutral[600] }} variant='bodyCopyRegular'>
                                    {(grantRequest.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT) ?
                                        'By deleting the recurring grant, all the next transfers of the series will be canceled.'
                                        :
                                        'If you cancel the grant, the money will no longer be transferred to the charity.'}

                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <List className='successor' sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    < > <ListItem
                                        sx={{ display: '-webkit-inline-box' }}
                                    >
                                        <Grid alignItems='center' container>
                                            <Grid item xs={12} sm={3} textAlign={'start'}>

                                                <ListItemText sx={{ display: 'inline-grid', width: 'auto', flex: 'none' }}
                                                    primary={<Typography sx={{ color: `neutral.main` }} variant='bodyCopyBold' gutterBottom>
                                                        {grantRequest.charity.name}

                                                    </Typography>}
                                                    secondary={<Typography sx={{ color: neutral[600] }} variant='smallCopyBold' >{`By ${grantRequest.user.firstName} ${grantRequest.user.lastName}`}</Typography>}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={3} textAlign={'center'}>
                                                <Typography variant='bodyCopyRegular' sx={{ color: neutral[600] }}>{(grantRequest.charity.country === "USA") ? einFormat(grantRequest.charity.ein) : grantRequest.charity.ein}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign={'center'}>
                                                <Typography variant='bodyCopyRegular' sx={{ color: neutral[600] }}>{shortFormat(getExecutionDate(grantRequest))}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign={'center'}>
                                                {
                                                    (grantRequest.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT) ?
                                                        <ListItemText sx={{ display: 'inline-grid', width: 'auto', flex: 'none' }}
                                                            primary={<Typography sx={{ color: neutral[600] }} variant='smallCopyBold' gutterBottom>
                                                                Every

                                                            </Typography>}
                                                            secondary={<Typography sx={{ color: neutral[900] }} variant='bodyCopyRegular' >{getFrequency(grantRequest.type, (grantRequest.scheduleInformation as RecurrentSchedule).frequency)}</Typography>}
                                                        /> :
                                                        <ListItemText sx={{ display: 'inline-grid', width: 'auto', flex: 'none' }}

                                                            primary={<Typography sx={{ color: neutral[900] }} variant='bodyCopyRegular' >{`One Time`}</Typography>}
                                                        />
                                                }

                                            </Grid>
                                            <Grid item xs={11} sm={2} textAlign={'end'}>
                                                <Typography sx={{ color: neutral[900] }} variant='bodyCopyBold' gutterBottom>
                                                    <> -${moneyFormat(grantRequest.information.requestedAmount)}</>
                                                </Typography>
                                            </Grid>


                                        </Grid>
                                    </ListItem>


                                    </>

                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={'center'}>
                                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={() => onClose(false)} >
                                    Don’t cancel
                                </Button>
                                <Button variant="contained" color='primary' size='large' onClick={submit}    >
                                    Yes, cancel {(grantRequest.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT && 'recurring')} grant
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

        </>
    );
};
