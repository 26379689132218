import React, { useContext, useEffect } from 'react';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Successor, } from 'types/successor';
import Layout from 'components/layout/layout';
import { AddSuccessorContent } from 'components/successor-information/add-successor-content';
import { SUCCESSOR } from 'constants/successor';
import { useLookup } from 'api/lookupService';
import { useNavigate } from 'react-router-dom';
import { FundSettingsPage, SuccessionPlanPage } from 'constants/pages';
import { AuthContext } from 'providers/userProvider';
import { AuthData } from 'types/auth';
import { useAddSuccessor } from 'api/SuccessorService';

export const AddSuccessor = () => {
    const { selectedAccount } = useContext<AuthData>(AuthContext)
    const [id, setID] = React.useState<any>(0)
    const navigate = useNavigate()
    const [titles, setTitles] = React.useState([]);
    const setLookupData = (data: any) => {
        setTitles(data.titles)
    }
    const addSuccessorMutation = useAddSuccessor()
    useEffect(() => {
        if (selectedAccount) {
            if (selectedAccount) {
                setID(selectedAccount.fundAccountId)
            }
        }
    }, [selectedAccount])
    useLookup(setLookupData)
    const submit = (successor: Successor) => {
        addSuccessorMutation.mutate({ fundId: id, successor: successor }, {
            onSuccess: (updated) => {
                navigate(SuccessionPlanPage)
            }
        })
    };
    const close = () => {
        navigate(SuccessionPlanPage)
    };
    return (
        <Layout>
            {() => (
                <>

                    <Box sx={{ my: 4, width: '100%' }}>
                        <React.Fragment>

                            <Container sx={{ my: 3, width: '100%' }}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link underline="hover" color="inherit" onClick={() => navigate(FundSettingsPage)}>
                                        <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Fund settings</Typography>
                                    </Link>
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        onClick={() => navigate(SuccessionPlanPage)}
                                    >
                                        <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Succession</Typography>
                                    </Link>
                                    <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Add successor</Typography>
                                </Breadcrumbs>
                                <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant='h3'>
                                            Add Successor
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <AddSuccessorContent selectedSuccessor={SUCCESSOR} saveSuccessor={submit} titles={titles} close={close} />


                            </Container>

                        </React.Fragment>

                    </Box>
                </>)}
        </Layout>



    )
}
