import { Box, Typography } from "@mui/material";
import { InvestmentList } from "components/investment/investment-list";
import Layout from "components/layout/layout";
import { createSearchParams, useNavigate } from "react-router-dom";
import React from "react";
import { InvestmentsBuyRequestPagePath } from "constants/pages";
export const InvestmentsPage = () => {
  const navigate = useNavigate();

  const openRequestPage = (ticker: string): void => {
    navigate({
      pathname: InvestmentsBuyRequestPagePath,
      search: `?${createSearchParams({ ticker: ticker })}`,
    });
  };

  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 4 }}>
          <Typography sx={{ color: "neutral.main" }} variant="h3" gutterBottom>
            Investments
          </Typography>
          <Typography variant="bodyCopyRegular" paragraph>
            As an advisor, you can recommend how to invest the amounts in your
            Fund. Below are several investment options pre-approved by Neta's
            Investment Committee. If you do not find what you want, please
            contact us to discuss adding new options.
          </Typography>{" "}
          <InvestmentList buy={openRequestPage} handleOpen={handleOpen} />
        </Box>
      )}
    </Layout>
  );
};
