import { FC } from "react";
import { Typography } from "@mui/material";

import { GrantVestment } from "types/grant-vestment";


interface GrantVestmentsDetailsTitlesProps {
    grantvestment: GrantVestment,
};

const GrantVestmentsDetailsTitles: FC<GrantVestmentsDetailsTitlesProps> = ({ grantvestment }) => {

    return (
        <>
            <Typography sx={{ color: "neutral.main", mb: 2 }} className="title" variant='subtitle2' gutterBottom>
                {grantvestment?.category.categoryName}
            </Typography>
            <Typography sx={{ color: "neutral.main" }} variant='h3' gutterBottom>
                {grantvestment?.name}
            </Typography>
            <Typography variant='bodyCopyRegular' paragraph>
                {grantvestment?.description}
            </Typography>
            <Typography variant='bodyCopyBold' sx={{ color: "neutral.main", mb: 5 }} >
                {grantvestment?.projectBy}

            </Typography>
        </>


    );
}

export default GrantVestmentsDetailsTitles;
