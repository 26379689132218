import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ErrorIcon } from "./../../../assets/Icons/error.svg";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

import {
  useGetGrantRequestQuery,
  useUpdateIGrantRequestMutation,
} from "hooks/use-services/grant-request.service";
import { MyGrantsRequestsPagePath } from "constants/pages";
import { neutral, primary } from "style/color";
import {
  CashInfo,
  GRANT_FREQUENCY_ENUM,
  GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  GrantRequest,
  InvestmentInfo,
  OnTimeSchedule,
  RecurrentSchedule,
  RecurrentScheduleInput,
} from "types/grant-request";
import { NumericFormatCustom } from "components/shared/currency-input";
import { moneyFormat } from "utils/money-format";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useGetFundInvestmentQuery } from "hooks/use-services/investment-request.service";
import { shortFormat } from "utils/date-format";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { einFormat } from "types/charity";
export const GrantTransactionEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ticker, setTicker] = useState<string | null>(null);
  const {
    data: grantRequest,
    isLoading,
    isSuccess,
  } = useGetGrantRequestQuery(id ? +id : 0);
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const { data: selectedFundInvestment } = useGetFundInvestmentQuery(
    selectedAccount?.fundAccountId || 0,
    ticker!
  );

  const [updatedGrantRequest, setGrantRequest] = useState<GrantRequest | null>(
    null
  );
  const [amountChanged, setAmountChanged] = useState<boolean>(false);
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [investmentAmountError, setInvestmentAmountError] =
    useState<boolean>(false);
  const { mutate } = useUpdateIGrantRequestMutation(
    id ? +id : 0,
    selectedAccount?.fundAccountId || 0
  );
  const handleInformationChange = (key: string, value: any) => {
    setInvestmentAmountError(false);
    setError(false);
    if (key === "requestedAmount") {
      setAmountChanged(true);
      setError(
        value > selectedAccount?.availableBalance! ||
        value === "" ||
        value === "0"
      );
    }
    if (updatedGrantRequest) {
      setGrantRequest({
        ...updatedGrantRequest,
        information: {
          ...updatedGrantRequest?.information,
          [key]: value,
        },
      });
    }
  };
  const handleScheduleInformationChange = (key: string, value: any) => {
    if (key === "endingOn" || key === "frequency") {
      setDateChanged(true);
    }
    if (updatedGrantRequest) {
      setGrantRequest({
        ...updatedGrantRequest,
        scheduleInformation: {
          ...updatedGrantRequest?.scheduleInformation,
          [key]: value,
        },
      });
    }
  };
  const handleRequestedAmountChange = (value: number) => {
    if (selectedFundInvestment?.investment?.marketPrice) {
      const units = value / selectedFundInvestment?.investment?.marketPrice;
      if (updatedGrantRequest) {
        setGrantRequest({
          ...updatedGrantRequest,
          information: {
            ...updatedGrantRequest?.information,
            requestedAmount: value,
            units: units,
          },
        });
      }
      const availableBalance =
        selectedFundInvestment?.investment?.marketPrice *
        selectedFundInvestment?.totalApprovedUnits;
      setInvestmentAmountError(value > availableBalance);
    }
  };
  const handleRequestedUnitsChange = (value: number) => {
    if (selectedFundInvestment?.investment?.marketPrice) {
      const amount = value * selectedFundInvestment?.investment?.marketPrice;
      if (updatedGrantRequest) {
        setGrantRequest({
          ...updatedGrantRequest,
          information: {
            ...updatedGrantRequest?.information,
            requestedAmount: amount,
            units: value,
          },
        });
      }
      const availableBalance =
        selectedFundInvestment?.investment?.marketPrice *
        selectedFundInvestment?.totalApprovedUnits;
      setInvestmentAmountError(amount > availableBalance);
      setError(amount > availableBalance);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (grantRequest) {
      setGrantRequest(grantRequest);
      if (
        grantRequest.transactionType ===
        GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT
      ) {
        setTicker((grantRequest.information as InvestmentInfo).ticker);
      }
    }
  }, [grantRequest]);
  const submit = (event: any) => {
    event.preventDefault();
    if (updatedGrantRequest) {
      mutate(updatedGrantRequest, {
        onSuccess: (data) => navigate(-1),
      });
    }
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Box sx={{ mt: 3, mx: 3 }}>
                <BackToPreviousButton />
                <Box
                  component="form"
                  autoComplete="off"
                  onSubmit={(event: any) => submit(event)}
                >
                  <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h3">Edit grant</Typography>
                    </Grid>
                    {isLoading && (
                      <Grid item xs={12} sm={10} textAlign={"center"}>
                        {" "}
                        <CircularProgress color="primary" />
                      </Grid>
                    )}
                    {isSuccess && grantRequest && updatedGrantRequest && (
                      <Grid item xs={12} sm={10}>
                        <Card sx={{ minWidth: 275, p: 2 }}>
                          <CardContent sx={{ pt: 0 }}>
                            <Grid sx={{ mt: 1 }} container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: neutral[900] }}
                                >
                                  Details
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  variant="subtitle3"
                                  color={neutral[900]}
                                  gutterBottom
                                >
                                  Charity information
                                </Typography>
                                <Typography variant="h3" color={neutral[900]}>
                                  {grantRequest?.charity.name}
                                </Typography>
                                <Typography
                                  variant="bodyCopyRegular"
                                  color={neutral[600]}
                                >
                                  {(grantRequest?.charity.country === "USA") ? einFormat(grantRequest?.charity.ein) : grantRequest?.charity.ein}
                                </Typography>
                              </Grid>

                              {grantRequest.transactionType ===
                                GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH && (
                                  <Grid item xs={12} sm={8}>
                                    <FormControl fullWidth>
                                      <InputLabel shrink={false}>
                                        Edit amount{" "}
                                      </InputLabel>
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Grid item xs={1} textAlign={"center"}>
                                          <Typography
                                            sx={{ color: "neutral.main" }}
                                            variant="subtitle3"
                                            gutterBottom
                                          >
                                            $
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={11} sm={5}>
                                          <TextField
                                            size="small"
                                            sx={{ width: "100%" }}
                                            name="requestedAmount"
                                            value={
                                              (
                                                updatedGrantRequest?.information as CashInfo
                                              ).requestedAmount
                                            }
                                            onChange={(event) =>
                                              handleInformationChange(
                                                "requestedAmount",
                                                event.target.value
                                              )
                                            }
                                            id="formatted-numberformat-input"
                                            InputProps={{
                                              inputComponent:
                                                NumericFormatCustom as any,
                                            }}
                                            required
                                            variant="outlined"
                                          />
                                        </Grid>
                                        {amountChanged && (
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              sx={{ color: neutral[500], mb: 0 }}
                                              className="title"
                                              variant="smallCopy"
                                              paragraph
                                            >
                                              Available balance
                                            </Typography>
                                            <Typography
                                              variant="bodyCopyBold"
                                              sx={{ color: neutral[500] }}
                                            >
                                              $
                                              {moneyFormat(
                                                selectedAccount?.availableBalance ||
                                                0
                                              )}
                                            </Typography>
                                          </Grid>
                                        )}

                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={11}>
                                          {error && (
                                            <FormHelperText sx={{ m: 0 }}>
                                              {" "}
                                              <ErrorIcon /> Not enough funds
                                              available.{" "}
                                            </FormHelperText>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                )}
                              {grantRequest.transactionType ===
                                GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
                                  <>
                                    {selectedFundInvestment && (
                                      <>
                                        <Grid sx={{ mt: 4, pl: 0 }} item xs={12}>
                                          <Typography
                                            variant="subtitle3"
                                            color={neutral[900]}
                                          >
                                            Edit amount
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Grid
                                            container
                                            alignItems="center"
                                            spacing={2}
                                            sx={{
                                              pt: 1,
                                              pb: 2,
                                              border: `1px solid ${neutral[400]}`,
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <Grid
                                              sx={{
                                                display: "grid",
                                                justifyContent: "start",
                                              }}
                                              item
                                              xs={12}
                                              sm={4}
                                            >
                                              <Typography
                                                variant="smallCopyBold"
                                                color={neutral[600]}
                                              >
                                                {
                                                  selectedFundInvestment
                                                    .investment.ticker
                                                }
                                              </Typography>
                                              <Typography
                                                variant="subtitle3"
                                                color={neutral[900]}
                                              >
                                                {
                                                  selectedFundInvestment
                                                    .investment.name
                                                }
                                              </Typography>
                                              <Typography
                                                variant="smallCopyBold"
                                                color={neutral[600]}
                                              >
                                                Available $
                                                {moneyFormat(
                                                  selectedFundInvestment.totalApprovedUnits *
                                                  selectedFundInvestment
                                                    .investment.marketPrice
                                                )}
                                              </Typography>
                                            </Grid>
                                            {(
                                              updatedGrantRequest.information as InvestmentInfo
                                            ).interestedIn === "UNITS" ? (
                                              <>
                                                <Grid item xs={11} sm={3}>
                                                  <TextField
                                                    required
                                                    helperText={
                                                      investmentAmountError && (
                                                        <>
                                                          <ErrorIcon /> Not enough
                                                          units available
                                                        </>
                                                      )
                                                    }
                                                    error={investmentAmountError}
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Units"
                                                    value={moneyFormat(
                                                      (
                                                        updatedGrantRequest.information as InvestmentInfo
                                                      ).units || 0,
                                                      4,
                                                      0
                                                    )}
                                                    onChange={(e) =>
                                                      handleRequestedUnitsChange(
                                                        parseFloat(e.target.value)
                                                      )
                                                    }
                                                    InputProps={{
                                                      inputComponent: NumericFormatCustom as any,
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={1}
                                                  textAlign={"center"}
                                                >
                                                  <Typography
                                                    sx={{ color: "neutral.main" }}
                                                    variant="subtitle3"
                                                    gutterBottom
                                                  >
                                                    units
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                  <IconButton
                                                    onClick={(event) =>
                                                      handleInformationChange(
                                                        "interestedIn",
                                                        "AMOUNT"
                                                      )
                                                    }
                                                    sx={{
                                                      backgroundColor:
                                                        primary[500],
                                                      color: "#fff",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          primary[800],
                                                      },
                                                    }}
                                                  >
                                                    <SyncAltIcon />
                                                  </IconButton>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={10}
                                                  sm={3}
                                                  textAlign={"start"}
                                                >
                                                  <Typography
                                                    variant="subtitle3"
                                                    color={neutral[900]}
                                                    gutterBottom
                                                  >
                                                    $
                                                    {moneyFormat(
                                                      (
                                                        updatedGrantRequest.information as InvestmentInfo
                                                      ).requestedAmount
                                                    )}
                                                    *
                                                  </Typography>
                                                </Grid>
                                              </>
                                            ) : (
                                              <>
                                                <Grid
                                                  item
                                                  xs={1}
                                                  textAlign={"center"}
                                                >
                                                  <Typography
                                                    sx={{ color: "neutral.main" }}
                                                    variant="subtitle3"
                                                    gutterBottom
                                                  >
                                                    $
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={11} sm={3}>
                                                  <TextField
                                                    required
                                                    error={investmentAmountError}
                                                    helperText={
                                                      investmentAmountError && (
                                                        <>
                                                          <ErrorIcon /> Not enough
                                                          units available
                                                        </>
                                                      )
                                                    }
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder={"Amount"}
                                                    value={moneyFormat(
                                                      (
                                                        updatedGrantRequest.information as InvestmentInfo
                                                      ).requestedAmount,
                                                      2,
                                                      0
                                                    )}
                                                    onChange={(e) =>
                                                      handleRequestedAmountChange(
                                                        parseFloat(e.target.value)
                                                      )
                                                    }
                                                    InputProps={{
                                                      inputComponent:
                                                        NumericFormatCustom as any,
                                                    }}
                                                  />
                                                </Grid>

                                                <Grid item xs={2} sm={1}>
                                                  <IconButton
                                                    onClick={(event) =>
                                                      handleInformationChange(
                                                        "interestedIn",
                                                        "UNITS"
                                                      )
                                                    }
                                                    sx={{
                                                      backgroundColor:
                                                        primary[500],
                                                      color: "#fff",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          primary[800],
                                                      },
                                                    }}
                                                  >
                                                    <SyncAltIcon />
                                                  </IconButton>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={10}
                                                  sm={3}
                                                  textAlign={"start"}
                                                >
                                                  <Typography
                                                    variant="subtitle3"
                                                    color={neutral[900]}
                                                    gutterBottom
                                                  >
                                                    {moneyFormat(
                                                      (
                                                        updatedGrantRequest.information as InvestmentInfo
                                                      ).units,
                                                      4
                                                    )}
                                                    * units
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          sx={{
                                            display: "grid",
                                            justifyContent: "end",
                                          }}
                                          item
                                          xs={12}
                                          sm={12}
                                        >
                                          <Typography
                                            sx={{ textAlign: "end" }}
                                            className="title"
                                            variant="smallCopy"
                                            color={neutral[600]}
                                          >
                                            Total amount
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            color={neutral[900]}
                                          >
                                            -$
                                            {moneyFormat(
                                              updatedGrantRequest.information
                                                .requestedAmount
                                            )}{" "}
                                            USD
                                          </Typography>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                )}
                              {grantRequest.type ===
                                GRANT_REQUEST_TYPE_ENUM.ONETIME &&
                                !(
                                  grantRequest.scheduleInformation as OnTimeSchedule
                                ).now && (
                                  <Grid item xs={12} sm={5}>
                                    <FormControl fullWidth>
                                      <InputLabel shrink={false}>
                                        Schedule for{" "}
                                      </InputLabel>

                                      <TextField
                                        size="small"
                                        sx={{ width: "100%" }}
                                        name="executionDate"
                                        value={
                                          (
                                            updatedGrantRequest?.scheduleInformation as OnTimeSchedule
                                          ).executionDate
                                        }
                                        onChange={(event) =>
                                          handleScheduleInformationChange(
                                            "executionDate",
                                            event.target.value
                                          )
                                        }
                                        type="date"
                                        required
                                        variant="outlined"
                                      />
                                    </FormControl>
                                  </Grid>
                                )}

                              {grantRequest.type ===
                                GRANT_REQUEST_TYPE_ENUM.RECURRENT && (
                                  <>
                                    <Grid item xs={12} sm={12}>
                                      <Typography
                                        variant="subtitle3"
                                        sx={{ color: neutral[900], mb: 0 }}
                                        paragraph
                                      >
                                        Custom recurrence
                                      </Typography>
                                      <Typography
                                        variant="bodyCopyRegular"
                                        sx={{ color: neutral[900] }}
                                      >
                                        Next grant planned on:
                                        <Typography
                                          variant="bodyCopyBold"
                                          sx={{ color: neutral[900] }}
                                        >
                                          {shortFormat(
                                            (
                                              grantRequest.scheduleInformation as RecurrentSchedule
                                            ).nextExecutionDate
                                          )}{" "}
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                    {dateChanged && (
                                      <Grid item xs={3}>
                                        <FormControl fullWidth>
                                          <InputLabel shrink={false}>
                                            Starting on
                                          </InputLabel>
                                          <OutlinedInput
                                            fullWidth
                                            name="nextExecutionDate"
                                            value={new Date(
                                              (
                                                updatedGrantRequest.scheduleInformation as RecurrentSchedule
                                              ).nextExecutionDate
                                            )
                                              .toISOString()
                                              .slice(0, 10)}
                                            onChange={(event) =>
                                              handleScheduleInformationChange(
                                                "nextExecutionDate",
                                                event.target.value
                                              )
                                            }
                                            required
                                            type="Date"
                                            size="small"
                                          />
                                        </FormControl>
                                      </Grid>
                                    )}

                                    <Grid item xs={3}>
                                      <FormControl fullWidth>
                                        <InputLabel shrink={false}>
                                          Ending on
                                        </InputLabel>
                                        <OutlinedInput
                                          fullWidth
                                          name="endingOn"
                                          value={
                                            (
                                              updatedGrantRequest.scheduleInformation as RecurrentScheduleInput
                                            ).endingOn
                                          }
                                          onChange={(event) =>
                                            handleScheduleInformationChange(
                                              "endingOn",
                                              event.target.value
                                            )
                                          }
                                          required
                                          type="Date"
                                          size="small"
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <FormControl fullWidth>
                                        <InputLabel shrink={false} id="title">
                                          Frequency
                                        </InputLabel>
                                        <Select
                                          id="frequency-select"
                                          size="small"
                                          name="frequency"
                                          value={
                                            (
                                              updatedGrantRequest.scheduleInformation as RecurrentScheduleInput
                                            ).frequency
                                          }
                                          onChange={(event) =>
                                            handleScheduleInformationChange(
                                              "frequency",
                                              event.target.value
                                            )
                                          }
                                        >
                                          <MenuItem
                                            value={GRANT_FREQUENCY_ENUM.YEARLY}
                                          >
                                            {"Yearly"}
                                          </MenuItem>
                                          <MenuItem
                                            value={GRANT_FREQUENCY_ENUM.QUARTERLY}
                                          >
                                            {"Quarterly"}
                                          </MenuItem>
                                          <MenuItem
                                            value={GRANT_FREQUENCY_ENUM.MONTHLY}
                                          >
                                            {"Monthly"}
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </>
                                )}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={10}>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          sx={{ mr: 2 }}
                          variant="text"
                          color="primary"
                          size="large"
                          onClick={() => navigate(MyGrantsRequestsPagePath)}
                        >
                          Cancel
                        </Button>
                        {
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            disabled={error}
                          >
                            Save
                          </Button>
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
