import React, { useContext } from "react";
import { Box } from "@mui/material";
import Layout from "components/layout/layout";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import BalanceBox from "components/shared/balance-box";
import { MovementsCard } from "./movements-card";

export const MyCashPage = () => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Box sx={{ mt: 3, mx: 3 }}>
                <BalanceBox
                  name={" My Cash"}
                  showRequestsButtons={true}
                  total={selectedAccount?.total as number}
                  available={selectedAccount?.availableBalance as number}
                />
                <MovementsCard title="Movements" limit={6}></MovementsCard>
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
