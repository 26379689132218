import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import {
  SUCCESSOR_TYPE,
  Successor,
  SuccessorCharity,
  SuccessorIndividual,
} from "types/successor";
import { ReactComponent as EditIcon } from "./../../assets/Icons/edit.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/Icons/Delete.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { neutral, successAlert } from "style/color";
import { USER_ROLE } from "types/account";
interface SuccessorListProps {
  successors: Successor[];
  openEdit: (successor: Successor, index: number) => void;
  removeSuccessors: (index: number) => void;
  updateSuccessorPercentage: (successors: Successor[]) => void;
  getTotal: () => number;
  role?: USER_ROLE;
}
export const SuccessorList: React.FC<SuccessorListProps> = ({
  successors,
  openEdit,
  removeSuccessors,
  updateSuccessorPercentage,
  getTotal,
  role,
}) => {
  const updateSuccessor = (index: number, value: number) => {
    let successorsList: Successor[] = successors;
    successorsList[index].percentage = +value;
    updateSuccessorPercentage(successorsList);
  };
  return (
    <>
      <Grid item xs={12} sm={12}>
        <List
          className="successor"
          sx={{ width: "100%", bgcolor: "background.paper" }}
        >
          {successors.map((successor, index) => (
            <>
              {" "}
              <ListItem
                className="successor-item"
                sx={{ display: "-webkit-inline-box" }}
                key={index}
              >
                <Grid alignItems="center" container>
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {successor.successorType === SUCCESSOR_TYPE.CHARITY ? (
                      <ListItemText
                        sx={{
                          display: "inline-grid",
                          width: "auto",
                          flex: "none",
                        }}
                        primary={
                          <Typography
                            sx={{ color: `neutral.dark` }}
                            variant="bodyCopyBold"
                            gutterBottom
                          >
                            {(successor.successorInfo as SuccessorCharity).name}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{ color: `neutral.lighter` }}
                            variant="smallCopyBold"
                          >
                            {(successor.successorInfo as SuccessorCharity).ein}
                          </Typography>
                        }
                      />
                    ) : (
                      <ListItemText
                        sx={{ width: "auto", flex: "none" }}
                        primary={
                          <Typography
                            sx={{ color: `neutral.dark` }}
                            variant="bodyCopyBold"
                            gutterBottom
                          >
                            {
                              (successor.successorInfo as SuccessorIndividual)
                                .firstName
                            }{" "}
                            {
                              (successor.successorInfo as SuccessorIndividual)
                                .lastName
                            }
                          </Typography>
                        }
                      />
                    )}
                    <IconButton
                      aria-label="edit email"
                      edge="end"
                      onClick={() => openEdit(successor, index)}
                      disabled={role && role !== USER_ROLE.ADMIN}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <OutlinedInput
                      fullWidth
                      inputProps={{
                        style: { textAlign: "end" },
                        min: 0,
                        max: 100,
                      }}
                      sx={{ fontWeight: "700" }}
                      size="small"
                      name="percentage"
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      value={successor.percentage}
                      onChange={(event) =>
                        updateSuccessor(index, +event.target.value)
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} textAlign={"center"}>
                    <IconButton
                      aria-label="comment"
                      onClick={() => removeSuccessors(index)}
                      disabled={role && role !== USER_ROLE.ADMIN}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
              {index !== successors.length - 1 && <Divider key={index + 100} />}
            </>
          ))}
        </List>
      </Grid>
      <Grid sx={{ px: 1 }} item xs={12} sm={12}>
        <Grid alignItems="center" container spacing={1}>
          <Grid
            item
            xs={12}
            sm={11}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Typography color="primary" variant="bodyCopyBold">
              Total: {getTotal()}%{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <CheckCircleIcon
              sx={{
                color: getTotal() === 100 ? successAlert[500] : neutral[300],
                ml: 1,
              }}
              fontSize="small"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={11}
            sx={{
              display: getTotal() === 100 ? "none" : "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Typography sx={{ color: "red" }} variant="smallCopyBold" paragraph>
              {getTotal() > 100
                ? "Total must be of 100%"
                : `Missing to distribute: ${100 - getTotal()}%`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
