import React from "react";
import { Box, Button } from "@mui/material";
import { warm } from "../../../style/color";





export const Links: React.FC = () => {


  return (
    <>
      <Box sx={{ color: warm[900], flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>

        <Button
          href='/policy'
          target='_blank'
          variant="footer"
        >
          Privacy Policy
        </Button>
        <Button
          variant="footer"

        >
          Terms & conditions
        </Button>
      </Box>
    </>




  );
}
export default Links;







