import { Card, CardContent, Box, Typography, Select, MenuItem, List, listItemClasses } from "@mui/material"
import React from "react"
import { neutral, warm } from "style/color";
import { numberRange } from "utils/date-format"
interface MyGrantsRequestListProps {
    title: string,
    updateYear?: (year: number) => void;
    children: any;
    length: number


}
export const MyGrantsRequestList: React.FC<MyGrantsRequestListProps> = ({
    title,
    children,
    updateYear,
    length
}) => {
    const [year, setYear] = React.useState<number>(new Date().getFullYear())

    const handleYearChange = (year: number) => {
        setYear(year)
        updateYear && updateYear(year)

    }
    return (
        <Card sx={{ boxShadow: 'none', p: 4 }} >
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ color: `neutral.main` }} variant="subtitle3" >{title}</Typography>

                    {
                        updateYear && <Select
                            id="title-select"
                            size='small'
                            name="title"
                            value={year}
                            onChange={(event) => handleYearChange(+event.target.value)}
                        >
                            {
                                numberRange(new Date().getFullYear(), 2023, -1).map(year => (
                                    <MenuItem key={year} value={year}>
                                        {year === new Date().getFullYear() ? 'Current year' : year}
                                    </MenuItem>
                                ))
                            }

                        </Select>
                    }



                </Box>
                {
                    (length !== 0) ?
                        <List className='successor' sx={{
                            width: '100%', bgcolor: 'background.paper', mt: 2,
                            [` & .${listItemClasses.root}:hover`]: {
                                backgroundColor: warm[200],
                                cursor: 'pointer'
                            }
                        }}>
                            {children}
                        </List>
                        :
                        <Typography sx={{ mt: 4, color: neutral[600] }} variant='bodyCopyRegular'>You don't have any past grants.</Typography>

                }


            </CardContent>
        </Card>
    )
}
