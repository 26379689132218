import React, { useContext, useEffect } from 'react';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import Layout from 'components/layout/layout';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'providers/userProvider';
import { FundSettingsPage } from 'constants/pages';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { SaveButton } from 'components/shared/save-button';
import { AddInterests } from 'components/fund-information/add-intrestes';
import { AuthData } from 'types/auth';
import { useUpdateFundAccountMutation } from 'hooks/use-services/fund.service';
import { Account, USER_ROLE } from 'types/account';
import { useGetCategoriesQuery } from 'hooks/use-services/category.service';



export const InterestsSettings = () => {
  const navigate = useNavigate()
  const { userData, selectedAccount, setSelectedAccount, refresh, setRefresh, userRole } = useContext<AuthData>(AuthContext)
  const [interests, setInterests] = React.useState<number[]>([])
  const { data: categoriesData } = useGetCategoriesQuery({})
  useEffect(() => {
    if (selectedAccount?.interests) {
      setInterests(selectedAccount.interests.map((item) => item.categoryId));
    }
  }, [selectedAccount, userData?.user]);
  const { mutate: updateFundAccount } = useUpdateFundAccountMutation(
    selectedAccount?.fundAccountId || 0
  );
  const handleChange = (key: string, value: number[]) => {
    setInterests(value);
  };
  const submit = (event: any) => {
    event.preventDefault();
    updateFundAccount(
      { interests: interests },
      {
        onSuccess: (newData) => {
          setSelectedAccount(newData as Account);
          setRefresh(!refresh);
          navigate(FundSettingsPage);
        },
      }
    );
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container sx={{ mt: 3, width: "100%" }}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(FundSettingsPage)}
                  >
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      Fund settings
                    </Typography>
                  </Link>
                  <Typography
                    variant="bodyCopyBold"
                    sx={{ color: "neutral.main" }}
                  >
                    Interests
                  </Typography>
                </Breadcrumbs>
                <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h3">Interests</Typography>
                  </Grid>
                </Grid>
                <Box
                  component="form"
                  autoComplete="off"
                  onSubmit={submit}
                >
                  {
                    categoriesData?.data && categoriesData?.data.length !== 0 &&
                    <AddInterests interests={interests} categories={categoriesData?.data!} handleAccountChange={handleChange} />

                  }
                  {
                    userRole === USER_ROLE.ADMIN &&
                    <SaveButton reset={() => navigate(FundSettingsPage)} secondaryButton='Cancel' />
                  }
                </Box>
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
