import { FC, useMemo, useState } from "react";
import { Charity } from "types/charity";
import { warm } from "style/color";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useGetSavedCharitiesQuery } from "hooks/use-services/charity.service";
import CharityCard from "components/charity-card/CharityCard";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import React from "react";
import { Account } from "types/account";

interface SavedCharitiesListProps {
  selectedAccount: Account;
  limit?: number;
  viewAllSavedCharities?: boolean;
  handleViewAllSavedCharities: () => void;
  handleOpen: (value: boolean) => void;
  openRequestPage: (ein: string) => void;

}

export const SavedCharitiesList: FC<SavedCharitiesListProps> = ({
  selectedAccount,
  limit,
  viewAllSavedCharities,
  handleViewAllSavedCharities,
  handleOpen,
  openRequestPage
}) => {
  const [offset] = useState(0);
  const {
    data: savedCharities,
    fetchNextPage: fetchSavedsavedCharitiesNextPage,
    isSuccess: isGetSavedCharitiesSuccess,
    hasNextPage: hasSavedCharitiesNextPage,
    isLoading: isGetSavedCharitiesLoading,
    isFetching: isGetSavedCharitiesFetching,
    isFetchingNextPage: isGetSavedCharitiesFetchingNextPage,
    refetch,
  } = useGetSavedCharitiesQuery(
    selectedAccount?.fundAccountId
      ? {
        activeFundAccountId: selectedAccount?.fundAccountId,
        limit: limit,
        offset: offset,
      }
      : { limit: limit, offset: offset }
  );
  const savedCharitiesData = useMemo<Charity[]>(
    () =>
      savedCharities ? savedCharities?.pages?.flatMap((item) => item.data) : [],
    [savedCharities]
  );

  const lastElementRefSaved = useIntersectionObserver({
    isFetching: isGetSavedCharitiesFetching,
    isLoading: isGetSavedCharitiesLoading,
    fetchNextPage: fetchSavedsavedCharitiesNextPage,
    hasNextPage: hasSavedCharitiesNextPage,
  });

  const viewAll = () => {
    handleViewAllSavedCharities();
  };
  const refresh = () => {
    refetch();
  };

  return (
    <>
      <Divider
        sx={{ marginY: 2, backgroundColor: warm[600], borderWidth: "1px" }}
      />
      <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
        <Typography
          className="title"
          variant="subtitle2"
          sx={{ color: "neutral.main", mr: 3 }}
        >
          You Saved{" "}
        </Typography>
        <Button
          onClick={viewAll}
          className="link"
          variant="text"
          color="primary"
          size="large"
        >
          {
            viewAllSavedCharities ? 'View less' : 'View all'
          }
        </Button>
      </Box>
      <Box sx={{ marginY: 4 }}>
        {isGetSavedCharitiesSuccess && (
          <>
            <Grid sx={{ mt: 2 }} container spacing={2}>
              {savedCharitiesData?.map((charity: any) => (
                <Grid key={charity.ein} item xs={12} md={4}>
                  <CharityCard
                    selectCharity={openRequestPage}
                    handleOpen={handleOpen}
                    refresh={refresh}
                    charity={charity}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {viewAllSavedCharities && (
          <Box ref={lastElementRefSaved}>
            {isGetSavedCharitiesFetchingNextPage && (
              <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
