import apiHttpClient from "libs/api-http-client";
import {
  FutureGrantRequest,
  GrantRequest,
  GrantRequestInput,
  ScheduledGrantRequest,
} from "types/grant-request";
import PaginatedEntityList from "types/PaginatedEntityList";

export const makeGrantRequestAPI = (
  ein: string,
  data: GrantRequestInput
): Promise<unknown> =>
  apiHttpClient.post(
    `/grant-request/${encodeURIComponent(ein)}/requests`,
    data
  );
export const updateGrantRequestAPI = (
  grantRequestId: number,
  fundId: number,
  data: GrantRequest
): Promise<unknown> =>
  apiHttpClient.patch(`/funds/${fundId}/grant-request/${grantRequestId}`, data);

export interface GetGrantRequestsQuery {
  offset?: number;
  limit?: number;
  filter?: {
    fundAccountId?: number;
    status?: string;
    statuses?: string[];
    type?: string;
    transactionType?: string;
  };
  dateTimeRange?: {
    startsAt?: Date;
    endsAt?: Date;
  };
}
export interface GetScheduledGrantRequestsQuery {
  offset?: number;
  limit?: number;
  filter?: {
    grantRequestId?: number;
    grantRequestIds?: number[];
    fundAccountId?: number;
    status?: string;
    statuses?: string[];
  };
  dateTimeRange?: {
    startsAt?: Date;
    endsAt?: Date;
  };
}
export const getGrantRequestsAPI = (
  query: GetGrantRequestsQuery
): Promise<PaginatedEntityList<GrantRequest>> =>
  apiHttpClient.get(`/grant-requests`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      filter: {
        fundAccountId: query.filter?.fundAccountId,
        status: query.filter?.status,
        statuses: query.filter?.statuses,
        transactionType: query.filter?.transactionType,
      },
      dateTimeRange: {
        startsAt: query.dateTimeRange?.startsAt,
        endsAt: query.dateTimeRange?.endsAt,
      },
    },
  });
export const getFutureGrantRequestsAPI = (
  query: GetGrantRequestsQuery
): Promise<PaginatedEntityList<FutureGrantRequest>> =>
  apiHttpClient.get(`/future-grant-requests`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      filter: {
        fundAccountId: query.filter?.fundAccountId,
        statuses: query.filter?.statuses,
      },
      dateTimeRange: {
        startsAt: query.dateTimeRange?.startsAt,
        endsAt: query.dateTimeRange?.endsAt,
      },
    },
  });
export const GetScheduledGrantRequestsApi = (
  query: GetScheduledGrantRequestsQuery
): Promise<PaginatedEntityList<ScheduledGrantRequest>> =>
  apiHttpClient.get(`/scheduled-grant-requests`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      filter: {
        fundAccountId: query.filter?.fundAccountId,
        status: query.filter?.status,
        statuses: query.filter?.statuses,
        grantRequestId: query.filter?.grantRequestId,
        grantRequestIds: query.filter?.grantRequestIds,
      },
      dateTimeRange: {
        startsAt: query.dateTimeRange?.startsAt,
        endsAt: query.dateTimeRange?.endsAt,
      },
    },
  });
export const getGrantRequestAPI = (
  grantRequestId: number
): Promise<GrantRequest> =>
  apiHttpClient.get(`/grant-requests/${grantRequestId}`);

export const updateGrantRequestStatusAPI = (
  grantRequestId: number,
  data: {
    status: string;
  }
): Promise<unknown> => {
  return apiHttpClient.patch(`/grant-requests/${grantRequestId}`, data);
};

export const getScheduledGrantRequestAPI = (
  scheduledGrantRequestId: number
): Promise<ScheduledGrantRequest> =>
  apiHttpClient.get(`/scheduled-grant-requests/${scheduledGrantRequestId}`);
