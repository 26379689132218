import apiHttpClient from "libs/api-http-client";
import { FundFee } from "types/fund-fee";

export interface GetFundFee {
  fundAccountId: number;
  feeId: number;
}
export const getFundFeeAPI = (input: GetFundFee): Promise<FundFee> =>
  apiHttpClient.get(`fund-fees`, {
    params: {
      fundAccountId: input.fundAccountId,
      feeId: input.feeId,
    },
  });
