import React, { FormEvent } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import { getPercentageTotal } from "types/successor";
import { FundDetails } from "components/fund-information/fund-details";
import { EntityInformation } from "components/fund-information/entity-information";
import { InviteAdvisors } from "components/fund-information/invite-advisors";
import { AddInterests } from "components/fund-information/add-intrestes";
import { AccountInput, Entity } from "types/account";
import { Category } from "types/category";
import { UserError } from "types/user";
import { SignUpSuccessionPlan } from "components/successor-information/signup-succession-plan";
interface FundProps {
  account: AccountInput;
  entity: Entity;
  types: string[];
  titles: string[];
  categories: Category[];
  error: UserError;
  handleAccountChange: (key: string, value: any) => void;
  handleEntityChange: (key: string, value: any) => void;
  handleInvalid: (event: FormEvent<HTMLElement>) => void;
  handleAddressChange: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}
export const Fund: React.FC<FundProps> = ({
  account,
  handleAccountChange,
  entity,
  handleEntityChange,
  handleAddressChange,
  handleSubmit,
  handleInvalid,
  error,
  types,
  categories,
  titles,
}) => {
  const [acceptPolicy, setAcceptPolicy] = React.useState(false);
  const getTotal = () => {
    return getPercentageTotal(account?.successors!);
  };
  return (
    <>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={(event) => handleSubmit(event)}
      >
        <Typography variant="h3">Fund Information</Typography>
        <Typography variant="bodyCopyRegular">
          Please complete all mandatory fields (*).
        </Typography>
        <div>
          <FundDetails
            account={account}
            handleAccountChange={handleAccountChange}
            handleInvalid={handleInvalid}
            error={error}
          />
          {account.fundAccountType === "ENTITY" && (
            <EntityInformation
              entity={entity}
              types={types}
              error={error}
              handleEntityChange={handleEntityChange}
              handleInvalid={handleInvalid}
              handleAddressChange={handleAddressChange}
            ></EntityInformation>
          )}
          <SignUpSuccessionPlan
            successorsList={account?.successors!}
            titles={titles}
            handleAccountChange={handleAccountChange}
          />
          <InviteAdvisors
            invitations={account?.invitations!}
            handleAccountChange={handleAccountChange}
          />
          <AddInterests
            categories={categories}
            handleAccountChange={handleAccountChange}
          />
          <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                control={
                  <Checkbox
                    sx={{ pt: 0 }}
                    color="secondary"
                    value={acceptPolicy}
                    name="one "
                    onChange={(event) => setAcceptPolicy(event.target.checked)}
                  />
                }
                label={
                  <>
                    <Typography variant="bodyCopyRegular">
                      By checking this box, you confirm that you have read{" "}
                    </Typography>
                    <Typography variant="bodyCopyBold">
                      <Link
                        href='/agreement-policies'
                        target='_blank'
                        underline="always"
                        color={"inherit"}
                        sx={{
                          mr: 1,
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        Neta’s DAF Program Guidelines
                      </Link>
                    </Typography>
                    <Typography variant="bodyCopyRegular">
                      and agree to be bound by its terms and conditions. You
                      further certify, to the best of your knowledge, that all
                      the information entered is accurate, and will promptly
                      notify Neta of any changes.{" "}
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        </div>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={
              !acceptPolicy ||
              (account.successors!.length !== 0 && getTotal() !== 100)
            }
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </>
  );
};
