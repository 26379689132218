import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Personal } from "./personal";
import { useParams, useLocation } from "react-router-dom";
import { Fund } from "./fund";
import { ACCOUNT_TYPE, AccountInput, USER_ROLE } from "types/account";
import { User, UserError } from "types/user";
import { useLookup } from "api/lookupService";
import Layout from "components/layout/layout";
import { USER_ERROR, USER } from "constants/user";
import { useSignupUserMutation } from "hooks/use-services/user.service";

export const SignUp = () => {
  const addUserMutation = useSignupUserMutation();
  const [titles, setTitles] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const setLookupData = (data: any) => {
    setTitles(data.titles);
    setCategories(data.categories.data);
    setTypes(data.types);
  };
  useLookup(setLookupData);
  const { id } = useParams();
  const location = useLocation();
  const steps = [
    "Personal information",
    "Fund information",
    "Approval by Neta",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [scroll, setScroll] = React.useState(false);
  const [error, setError] = React.useState<UserError>(USER_ERROR);
  const [user, setUser] = React.useState<User>(USER);
  const [account, setAccount] = React.useState<AccountInput>({
    fundAccountName: "",
    fundAccountType: ACCOUNT_TYPE.PERSONAL,
    role: USER_ROLE.ADMIN,
    successors: [],
    interests: [],
    invitations: [],
  });
  const [entity, setEntity] = React.useState({
    entityName: "",
    zip: "",
    city: "",
    state: "",
    firstAddressLine: "",
    secondAddressLine: "",
    type: "",
    role: "",
  });
  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const ToSignIn = (handleOpen: (value: boolean) => void) => {
    if (activeStep === 2) {
      handleOpen(false);
    }
  };
  const change = (
    key: string,
    value: any,
    object: any,
    setObject: (item: any) => void
  ) => {
    setScroll(false);
    setError({ ...error, [key]: false });
    setObject({ ...object, [key]: value });
  };
  const handleChange = (key: string, value: any) => {
    change(key, value, user, setUser);
  };
  const handleAccountChange = (key: string, value: any) => {
    change(key, value, account, setAccount);
  };
  const handleEntityChange = (key: string, value: any) => {
    change(key, value, entity, setEntity);
  };
  const handleAddressChange = () => {
    setScroll(false);
    setError({
      ...error,
      firstAddressLine: false,
      secondAddressLine: false,
      city: false,
      state: false,
      zip: false,
    } as any);
    setEntity({
      ...entity,
      firstAddressLine: user.addressLine1,
      secondAddressLine: user.addressLine2,
      city: user.city,
      state: user.state,
      zip: user.zip,
    });
  };
  const handleInvalid = (event: any) => {
    if (!scroll) {
      event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      setScroll(true);
    } else {
      event.preventDefault();
    }
    setError({
      ...error,
      [event.target.name]: true,
    });
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const fundAccount =
      account.fundAccountType === ACCOUNT_TYPE.ENTITY
        ? { ...account, entityInformation: entity }
        : account;
    const userWithFund = {
      ...user,
      email: location.state.email,
      sub: id!,
      account: fundAccount,
      interests: fundAccount.interests,
    };
    addUserMutation.mutate(userWithFund as any, {
      onSuccess: () => handleNext(),
    });
  };

  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Container>
          <Box sx={{ my: 4, width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>
                      <Typography variant="smallCopy" color="neutral">
                        Step {index + 1}
                      </Typography>
                      <br />
                      <Typography variant="subtitle3">{label}</Typography>
                      {activeStep === 2 && index === 2 && (
                        <Typography
                          sx={{ mt: 1 }}
                          variant="smallCopy"
                          paragraph
                        >
                          pending (1 to 3 days)
                        </Typography>
                      )}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              <Container sx={{ my: 8, width: "100%" }}>
                {activeStep === 0 && (
                  <Personal
                    error={error}
                    handleInvalid={handleInvalid}
                    handleNext={handleNext}
                    user={user}
                    account={account}
                    handleChange={handleChange}
                    handleAccountChange={handleAccountChange}
                    titles={titles}
                  />
                )}
                {activeStep === 1 && (
                  <Fund
                    error={error}
                    handleInvalid={handleInvalid}
                    handleSubmit={handleSubmit}
                    account={account}
                    entity={entity}
                    handleAccountChange={handleAccountChange}
                    handleEntityChange={handleEntityChange}
                    handleAddressChange={handleAddressChange}
                    types={types}
                    categories={categories}
                    titles={titles}
                  />
                )}

                {activeStep === 2 && (
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Box mx={2} pt={3}>
                        <Typography variant="h3">Thank you</Typography>
                      </Box>
                      <Box m={2}>
                        <Typography sx={{ mt: 2 }} variant="bodyCopyRegular">
                          Our team will review it as soon as possible and get back to you.{" "}
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              ToSignIn(handleOpen);
                            }}
                            underline="always"
                            color={"inherit"}
                            sx={{
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            Sign In
                          </Link>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </Container>
      )}
    </Layout>
  );
};
