import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { neutral, primary, warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  InvestmentsBuyRequestPagePath,
  InvestmentsSellRequestPagePath,
  MyInvestmentsRequestsPage,
} from "constants/pages";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";

interface InvestmentRequestAssetCardProps {
  name: string;
  ticker: string;
  marketPrice: number;
  dailyChange: number;
  totalApprovedUnits: number;
  totalPendingUnits: number;
  role: USER_ROLE,
  userStatus: USER_STATUS,
  isAFP: boolean,
}

export const InvestmentRequestAssetCard: React.FC<
  InvestmentRequestAssetCardProps
> = ({ marketPrice, ticker, name, totalApprovedUnits, totalPendingUnits, dailyChange, userStatus, role, isAFP }) => {
  const navigate = useNavigate();
  const units = totalApprovedUnits + totalPendingUnits;

  return (
    <Card
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        display: "flex",
        my: 2,
      }}
    >
      <CardActionArea
        sx={{
          "& :hover": {
            backgroundColor: warm[200],
            cursor: 'pointer'
          },
        }}
        onClick={() => {
          ticker &&
            navigate({
              pathname: `${MyInvestmentsRequestsPage}/${ticker}`,
            });
        }}
      >
        <CardContent sx={{ p: 5, "&:last-child": { pb: 5 }, width: "100%" }}>
          <Grid container alignItems={"center"}>
            <Grid
              item
              xs={12}
              md={2}
            >
              <Typography variant="bodyCopyBold" sx={{ color: neutral[900] }}>
                {name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
            >
              <Typography variant="bodyCopyBold" sx={{ color: neutral[900] }}>
                {ticker}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
            >
              <Typography variant="bodyCopyBold" sx={{ color: neutral[900] }}>
                {moneyFormat(units, 4)}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              xs={12}
              md={2}
            >
              <Box>
                <Typography
                  variant="bodyCopyBold"
                  sx={{
                    color: neutral[900],
                  }}
                >
                  ${moneyFormat(marketPrice)}
                </Typography>
                <Typography
                  variant="bodyCopyRegular"
                  sx={{
                    color: neutral[600],
                    display: "flex",
                    alignItems: "center",
                  }}
                  gutterBottom
                >
                  <ArrowOutwardIcon fontSize="small" />{" "}
                  {dailyChange >= 0
                    ? `${moneyFormat(dailyChange, 2)}`
                    : moneyFormat(dailyChange, 2)}
                  %
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
            >
              <Typography
                variant='subtitle1'
                component={"div"}
                sx={{
                  color: totalPendingUnits !== 0 ? neutral[500] : neutral[900],
                }}
              >
                ${moneyFormat(units * marketPrice)}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2.5,
                alignSelf: "center",
                justifyContent: "flex-end",
              }}
              xs={12}
              md={2}
            >
              <IconButton
                sx={{ backgroundColor: primary[500] }}
                disabled={role === USER_ROLE.VIEWER || userStatus === USER_STATUS.PENDING || !isAFP}

                onClick={(event) => {
                  event.stopPropagation();
                  ticker &&
                    navigate({
                      pathname: InvestmentsBuyRequestPagePath,
                      search: `?${createSearchParams({ ticker: ticker })}`,
                    });
                }}
              >
                <AddIcon sx={{ color: "white" }} />

              </IconButton>
              <IconButton
                sx={{ backgroundColor: primary[500] }}
                disabled={role === USER_ROLE.VIEWER || userStatus === USER_STATUS.PENDING}

                onClick={(event) => {
                  event.stopPropagation();
                  ticker &&
                    navigate({
                      pathname: InvestmentsSellRequestPagePath,
                      search: `?${createSearchParams({ ticker: ticker })}`,
                    });
                }}
              >
                <RemoveIcon sx={{ color: "white" }} />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
