import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Layout from "components/layout/layout";
import { FundSettingsPage } from "constants/pages";
import { useNavigate } from "react-router-dom";
import { CustomSwitch } from "components/shared/custom-switch";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import {
  useGetUserNotificationQuery,
  useUpdateUserNotificationMutation,
} from "hooks/use-services/user-notifcation.service";
import { UserNotification } from "types/user-notification";
export const EmailSettings = () => {
  const { userData, selectedAccount } = useContext<AuthData>(AuthContext);
  const { data, isLoading } = useGetUserNotificationQuery({
    fundId: selectedAccount?.fundAccountId || 0,
    userId: userData?.user?.userId || 0,
  });
  const { mutate } = useUpdateUserNotificationMutation();

  const navigate = useNavigate();
  const [enableNotification, setEnableNotification] = useState<boolean>(true);
  const [emailSettings, setEmailSettings] = useState<Partial<UserNotification>>(
    {
      contributionRequestConfirmation: true,
      contributionRequestExecution: true,
      contributionRequestCancellation: false,
      reminderPendingContributionRequest: true,
      grantVestmentRequestConfirmation: true,
      grantVestmentRequestApproval: false,
      grantVestmentRequestDeclination: true,
      additionGrantVestmentRequestOfInterest: false,
      updateFollowedGrantVestment: true,
      reminderToBackFollowedGrantVestment: false,
      followedGrantVestmentReachGoal: false,
      backedGrantVestmentReachGoal: true,
      backedGrantVestmentDoNotReachGoal: true,
      investmentRequestConfirmation: true,
      investmentRequestApproval: false,
      investmentRequestDeclined: true,
      grantRequestConfirmation: true,
      grantRequestApproval: false,
      grantRequestDeclined: true,
    }
  );

  useEffect(() => {
    if (data) {
      setEnableNotification(!data.disableAll);
      setEmailSettings(data)
    }
  }, [data])
  const handelEmailSettingsChange = (key: string, value: boolean) => {
    setEmailSettings({
      ...emailSettings,
      [key]: value
    })
  }
  const handelEnableEmailSettingsChange = (value: boolean) => {
    setEnableNotification(value)
    if (!value) {
      setEmailSettings({
        ...emailSettings,
        contributionRequestConfirmation: false,
        contributionRequestExecution: false,
        contributionRequestCancellation: false,
        reminderPendingContributionRequest: false,
        grantVestmentRequestConfirmation: false,
        grantVestmentRequestApproval: false,
        grantVestmentRequestDeclination: false,
        additionGrantVestmentRequestOfInterest: false,
        updateFollowedGrantVestment: false,
        reminderToBackFollowedGrantVestment: false,
        followedGrantVestmentReachGoal: false,
        backedGrantVestmentReachGoal: false,
        backedGrantVestmentDoNotReachGoal: false,
        investmentRequestConfirmation: false,
        investmentRequestApproval: false,
        investmentRequestDeclined: false,
        grantRequestConfirmation: false,
        grantRequestApproval: false,
        grantRequestDeclined: false,
      })
    }
  }

  const submit = () => {
    mutate(emailSettings, {
      onSuccess: () => navigate(-1),
    });
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, mx: 3 }}>
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => navigate(FundSettingsPage)}
                    >
                      <Typography
                        variant="bodyCopyBold"
                        sx={{ color: "neutral.main" }}
                      >
                        Fund settings
                      </Typography>
                    </Link>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      Emails & notifications
                    </Typography>
                  </Breadcrumbs>
                  <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography variant="h3">Email notifications</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="bodyCopyRegular">
                        These preferences will only be reflected on your
                        account.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Card sx={{ boxShadow: "none", p: 4 }}>
                    <CardContent>
                      <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                        <Grid item xs={12} sm={8}>
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="subtitle1"
                            gutterBottom
                          >
                            Enable notifications for this fund{" "}
                          </Typography>
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="bodyCopyRegular"
                            gutterBottom
                          >
                            You can easily enable or disable all notifications
                            for each fund. These changes only affect this fund
                            and your account.{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} textAlign={"end"}>
                          {isLoading && <CircularProgress color="primary" />}
                          {!isLoading && data && (
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEnableEmailSettingsChange(
                                  event.target.checked
                                )
                              }
                              checked={enableNotification}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  {enableNotification && (
                    <Card sx={{ boxShadow: "none", p: 4, mt: 2 }}>
                      <CardContent>
                        <Grid
                          sx={{ mb: 5, mt: 2 }}
                          container
                          spacing={2}
                          alignItems={"center"}
                        >
                          <Grid item xs={12} sm={12}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="subtitle1"
                              gutterBottom
                            >
                              Set notifications by channel{" "}
                            </Typography>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyRegular"
                              gutterBottom
                            >
                              Which emails you would like to receive{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="subtitle3"
                              gutterBottom
                            >
                              Contributions{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Confirmation of contribution request
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "contributionRequestConfirmation",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.contributionRequestConfirmation
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Confirmation when the contribution is added to
                              your fund
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "contributionRequestExecution",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.contributionRequestExecution
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Reminders of pending contributions
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "reminderPendingContributionRequest",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.reminderPendingContributionRequest
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Confirmation of contribution cancellation{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "contributionRequestCancellation",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.contributionRequestCancellation
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="subtitle3"
                              gutterBottom
                            >
                              GrantVestments{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Confirmation of GrantVestment recommendation
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "grantVestmentRequestConfirmation",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.grantVestmentRequestConfirmation
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              GrantVestment recommendation approved
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "grantVestmentRequestApproval",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.grantVestmentRequestApproval
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              GrantVestment recommendation declined
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "grantVestmentRequestDeclination",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.grantVestmentRequestDeclination
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              A new GrantVestment is added to one of your
                              categories of interest{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "additionGrantVestmentRequestOfInterest",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.additionGrantVestmentRequestOfInterest
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Updates added on a GrantVestement you follow{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "updateFollowedGrantVestment",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.updateFollowedGrantVestment
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Reminders to back GrantVestments you are following{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "reminderToBackFollowedGrantVestment",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.reminderToBackFollowedGrantVestment
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              GrantVestments that you follow reach their goal
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "followedGrantVestmentReachGoal",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.followedGrantVestmentReachGoal
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              GrantVestment that you backed has reached its goal{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "backedGrantVestmentReachGoal",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.backedGrantVestmentReachGoal
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              GrantVestment that you backed hasn’t reached its
                              goal{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "backedGrantVestmentDoNotReachGoal",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.backedGrantVestmentDoNotReachGoal
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="subtitle3"
                              gutterBottom
                            >
                              Investments{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Confirmation of investment recommendation
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "investmentRequestConfirmation",
                                  event.target.checked
                                )
                              }
                              checked={
                                emailSettings.investmentRequestConfirmation
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Investment recommendation approved
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "investmentRequestApproval",
                                  event.target.checked
                                )
                              }
                              checked={emailSettings.investmentRequestApproval}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Investment recommendation declined
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "investmentRequestDeclined",
                                  event.target.checked
                                )
                              }
                              checked={emailSettings.investmentRequestDeclined}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="subtitle3"
                              gutterBottom
                            >
                              Grants{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Confirmation of grant recommendation
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "grantRequestConfirmation",
                                  event.target.checked
                                )
                              }
                              checked={emailSettings.grantRequestConfirmation}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Grant recommendation approved
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "grantRequestApproval",
                                  event.target.checked
                                )
                              }
                              checked={emailSettings.grantRequestApproval}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              className="switch-label"
                              gutterBottom
                            >
                              Grant recommendation declined
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} textAlign={"end"}>
                            <CustomSwitch
                              onChange={(event: any) =>
                                handelEmailSettingsChange(
                                  "grantRequestDeclined",
                                  event.target.checked
                                )
                              }
                              checked={emailSettings.grantRequestDeclined}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} sm={10} textAlign={"end"} sx={{ my: 4 }}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="text"
                    color="primary"
                    size="large"
                    onClick={() => navigate(-1)}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    onClick={submit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
