import { Typography, Card, CardContent, Grid } from "@mui/material";
import { FC } from "react";

import { INVESTMENT_REQUEST_INTEREST_ENUM, INVESTMENT_REQUEST_TYPE_ENUM } from "types/investment-request";
import { moneyFormat } from "utils/money-format";

interface Props {
  investmentName: string;
  requestedAmount: number;
  type: INVESTMENT_REQUEST_TYPE_ENUM;
  requestUnits: number
  interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM
}

export const InvestmentRequestSuccess: FC<Props> = ({
  investmentName,
  requestedAmount,
  type,
  requestUnits,
  interestedIn
}) => {
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid sx={{ m: 5 }} container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h3">Thank you!</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography sx={{ color: "neutral.main" }}>
                <Typography variant="bodyCopyBold">
                  You have sent a request to{" "}
                  {type === INVESTMENT_REQUEST_TYPE_ENUM.BUY ? "buy " : "sell "}
                </Typography>
                <Typography variant="bodyCopyBold">
                  {
                    (interestedIn === INVESTMENT_REQUEST_INTEREST_ENUM.AMOUNT) ?
                      <>${moneyFormat(requestedAmount)}
                      </> :
                      <>{moneyFormat(requestUnits)} unit(s)
                      </>
                  }
                </Typography>
                <Typography variant="bodyCopyBold"> of </Typography>
                <Typography variant="bodyCopyBold">{investmentName}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={11}>
              <Typography sx={{ mt: 2 }} variant="bodyCopyRegular">
                We will process this recommendation as soon as possible. Once
                processed, you will see exact amounts in your portfolio. Units
                or cash value may differ slightly due to change in market
                conditions between now and time of processing.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
