import { Account } from "./account";
import { User } from "./user";

export enum TRANSACTION_STATUS {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  PROCESSED = "PROCESSED",
}
export enum PAYMENT_METHOD_ENUM {
  TRANSFER = "TRANSFER",
  CHECK = "CHECK",
  DAF = "DAF",
}
export enum TRANSACTION_TYPE_ENUM {
  CASH = "CASH",
  SECURITIES = "SECURITIES",
  OTHER = "OTHER",
}
export interface CashInformation {
  requestedAmount: number;
  appliedAmount?: number;
  paymentMethod: PAYMENT_METHOD_ENUM;
}

export interface SecuritiesInformation {
  securityName: string;
  ticker: string;
  quantity: number;
  estimatedAmount?: number;
  estimatedMarketRate?: number;
}
export interface OtherAssetsInformation {
  estimatedAmount?: number;
  description?: string;
}
export interface ContributionReport {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  donorEmail: string;
  appliedAmount?: number;
}
export default interface ContributionTransaction {
  contributionId: number;
  fund: Account;
  user: User;
  type: TRANSACTION_TYPE_ENUM;
  status: TRANSACTION_STATUS;
  contributionInformation:
    | CashInformation
    | SecuritiesInformation
    | OtherAssetsInformation;
  contributionReport: ContributionReport;
  createdAt: Date;
  updatedAt: Date;
  receipt?: {
    url: string;
    fileName: string;
  };
}
export interface ContributionTransactionInput {
  sub: string;
  type: TRANSACTION_TYPE_ENUM;
  contributionInformation:
    | CashInformation
    | SecuritiesInformation
    | OtherAssetsInformation;
  contributionReport: ContributionReport;
}
export interface ContributionTransactionsQuery {
  offset?: number;
  limit?: number;
  year?: number;
  status?: TRANSACTION_STATUS;
  userId?: number;
  fundId?: number;
  orderBy?: {
    createdAt?: "ASC" | "DESC";
    updatedAt?: "ASC" | "DESC";
  };
}
