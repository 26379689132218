import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PersonalInformation } from '../user-information/personal-information';
import { Address } from '../user-information/address';
import { ContactInformationSuccessor } from './contact-information-successor';
import { SaveButton } from '../shared/save-button';
import { SUCCESSOR, SUCCESSOR_CHARITY, SUCCESSOR_ERROR, SUCCESSOR_INDIVIDUAL } from '../../constants/successor';
import { CharityDetailSuccessor } from './charity-detail-successor';
import { SUCCESSOR_TYPE, Successor, SuccessorCharity, SuccessorIndividual } from 'types/successor';
interface AddSuccessorContentProps {
    selectedSuccessor: Successor;
    edit?: boolean;
    saveSuccessor: (successor: Successor) => void;
    close: () => void;
    titles: string[]
}
export const AddSuccessorContent: React.FC<AddSuccessorContentProps> = ({
    selectedSuccessor, titles, close, saveSuccessor, edit
}) => {
    const [successor, setSuccessor] = useState<Successor>(SUCCESSOR)
    const [error, setError] = useState(SUCCESSOR_ERROR)
    useEffect(() => {
        setSuccessor(selectedSuccessor)
    }, [selectedSuccessor])
    const handleTypeChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        if (value !== null) {
            setSuccessor({
                ...successor,
                successorType: value as SUCCESSOR_TYPE,
                successorInfo: (value === SUCCESSOR_TYPE.CHARITY) ? SUCCESSOR_CHARITY : SUCCESSOR_INDIVIDUAL
            })
        }
    };
    const handleChange = (key: string, value: string) => {
        setError({
            ...error,
            [key]: false
        })
        setSuccessor({
            ...successor,
            successorInfo: { ...successor.successorInfo, [key]: value }
        })
    }
    const handleInvalid = (event: any) => {
        setError({
            ...error,
            [event.target.name]: true
        })
    }
    const onSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        saveSuccessor(successor)
    }
    return (
        <Box
            component="form"
            autoComplete="off"
            onSubmit={onSubmit}
        >
            {
                !edit &&
                <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 4 }}>
                    <Typography variant='subtitle1' gutterBottom sx={{ mr: 3 }}>Give to</Typography>
                    <ToggleButtonGroup
                        value={successor.successorType}
                        exclusive
                        onChange={handleTypeChange}
                        className='successor-toggle'
                        size='large'
                    >
                        <ToggleButton className='successor-toggle-button' value={SUCCESSOR_TYPE.CHARITY} >
                            <div> A charity</div>
                        </ToggleButton>
                        <ToggleButton className='successor-toggle-button' value={SUCCESSOR_TYPE.INDIVIDUAL}>
                            <div> An individual</div>
                        </ToggleButton>

                    </ToggleButtonGroup>
                </Box>
            }

            {
                (successor.successorType === SUCCESSOR_TYPE.INDIVIDUAL) ?
                    <>
                        <PersonalInformation successor={true} titles={titles} user={successor.successorInfo as SuccessorIndividual} handleChange={handleChange} error={error} handleInvalid={handleInvalid}   ></PersonalInformation>
                        <Address user={successor.successorInfo as SuccessorIndividual} handleChange={handleChange} error={error} handleInvalid={handleInvalid}  ></Address>
                        <ContactInformationSuccessor user={successor.successorInfo as SuccessorIndividual} handleChange={handleChange} error={error} handleInvalid={handleInvalid} ></ContactInformationSuccessor>

                    </>
                    :
                    <CharityDetailSuccessor successor={successor.successorInfo as SuccessorCharity} handleChange={handleChange} error={error} handleInvalid={handleInvalid} ></CharityDetailSuccessor>

            }

            <SaveButton reset={close} />
        </Box>


    )
}
