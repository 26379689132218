import { getCategoriesAPI, GetCategoriesQuery } from "api/category.api";
import { useInfiniteQuery, useQuery } from "react-query"
import { Category } from "types/category";
import PaginatedEntityList from "types/PaginatedEntityList";

const CATEGORY_RQ_KEY = 'categories';
export const useGetCategoriesQueryWithPagination = (query: GetCategoriesQuery) => {
  return useInfiniteQuery<PaginatedEntityList<Category>>({
    queryKey: [CATEGORY_RQ_KEY, query],
    queryFn: ({ pageParam = 0 }) => getCategoriesAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetCategoriesQuery = (query: GetCategoriesQuery) => {
  return useQuery<PaginatedEntityList<Category>>({
    queryKey: [CATEGORY_RQ_KEY, query],
    queryFn: () => getCategoriesAPI(query),
  })
}




