import React from 'react';
import { Box, Button } from '@mui/material';
interface SaveButtonProps {
    reset: () => void;
    submit?: () => void
    secondaryButton?: string;
    disabled?: boolean
}
export const SaveButton: React.FC<SaveButtonProps> = ({
    reset,
    submit,
    secondaryButton,
    disabled
}) => {
    return (

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={reset}   >
                {secondaryButton ? secondaryButton : "Discard"}
            </Button>
            {
                submit ?
                    <Button variant="contained" color='primary' size='large' type='submit' onClick={submit} disabled={disabled} >
                        Save
                    </Button>
                    :
                    <Button variant="contained" color='primary' size='large' type='submit' disabled={disabled} >
                        Save
                    </Button>
            }

        </Box>


    )
}
