export const shortFormat = (date: Date | undefined) => {
  if (date)
    return new Date(date)?.toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
};
export const shortFormat2 = (date: Date | undefined) => {
  if (date)
    return new Date(date)?.toLocaleDateString("en-us", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
};
export const longFormat = (date: Date | string) => {
  return new Date(date)?.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};
export const numberOfDays = (
  date1: Date | string | undefined,
  date2?: Date | string | undefined
) => {
  if (date1) {
    const date = date2 ? new Date(date2) : new Date();
    const Difference_In_Time = new Date(date1).getTime() - date.getTime();
    const daysLeft = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    return daysLeft;
  }
  return 0;
};
export const numberRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
export const addWeeksToDate = (numberOfWeeks: number) => {
  const dateObj = new Date();
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj;
};
export const dateFormat = (date: Date) => {
  return new Date(
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  );
};
