import React, { FormEvent } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SuccessorError, SuccessorIndividual } from "types/successor";
interface ContactInformationSuccessorProps {
  settings?: boolean;
  user: SuccessorIndividual;
  handleChange: (key: string, value: string) => void;
  handleInvalid: (event: FormEvent<HTMLElement>) => void;
  error: SuccessorError;
}
export const ContactInformationSuccessor: React.FC<
  ContactInformationSuccessorProps
> = ({ settings, user, handleChange, handleInvalid, error }) => {
  return (
    <Accordion defaultExpanded={!settings}>
      <AccordionSummary
        tabIndex={-1}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="contact-information"
        id="contact-information"
        className="hasText"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          Contact information{" "}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className="hasText">
        <Typography sx={{ color: `neutral.dark` }} variant="bodyCopyRegular">
          Please note that We will not contact them now, but we need this in
          case we need to contact them in the future in the case of a succession
          event.
        </Typography>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormControl error={error.email} fullWidth>
              <InputLabel shrink={false}>email* </InputLabel>

              <OutlinedInput
                name="email"
                value={user.email}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) => handleChange("email", event.target.value)}
                type="text"
                required
              />
              {error.email && (
                <FormHelperText color={"red"}>Email is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>Phone</InputLabel>
              <OutlinedInput
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={(event) =>
                  handleChange("phoneNumber", event.target.value)
                }
                type="text"
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
