import React from 'react';
import { Grid, Typography } from '@mui/material';
import { warm, neutral } from 'style/color';
interface TransactionDetailsItemProps {
    label: string;
    value: string
}
export const TransactionDetailsItem: React.FC<TransactionDetailsItemProps> = ({
    label,
    value,
}) => {
    return (

        <Grid item xs={12} sm={12}>
            <Grid sx={{ mt: 1, pb: 3, borderBottom: 'solid 2px', borderBottomColor: warm[500], }} container spacing={2}>
                <Grid item xs={3} sm={3}> <Typography className="title" variant='smallCopy' sx={{ color: neutral[500] }}>{label}</Typography></Grid>
                <Grid item xs={9} sm={9}> <Typography variant='subtitle3' sx={{ color: neutral[900] }}>{value}</Typography></Grid>

            </Grid>
        </Grid>


    )
}
