import React, { FormEvent } from 'react';
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { User, UserError } from 'types/user';
import { Account } from 'types/account';
import { Address } from 'components/user-information/address';
import { ContactInformation } from 'components/user-information/contact-information';
import { PersonalInformation } from 'components/user-information/personal-information';

interface PersonalProps {
    user: User
    account: Account;
    titles: string[];
    error: UserError;
    handleChange: (key: string, value: any) => void;
    handleAccountChange: (key: string, value: any) => void;
    handleInvalid: (event: FormEvent<HTMLElement>) => void;
    handleNext: () => void;
}
export const Personal: React.FC<PersonalProps> = ({ user, handleChange, account, handleAccountChange, handleNext, handleInvalid, error, titles }) => {
    const [target, setTarget] = React.useState<any>(null)
    const handleFutureDateError = (target: any, error: any) => {
        setTarget((error) ? target : null)
    }
    const onSubmit = (event: any) => {
        event.preventDefault()
        if (target) {
            target.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        else {
            handleNext()
        }
    }
    return (
        <Box
            component="form"
            autoComplete="off"
            onSubmit={(event) => onSubmit(event)}
        >
            <Typography variant='h3'>
                Personal Information
            </Typography>
            <Typography variant='bodyCopyRegular'>
                Please complete all mandatory fields (*).
            </Typography>
            <div>
                <Accordion defaultExpanded>
                    <AccordionSummary tabIndex={-1}
                        className='hasText'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="type-of-fund"
                        id="type-of-fund"
                    >
                        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">Type of fund</Typography>
                    </AccordionSummary>
                    <AccordionDetails className='hasText'>
                        <Typography sx={{ color: `neutral.dark` }} variant='bodyCopyRegular'>
                            Please tell us who this fund is for
                        </Typography>
                        <Grid sx={{ mt: 2 }} container>
                            <FormControl sx={{ mt: 2 }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="fundAccountType"
                                    value={account.fundAccountType}
                                    onChange={(event) => handleAccountChange("fundAccountType", event.target.value)}
                                >
                                    <FormControlLabel value="PERSONAL" control={<Radio />} label="This fund is for personal use" />
                                    <FormControlLabel value="ENTITY" control={<Radio />} label="This fund is for an entity (corporation, trust, foundation, ....)" />
                                    {
                                        account.fundAccountType === "ENTITY" &&
                                        <Typography variant='bodyCopyRegular' sx={{
                                            color: "neutral.dark",
                                            mx: 4
                                        }}>The entity information will be requested in the next page</Typography>
                                    }

                                </RadioGroup>
                            </FormControl></Grid>

                    </AccordionDetails>
                </Accordion>
                <PersonalInformation titles={titles} user={user} handleChange={handleChange} error={error} handleInvalid={handleInvalid} handleFutureDateError={handleFutureDateError} ></PersonalInformation>
                <Address user={user} handleChange={handleChange} error={error} handleInvalid={handleInvalid} ></Address>
                <ContactInformation user={user} handleChange={handleChange} error={error} handleInvalid={handleInvalid} ></ContactInformation>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        tabIndex={-1}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="reference"
                        id="reference"
                        className='hasText'
                    >
                        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">Reference </Typography>
                    </AccordionSummary>
                    <AccordionDetails className='hasText'>
                        <Typography sx={{ color: `neutral.dark` }} variant='bodyCopyRegular'>
                            We carefully screen our DAF users. Please help us expedite your account approval by telling us who referred you to Neta or who you know that works at or uses Neta, or telling us a bit about yourself (how you found out about Neta, maybe your LinkedIn profile, … or any other relevant information)
                        </Typography>
                        <br />
                        <Grid sx={{ mt: 2 }} container>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-multiline-static"
                                    name="reference"
                                    value={user.reference}
                                    onChange={(event) => handleChange("reference", event.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" color='primary' size='large' type='submit'  >
                    Continue
                </Button>
            </Box>
        </Box>
    )
}
