export const primary = {
  50: "#E7EBF2",
  100: "#B3C2D8",
  200: "#8FA4C5",
  300: "#5C7BAB",
  400: "#3C619A",
  500: "#0B3A81",
  600: "#0A3575",
  700: "#08295C",
  800: "#062047",
  900: "#051836",
};
export const accent = {
  50: "#FFF6F3",
  100: "#FFE4DB",
  200: "#FFD7CA",
  300: "#FFC4B1",
  400: "#FFB9A2",
  500: "#FFA78B",
  600: "#E8987E",
  700: "#B57763",
  800: "#8C5C4C",
  900: "#6B463A",
};
export const neutral = {
  50: "#F7F7F7",
  100: "#F5F5F5",
  200: "#EEEEEE",
  300: "#E0E0E0",
  400: "#BDBDBD",
  500: "#9E9E9E",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
};
export const warm = {
  50: "#FDFCFC",
  100: "#F9F7F6",
  200: "#F7F3F1",
  300: "#F3EEEB",
  400: "#F1EAE7",
  500: "#EDE5E1",
  600: "#D8D0CD",
  700: "#A8A3A0",
  800: "#827E7C",
  900: "#64605F",
};
export const successAlert = {
  500: "#19AA55",
  100: "#9AEFBE",
};
export const errorAlert = {
  100: "#F0B7AC",
  300: "#FF6961",
  500: "#DB3B21",
};
