import React, { useContext } from "react";
import { Grid, Typography, Card, CardContent, IconButton } from "@mui/material";
import { ReactComponent as CopyIcon } from "./../../../assets/Icons/copy.svg";

import { warm } from "style/color";
import { NetaSetting } from "constants/neta-settings";
import { NetaSettingContext } from "providers/netaSettingsProvider";

export const DafCashPaymentDetails: React.FC = () => {
  const NETA = useContext<NetaSetting>(NetaSettingContext);

  const customIconButton = (text: string) => {
    return (
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(text);
        }}
        sx={{
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <CopyIcon />
      </IconButton>
    );
  };
  return (
    <Grid item xs={12} sm={12}>
      <Card sx={{ backgroundColor: warm[100], boxShadow: "none" }}>
        <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={2}>
              <Typography
                className="title"
                variant="smallCopy"
                sx={{ color: `neutral.dark` }}
              >
                Charity Name:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyCopyBold" sx={{ color: `neutral.main` }}>
                {NETA.name}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1}>
              {customIconButton(NETA.name)}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Typography
                className="title"
                variant="smallCopy"
                sx={{ color: `neutral.dark` }}
              >
                EIN:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyCopyBold" sx={{ color: `neutral.main` }}>
                {NETA.ein}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1}>
              {customIconButton(NETA.ein)}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Typography
                className="title"
                variant="smallCopy"
                sx={{ color: `neutral.dark` }}
              >
                Address:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyCopyBold" sx={{ color: `neutral.main` }}>
                {NETA.addressLine1} <br></br> {NETA.addressLine2}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1}>
              {customIconButton(`${NETA.addressLine1} ${NETA.addressLine2}`)}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Typography
                className="title"
                variant="smallCopy"
                sx={{ color: `neutral.dark` }}
              >
                Contact Person:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyCopyBold" sx={{ color: `neutral.main` }}>
                {NETA.personContact}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1}>
              {customIconButton(NETA.personContact)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
