import React, { useContext, useEffect } from "react";
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";

import ContributionTransaction, { TRANSACTION_TYPE_ENUM, CashInformation, SecuritiesInformation, PAYMENT_METHOD_ENUM } from "types/contribution-transaction";
import { SaveButton } from "components/shared/save-button";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { useGetContributionTransactionQuery, useUpdateContributionTransactionMutation } from "hooks/use-services/contribution-transaction.service";
import { ContributionTransactionDetails } from "components/contribution-transaction/contribution-details";
import { DonorInformation } from "components/contribution-transaction/donor-information";
import { PaymentMethodDetails } from "components/contribution-transaction/payment-method";

export const EditContributionTransaction = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { selectedAccount } = useContext<AuthData>(AuthContext);
    const [updatedContributionTransaction, setUpdatedContributionTransaction] = React.useState<ContributionTransaction | null>(null);
    const { data: contributionTransaction, isSuccess, isLoading } = useGetContributionTransactionQuery(selectedAccount?.fundAccountId || 0, (id) ? +id : 0)
    const updateContributionTransaction = useUpdateContributionTransactionMutation(selectedAccount?.fundAccountId || 0, (id) ? +id : 0)

    useEffect(() => {
        if (id && contributionTransaction) {
            setUpdatedContributionTransaction({
                ...contributionTransaction!,
                contributionId: +id!
            })
        }
    }, [contributionTransaction, id]);
    const handleChange = (key: string, value: any) => {
        if (updatedContributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH && key === "contributionInformation") {
            setUpdatedContributionTransaction({
                ...updatedContributionTransaction!,
                contributionInformation: {
                    requestedAmount: value.requestedAmount!,
                    paymentMethod: (updatedContributionTransaction?.contributionInformation as CashInformation)?.paymentMethod!,
                }
            })
        }
        else {
            setUpdatedContributionTransaction({
                ...updatedContributionTransaction!,
                [key]: value
            })
        }

    }
    const handlePaymentMethodChange = (key: string, value: any) => {
        setUpdatedContributionTransaction({
            ...updatedContributionTransaction!,
            contributionInformation: {
                requestedAmount: (updatedContributionTransaction?.contributionInformation as CashInformation)?.requestedAmount!,
                paymentMethod: value.paymentMethod
            }
        })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let newCont = updatedContributionTransaction;
        if (updatedContributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH) {
            (newCont?.contributionInformation as CashInformation).requestedAmount = +(updatedContributionTransaction.contributionInformation as CashInformation).requestedAmount
        }
        if (updatedContributionTransaction?.type === TRANSACTION_TYPE_ENUM.SECURITIES) {
            (newCont?.contributionInformation as SecuritiesInformation).quantity = +(updatedContributionTransaction.contributionInformation as SecuritiesInformation).quantity
        }
        updateContributionTransaction.mutate(updatedContributionTransaction!, {
            onSuccess: () => {
                navigate(-1)
            }
        })

    }

    return (
        <Layout>
            {() => (
                <>
                    <Box sx={{ my: 4, width: "100%" }}>
                        <React.Fragment>
                            <Box sx={{ mt: 3, mx: 3 }} >
                                <BackToPreviousButton />
                                <Box
                                    component="form"
                                    autoComplete="off"
                                    onSubmit={(event: any) => handleSubmit(event)}
                                >
                                    <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='h3'>
                                                Edit donation
                                            </Typography>
                                        </Grid>
                                        {

                                            (isLoading) && <Grid item xs={12} sm={10} textAlign={'center'}> <CircularProgress color='primary' />
                                            </Grid>
                                        }
                                        {
                                            isSuccess && contributionTransaction && updatedContributionTransaction &&
                                            <Grid item xs={12} sm={10}>
                                                <ContributionTransactionDetails contributionTransaction={contributionTransaction!} handleChange={handleChange} />
                                                <PaymentMethodDetails information={(contributionTransaction?.type === TRANSACTION_TYPE_ENUM.CASH) ? (contributionTransaction?.contributionInformation as CashInformation) : null} transactionType={updatedContributionTransaction?.type!} handleChange={handlePaymentMethodChange} />
                                                {
                                                    contributionTransaction?.type !== TRANSACTION_TYPE_ENUM.OTHER &&
                                                    <DonorInformation newDonorDisabled={updatedContributionTransaction.type === TRANSACTION_TYPE_ENUM.CASH && (updatedContributionTransaction.contributionInformation as CashInformation).paymentMethod === PAYMENT_METHOD_ENUM.DAF} contributionReport={updatedContributionTransaction?.contributionReport!} handleChange={handleChange} />
                                                }
                                            </Grid>
                                        }

                                        <Grid item xs={12} sm={12}>
                                            <SaveButton reset={() => navigate(-1)} />
                                        </Grid>
                                    </Grid>


                                </Box>
                            </Box>
                        </React.Fragment>
                    </Box>

                </>
            )}
        </Layout>
    );
};
