import apiHttpClient from "libs/api-http-client";
import { FundDividendDistribution } from "types/fund-dividend-distribution";

export interface GetFundDividend {
  fundAccountId: number;
  dividendId: number;
}
export const getFundDividendAPI = (
  input: GetFundDividend
): Promise<FundDividendDistribution> =>
  apiHttpClient.get(`fund-dividend-distribution`, {
    params: {
      fundAccountId: input.fundAccountId,
      dividendId: input.dividendId,
    },
  });
