import { UserInvitationQuery, getUserNotificationAPI, updateUserNotificationAPI } from "api/user-invitation.api";
import { useMutation, useQuery } from "react-query"
import { UserNotification } from "types/user-notification";

const USER_NOTIFICATION_RQ_KEY = 'user-notification';


export const useUpdateUserNotificationMutation = () => {
  return useMutation<unknown, unknown, Partial<UserNotification>>({
    mutationKey: [USER_NOTIFICATION_RQ_KEY],
    mutationFn: (data) => updateUserNotificationAPI(data),
  })
}

export const useGetUserNotificationQuery = (query: UserInvitationQuery) => {
  return useQuery<UserNotification>({
    queryKey: [USER_NOTIFICATION_RQ_KEY, query],
    enabled: query.fundId !== 0 && query.userId !== 0,
    queryFn: () => getUserNotificationAPI(query),
  })

}


