import React, { useContext, useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Layout from "components/layout/layout";
import { useNavigate } from "react-router-dom";
import { FundSettingsPage } from "constants/pages";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { ContributionReceiptsList } from "../../components/contribution-transaction/contribution-recipits/contribution-receipts-list";
import ContributionTransaction, {
  TRANSACTION_STATUS,
} from "types/contribution-transaction";
import { numberRange } from "utils/date-format";
import { MyPendingContributionList } from "./contribution";
import { useGetContributionTransactionsQuery } from "hooks/use-services/contribution-transaction.service";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
export const ContributionReceipts = () => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const [limit] = React.useState<any>(18);
  const [year, setYear] = React.useState<number>(new Date().getFullYear());

  const { data: paginatedData, isFetching, isLoading,
    fetchNextPage,
    hasNextPage, isFetchingNextPage } = useGetContributionTransactionsQuery({
      limit: limit,
      fundId: selectedAccount?.fundAccountId || 0,
      status: TRANSACTION_STATUS.ACCEPTED,
    });
  const pastContributionTransactions = useMemo<ContributionTransaction[]>(
    () =>
      paginatedData ? paginatedData?.pages?.flatMap((item) => item.data) : [],
    [paginatedData]
  );
  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  const navigate = useNavigate();


  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container sx={{ my: 3, width: "100%" }}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(FundSettingsPage)}
                  >
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      Fund settings
                    </Typography>
                  </Link>
                  <Typography
                    variant="bodyCopyBold"
                    sx={{ color: "neutral.main" }}
                  >
                    Contribution receipts
                  </Typography>
                </Breadcrumbs>
                <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={8} sx={{ mb: 2 }}>
                    <Typography variant="h3">Contribution receipts</Typography>
                  </Grid>
                  <MyPendingContributionList />
                  <Grid item xs={12} sm={12}>
                    <Card sx={{ boxShadow: "none", p: 4 }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="subtitle3"
                          >
                            Past contributions{" "}
                          </Typography>
                          <Select
                            id="title-select"
                            size="small"
                            name="title"
                            value={year}
                            onChange={(event) => setYear(+event.target.value)}
                          >
                            {numberRange(
                              new Date().getFullYear(),
                              new Date().getFullYear() - 20,
                              -1
                            ).map((year) => (
                              <MenuItem key={year} value={year}>
                                {year === new Date().getFullYear()
                                  ? "Current year"
                                  : year}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        {isLoading && (
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            textAlign={"center"}
                            sx={{ m: 3 }}
                          >
                            {" "}
                            <CircularProgress color="primary" />
                          </Grid>
                        )}
                        {pastContributionTransactions &&
                          pastContributionTransactions.length !== 0 && (
                            <>
                              <ContributionReceiptsList
                                pending={false}
                                contributionTransactions={
                                  pastContributionTransactions
                                }
                              />
                              <Box ref={lastElementRef}>
                                {isFetchingNextPage && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    textAlign={"center"}
                                    sx={{ m: 3 }}
                                  >
                                    {" "}
                                    <CircularProgress color="primary" />
                                  </Grid>
                                )}
                              </Box>
                            </>
                          )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
