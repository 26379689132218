import apiHttpClient from "libs/api-http-client";
import { PaginatedEntityList } from "types/pagination";
import { FundBackedGrantVestment } from "types/fund-backed-grant-vestment";

export const getFundBackedGrantVestmentTotalAPI = (
  fundId: number,
  grantvestmentId?: number
): Promise<{
  totalAmount: number;
}> =>
  apiHttpClient.get(`/funds/${fundId}/total-fund-backed-grant-vestments`, {
    params: {
      grantvestmentId: grantvestmentId,
    },
  });

export const getFundBackedGrantVestmentAPI = (query: {
  fundId: number;
  limit?: number;
  offset?: number;
}): Promise<PaginatedEntityList<FundBackedGrantVestment>> =>
  apiHttpClient.get(`/funds/${query.fundId}/fund-backed-grant-vestments`, {
    params: {
      limit: query?.limit,
      offset: query?.offset,
    },
  });
