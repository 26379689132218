import React, { FormEvent, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { User, UserError } from 'types/user';
import { SuccessorIndividual, SuccessorError } from 'types/successor';
interface PersonalInformationProps {
  settings?: boolean;
  user: SuccessorIndividual | User;
  titles: string[],
  successor?: boolean
  handleChange: (key: string, value: string) => void;
  handleInvalid: (event: FormEvent<HTMLElement>) => void;
  error: UserError | SuccessorError;
  handleFutureDateError?: (target: any, error: string) => void;
}
export const PersonalInformation: React.FC<PersonalInformationProps> = ({
  settings,
  user,
  handleChange,
  handleInvalid,
  error,
  titles,
  successor,
  handleFutureDateError
}) => {
  const ref = useRef(null);
  const [errorDate, setErrorDate] = React.useState(false)
  const getDate = (newValue: any) => {
    setErrorDate(false)
    handleChange("dateOfBirth", new Date(newValue).toLocaleDateString("en-us"));
  };
  const handleInvalidDate = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    handleInvalid(event);
  };
  const handleError = (event: any) => {
    handleFutureDateError && handleFutureDateError(ref.current, event)
    setErrorDate((event) ? true : false)
  };
  const getDateError = () => {
    if (errorDate) {
      return "The date of birth is invalid"
    }
    if ((error as UserError).dateOfBirth) {
      return "The date of birth is required"
    }
    return ""
  };
  return (
    <Accordion defaultExpanded={!settings}>
      <AccordionSummary tabIndex={-1}
        className="hasText"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="personal-information"
        id="personal-information"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          Personal information
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="hasText" >
        {
          settings && <Typography sx={{ color: `neutral.dark` }} variant='bodyCopyRegular'>
            In order to change your personal information, please <Link target="_blank" href={`mailto:admin@netafoundation.org`} underline="always" color={'inherit'} sx={{
              "&:hover": {
                cursor: "pointer",
              }
            }}> contact us</Link>.
          </Typography>
        }
        <Grid sx={{ mt: 2 }} container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel shrink={false} id="title">
                Title
              </InputLabel>
              <Select
                id="title-select"
                name="title"
                value={user?.title}
                onChange={(event) => handleChange("title", event.target.value)}
              >
                {titles?.map((title, index) => {
                  return (
                    <MenuItem key={index} value={title}>
                      {title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}></Grid>
          <Grid item xs={12} sm={4}>
            <FormControl error={error?.firstName} fullWidth>
              <InputLabel shrink={false}>First name*</InputLabel>
              <OutlinedInput
                name="firstName"
                value={user?.firstName}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) =>
                  handleChange("firstName", event.target.value)
                }
                type="text"
                required
                disabled={settings}
              />
              {error?.firstName && (
                <FormHelperText color={'red'}>
                  First name is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>Middle name</InputLabel>
              <OutlinedInput
                disabled={settings}
                name="middleName"
                value={user?.middleName}
                onChange={(event) =>
                  handleChange("middleName", event.target.value)
                }
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl error={error?.lastName} fullWidth>
              <InputLabel shrink={false}>Last name*</InputLabel>
              <OutlinedInput
                disabled={settings}
                name="lastName"
                value={user?.lastName}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) =>
                  handleChange("lastName", event.target.value)
                }
                type="text"
                required
              />
              {error?.lastName && (
                <FormHelperText color={'red'}>
                  Last name is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>Suffix</InputLabel>
              <OutlinedInput
                disabled={settings}
                name="suffix"
                value={user?.suffix}
                onChange={(event) => handleChange("suffix", event.target.value)}
                type="text"
              />
            </FormControl>
          </Grid>
          {
            !successor && <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl error={(error as UserError).dateOfBirth || errorDate} fullWidth>
                  <InputLabel shrink={false}>Date of birth*</InputLabel>
                  <DatePicker
                    disabled={settings}
                    inputRef={ref}
                    slotProps={{
                      textField: {
                        id: "datePicker",
                        name: "dateOfBirth",
                        helperText: getDateError(),
                        required: true,
                        onInvalid: (event) => handleInvalidDate(event),
                        error: (error as UserError).dateOfBirth || errorDate,
                      },
                    }}
                    onError={(event) => handleError(event)}
                    disableFuture
                    format="MM/DD/YYYY"
                    name="dateOfBirth"
                    value={(user as User)?.dateOfBirth}
                    onChange={getDate}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
