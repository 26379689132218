import { FC } from "react";
import { Chip, Grid } from "@mui/material";

import { GrantVestment } from "types/grant-vestment";


interface GrantVestmentsDetailsTagsProps {
    grantvestment: GrantVestment,
};

const GrantVestmentsDetailsTags: FC<GrantVestmentsDetailsTagsProps> = ({ grantvestment }) => {

    return (

        <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
            <Grid item xs={12} sm={6}>
                {grantvestment?.tags && grantvestment?.tags.map(tag => { return <Chip sx={{ mr: 2 }} label={tag.tagName} /> })}
            </Grid>
        </Grid>


    );
}

export default GrantVestmentsDetailsTags;
