import apiHttpClient from "libs/api-http-client";
import { UserNotification } from "types/user-notification";

export interface UserInvitationQuery {
  fundId: number;
  userId: number;
}
export const updateUserNotificationAPI = (
  userNotification: Partial<UserNotification>
): Promise<unknown> =>
  apiHttpClient.patch(
    `user-notification/${userNotification.notificationId}`,
    userNotification
  );

export const getUserNotificationAPI = (
  query: UserInvitationQuery
): Promise<UserNotification> =>
  apiHttpClient.get(`user-notification`, {
    params: {
      fundId: query.fundId,
      userId: query.userId,
    },
  });
