import { getFundBackedGrantVestmentAPI, getFundBackedGrantVestmentTotalAPI } from "api/fund-backed-grant-vestment.api";
import { useInfiniteQuery, useQuery } from "react-query"
import { FundBackedGrantVestment } from "types/fund-backed-grant-vestment";
import { PaginatedEntityList } from "types/pagination";

const GRANT_VESTMENT_RQ_KEY = 'fund-backed-grant-vestments';

export const useGetFundBackedGrantVestmentQuery = (
  query: {
    fundId: number;
    limit?: number;
    offset?: number;
  }
) => {
  return useInfiniteQuery<PaginatedEntityList<FundBackedGrantVestment>>({
    queryKey: [GRANT_VESTMENT_RQ_KEY, query],
    enabled: query.fundId !== 0,
    queryFn: ({ pageParam = 0 }) => getFundBackedGrantVestmentAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetFundBackedGrantVestmentTotalQuery = ({ fundId, grantvestmentId }: {
  fundId: number,
  grantvestmentId?: number
}) => {
  return useQuery<{
    totalAmount: number;
  }>({
    queryKey: [GRANT_VESTMENT_RQ_KEY, fundId],
    enabled: fundId !== 0,
    queryFn: () => getFundBackedGrantVestmentTotalAPI(fundId, grantvestmentId),
  })
}
