import React, { FormEvent } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { User, UserError } from "types/user";
import { SuccessorIndividual, SuccessorError } from "types/successor";
interface AddressProps {
  settings?: boolean;
  user: SuccessorIndividual | User;
  handleChange: (key: string, value: string) => void;
  handleInvalid: (event: FormEvent<HTMLElement>) => void;
  error: UserError | SuccessorError;
}
export const Address: React.FC<AddressProps> = ({
  settings,
  user,
  handleChange,
  handleInvalid,
  error,
}) => {
  return (
    <Accordion defaultExpanded={!settings}>
      <AccordionSummary
        tabIndex={-1}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="address"
        id="address"
        className="hasText"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          Address{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="hasText">
        <Typography sx={{ color: `neutral.dark` }} variant="bodyCopyRegular">
          Please enter here your personal mailing address
        </Typography>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormControl error={error.addressLine1} fullWidth>
              <InputLabel shrink={false}>Address line 1*</InputLabel>
              <OutlinedInput
                name="addressLine1"
                value={user.addressLine1}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) =>
                  handleChange("addressLine1", event.target.value)
                }
                type="text"
                required
              />
              {error.addressLine1 && (
                <FormHelperText color={"red"}>
                  Address is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>Address line 2</InputLabel>
              <OutlinedInput
                name="addressLine2"
                value={user.addressLine2}
                onChange={(event) =>
                  handleChange("addressLine2", event.target.value)
                }
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <FormControl error={error.city} fullWidth>
              <InputLabel shrink={false}>City*</InputLabel>
              <OutlinedInput
                name="city"
                value={user.city}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) => handleChange("city", event.target.value)}
                type="text"
                required
              />
              {error.city && (
                <FormHelperText color={"red"}>City is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl error={error.state} fullWidth>
              <InputLabel shrink={false}>State*</InputLabel>
              <OutlinedInput
                name="state"
                value={user.state}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) => handleChange("state", event.target.value)}
                type="text"
                required
              />
              {error.state && (
                <FormHelperText color={"red"}>State is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl error={error.zip} fullWidth>
              <InputLabel shrink={false}>ZIP* </InputLabel>
              <OutlinedInput
                name="zip"
                value={user.zip}
                onInvalid={(event) => handleInvalid(event)}
                onChange={(event) => handleChange("zip", event.target.value)}
                type="text"
                required
              />
              {error.zip && (
                <FormHelperText color={"red"}>ZIP is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
