import React, { useContext } from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import { warm } from "style/color";
import { NetaSetting } from "constants/neta-settings";
import { NetaSettingContext } from "providers/netaSettingsProvider";

export const OtherPaymentDetails: React.FC = () => {
  const NETA = useContext<NetaSetting>(NetaSettingContext);

  return (
    <Grid item xs={12} sm={12}>
      <Card sx={{ backgroundColor: warm[100], boxShadow: "none" }}>
        <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography
                className="title"
                variant="smallCopy"
                sx={{ color: `neutral.dark` }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="bodyCopyBold" sx={{ color: `neutral.main` }}>
                {NETA.supportEmail}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                className="title"
                variant="smallCopy"
                sx={{ color: `neutral.dark` }}
              >
                Phone
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="bodyCopyBold" sx={{ color: `neutral.main` }}>
                {NETA.phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
