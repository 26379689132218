import { GetFundFee, getFundFeeAPI } from "api/fund-fee.api";
import { useQuery } from "react-query";
import { FundFee } from "types/fund-fee";

const FUND_FEE_KEY = "fund-fee";
export const useGetFundFeeQuery = (query: GetFundFee) => {
  return useQuery<FundFee>({
    queryKey: [FUND_FEE_KEY, query.feeId],
    enabled: query.feeId !== 0,
    queryFn: () => getFundFeeAPI(query),
  });
};
