import { GetInvestmentsQuery, getInvestmentAPI, getInvestmentsAPI, makeInvestmentRequestAPI } from "api/investments.api";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { Investment } from "types/Investment";
import PaginatedEntityList from "types/PaginatedEntityList";
import { InvestmentRequestInput } from "types/investment-request";

const INVESTMENT_RQ_KEY = "Investments";
export const useGetInvestmentsQuery = (query: GetInvestmentsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<Investment>>({
    queryKey: [INVESTMENT_RQ_KEY, query],
    queryFn: ({ pageParam = 0 }) =>
      getInvestmentsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.nextOffset;
    },
  });
};
export const useGetInvestmentQuery = ({ ticker }: { ticker: string }) => {
  return useQuery<Investment>({
    queryKey: [INVESTMENT_RQ_KEY, ticker],
    enabled: ticker !== "",
    queryFn: () => getInvestmentAPI(ticker),
  })
}
export const useMakeInvestmentRequestMutation = (ticker: string) => {
  return useMutation<unknown, unknown, InvestmentRequestInput>({
    mutationKey: [INVESTMENT_RQ_KEY, ticker],
    mutationFn: (data) => makeInvestmentRequestAPI(ticker, data),
  })
}
