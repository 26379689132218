import React from "react";
import { Typography } from "@mui/material";






export const Contact: React.FC = () => {

    return (<>
        <Typography variant="bodyCopyBold" paragraph>Contact us</Typography>
        <Typography sx={{ m: 0 }} variant="bodyCopyRegular" paragraph>+1 (646) 600-9433 </Typography>
        <Typography sx={{ m: 0 }} variant="bodyCopyRegular" paragraph>contact@netafoundation.org</Typography>
        <Typography sx={{ m: 0 }} variant="bodyCopyRegular" paragraph>2248 Broadway #1702 <br></br> New York, NY 10024 </Typography>




    </>
    );
}
export default Contact;







