import { getFutureGrantRequestsAPI, getGrantRequestAPI, getGrantRequestsAPI, GetGrantRequestsQuery, getScheduledGrantRequestAPI, GetScheduledGrantRequestsApi, GetScheduledGrantRequestsQuery, makeGrantRequestAPI, updateGrantRequestAPI, updateGrantRequestStatusAPI } from "api/grant-request.api";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { FutureGrantRequest, GrantRequest, GrantRequestInput, ScheduledGrantRequest } from "types/grant-request";
import PaginatedEntityList from "types/PaginatedEntityList";

const GRANT_REQUEST_RQ_KEY = "GrantRequest";


export const useMakeIGrantRequestMutation = (ein: string) => {
  return useMutation<unknown, unknown, GrantRequestInput>({
    mutationKey: [GRANT_REQUEST_RQ_KEY, ein],
    mutationFn: (data) => makeGrantRequestAPI(ein, data),
  })
}
export const useUpdateIGrantRequestMutation = (grantRequestId: number,
  fundId: number) => {
  return useMutation<unknown, unknown, GrantRequest>({
    mutationKey: [GRANT_REQUEST_RQ_KEY, grantRequestId, fundId],
    mutationFn: (data) => updateGrantRequestAPI(grantRequestId, fundId, data),
  })
}

export const useGetGrantRequestsQuery = (query: GetGrantRequestsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<GrantRequest>>({
    queryKey: [GRANT_REQUEST_RQ_KEY, query],
    enabled: query.filter?.fundAccountId !== 0,
    queryFn: ({ pageParam = 0 }) => getGrantRequestsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetFutureGrantRequestsQuery = (query: GetGrantRequestsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<FutureGrantRequest>>({
    queryKey: [GRANT_REQUEST_RQ_KEY, query],
    enabled: query.filter?.fundAccountId !== 0,
    queryFn: ({ pageParam = 0 }) => getFutureGrantRequestsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetScheduledGrantRequestsQuery = (query: GetScheduledGrantRequestsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<ScheduledGrantRequest>>({
    queryKey: [GRANT_REQUEST_RQ_KEY, query],
    enabled: query.filter?.fundAccountId !== 0,
    queryFn: ({ pageParam = 0 }) => GetScheduledGrantRequestsApi({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetGrantRequestQuery = (grantRequestId: number) => {
  return useQuery<GrantRequest>({
    queryKey: [GRANT_REQUEST_RQ_KEY, grantRequestId],
    enabled: grantRequestId !== 0,
    queryFn: () => getGrantRequestAPI(grantRequestId),
  })
}

export const useUpdateGrantRequestStatusMutation = (grantRequestId: number) => {
  return useMutation<unknown, unknown, {
    status: string
  }>({
    mutationKey: [GRANT_REQUEST_RQ_KEY, grantRequestId],
    mutationFn: (data) => updateGrantRequestStatusAPI(grantRequestId, data),
  });
}
export const useGetScheduledGrantRequestQuery = (scheduledGrantRequestId: number) => {
  return useQuery<ScheduledGrantRequest>({
    queryKey: [GRANT_REQUEST_RQ_KEY, scheduledGrantRequestId],
    enabled: scheduledGrantRequestId !== 0,
    queryFn: () => getScheduledGrantRequestAPI(scheduledGrantRequestId),
  })
}
