import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateBefore";
import { neutral } from "style/color";

interface Props {
  title: string;
  navigateTo: string;
}

export const CustomBreadcrumb: FC<Props> = ({ title, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(navigateTo)}
      className="footer"
      variant="text"
      sx={{
        color: neutral[900],
        "&:hover": {
          backgroundColor: "transparent",
          textDecoration: "underline",
        },
      }}
      startIcon={<NavigateNextIcon />}
    >
      <Typography variant="bodyCopyBold">{title}</Typography>
    </Button>
  );
};
