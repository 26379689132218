import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { ContributionReceiptsList } from "components/contribution-transaction/contribution-recipits/contribution-receipts-list";
import { ContributionReceiptsPage } from "constants/pages";
import { useCancelContributionTransactionMutation, useGetContributionTransactionsQuery } from "hooks/use-services/contribution-transaction.service";
import { AuthContext } from "providers/userProvider";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "types/auth";
import ContributionTransaction, {
  TRANSACTION_STATUS,
} from "types/contribution-transaction";

interface MyPendingContributionListProps {
  dashboardStyle?: boolean;
  limit?: number;
}

export const MyPendingContributionList: React.FC<
  MyPendingContributionListProps
> = ({ dashboardStyle: DashboardStyle = false, limit = 30 }) => {
  const navigate = useNavigate()
  const { selectedAccount } = useContext<AuthData>(AuthContext);

  const { data: paginatedData, isLoading, refetch } = useGetContributionTransactionsQuery({
    limit: limit,
    fundId: selectedAccount?.fundAccountId || 0,
    status: TRANSACTION_STATUS.PENDING,
  });
  const pendingContributionTransactions = useMemo<ContributionTransaction[]>(
    () =>
      paginatedData ? paginatedData?.pages?.flatMap((item) => item.data) : [],
    [paginatedData]
  );
  const { mutate: cancelContributionTransactionRequest } =
    useCancelContributionTransactionMutation(selectedAccount?.fundAccountId || 0);

  const cancelContribution = (id: number) => {
    cancelContributionTransactionRequest(id, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  return (
    ((DashboardStyle && pendingContributionTransactions.length !== 0) || !DashboardStyle) ?
      <Grid item xs={12} sm={12}>
        <Card sx={{ boxShadow: "none", p: 4 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ color: `neutral.main` }}
                variant="subtitle3"
                gutterBottom
              >
                Pending contributions{" "}
                {DashboardStyle && <Button
                  onClick={() => navigate(ContributionReceiptsPage)}
                  className="link"
                  variant="text"
                  color="primary"
                  size="large"
                >
                  See all
                </Button>}
              </Typography>
            </Box>
            {isLoading && (
              <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            )}
            {pendingContributionTransactions &&
              pendingContributionTransactions.length !== 0 && (
                <ContributionReceiptsList
                  cancelContribution={cancelContribution}
                  pending={true}
                  contributionTransactions={pendingContributionTransactions}
                  dashboardStyle={DashboardStyle}
                />
              )}
          </CardContent>
        </Card>
      </Grid> : <></>

  );
};
