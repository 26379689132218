import React from "react";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Button
} from "@mui/material";

import ContributionTransaction, { CashInformation, SecuritiesInformation, TRANSACTION_TYPE_ENUM } from "types/contribution-transaction";
import CloseIcon from '@mui/icons-material/Close';
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
interface CancelContributionProps {
    contributionTransaction: ContributionTransaction,
    open: boolean;
    onClose: () => void;
    cancelContribution: (id: number) => void;
}
export const CancelContribution: React.FC<
    CancelContributionProps
> = ({ open, onClose, contributionTransaction, cancelContribution }) => {
    const submit = () => {
        cancelContribution(contributionTransaction.contributionId);
        onClose()
    }
    return (
        <>
            {
                contributionTransaction &&
                <Dialog sx={{ m: 2 }} fullWidth={true}
                    maxWidth={'sm'} open={open} onClose={onClose}>

                    <DialogTitle sx={{ my: 2, pl: 4 }}>
                        <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                            Are you sure to want to cancel this contribution?
                            <IconButton
                                onClick={onClose}
                                sx={{
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    }
                                }}
                            > <CloseIcon />
                            </IconButton>

                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ pl: 4 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: "neutral" }} variant='bodyCopyRegular'>
                                    By deleting this contribution you are indicating that you are no longer planning on transferring the money or securities to your fund.


                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <List className='successor' sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    < > <ListItem
                                        sx={{ display: '-webkit-inline-box' }}
                                    >
                                        <Grid alignItems='center' container>
                                            <Grid item xs={12} sm={4} textAlign={'start'}>

                                                <ListItemText sx={{ display: 'inline-grid', width: 'auto', flex: 'none' }}
                                                    primary={<Typography sx={{ color: `primary.main` }} variant='bodyCopyBold' gutterBottom>
                                                        Initiated on {shortFormat(contributionTransaction.createdAt)}


                                                    </Typography>}
                                                    secondary={<Typography sx={{ color: `neutral.dark` }} variant='smallCopyBold' >{`By ${contributionTransaction.user.firstName} ${contributionTransaction.user.lastName}`}</Typography>}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign={'center'}>
                                                <Typography variant='bodyCopyRegular' sx={{ color: "neutral.main", textTransform: 'capitalize' }}>{contributionTransaction.type.toLowerCase()}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign={'end'}>
                                                <Typography sx={{ color: `primary.main` }} variant='bodyCopyBold' gutterBottom>
                                                    {
                                                        contributionTransaction.type === TRANSACTION_TYPE_ENUM.CASH &&
                                                        <> ${moneyFormat((contributionTransaction.contributionInformation as CashInformation).requestedAmount)}</>

                                                    }
                                                    {
                                                        contributionTransaction.type === TRANSACTION_TYPE_ENUM.SECURITIES &&
                                                        <> {(contributionTransaction.contributionInformation as SecuritiesInformation).quantity} {(contributionTransaction.contributionInformation as SecuritiesInformation).securityName}</>

                                                    }
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </ListItem>


                                    </>

                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={'center'}>
                                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose} >
                                    Don’t cancel
                                </Button>
                                <Button variant="contained" color='primary' size='large' onClick={submit}    >
                                    Cancel contribution
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

        </>
    );
};
