import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import React, { useContext, useMemo } from "react";
import { neutral, warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BalanceBox from "components/shared/balance-box";
import { InvestmentRequestActivityCard } from "./investment-request-activity-card";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { AuthData } from "types/auth";
import { AuthContext } from "providers/userProvider";
import {
  INVESTMENT_REQUEST_STATUS_ENUM,
  InvestmentRequest,
} from "types/investment-request";
import { LoadingCard } from "components/loader/loading-card";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import {
  InvestmentsBuyRequestPagePath,
  InvestmentsSellRequestPagePath,
} from "constants/pages";
import {
  useGetFundInvestmentTotalQuery,
  useGetInvestmentRequestsQuery,
} from "hooks/use-services/investment-request.service";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
interface InvestmentRequestDetailsProps {}

export const InvestmentRequestDetails: React.FC<
  InvestmentRequestDetailsProps
> = () => {
  let { ticker } = useParams();
  const { selectedAccount, userData, userRole } =
    useContext<AuthData>(AuthContext);
  const navigate = useNavigate();

  const {
    data,
    fetchNextPage,
    isSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetInvestmentRequestsQuery({
    limit: 10,
    filter: {
      ticker: ticker,
      fundId: selectedAccount?.fundAccountId,
    },
  });
  const { data: fundInvestmentTotal } = useGetFundInvestmentTotalQuery({
    fundId: (selectedAccount?.fundAccountId as number) || 0,
    ticker: ticker,
  });
  const investmentsRequestsData = useMemo<InvestmentRequest[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });

  return (
    <Layout>
      {() => (
        <Grid sx={{ my: 4, width: "100%" }}>
          <Grid item xs={12} sm={9}>
            <BackToPreviousButton />
          </Grid>
          <BalanceBox
            name={investmentsRequestsData[0]?.investment.name}
            children={
              <>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ px: 6 }}
                  disabled={
                    userRole === USER_ROLE.VIEWER ||
                    userData?.user?.status === USER_STATUS.PENDING ||
                    !investmentsRequestsData[0]?.investment.isAFP
                  }
                  onClick={() => {
                    ticker &&
                      navigate({
                        pathname: InvestmentsBuyRequestPagePath,
                        search: `?${createSearchParams({ ticker: ticker })}`,
                      });
                  }}
                >
                  Buy
                </Button>
                <Button
                  startIcon={<RemoveIcon />}
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{ px: 6 }}
                  disabled={
                    userRole === USER_ROLE.VIEWER ||
                    userData?.user?.status === USER_STATUS.PENDING
                  }
                  onClick={() => {
                    ticker &&
                      navigate({
                        pathname: InvestmentsSellRequestPagePath,
                        search: `?${createSearchParams({ ticker: ticker })}`,
                      });
                  }}
                >
                  Sell
                </Button>
              </>
            }
            total={
              fundInvestmentTotal?.totalAmount
                ? fundInvestmentTotal?.totalAmount
                : 0
            }
          />
          {isLoading ? (
            <LoadingCard nbElement={1} height={100} color={warm[500]} />
          ) : (
            <Grid xs={12} sm={4} mx={3}>
              <Card
                sx={{
                  backgroundColor: warm[500],
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"baseline"}
                    sx={{
                      pt: 1,
                    }}
                  >
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="smallCopy"
                        sx={{
                          color: neutral[700],
                          display: "flex",
                        }}
                        gutterBottom
                      >
                        Current value
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "baseline",
                        gap: 1,
                      }}
                      xs={12}
                      md={4}
                    >
                      <Typography variant="subtitle1" component={"div"}>
                        $
                        {moneyFormat(
                          investmentsRequestsData[0]?.investment?.marketPrice,
                          6
                        )}
                      </Typography>
                      <ArrowOutwardIcon
                        fontSize="small"
                        sx={{
                          color: neutral[900],
                          width: "16.72px",
                          height: "16.72px",
                          alignSelf: "center",
                        }}
                      />
                      <Typography
                        component={"div"}
                        variant="smallCopyBold"
                        sx={{
                          color: neutral[900],

                          alignSelf: "center",
                        }}
                      >
                        {investmentsRequestsData[0]?.investment?.dailyChange >=
                        0
                          ? `+${moneyFormat(
                              investmentsRequestsData[0]?.investment
                                ?.dailyChange,
                              2
                            )}`
                          : moneyFormat(
                              investmentsRequestsData[0]?.investment
                                ?.dailyChange,
                              2
                            )}
                        %{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid sx={{ pt: 5, px: 4 }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: neutral[900],
              }}
              gutterBottom
            >
              YOUR ACTIVITY
            </Typography>
            {isLoading ? (
              <LoadingCard nbElement={3} height={100} color={"white"} />
            ) : (
              <Grid xs={12} sm={4}>
                {ticker && isSuccess && investmentsRequestsData.length > 0 ? (
                  investmentsRequestsData.map((invReq: InvestmentRequest) => (
                    <InvestmentRequestActivityCard
                      date={
                        invReq?.executionDate &&
                        invReq.status ===
                          INVESTMENT_REQUEST_STATUS_ENUM.APPROVED
                          ? new Date(invReq.executionDate)
                          : new Date(invReq.createdAt)
                      }
                      status={invReq.status as INVESTMENT_REQUEST_STATUS_ENUM}
                      user={{
                        firstName: invReq.user.firstName,
                        lastName: invReq.user.lastName,
                      }}
                      quantity={invReq.appliedUnits}
                      amount={invReq.appliedAmount}
                      type={invReq.type}
                      ticker={ticker!}
                      id={invReq.investmentRequestId}
                    />
                  ))
                ) : (
                  <Typography variant="subtitle3">
                    No investment request found.
                  </Typography>
                )}
                <Box ref={lastElementRef}>
                  {isFetchingNextPage && (
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      textAlign={"center"}
                      sx={{ m: 3 }}
                    >
                      {" "}
                      <CircularProgress color="primary" />
                    </Grid>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};
