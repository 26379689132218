import React, { useContext } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography
} from "@mui/material";
import { warm, primary, neutral } from "style/color";
import { moneyFormat } from "utils/money-format";
import { Account, USER_ROLE } from "types/account";
import { FundDashboardPage } from "constants/pages";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useNavigate } from "react-router-dom";
import { MyFundsMenu } from "./my-funds-menu";
import { useGetFundPortfolioStatisticsQuery } from "hooks/use-services/movements.service";
interface MyFundsCardProps {
    fund: Account;
    role: USER_ROLE;
}
export const MyFundsCard: React.FC<
    MyFundsCardProps
> = ({
    fund, role
}) => {
        const navigate = useNavigate();
        const { setSelectedAccount, setUserRole } = useContext<AuthData>(AuthContext);
        const handleAccountChange = (event: any) => {
            event.preventDefault();
            event.stopPropagation();
            localStorage.setItem(
                "selectedAccount",
                fund?.fundAccountId?.toString()!
            );
            setSelectedAccount(fund!);
            setUserRole(role!)
            navigate(FundDashboardPage)
        };
        const { data: fundPortfolioStatistcs } = useGetFundPortfolioStatisticsQuery(
            fund?.fundAccountId as number || 0,
        );
        return (
            <Grid onClick={(event) => handleAccountChange(event)} sx={{
                '& :hover': {
                    backgroundColor: warm[500], cursor: 'pointer'
                }
            }} key={fund.fundAccountId} item xs={12} md={4} >
                <Card >
                    <CardHeader
                        action={
                            <MyFundsMenu />
                        }
                        title={
                            <Typography variant='subtitle1' sx={{ color: primary[500] }}>
                                {fund.fundAccountName}
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Typography className="title" variant='smallCopy' sx={{ color: neutral[900] }} gutterBottom>
                            Total value
                        </Typography>
                        <Typography variant='subtitle1' sx={{ color: neutral[900] }}>
                            ${moneyFormat(fundPortfolioStatistcs?.totalValue || 0)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    };
