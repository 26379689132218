import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Layout from "components/layout/layout";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { neutral } from "style/color";
import AddIcon from "@mui/icons-material/Add";
import { AddFundPage } from "constants/pages";
import { MyFundsCard } from "./my-funds-card";

export const MyFundsPage = () => {
  const navigate = useNavigate();
  const { userData } = useContext<AuthData>(AuthContext);
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Box sx={{ mt: 3, mx: 3 }}>
                <Typography variant="h3" sx={{ color: neutral[900] }}>
                  Hello, {userData?.user?.firstName}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    mt: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className="title"
                    variant="subtitle2"
                    sx={{ color: neutral[900] }}
                  >
                    My funds
                  </Typography>
                  <Button
                    onClick={() => navigate(AddFundPage)}
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Add fund
                  </Button>
                </Box>
                <Grid sx={{ mt: 2 }} container spacing={2}>
                  {userData?.user?.fundAccounts?.map((fundMember) => {
                    return <MyFundsCard fund={fundMember.fund} role={fundMember.role}></MyFundsCard>;
                  })}
                </Grid>
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
