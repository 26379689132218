import React, { FormEvent } from 'react';
import { FormControl, Checkbox, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UserError } from 'types/user';
import { Entity, USER_ROLE } from 'types/account';
interface EntityInformationProps {
    settings?: boolean;
    entity: Entity;
    types: string[];
    handleEntityChange: (key: string, value: string) => void;
    handleInvalid: (event: FormEvent<HTMLElement>) => void;
    handleAddressChange?: () => void;
    error: UserError;
    role?: USER_ROLE
}
export const EntityInformation: React.FC<EntityInformationProps> = ({
    settings, entity, types, handleEntityChange, handleInvalid, handleAddressChange, error, role
}) => {
    const [sameAddress, setSameAddress] = React.useState<boolean>(false);

    const handleChange = (event: any) => {
        setSameAddress(event.target.checked);
        if (handleAddressChange) { handleAddressChange() }
    };
    return (
        <>
            <Accordion defaultExpanded>
                <AccordionSummary tabIndex={-1}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="entity"
                    id="entity"
                >
                    <Typography sx={{ color: `neutral.main` }} variant="subtitle1">Entity information </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid sx={{ mb: 3 }} container spacing={2}>

                        <Grid item xs={12} sm={10}>
                            <FormControl error={error.entityName} fullWidth>
                                <InputLabel shrink={false}>Entity name*</InputLabel>
                                <OutlinedInput name="entityName"
                                    disabled={role && role !== USER_ROLE.ADMIN}
                                    value={entity.entityName}
                                    onInvalid={(event) => handleInvalid(event)}
                                    onChange={(event) => handleEntityChange("entityName", event.target.value)} type="text" required />
                                {error.entityName && <FormHelperText color={'red'}>Entity name is required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormControl error={error.type} fullWidth>
                                <InputLabel shrink={false}>Select entity type*</InputLabel>
                                <TextField
                                    id="type-select"
                                    select
                                    required
                                    defaultValue="Please select"
                                    name="type"
                                    value={entity.type}
                                    error={error.fundAccountName}
                                    onChange={(event) => handleEntityChange("type", event.target.value)}
                                    onInvalid={(event) => handleInvalid(event)}
                                    helperText={(error.type) ? "Entity type is requierd" : ""}
                                    disabled={role && role !== USER_ROLE.ADMIN}
                                >
                                    {
                                        types.map((type, index) => {
                                            return (
                                                <MenuItem key={index} value={type}>{type}</MenuItem>
                                            )
                                        })
                                    }

                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormControl error={error.role} fullWidth>
                                <InputLabel shrink={false}>Indicate your role at the entity*</InputLabel>
                                <OutlinedInput name="role"
                                    disabled={role && role !== USER_ROLE.ADMIN}
                                    value={entity.role}
                                    onInvalid={(event) => handleInvalid(event)}
                                    onChange={(event) => handleEntityChange("role", event.target.value)} type="text" required />
                                {error.role && <FormHelperText color={'red'}>Role is required</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <>
                        <Typography sx={{ mt: 4, color: `neutral.main` }} variant="subtitle1" gutterBottom>Address </Typography>
                        <Typography sx={{ color: `neutral.dark` }} variant='bodyCopyRegular'>
                            {(settings) ? "Address to where contribution receipts and other official documents will be sent"
                                : "This is the address of the entity, to where communications (e.g., gift receipts) will be addressed."}
                        </Typography>
                        <Grid sx={{ mt: 2 }} container spacing={2}>
                            {!settings && <Grid item xs={12} sm={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sameAddress}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Reuse same address as personal   "
                                    name='reuse'
                                />
                            </Grid>}

                            <Grid item xs={12} sm={8}>

                                <FormControl error={error.firstAddressLine} fullWidth>
                                    <InputLabel shrink={false}>Address line 1*</InputLabel>
                                    <OutlinedInput disabled={sameAddress || (role && role !== USER_ROLE.ADMIN)} name="firstAddressLine"
                                        value={entity.firstAddressLine}
                                        onInvalid={(event) => handleInvalid(event)}
                                        onChange={(event) => handleEntityChange("firstAddressLine", event.target.value)} type="text" required />
                                    {error.firstAddressLine && <FormHelperText color={'red'}>
                                        Address is required</FormHelperText>}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={8}>

                                <FormControl fullWidth>
                                    <InputLabel shrink={false}>Address line 2</InputLabel>
                                    <OutlinedInput disabled={sameAddress || (role && role !== USER_ROLE.ADMIN)} name="secondAddressLine"
                                        value={entity.secondAddressLine}
                                        onChange={(event) => handleEntityChange("secondAddressLine", event.target.value)} type="text" /></FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={error.city} fullWidth>
                                    <InputLabel shrink={false}>City*</InputLabel>
                                    <OutlinedInput name="city" disabled={sameAddress || (role && role !== USER_ROLE.ADMIN)}
                                        value={entity.city}
                                        onInvalid={(event) => handleInvalid(event)}
                                        onChange={(event) => handleEntityChange("city", event.target.value)} type="text" required />
                                    {error.city && <FormHelperText color={'red'}>City is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl error={error.state} fullWidth>
                                    <InputLabel shrink={false}>State*</InputLabel>
                                    <OutlinedInput name="state" disabled={sameAddress || (role && role !== USER_ROLE.ADMIN)}
                                        value={entity.state}
                                        onInvalid={(event) => handleInvalid(event)}
                                        onChange={(event) => handleEntityChange("state", event.target.value)} type="text" required />
                                    {error.state && <FormHelperText color={'red'}>State is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl error={error.zip} fullWidth>
                                    <InputLabel shrink={false}>ZIP* </InputLabel>
                                    <OutlinedInput name="zip" disabled={sameAddress || (role && role !== USER_ROLE.ADMIN)}
                                        value={entity.zip}
                                        onInvalid={(event) => handleInvalid(event)}
                                        onChange={(event) => handleEntityChange("zip", event.target.value)} type="text" required />
                                    {error.zip && <FormHelperText color={'red'}>ZIP required</FormHelperText>}
                                </FormControl>
                            </Grid>


                        </Grid>
                    </>
                </AccordionDetails>
            </Accordion>
        </>




    )
}
