import { useContext } from "react";
import Layout from "../../components/layout/layout";
import { Box } from "@mui/material";

import { AuthContext } from "../../providers/userProvider";
import { useLocation } from "react-router-dom";
import { AuthData } from "types/auth";
import GrantVestmentsDetailsCard from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-card";
import GrantVestmentsDetailsTags from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tags";
import GrantVestmentsDetailsTitles from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-titles";
import GrantVestmentsDetailsTabs from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tabs";
import { useGetGrantVestmentQuery } from "hooks/use-services/grant-vestment.service";

export const Grantvestment = () => {
    const { state } = useLocation();
    const { userData } = useContext<AuthData>(AuthContext)
    const { data: grantvestment } = useGetGrantVestmentQuery({
        grantVestmentId: parseInt(state.id || "0"),
    });
    return (
        <Layout>
            {(handleOpen: (value: boolean) => void) => (
                <Box sx={{ m: 4 }}>
                    {
                        grantvestment &&
                        <>
                            {/* details-titles */}
                            <GrantVestmentsDetailsTitles grantvestment={grantvestment!} />
                            {/* details-card */}
                            <GrantVestmentsDetailsCard handleOpen={handleOpen} grantvestment={grantvestment!} isAuthenticated={userData?.user !== null} />
                            {/* details-tags */}
                            <GrantVestmentsDetailsTags grantvestment={grantvestment!} />
                            {/* details-tabs */}
                            <GrantVestmentsDetailsTabs grantvestment={grantvestment!} isAuthenticated={userData?.user !== null} handleOpen={handleOpen} />
                        </>

                    }

                </Box >
            )}

        </Layout >);
}
