import React, { FormEvent, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Layout from 'components/layout/layout';
import { FundSettingsPage, RolesPermissionsPagePath } from 'constants/pages';
import { useNavigate, useParams } from 'react-router-dom';
import { USER_ROLE } from 'types/account';
import { RolesRadioGroup } from 'components/roles-permissions/roles';
import { useGetFundRoleQuery, useUpdateUserInvitationMutation, useUpdateUserRoleMutation } from 'hooks/use-services/fund-account-invitations.service';
import { FUND_USERS_ROLES_TYPE_ENUM } from 'types/FundUsersRoles';
export const ChangeUserRolePage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data } = useGetFundRoleQuery(parseInt(id || '0'));
    const { mutate } = useUpdateUserInvitationMutation(parseInt(id || '0'))
    const { mutate: updateUserRole } = useUpdateUserRoleMutation(parseInt(id || '0'))



    const [user, setUser] = useState<any>({
        role: USER_ROLE.ADMIN
    })
    useEffect(() => {
        if (data) {
            setUser({
                ...data,
            })
        }

    }, [data])
    const handleChange = (key: string, value: string) => {
        setUser({
            ...user,
            [key]: value
        })
    }

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (user.type === FUND_USERS_ROLES_TYPE_ENUM.INVITED) {
            mutate({
                email: user.email,
                role: user.role
            }, {
                onSuccess: () => navigate(-1),
            });
        }
        else {
            updateUserRole({
                role: user.role
            }, {
                onSuccess: () => navigate(-1),
            });
        }

    }
    return (
        <Layout>
            {() => (
                <>
                    <Box component="form"
                        autoComplete="off"
                        onSubmit={submit} sx={{ my: 4, mx: 3 }}>
                        <React.Fragment>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Link underline="hover" color="inherit" onClick={() => navigate(FundSettingsPage)}>
                                    <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Fund settings</Typography>
                                </Link>
                                <Link underline="hover" color="inherit" onClick={() => navigate(RolesPermissionsPagePath)}>
                                    <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Roles & permissions</Typography>
                                </Link>
                                <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Change role</Typography>
                            </Breadcrumbs>
                            <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                                <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                                    <Typography variant='h3'>
                                        Change role
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Card sx={{ boxShadow: 'none', p: 4 }} >
                                        <CardContent>
                                            <Typography sx={{ color: `neutral.main` }} variant="subtitle1" gutterBottom>{data?.email}  </Typography>
                                            <Typography sx={{ color: `neutral.main`, mb: 4 }} variant='bodyCopyRegular' gutterBottom>{data?.user}  </Typography>

                                            <Grid container spacing={2}>


                                                <RolesRadioGroup userInvite={user} handleChange={handleChange} />


                                            </Grid>


                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={10} textAlign={'end'} sx={{ my: 4 }}>
                                    <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={() => navigate(-1)}  >
                                        Discard
                                    </Button>
                                    <Button variant="contained" color='primary' size='large' type='submit' >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>






                        </React.Fragment>

                    </Box>
                </>)}
        </Layout>



    )
}
