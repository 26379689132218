import { Account } from "./account";
import { Charity } from "./charity";
import { INVESTMENT_REQUEST_INTEREST_ENUM } from "./investment-request";
import { User } from "./user";

export interface CashInfo {
  requestedAmount: number;
  appliedAmount?: number;
}

export interface InvestmentInfo {
  requestedAmount: number;
  appliedAmount?: number;
  interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM;
  units: number;
  ticker: string;
}
export interface RecurrentSchedule {
  startingOn: Date;
  endingOn: Date;
  frequency: GRANT_FREQUENCY_ENUM;
  lastExecutionDate: Date;
  nextExecutionDate: Date;
}
export interface RecurrentScheduleInput {
  startingOn: Date;
  endingOn: Date;
  frequency: GRANT_FREQUENCY_ENUM;
}
export interface OnTimeSchedule {
  now: boolean;
  executionDate: Date;
}
export interface Acknowledgement {
  anonymous: boolean;
  showFundName: boolean;
  showDonorAddress: boolean;
  donorAddress: string;
  thirdPartNameAndAddress?: string;
  inHonorOf?: string;
  inMemoryOf?: string;
  thirdPartiesEmails: string[];
  visibility: boolean;
}
export interface GrantPurpose {
  type: GRANT_PURPOSE_ENUM;
  note?: string;
}
export enum GRANT_REQUEST_STATUS_ENUM {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  FULFILLED = "FULFILLED",
  STOPPED = "STOPPED",
  SCHEDULED = "SCHEDULED",
  PROCESSED = "PROCESSED",
}
export enum GRANT_REQUEST_TYPE_ENUM {
  ONETIME = "ONETIME",
  RECURRENT = "RECURRENT",
}
export enum GRANT_PURPOSE_ENUM {
  UNRESTRICTED = "UNRESTRICTED",
  SPECIFIC = "SPECIFIC",
}
export enum GRANT_FREQUENCY_ENUM {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
}
export enum GRANT_REQUEST_TRANSACTION_TYPE_ENUM {
  CASH = "CASH",
  INVESTMENT = "INVESTMENT",
}
export interface GrantRequest {
  grantRequestId: number;
  charity: Charity;
  fundAccount: Account;
  user: User;
  status: GRANT_REQUEST_STATUS_ENUM;
  type: GRANT_REQUEST_TYPE_ENUM;
  transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM;
  toInform: string;
  information: CashInfo | InvestmentInfo;
  scheduleInformation: RecurrentSchedule | OnTimeSchedule;
  acknowledgement: Acknowledgement;
  grantPurpose: GrantPurpose;
  rejectionReason?: string;
  scheduledGrantRequests: ScheduledGrantRequest[];
}

export interface GrantRequestInput {
  fundId: number;
  sub: string;
  type: GRANT_REQUEST_TYPE_ENUM;
  transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM;
  toInform: string;
  information: CashInfo | InvestmentInfo;
  scheduleInformation: RecurrentScheduleInput | OnTimeSchedule;
  acknowledgement: Acknowledgement;
  grantPurpose: GrantPurpose;
}
export const getExecutionDate = (request: GrantRequest) => {
  if (request.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT) {
    return (request.scheduleInformation as RecurrentSchedule).startingOn;
  } else {
    return (request.scheduleInformation as OnTimeSchedule).executionDate;
  }
};
export const getFrequency = (
  type: GRANT_REQUEST_TYPE_ENUM,
  frequency: GRANT_FREQUENCY_ENUM
) => {
  if (type === GRANT_REQUEST_TYPE_ENUM.RECURRENT) {
    switch (frequency) {
      case GRANT_FREQUENCY_ENUM.YEARLY:
        return "12 months";
      case GRANT_FREQUENCY_ENUM.MONTHLY:
        return "month";
      case GRANT_FREQUENCY_ENUM.QUARTERLY:
        return "3 months";
    }
  }
};
export enum SCHEDULED_GRANT_REQUEST_STATUS_ENUM {
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
}
export interface ScheduledGrantRequest {
  scheduledGrantRequestId: number;
  grantRequest: GrantRequest;
  appliedAmount: number;
  executionDate: Date;
  status: SCHEDULED_GRANT_REQUEST_STATUS_ENUM;
  receipt?: {
    url: string;
    fileName: string;
  };
}

export interface FutureGrantRequest {
  grantRequestId: number;
  userId: number;
  fundId: number;
  charityName: string;
  charityEin: string;
  charityCountry: string;
  type: GRANT_REQUEST_TYPE_ENUM;
  frequency: GRANT_FREQUENCY_ENUM;
  amount: number;
  status: GRANT_REQUEST_STATUS_ENUM;
  date: Date;
  endingDate: Date;
}
