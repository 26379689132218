
import awsExports from 'aws-exports';
import { UserSettingsPage } from 'constants/pages';
import { AuthContext } from 'providers/userProvider';
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom";
import { USER_ROLE } from 'types/account';
import { AuthData } from 'types/auth';
import { USER_STATUS } from 'types/fund-account-invitation';

interface PrivateRouteProps {
  element: React.ReactElement;
  roles: USER_ROLE[];
  pending?: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, roles, pending }) => {
  const { userData, userRole } = useContext<AuthData>(AuthContext);
  const loggedIn = localStorage.getItem("loggedIn");

  if (typeof window !== "undefined" && loggedIn === "true") {
    if (userRole && !roles.includes(userRole!)) {
      <Navigate to={UserSettingsPage} />

    }
    if (!pending && userData?.user?.status === USER_STATUS.PENDING) {
      return <Navigate to={UserSettingsPage} />;

    }
  }
  else {
    if (awsExports.endpoints[0].endpoint.includes('prod')) {
      window.location.replace("https://www.netafoundation.org/");
      return null;
    }
    else {
      return <Navigate to="/signIn" />;
    }

  }



  return element;
};

export default PrivateRoute;
