import React, { FormEvent } from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SuccessorCharity, SuccessorError } from 'types/successor';
interface CharityDetailSuccessorProps {
    settings?: boolean;
    successor: SuccessorCharity;
    handleChange: (key: string, value: string) => void;
    handleInvalid: (event: FormEvent<HTMLElement>) => void;
    error: SuccessorError
}
export const CharityDetailSuccessor: React.FC<CharityDetailSuccessorProps> = ({
    settings, successor, handleChange, handleInvalid, error }) => {
    return (
        <Accordion defaultExpanded={!settings}>
            <AccordionSummary tabIndex={-1}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="details"
                id="details"
            >
                <Typography sx={{ color: `neutral.main` }} variant="subtitle1">Details </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={8}>
                        <FormControl error={error.name} fullWidth>
                            <InputLabel shrink={false}>Name* </InputLabel>
                            <OutlinedInput name="name"
                                value={successor.name}
                                onInvalid={(event) => handleInvalid(event)}
                                onChange={(event) => handleChange("name", event.target.value)} type="text" required />
                            {error.name && <FormHelperText color={'red'}>Name is required</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormControl error={error.ein} fullWidth>
                            <InputLabel shrink={false}>EIN* </InputLabel>
                            <OutlinedInput name="ein"
                                value={successor.ein}
                                onInvalid={(event) => handleInvalid(event)}
                                onChange={(event) => handleChange("ein", event.target.value)} type="text" required />
                            {error.ein && <FormHelperText color={'red'}>EIN is required</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormControl fullWidth>
                            <InputLabel shrink={false}>Specific instruction </InputLabel>
                            <OutlinedInput name="instruction"
                                value={successor.specificInstruction}
                                onChange={(event) => handleChange("specificInstruction", event.target.value)} type="text" multiline
                                rows={4} />

                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>



    )
}
