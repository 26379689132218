import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { CustomBreadcrumb } from "components/shared/custom-breadcrumb";
import { CustomStepper } from "components/shared/custom-stepper";
import { FundDashboardPage } from "constants/pages";
import { ReactComponent as CashIcon } from "./../../../assets/Icons/Cash.svg";
import { ReactComponent as EditIcon } from "./../../../assets/Icons/edit.svg";

import { ReactComponent as Localization } from "./../../../assets/Icons/localization.svg";
import { CustomAccordion } from "components/shared/custom-accordion";
import { GrantDetails } from "./grant-request-details";
import { GrantAcknowledgementDetails } from "./grant-request-acknowledgement";
import { GrantSummary } from "./grant-request-summary";
import { GrantSuccess } from "./grant-request-success";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useGetCharityQuery } from "hooks/use-services/charity.service";
import { neutral, primary, warm } from "style/color";
import {
  CashInfo,
  GRANT_PURPOSE_ENUM,
  GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  GrantRequestInput,
  InvestmentInfo,
} from "types/grant-request";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { moneyFormat } from "utils/money-format";
import { useMakeIGrantRequestMutation } from "hooks/use-services/grant-request.service";
import { GrantRequestCharityList } from "./grant-request-charities-list";
import { einFormat } from "types/charity";
import { INVESTMENT_REQUEST_INTEREST_ENUM } from "types/investment-request";

const GrantRequestHomePage = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const { selectedAccount, userData } = useContext<AuthData>(AuthContext);
  const [ein, setEin] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [exchangeValue, setExchangeValue] = useState<boolean>(false);
  const [obligationPayment, setObligationPayment] = useState<boolean>(false);
  const [grantRequest, setGrantRequest] = useState<GrantRequestInput>({
    sub: userData?.sub!,
    fundId: selectedAccount?.fundAccountId!,
    type: GRANT_REQUEST_TYPE_ENUM.ONETIME,
    transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH,
    toInform: "",
    information: {
      requestedAmount: "" as any,
    },
    scheduleInformation: {
      now: true,
      executionDate: new Date(),
    },
    acknowledgement: {
      anonymous: false,
      showFundName: true,
      showDonorAddress: false,
      donorAddress: "",
      inHonorOf: "",
      inMemoryOf: "",
      thirdPartiesEmails: [""],
      visibility: true,
    },
    grantPurpose: {
      type: GRANT_PURPOSE_ENUM.UNRESTRICTED,
      note: "",
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const initialized = useRef(false);
  const { data: charity, isSuccess } = useGetCharityQuery(ein);
  const { mutate, isLoading } = useMakeIGrantRequestMutation(ein);
  const handleTransactionTypeChange = (
    transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
    information: any
  ) => {
    setGrantRequest({
      ...grantRequest,
      transactionType: transactionType,
      information: information,
      type: GRANT_REQUEST_TYPE_ENUM.ONETIME,
    });
  };
  const handleTypeChange = (
    type: GRANT_REQUEST_TYPE_ENUM,
    scheduleInformation: any
  ) => {
    setGrantRequest({
      ...grantRequest,
      type: type,
      scheduleInformation: scheduleInformation,
    });
  };
  const handleGrantRequestChange = (key: string, value: any) => {
    setGrantRequest({
      ...grantRequest,
      [key]: value,
    });
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (searchParams && searchParams.get("ein")) {
        setEin(searchParams.get("ein") || "");
        setActiveStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  const getAmount = () => {
    if (
      grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH
    ) {
      return `$${moneyFormat(
        +(grantRequest.information as CashInfo).requestedAmount
      )}`;
    }
    if (
      grantRequest.transactionType ===
      GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT
    ) {
      const interestedIn = (grantRequest.information as InvestmentInfo).interestedIn;
      if (interestedIn === INVESTMENT_REQUEST_INTEREST_ENUM.AMOUNT) {
        return `$${moneyFormat(
          +(grantRequest.information as InvestmentInfo).requestedAmount
        )}`;
      }
      else {
        return `${moneyFormat(
          +(grantRequest.information as InvestmentInfo).units,
          4,
          2
        )} unit(s) of ${(grantRequest.information as InvestmentInfo).ticker} `;
      }
    }
  };
  const submit = () => {
    window.scrollTo(0, 0);
    let newGrantRequest = grantRequest;
    if (
      grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH
    ) {
      (newGrantRequest.information as CashInfo).requestedAmount = +(
        grantRequest.information as CashInfo
      ).requestedAmount;
    }
    if (
      grantRequest.transactionType ===
      GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT
    ) {
      (newGrantRequest.information as InvestmentInfo).requestedAmount = +(
        grantRequest.information as InvestmentInfo
      ).requestedAmount;
      (newGrantRequest.information as InvestmentInfo).units = +(
        grantRequest.information as InvestmentInfo
      ).units;
    }
    if (
      grantRequest.acknowledgement.thirdPartiesEmails.length === 1 &&
      grantRequest.acknowledgement.thirdPartiesEmails[0] === ""
    ) {
      newGrantRequest.acknowledgement.thirdPartiesEmails = [];
    }
    newGrantRequest.fundId = selectedAccount?.fundAccountId!;
    newGrantRequest.sub = userData?.sub!;
    mutate(newGrantRequest, {
      onSuccess: () => setActiveStep(3),
    });
  };
  const selectCharity = (ein: string) => {
    setEin(ein);
    setSearchParams({ ein: ein });
    setActiveStep(1);
  };
  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 5 }}>
          <CustomBreadcrumb
            title={"Back to fund dashboard "}
            navigateTo={FundDashboardPage}
          />
          <CustomStepper
            stepsTitles={[
              "Select charity",
              "Enter amount and details",
              "Confirm",
              "Request review",
            ]}
            activeStep={activeStep}
          />
          {activeStep === 0 && (
            <GrantRequestCharityList
              selectCharity={selectCharity}
              handleOpen={handleOpen}
            />
          )}
          {activeStep === 1 && isSuccess && (
            <Box
              component="form"
              autoComplete="off"
              onSubmit={(event) => {
                window.scrollTo(0, 0);
                setActiveStep(2);
              }}
            >
              <Typography variant="subtitle3" color={neutral[900]} gutterBottom>
                Charity information
              </Typography>
              <Typography variant="h3" color={neutral[900]}>
                {charity.name}
              </Typography>
              <Typography variant="bodyCopyRegular" color={neutral[600]}>
                {(charity.country === "USA") ? einFormat(charity.ein) : charity.ein}
              </Typography>
              <Grid container py={1} spacing={1}>
                <Grid item>
                  <Localization />
                </Grid>
                <Grid item>
                  <Typography variant="bodyCopyRegular" color={neutral[600]}>
                    {charity.street} {charity.street2},<br></br>
                    {charity.city}, {charity.state} {charity.zip},{" "}
                    {charity.country}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                  <CustomAccordion
                    title={"Enter grant details"}
                    defaultExpanded={true}
                  >
                    <GrantDetails
                      grantRequest={grantRequest}
                      setError={setError}
                      handleGrantRequestChange={handleGrantRequestChange}
                      handleTransactionTypeChange={handleTransactionTypeChange}
                      handleTypeChange={handleTypeChange}
                    />
                  </CustomAccordion>
                  <CustomAccordion
                    title={"Enter acknowledgement details"}
                    defaultExpanded={true}
                  >
                    <GrantAcknowledgementDetails
                      charity={charity}
                      grantRequest={grantRequest}
                      handleGrantRequestChange={handleGrantRequestChange}
                    />
                  </CustomAccordion>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      sx={{ paddingY: 2, paddingX: 3 }}
                      disabled={error}
                    >
                      Next
                    </Button>
                  </Box>
                </Grid>
                {((grantRequest.transactionType ===
                  GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH &&
                  (grantRequest.information as CashInfo).requestedAmount !==
                  0) ||
                  (grantRequest.transactionType ===
                    GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT &&
                    (grantRequest.information as InvestmentInfo)
                      .requestedAmount !== 0)) && (
                    <Grid item xs={12} sm={3}>
                      <Card sx={{ boxShadow: "none", mt: 2 }}>
                        <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: `neutral.main` }}
                            gutterBottom
                          >
                            Summary
                          </Typography>
                          <Typography
                            className="title"
                            variant="smallCopy"
                            sx={{ color: `neutral.main` }}
                            paragraph
                          >
                            To
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: `neutral.main` }}
                            gutterBottom
                          >
                            {charity.name}
                          </Typography>

                          <Divider
                            sx={{
                              backgroundColor: warm[600],
                              borderWidth: "1px",
                              my: 2,
                            }}
                          />
                          <Typography
                            className="title"
                            variant="smallCopy"
                            sx={{ color: `neutral.main` }}
                            paragraph
                          >
                            via
                          </Typography>
                          <Typography
                            variant="bodyCopyBold"
                            sx={{
                              color: `neutral.main`,
                              display: "flex",
                              alignItems: "center",
                            }}
                            paragraph
                          >
                            {grantRequest.transactionType ===
                              GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH && (
                                <>
                                  <SvgIcon
                                    sx={{
                                      mr: 2,
                                      "&  svg  path": {
                                        fill: neutral[900],
                                      },
                                      "&  svg  circle": {
                                        stroke: neutral[900],
                                      },
                                    }}
                                  >
                                    {" "}
                                    <CashIcon />
                                  </SvgIcon>{" "}
                                  Cash{" "}
                                </>
                              )}
                            {grantRequest.transactionType ===
                              GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
                                <>
                                  <SvgIcon
                                    sx={{
                                      mr: 2,
                                      "&  svg  path": {
                                        fill: neutral[900],
                                      },
                                      "&  svg  circle": {
                                        stroke: neutral[900],
                                      },
                                    }}
                                  >
                                    {" "}
                                    <CashIcon />
                                  </SvgIcon>{" "}
                                  Investments{" "}
                                </>
                              )}
                          </Typography>
                          {grantRequest.transactionType ===
                            GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
                              <>
                                <Divider
                                  sx={{
                                    backgroundColor: warm[600],
                                    borderWidth: "1px",
                                    my: 2,
                                  }}
                                />
                                <Typography
                                  className="title"
                                  variant="smallCopyBold"
                                  sx={{ color: neutral[600] }}
                                  paragraph
                                >
                                  {
                                    (grantRequest.information as InvestmentInfo)
                                      .ticker
                                  }
                                </Typography>
                                <Typography
                                  variant="bodyCopyRegular"
                                  sx={{ color: `neutral.main` }}
                                  paragraph
                                >
                                  $
                                  {moneyFormat(
                                    +(grantRequest.information as InvestmentInfo)
                                      .requestedAmount
                                  )}
                                </Typography>
                              </>
                            )}
                          <Divider
                            sx={{
                              backgroundColor: warm[600],
                              borderWidth: "1px",
                              my: 2,
                            }}
                          />
                          <Typography
                            className="title"
                            variant="smallCopy"
                            sx={{ color: `neutral.main` }}
                            paragraph
                          >
                            Total amount
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: primary[500] }}
                            gutterBottom
                          >
                            {grantRequest.transactionType ===
                              GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH && (
                                <>
                                  $
                                  {moneyFormat(
                                    +(grantRequest.information as CashInfo)
                                      .requestedAmount
                                  )}
                                </>
                              )}
                            {grantRequest.transactionType ===
                              GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
                                <>
                                  -$
                                  {moneyFormat(
                                    +(grantRequest.information as InvestmentInfo)
                                      .requestedAmount
                                  )}
                                </>
                              )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )}

          {activeStep === 2 && (
            <>
              <Grid>
                <Card sx={{ boxShadow: "none", mt: 2 }}>
                  <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                    {charity && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="subtitle1"
                          >
                            Summary
                          </Typography>
                          <IconButton
                            className="black"
                            edge="end"
                            onClick={() => setActiveStep(1)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Box>
                        <GrantSummary
                          grantRequest={grantRequest}
                          charity={charity}
                        />
                      </>
                    )}
                  </CardContent>
                </Card>
                <Grid
                  container
                  display={"flex"}
                  sx={{
                    paddingTop: 2,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle3">
                      Please confirm the following
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={exchangeValue}
                      name="exchangeValue"
                      onChange={(event: any) =>
                        setExchangeValue(event.target.checked)
                      }
                      control={<Checkbox checked={exchangeValue} />}
                      label={
                        <Typography
                          variant="bodyCopyRegular"
                          sx={{ paddingTop: 1 }}
                          color={neutral[900]}
                        >
                          You do not expect you, or any other person related to
                          you or to this Fund, to receive anything of value in
                          exchange for this grant. Things of value include (but
                          are not limited to) cash or goods and services,
                          including meals, items, purchased at auctions,
                          tickets, voucher or admission to events.
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={obligationPayment}
                      name="obligationPayment"
                      onChange={(event: any) =>
                        setObligationPayment(event.target.checked)
                      }
                      control={<Checkbox checked={obligationPayment} />}
                      label={
                        <Typography
                          variant="bodyCopyRegular"
                          color={neutral[900]}
                        >
                          This grant from Neta Foundation will not be used to
                          pay any personal legal obligation you may have,
                          including any legally binding commitment to make a
                          gift to the grantee.
                        </Typography>
                      }
                    />
                    <Typography
                      variant="bodyCopyRegular"
                      color={neutral[900]}
                    ></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  sx={{ paddingY: 2, paddingX: 3 }}
                  disabled={!exchangeValue || !obligationPayment}
                  startIcon={
                    isLoading && <CircularProgress size={20} color="inherit" />
                  }
                  onClick={submit}
                >
                  Next
                </Button>
              </Box>
            </>
          )}

          {activeStep === 3 && charity && (
            <>
              <Grid container spacing={5}>
                <Grid sx={{ mt: 8 }} item xs={12} sm={9}>
                  <GrantSuccess
                    interestedIn={(grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH) ? null : (grantRequest.information as InvestmentInfo).interestedIn}
                    amount={getAmount()!}
                    charityName={charity?.name}
                    transactionType={grantRequest.transactionType}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};

export default GrantRequestHomePage;
