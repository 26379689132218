import React from 'react';
import { AccountSettings } from '@aws-amplify/ui-react';
import { ValidatorOptions } from '@aws-amplify/ui';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
interface PasswordSettingsProps {
  open: boolean;
  handleClose: () => void;
  openSuccessMessage: (text:string) => void
}
export const PasswordSettings: React.FC<PasswordSettingsProps> = ({
  open, handleClose, openSuccessMessage
}) => {
    const handleSuccess = () => {
        handleClose()
        openSuccessMessage("Your account password was successfully changed!")
      }
      const minLength: ValidatorOptions = {
        validationMode: 'onChange',
        validator: (password:string) => password.length >= 8,
        message: 'Password must have length 8 or greater',
      };
      const needsNumber: ValidatorOptions = {
        validationMode: 'onChange',
        validator: (password:string) => /[0-9]/.test(password),
        message: 'Password must contains a least one number',
      };
      const needsLowerCase: ValidatorOptions = {
        validationMode: 'onChange',
        validator: (password:string) => /[a-z]/.test(password),
        message: 'Password must contains a least one lower case',
      };
      const needsUpperCase: ValidatorOptions = {
        validationMode: 'onChange',
        validator: (password:string) => /[A-Z]/.test(password),
        message: 'Password must contains a least one upper case',
      };
      const needsSpecialChar: ValidatorOptions = {
        validationMode: 'onChange',
        validator: (password:string) => /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password),
        message: 'Password must contains a least one special character',
      };
      const components = {
        SubmitButton: ({ onClick, isDisabled }:any) => (
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={handleClose}   >
                Cancel
            </Button>
            <Button variant="contained" color='primary' size='large' type="submit" onClick={onClick} disabled={isDisabled}>
            Next
          </Button>
        </Box>
          
        )
      };
      return (
        <Dialog  fullWidth={true}
        maxWidth={'sm'} open={open} onClose={handleClose}>
            <DialogTitle sx={{ my:2}}> <Typography sx={{color:"neutral"}} variant='subtitle1'>Change your password</Typography> </DialogTitle>
            <DialogContent>
            <AccountSettings.ChangePassword components={components} validators={[minLength, needsNumber,needsLowerCase,needsUpperCase,needsSpecialChar ]} onSuccess={handleSuccess}/>

            </DialogContent>

      </Dialog>
        
      );
}
