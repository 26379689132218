import {
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { SelectMultipleCheckboxDropdown } from "components/shared/select-multiple-checkbox-drop-down";
import { useMemo, useState } from "react";
import { Option } from "./../../components/shared/select-multiple-checkbox-drop-down";
import ClearIcon from "@mui/icons-material/Clear";
import { InvestmentCard } from "components/investment/investment-card";
import { useGetInvestmentsQuery } from "hooks/use-services/investment.service";
import {
  INVESTMENT_FORMAT_ENUM,
  INVESTMENT_RISK_TYPE_ENUM,
  Investment,
} from "types/Investment";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
interface InvestmentListProps {
  buy: (ticker: string) => void;
  handleOpen?: any;
}
const risksOptions: Option[] = [
  {
    key: 1,
    label: "Equity",
    value: INVESTMENT_RISK_TYPE_ENUM.EQUITY,
  },
  {
    key: 2,
    label: "Fixed Income",
    value: INVESTMENT_RISK_TYPE_ENUM.FIXED_INCOME,
  },
  {
    key: 3,
    label: "Other",
    value: INVESTMENT_RISK_TYPE_ENUM.OTHER,
  },
];

const formatOptions: Option[] = [
  {
    key: 1,
    label: "Shares",
    value: INVESTMENT_FORMAT_ENUM.SHARES,
  },
  {
    key: 2,
    label: "ETF- Exchange Traded Funds",
    value: INVESTMENT_FORMAT_ENUM.ETF,
  },
  {
    key: 3,
    label: "Mutual Funds",
    value: INVESTMENT_FORMAT_ENUM.MUTUAL_FUNDS,
  },
  {
    key: 4,
    label: "Bond",
    value: INVESTMENT_FORMAT_ENUM.BOND,
  },
];

export const InvestmentList: React.FC<InvestmentListProps> = ({
  buy,
  handleOpen,
}) => {
  const [risks, setRisks] = useState<Option[]>([]);
  const [formats, setFormats] = useState<Option[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const {
    data,
    fetchNextPage,
    isSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetInvestmentsQuery({
    limit: 10,
    searchTerm: keyword,
    filter: {
      riskTypes: risks.map((risk) => risk.value) as [INVESTMENT_RISK_TYPE_ENUM],
      formats: formats.map((format) => format.value) as [
        INVESTMENT_FORMAT_ENUM
      ],
      isActive: true,
    },
  });

  const investmentsData = useMemo<Investment[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });

  const removeOption = (
    item: Option,
    setValue: React.Dispatch<React.SetStateAction<Option[]>>,
    array: Option[]
  ) => {
    let options: Option[] = [];
    options = array.filter((selected) => selected.key !== item.key);
    setValue(options);
  };

  const handleWordChange = (event: any) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      setKeyword(value);
    }
  };

  return (
    <>
      {" "}
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item xs={12} md={4}>
          <SelectMultipleCheckboxDropdown
            setSelected={setRisks}
            options={risksOptions}
            selected={risks}
            title={"All Risk Types"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl size="small" fullWidth>
            <SelectMultipleCheckboxDropdown
              setSelected={setFormats}
              options={formatOptions}
              selected={formats}
              title={"All Formats"}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" fullWidth>
            <FilledInput
              className="search"
              placeholder="Type keyword or name"
              size="small"
              id="keyword"
              defaultValue={keyword}
              name="keyword"
              onChange={(event) => event?.target.value === "" && setKeyword("")}
              onKeyDown={(event) => handleWordChange(event)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        sx={{ my: 2 }}
        container
        justifyContent={{ xs: "start", md: "flex-wrap" }}
        alignItems="center"
        columns={16}
        spacing={{ xs: 1, md: 0 }}
        rowSpacing={2}
      >
        {((risks && risks.length > 0) ||
          (formats && formats.length) ||
          keyword) && (
            <Typography
              sx={{ color: "neutral.main" }}
              variant="subtitle3"
              component={"span"}
            >
              Showing results for
            </Typography>
          )}
        {risks &&
          risks.length > 0 &&
          risks.map((risk) => (
            <Grid margin={{ md: 1 }}>
              <FormControl size="small">
                <OutlinedInput
                  value={risk.label}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => removeOption(risk, setRisks, risks)}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </FormControl>
            </Grid>
          ))}
        {formats &&
          formats.length > 0 &&
          formats.map((format) => (
            <Grid margin={{ md: 1 }}>
              <FormControl size="small">
                <OutlinedInput
                  value={format.label}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          removeOption(format, setFormats, formats)
                        }
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                ></OutlinedInput>
              </FormControl>
            </Grid>
          ))}
        {keyword && (
          <Grid margin={{ md: 1 }}>
            <FormControl size="small">
              <OutlinedInput
                value={keyword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setKeyword("")} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></OutlinedInput>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Box sx={{ marginY: 4 }}>
        {isSuccess && (
          <>
            <Grid container rowSpacing={2}>
              {investmentsData && investmentsData.length > 0 ? (
                investmentsData.map((investment, index) => (
                  <Grid key={`investment-card-key-${index}`} item xs={12}>
                    <InvestmentCard
                      investment={investment}
                      buy={buy}
                      handleOpen={handleOpen}
                    />
                  </Grid>
                ))
              ) : (
                <Typography variant="subtitle3">
                  No investment found.
                </Typography>
              )}
            </Grid>
          </>
        )}
        <Box ref={lastElementRef}>
          {isFetchingNextPage && (
            <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};
