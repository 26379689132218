import React from 'react';
import { updateUserAttributes, confirmUserAttribute } from 'aws-amplify/auth';
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
interface EmailSettingsProps {
  oldEmail: string;
  open: boolean;
  handleClose: () => void;
  openSuccessMessage: (text: string) => void;
  submit: (email: string) => void;
}
export const EmailSettings: React.FC<EmailSettingsProps> = ({
  oldEmail, open, handleClose, openSuccessMessage, submit
}) => {
  const [email, setEmail] = React.useState<any>({
    newEmail: "",
    confirm: ""
  })
  const [error, setError] = React.useState<any>({
    sameOldEmail: false,
    differentEmails: false
  })
  const [codeMismatchError, setCodeMismatchError] = React.useState<boolean>(false)
  const [step, setStep] = React.useState(0)
  const [confirmCode, setConfirmCode] = React.useState('')
  const handleUpdateUserAttribute = async () => {
    try {
      await updateUserAttributes({
        userAttributes: {
          email: email.newEmail
        }
      });
      setStep(1)
    } catch (error) {
      console.log(error);
    }
  }
  const handleConfirmUserAttribute = async () => {

    try {
      await confirmUserAttribute({ userAttributeKey: 'email', confirmationCode: confirmCode });
      await submit(email.newEmail)
      setStep(2)
    } catch (error: any) {
      if (error?.name === 'CodeMismatchException') {
        setCodeMismatchError(true)
      }
    }
  }
  const handleSuccess = () => {
    onClose()
    openSuccessMessage("Your account email was successfully changed!")
  }
  const onClose = () => {
    setEmail({})
    setError({})
    setConfirmCode("")
    if (step === 2) {
      openSuccessMessage("Your account email was successfully changed!")
    }
    handleClose()
    setStep(0)

  }
  const checkEmail = () => {
    if (email.newEmail === oldEmail) {
      setError({ ...error, sameOldEmail: true })
    }
    else {
      setError({ ...error, sameOldEmail: false })
    }
  }
  const checkMismatchedEmails = () => {
    if (email.newEmail !== email.confirm) {
      setError({ ...error, differentEmails: true })
    }
    else {
      setError({ ...error, differentEmails: false })
    }
  }
  return (
    <Dialog sx={{ m: 2 }} fullWidth={true}
      maxWidth={'sm'} open={open} onClose={onClose}>
      {
        step === 0 && (
          <>
            <DialogTitle sx={{ my: 2 }} > <Typography sx={{ color: "neutral" }} variant='subtitle1'>Change your email</Typography> </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>

                  <FormControl error={error.sameOldEmail} fullWidth>
                    <InputLabel shrink={false}>Enter new account email</InputLabel>
                    <OutlinedInput name="email"
                      value={email.newEmail}
                      onChange={(event) => { setEmail({ ...email, newEmail: event.target.value }) }}
                      onBlur={checkEmail}
                      type="text" required />
                    {
                      error.sameOldEmail && <FormHelperText color={'red'}>The new account email is the same as the old one</FormHelperText>
                    }

                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>

                  <FormControl error={error.differentEmails} fullWidth>
                    <InputLabel shrink={false}>Confirm new account email </InputLabel>
                    <OutlinedInput name="email"
                      value={email.confirm}
                      onChange={(event) => { setEmail({ ...email, confirm: event.target.value }) }}
                      onBlur={checkMismatchedEmails}
                      type="text" required />
                    {
                      error.differentEmails && <FormHelperText color={'red'}>Your emails must match</FormHelperText>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose}>
                      Cancel
                    </Button>
                    <Button variant="contained" color='primary' size='large' onClick={handleUpdateUserAttribute} disabled={error.differentEmails || error.sameOldEmail} type="submit" >
                      Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </>)
      }
      {
        step === 1 && (
          <>
            <DialogTitle sx={{ my: 2 }}> <Typography sx={{ color: "neutral" }} variant='subtitle1'>Confirm email address</Typography> </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>

                  <FormControl fullWidth>
                    <InputLabel shrink={false}>Confirmation Code*</InputLabel>
                    <OutlinedInput name="confirmCode"
                      value={confirmCode}
                      onChange={(event) => { setConfirmCode(event.target.value); setCodeMismatchError(false) }}
                      type="text" required />


                  </FormControl>
                </Grid>
                {
                  codeMismatchError &&
                  <Grid item xs={12} sm={12}>
                    <Alert className='error' icon={false} onClose={() => setCodeMismatchError(false)}>Invalid verification code provided, please try again.</Alert>
                  </Grid>
                }



                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="contained" color='primary' size='large' onClick={handleConfirmUserAttribute} type="submit" >
                      Confirm
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </>)
      }
      {
        step === 2 && (
          <>
            <DialogTitle sx={{ my: 2, pl: 4 }}> <Typography sx={{ color: "neutral" }} variant='subtitle1'>Your new email has been verified</Typography> </DialogTitle>
            <DialogContent sx={{ pl: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant='bodyCopyRegular'>
                    You can now use your new email address to login in your Neta account.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="contained" color='primary' size='large' onClick={handleSuccess} type="submit" >
                      Got it
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </>)
      }


    </Dialog>

  );
}
