import { Typography, Box } from "@mui/material";
import { FC } from "react";
import { neutral } from "style/color";
import { InvestmentList } from "../investment-list";

interface Props {
  selectAsset: (ticker: string) => void;
}

export const InvestmentRequestSelectAsset: FC<Props> = ({ selectAsset }) => {
  return (
    <Box>
      <Typography variant="h3" color={neutral[900]}>
        Select Asset
      </Typography>
      <InvestmentList buy={selectAsset} />
    </Box>
  );
};
