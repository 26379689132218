import {
  Box,
  FilledInput,
  Grid,
  InputAdornment,
  Typography,
  FormControl,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import Layout from "components/layout/layout";
import { neutral, warm } from "style/color";
import SearchIcon from "@mui/icons-material/Search";
import { useGetGrantedCharitiesQuery } from "hooks/use-services/charity.service";
import { useContext, useMemo, useState } from "react";
import CharityCard from "components/charity-card/CharityCard";
import { Charity } from "types/charity";
import { AuthData } from "types/auth";
import { AuthContext } from "providers/userProvider";
import { createSearchParams, useNavigate } from "react-router-dom";
import { CharitiesSearchPagePath, CharityGrantRequestPagePath } from "constants/pages";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { SavedCharitiesList } from "./saved-charities-list";
import { RecommendCharity } from "components/charity-card/recommendCharity";

const CharitiesPage = () => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const navigate = useNavigate();
  const [open, setOpen] =
    useState<boolean>(false);
  const [offset] = useState(0);
  const [limit] = useState(6);
  const [savedCharitieslimit, setLimit] = useState(3);
  const [viewAllCharities, setViewAllCharities] = useState<boolean>(false);
  const [viewAllSavedCharities, setViewAllSavedCharities] =
    useState<boolean>(false);
  const {
    data,
    fetchNextPage,
    isSuccess: isGetCharitiesSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useGetGrantedCharitiesQuery(
    selectedAccount?.fundAccountId
      ? {
        activeFundAccountId: selectedAccount?.fundAccountId,
        limit: limit,
        offset: offset,
      }
      : { limit: limit, offset: offset }
  );
  const charitiesData = useMemo<Charity[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });

  const searchByKeyword = (event: any) => {
    if (event.key === "Enter") {
      navigate({
        pathname: CharitiesSearchPagePath,
        search: `?${createSearchParams({ keyword: event.target.value })}`,
      });
    }
  };
  const refresh = () => {
    refetch();
  };
  const openRequestPage = (ein: string): void => {
    navigate({
      pathname: CharityGrantRequestPagePath,
      search: `?${createSearchParams({ ein: ein })}`,
    });

  };
  const handleViewAllCharities = () => {
    setViewAllCharities((prev) => {
      return !prev;
    });
  };
  const handleViewAllSavedCharities = () => {
    setViewAllSavedCharities((prev) => {
      if (!prev === true) {
        setLimit(6);
      } else {
        setLimit(3);
      }
      return !prev;
    });
  };
  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 5 }}>
          <Box>
            <Typography variant="h3" color={neutral[900]}>
              Charities
            </Typography>
            <Typography variant="bodyCopyRegular">
              From your DAF you can grant to a wide variety of non-profits. Type their name or EIN in the search bar below to find the charity you want to support. You can then save them for later. If you don't find what you are looking for, please contact us and we can add new ones to the database.
            </Typography>
          </Box>
          <Box sx={{ marginY: 4 }}>
            <Grid item xs={12} sm={12} sx={{ textAlign: "end" }}>
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  onKeyDown={(event) => {
                    searchByKeyword(event);
                  }}
                  placeholder="Type keyword or name"
                  size="small"
                  id="outlined-adornment-weight"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Box>
          {selectedAccount && !viewAllCharities && (
            <SavedCharitiesList
              handleOpen={handleOpen}
              handleViewAllSavedCharities={handleViewAllSavedCharities}
              selectedAccount={selectedAccount}
              limit={savedCharitieslimit}
              viewAllSavedCharities={viewAllSavedCharities}
              openRequestPage={openRequestPage}
            />
          )}
          {!viewAllSavedCharities && (
            <>
              <Divider
                sx={{
                  marginY: 2,
                  backgroundColor: warm[600],
                  borderWidth: "1px",
                }}
              />
              <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
                <Typography
                  className="title"
                  variant="subtitle2"
                  sx={{ color: "neutral.main", mr: 3 }}
                >
                  You previously granted to{" "}
                </Typography>
                <Button
                  onClick={handleViewAllCharities}
                  className="link"
                  variant="text"
                  color="primary"
                  size="large"
                >
                  {
                    viewAllCharities ? 'View less' : 'View all'
                  }
                </Button>
              </Box>
              <Box sx={{ marginY: 4 }}>
                {isGetCharitiesSuccess && (
                  <>
                    <Grid sx={{ mt: 2 }} container spacing={2}>
                      {charitiesData?.map((charity: any) => (
                        <Grid key={charity.ein} item xs={12} md={4}>
                          <CharityCard selectCharity={openRequestPage} handleOpen={handleOpen} refresh={refresh} charity={charity} />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                <Box ref={lastElementRef}>
                  {isFetchingNextPage && (
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      textAlign={"center"}
                      sx={{ m: 3 }}
                    >
                      {" "}
                      <CircularProgress color="primary" />
                    </Grid>
                  )}
                </Box>
              </Box>
            </>
          )}
          <Box sx={{ backgroundColor: warm[500], py: 6, px: 8, borderRadius: '8px' }}>
            <Typography variant="h3" color={neutral[900]} paragraph>
              Your favorite charity isn’t appearing?
            </Typography>
            <Typography variant='bodyCopyRegular' color={neutral[900]} paragraph>
              Click below to suggest a new charity and our team will review it to have it added to our list.
            </Typography>
            <Button variant='contained' color='primary' onClick={() => setOpen(true)} >Suggest a charity</Button>
          </Box>
          <RecommendCharity open={open} onClose={() => setOpen(false)}></RecommendCharity>
        </Box>
      )}
    </Layout>
  );
};

export default CharitiesPage;
