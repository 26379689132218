import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { updateMFAPreference,verifyTOTPSetup } from 'aws-amplify/auth';
import QRCode from "react-qr-code";
interface MFASettingsProps {
  qrCode:{ secret: string, url: string};
  open: boolean;
  handleClose: () => void;
  openSuccessMessage: (text:string) => void
  getMFAData: () => void;
}
export const MFASettings: React.FC<MFASettingsProps> = ({
  qrCode, open, handleClose, openSuccessMessage, getMFAData
}) => {
  const [code, setCode] = React.useState("")
  const [error, setError] = React.useState("")
  const verifyCode = async () => {
    try {
     await verifyTOTPSetup({ code: code });
     await updateMFAPreference({ totp: 'ENABLED' });
     handleSuccess()
    } catch (error) {
      setError("Code mismatch");
    }
  }
  const handleCodeChange = (value:string) => {
    setError("")
    setCode(value)
  }
  const handleSuccess = () => {
    getMFAData()
    onClose()
    openSuccessMessage("Double authentication was successfully activated!")
  }
  const onClose = () => {
    handleClose()
    setError("")
    setCode("")

  }

  return (
    <Dialog sx={{ m: 2 }} fullWidth={true}
      maxWidth={'sm'} open={open} onClose={onClose}>
      <>
        <DialogTitle sx={{ my: 2 }} > <Typography sx={{ color: "neutral" }} variant='subtitle1'>Setup Two-Factor authentication</Typography> </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={12} >
            <QRCode size={228} value={qrCode.url} />
            </Grid>
            <Grid item sm={12} >
            <Typography>{qrCode.secret}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>

              <FormControl error={error!=="" } fullWidth>
                <InputLabel shrink={false}>Code *</InputLabel>
                <OutlinedInput name="code"
                  value={code}
                  onChange={(event) => { handleCodeChange(event.target.value) }}
                  type="text" required />
{
                      error!=="" && <FormHelperText color={'red'}>{error}</FormHelperText>
                    }

              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>

            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="contained" color='primary' size='large' onClick={verifyCode} type="submit" >
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </>)



    </Dialog>

  );
}
