import { SUCCESSOR_TYPE } from "types/successor";

export const SUCCESSOR_INDIVIDUAL = {
  title: "",
  firstName: "",
  lastName: "",
  middleName: "",
  suffix: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  phoneNumber: "",
}
export const SUCCESSOR_CHARITY = {
  name: "",
  ein: "",
  specificInstruction:""
}
export const SUCCESSOR = {
  successorInfo: SUCCESSOR_INDIVIDUAL,
  successorType: SUCCESSOR_TYPE.INDIVIDUAL,
  percentage: 0
};
export const SUCCESSOR_ERROR = {
  firstName: false,
  lastName: false,
  email: false,
  addressLine1: false,
  city: false,
  state: false,
  zip: false,
  name: false,
  ein: false,
  type: false,
};
