import { addContributionTransactionAPI, cancelContributionTransactionAPI, getContributionTransactionAPI, getContributionTransactionsAPI, updateContributionTransactionAPI } from "api/contribution-transaction.api";
import { useInfiniteQuery, useMutation, useQuery } from "react-query"
import ContributionTransaction, { ContributionTransactionInput, ContributionTransactionsQuery } from "types/contribution-transaction";
import { PaginatedEntityList } from "types/pagination";

const CONTRIBUTION_TRANSACTION_RQ_KEY = 'contribution-transaction';

;
export const useAddContributionTransactionMutation = (fundId: number) => {
  return useMutation<unknown, unknown, ContributionTransactionInput>({
    mutationKey: [CONTRIBUTION_TRANSACTION_RQ_KEY, fundId],
    mutationFn: (data) => addContributionTransactionAPI(fundId, data),
  })
}
export const useUpdateContributionTransactionMutation = (fundId: number, contributionId: number) => {
  return useMutation<unknown, unknown, ContributionTransaction>({
    mutationKey: [CONTRIBUTION_TRANSACTION_RQ_KEY, fundId],
    mutationFn: (data) => updateContributionTransactionAPI(fundId, contributionId, data),
  })
}
export const useCancelContributionTransactionMutation = (fundId: number) => {
  return useMutation<unknown, unknown, number>({
    mutationKey: [CONTRIBUTION_TRANSACTION_RQ_KEY, fundId],
    mutationFn: (data) => cancelContributionTransactionAPI(fundId, data),
  })
}
export const useGetContributionTransactionQuery = (fundId: number, transactionId: number) => {
  return useQuery<ContributionTransaction>({
    queryKey: [CONTRIBUTION_TRANSACTION_RQ_KEY, fundId, transactionId],
    enabled: fundId !== 0 && transactionId !== 0,
    queryFn: () => getContributionTransactionAPI(fundId, transactionId),
  })

}
export const useGetContributionTransactionsQuery = (query: ContributionTransactionsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<ContributionTransaction>>({
    queryKey: [CONTRIBUTION_TRANSACTION_RQ_KEY, query],
    enabled: query.fundId !== 0,
    queryFn: ({ pageParam = 0 }) => getContributionTransactionsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })

}
