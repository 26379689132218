import React, { FormEvent, useContext, useState } from 'react';
import { Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Link, OutlinedInput, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Layout from 'components/layout/layout';
import { FundSettingsPage, RolesPermissionsPagePath } from 'constants/pages';
import { useNavigate } from 'react-router-dom';
import { USER_ROLE } from 'types/account';
import { AuthContext } from 'providers/userProvider';
import { AuthData } from 'types/auth';
import { RolesRadioGroup } from 'components/roles-permissions/roles';
import { useInviteUserToFundMutation } from 'hooks/use-services/fund-account-invitations.service';
import { useGetUserByEmailQuery } from 'hooks/use-services/user.service';
import { signUp } from 'aws-amplify/auth';
export const InviteUserPage = () => {
    const navigate = useNavigate()
    const { selectedAccount } = useContext<AuthData>(AuthContext);
    const { mutate, isLoading } = useInviteUserToFundMutation();
    const [userExistError, setUserExistError] = useState("")
    const [error, setError] = useState(false)
    const [userInvite, setUserInvite] = useState({
        email: '',
        fundId: selectedAccount?.fundAccountId,
        role: USER_ROLE.ADMIN
    })
    const { data } = useGetUserByEmailQuery(userInvite.email)
    const handleChange = (key: string, value: string) => {
        if (key === 'email') {
            setError(false)
            setUserExistError("")
        }
        setUserInvite({
            ...userInvite,
            [key]: value
        })
    }
    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await signUp({
                username: userInvite.email,
                password: '123',
            });
        } catch (error: any) {
            if (error.name === ("UsernameExistsException") && !data?.email) {
                setUserExistError("Oops! something went wrong, please try with a different email")
            }
            else {
                const input = {
                    ...userInvite,
                    fundId: selectedAccount?.fundAccountId!,
                }
                mutate(input, {
                    onSuccess: () => navigate(-1),
                    onError: (error: any) => {
                        if (error.response.data.message === "The user is already member of the fund account") {
                            setUserExistError("The user is already member of the fund account")
                        }
                    }
                });

            }
        }
    }
    return (
        <Layout>
            {() => (
                <>
                    <Box component="form"
                        autoComplete="off"
                        onSubmit={submit} sx={{ my: 4, mx: 3 }}>
                        <React.Fragment>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Link underline="hover" color="inherit" onClick={() => navigate(FundSettingsPage)}>
                                    <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Fund settings</Typography>
                                </Link>
                                <Link underline="hover" color="inherit" onClick={() => navigate(RolesPermissionsPagePath)}>
                                    <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Roles & permissions</Typography>
                                </Link>
                                <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Invite user</Typography>
                            </Breadcrumbs>
                            <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h3'>
                                        Roles & permissions
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant='bodyCopyRegular'>
                                        Invite others to manage this fund, assign them roles and set their permissions. .
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Card sx={{ boxShadow: 'none', p: 4 }} >
                                        <CardContent>
                                            <Typography sx={{ color: `neutral.main`, mb: 4 }} variant="subtitle1" gutterBottom>List of people with access to the fund  </Typography>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl error={error} fullWidth>
                                                        <InputLabel shrink={false}>Email </InputLabel>
                                                        <OutlinedInput name="email"
                                                            value={userInvite.email}
                                                            onChange={(event) => handleChange("email", event.target.value)}
                                                            onInvalid={() => setError(true)}
                                                            type="text" required />
                                                        {error && <FormHelperText color={'red'}>Email is required</FormHelperText>}
                                                        {userExistError !== "" && <FormHelperText color={'red'}>{userExistError}</FormHelperText>}


                                                    </FormControl>
                                                </Grid>
                                                <RolesRadioGroup userInvite={userInvite} handleChange={handleChange} />

                                            </Grid>


                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={10} textAlign={'end'} sx={{ my: 4 }}>
                                    <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={() => navigate(-1)}   >
                                        Discard
                                    </Button>
                                    <Button variant="contained" color='primary' size='large' type='submit' startIcon={
                                        isLoading && <CircularProgress size={20} color="inherit" />
                                    } >
                                        Send invite
                                    </Button>
                                </Grid>
                            </Grid>






                        </React.Fragment>

                    </Box>
                </>)}
        </Layout>



    )
}
