import React, { useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Category } from "types/category";
interface AddInterestsProps {
  categories: Category[];
  interests?: number[];
  handleAccountChange: (key: string, value: any) => void;
}
export const AddInterests: React.FC<AddInterestsProps> = ({
  categories,
  handleAccountChange,
  interests,
}) => {
  const [values, setValues] = React.useState<number[]>([]);
  useEffect(() => {
    if (interests) {
      setValues(interests);
    }
  }, [interests]);
  const handleChange = (event: any) => {
    const { checked, name } = event.target;
    let newValues = [...values];
    if (checked) {
      newValues.push(+name);
      setValues([...newValues]);
    } else {
      const index = values.findIndex((item) => item === +name);
      newValues.splice(index, 1);
      setValues(newValues);
    }
    handleAccountChange("interests", newValues);
  };
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        tabIndex={-1}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="interests"
        id="interests"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          Interests{" "}
          <Typography
            sx={{ color: `neutral.lighter`, mx: 3 }}
            variant="bodyCopyBold"
          >
            Optional{" "}
          </Typography>{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ color: `neutral.dark` }} variant="bodyCopyRegular">
          We will use this information to show recommendations of charities and
          GrantVestment adapted to the areas of interest and impact of your
          fund.
        </Typography>
        <br />
        <FormControl
          sx={{ mt: 3 }}
          component="fieldset"
          variant="standard"
          fullWidth
        >
          <FormLabel component="legend">Type below</FormLabel>
          <FormGroup>
            <Grid sx={{ mt: 2 }} container spacing={1} columnSpacing={0}>
              {categories && categories.length === 0 ? (
                <Typography
                  sx={{ color: `neutral.dark` }}
                  variant="bodyCopyRegular"
                >
                  No interest found.
                </Typography>
              ) : (
                categories.map((category) => {
                  return (
                    <Grid key={category.categoryId} item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.includes(category.categoryId)}
                            onChange={handleChange}
                            name={category.categoryId.toString()}
                          />
                        }
                        label={category.categoryName}
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
