export enum SUCCESSOR_TYPE {
    INDIVIDUAL = "INDIVIDUAL",
    CHARITY = "CHARITY"
  }
  export interface SuccessorIndividual {
    title?: string,
    firstName: string,
    lastName: string,
    middleName?: string,
    suffix?: string,
    email: string,
    addressLine1: string,
    addressLine2?: string,
    city: string,
    state: string,
    zip: string,
    phoneNumber?: string,
}
export interface SuccessorCharity {
    name: string,
    ein: string,
    specificInstruction?: string,
}
export interface Successor {
    successorId?:number;
    successorInfo : SuccessorIndividual|SuccessorCharity ,
    successorType: SUCCESSOR_TYPE,
    percentage: number
}
export interface SuccessorError {
    firstName?: boolean,
    lastName?: boolean,
    email?: boolean,
    addressLine1?: boolean,
    city?: boolean,
    state?: boolean,
    zip?: boolean,
    name?:boolean,
    ein?:boolean,
    type: boolean,
}
export const getPercentageTotal = (successors:Successor[]) => {
    return successors.reduce((n, { percentage }) => n + percentage, 0);

};
