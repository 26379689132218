export const USER = {
  sub: "",
  title: "",
  firstName: "",
  lastName: "",
  middleName: "",
  suffix: "",
  dateOfBirth: null,
  email: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  phoneNumber: "",
  reference: "",
  alternativePhoneNumber: "",
  phoneNumberIsMobile: false,
  alternativePhoneNumberIsMobile: false,
  alternativeEmail: "",
  status: "PENDING",
};
export const USER_ERROR = {
  firstName: false,
  lastName: false,
  dateOfBirth: false,
  city: false,
  state: false,
  zip: false,
  phoneNumber: false,
  email: false,
};
