import React from "react";
import { confirmSignUp } from "aws-amplify/auth";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
interface EmailConfirmProps {
  email: string;
  open: boolean;
  handleClose: () => void;
}
export const EmailConfirm: React.FC<EmailConfirmProps> = ({
  email,
  open,
  handleClose,
}) => {
  const [codeMismatchError, setCodeMismatchError] =
    React.useState<boolean>(false);
  const [confirmCode, setConfirmCode] = React.useState("");
  const handleConfirmUserAttribute = async () => {
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: confirmCode,
      });
      handleClose();
    } catch (error: any) {
      if (error?.name === "CodeMismatchException") {
        setCodeMismatchError(true);
      }
    }
  };

  const onClose = () => {
    setConfirmCode("");
    handleClose();
  };
  return (
    <Dialog
      sx={{ m: 2 }}
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
    >
      <>
        <DialogTitle sx={{ my: 2 }}>
          {" "}
          <Typography sx={{ color: "neutral" }} variant="subtitle1">
            Confirm email address
          </Typography>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel shrink={false}>Confirmation Code*</InputLabel>
                <OutlinedInput
                  name="confirmCode"
                  value={confirmCode}
                  onChange={(event) => {
                    setConfirmCode(event.target.value);
                    setCodeMismatchError(false);
                  }}
                  type="text"
                  required
                />
              </FormControl>
            </Grid>
            {codeMismatchError && (
              <Grid item xs={12} sm={12}>
                <Alert
                  className="error"
                  icon={false}
                  onClose={() => setCodeMismatchError(false)}
                >
                  Invalid verification code provided, please try again.
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleConfirmUserAttribute}
                  type="submit"
                >
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    </Dialog>
  );
};
