import React from "react";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Button
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { primary } from "style/color";
import { FUND_USERS_ROLES_TYPE_ENUM, FundUsersRoles } from "types/FundUsersRoles";
import { useDeleteUserFromFundMutation, useDeleteUserInvitationMutation } from "hooks/use-services/fund-account-invitations.service";
interface DeleteUserFromFundProps {
    user: FundUsersRoles,
    open: boolean;
    onClose: () => void;
}
export const DeleteUserFromFund: React.FC<
    DeleteUserFromFundProps
> = ({ open, onClose, user }) => {
    const { mutate: deleteInvite } = useDeleteUserInvitationMutation()
    const { mutate } = useDeleteUserFromFundMutation()

    const submit = () => {
        if (user.type === FUND_USERS_ROLES_TYPE_ENUM.INVITED) {
            deleteInvite(user.id, {
                onSuccess: () => onClose(),
            });
        }
        else {
            mutate(user.id, {
                onSuccess: () => onClose(),
            });
        }

    }
    return (
        <>
            {
                user &&
                <Dialog sx={{ m: 2 }} fullWidth={true}
                    maxWidth={'sm'} open={open} onClose={onClose}>

                    <DialogTitle sx={{ mt: 3, pl: 4 }}>
                        <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                            Remove from this fund?
                            <IconButton
                                onClick={onClose}
                                sx={{
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    }
                                }}
                            > <CloseIcon />
                            </IconButton>

                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ pl: 4, mb: 3 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: "neutral" }} variant='bodyCopyRegular'>
                                    You’re about to remove{" "}
                                    <Typography sx={{ color: primary[500] }} variant='bodyCopyBold'>
                                        {user.email}
                                    </Typography>
                                    <br></br>
                                    Once removed, they won’t be able to access the fund.

                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={'end'}>
                                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose} >
                                    Cancel
                                </Button>
                                <Button variant="contained" color='primary' size='large' onClick={submit}    >
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

        </>
    );
};
