import React, { FormEvent } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { User, UserError } from 'types/user';
interface ContactInformationProps {
    settings?: boolean;
    user: User;
    handleChange: (key: string, value: string | boolean) => void;
    handleInvalid: (event: FormEvent<HTMLElement>) => void;
    error: UserError
}
export const ContactInformation: React.FC<ContactInformationProps> = ({
    settings, user, handleChange, handleInvalid, error
}) => {
    return (
        <Accordion defaultExpanded={!settings}>
            <AccordionSummary tabIndex={-1}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="contact-information"
                id="contact-information"
            >
                <Typography sx={{ color: `neutral.main` }} variant="subtitle1">Contact information </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>

                        <FormControl error={error.phoneNumber} fullWidth>
                            <InputLabel shrink={false}>Phone*</InputLabel>
                            <OutlinedInput name="phoneNumber"
                                value={user.phoneNumber}
                                onInvalid={(event) => handleInvalid(event)}
                                onChange={(event) => handleChange("phoneNumber", event.target.value)} type="text" required />
                            {error.phoneNumber && <FormHelperText color={'red'}>Phone is required</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>

                        <FormControl fullWidth>
                            <InputLabel shrink={false}>Alternate phone </InputLabel>
                            <OutlinedInput name="alternativePhoneNumber"
                                value={user.alternativePhoneNumber}
                                onChange={(event) => handleChange("alternativePhoneNumber", event.target.value)} type="text" /></FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox color='secondary' id="phoneNumberIsMobile" name="phoneNumberIsMobile"
                                    value={user.phoneNumberIsMobile}
                                    onChange={(event) => handleChange("phoneNumberIsMobile", event.target.checked)} checked={user.phoneNumberIsMobile} />}
                                label="This is a mobile number"
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox color='secondary' id="alternativePhoneNumberIsMobile" name="alternativePhoneNumberIsMobile"
                                    value={user.alternativePhoneNumberIsMobile}
                                    onChange={(event) => handleChange("alternativePhoneNumberIsMobile", event.target.checked)} checked={user.alternativePhoneNumberIsMobile} />}
                                label="This is a mobile number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <FormControl fullWidth>
                            <InputLabel shrink={false}>Alternate email </InputLabel>
                            <OutlinedInput name="alternativeEmail"
                                value={user.alternativeEmail}
                                onChange={(event) => handleChange("alternativeEmail", event.target.value)} type="text" />
                        </FormControl>
                    </Grid>


                </Grid>
            </AccordionDetails>
        </Accordion>



    )
}
