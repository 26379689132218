import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { NumericFormatCustom } from "components/shared/currency-input";
import { AuthContext } from "providers/userProvider";
import { FC, useContext, useMemo, useState } from "react";
import { neutral, primary } from "style/color";
import { AuthData } from "types/auth";
import { CashInfo, GRANT_FREQUENCY_ENUM, GRANT_PURPOSE_ENUM, GRANT_REQUEST_TRANSACTION_TYPE_ENUM, GRANT_REQUEST_TYPE_ENUM, GrantRequestInput, InvestmentInfo, OnTimeSchedule, RecurrentScheduleInput } from "types/grant-request";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as ErrorIcon } from './../../../assets/Icons/error.svg'
import { ReactComponent as ExclamationIcon } from './../../../assets/Icons/exclamation.svg'
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { useGetFundInvestmentRequestsQuery } from "hooks/use-services/investment-request.service";
import { FundInvestment } from "types/fund-investment";
import SyncAltIcon from "@mui/icons-material/SyncAlt";


interface GrantDetailsProps {
  grantRequest: GrantRequestInput;
  setError: (error: boolean) => void;
  handleGrantRequestChange: (key: string, value: any) => void;
  handleTransactionTypeChange: (transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM, information: any) => void;
  handleTypeChange: (type: GRANT_REQUEST_TYPE_ENUM, scheduleInformation: any) => void;
}

export const GrantDetails: FC<GrantDetailsProps> = ({ grantRequest, handleGrantRequestChange, handleTransactionTypeChange, handleTypeChange, setError }) => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const [investmentAmountError, setInvestmentAmountError] = useState<boolean>(false)
  const [selectedFundInvestment, setSelectedFundInvestment] = useState<FundInvestment | null>(null)
  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetFundInvestmentRequestsQuery(selectedAccount?.fundAccountId || 0, {
    limit: 10,
  });

  const investmentsRequestsData = useMemo<FundInvestment[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  const handleTransactionType = (
    event: React.MouseEvent<HTMLElement>,
    newType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM | null,
  ) => {
    setError(false)
    if (newType !== null) {
      setInvestmentAmountError(false)
      setSelectedFundInvestment(null)
      handleTransactionTypeChange(newType,
        (newType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH) ?
          {
            requestedAmount: 0,
          } : {
            requestedAmount: 0,
            interestedIn: "UNITS",
            units: 0,
            ticker: "",
          })

    }

  };
  const handleType = (
    newType: GRANT_REQUEST_TYPE_ENUM,
  ) => {
    if (newType !== null) {
      handleTypeChange(newType,
        (newType === GRANT_REQUEST_TYPE_ENUM.ONETIME) ?
          {
            now: true,
            executionDate: new Date()
          } : {
            startingOn: new Date(),
            endingOn: new Date(),
            frequency: GRANT_FREQUENCY_ENUM.YEARLY,
          })

    }

  };
  const handleInformationChange = (key: string, value: any) => {
    setInvestmentAmountError(false)
    setError(false)
    if (key === 'requestedAmount') {
      setError(value > selectedAccount?.availableBalance! || value === '' || value === '0')
    }
    handleGrantRequestChange("information",
      {
        ...grantRequest.information,
        [key]: value
      })
  }
  const handleGrantPurposeChange = (key: string, value: any) => {
    handleGrantRequestChange("grantPurpose",
      {
        ...grantRequest.grantPurpose,
        [key]: value
      })
  }
  const handleScheduleInformationChange = (key: string, value: any) => {
    handleGrantRequestChange("scheduleInformation",
      {
        ...grantRequest.scheduleInformation,
        [key]: value
      })
  }

  const selectFundInvestment = (ticker: string) => {
    setInvestmentAmountError(false)
    handleGrantRequestChange("information",
      {
        ...grantRequest.information,
        ticker: ticker,
        requestedAmount: 0,
        units: 0
      })
    const fundInvestment = investmentsRequestsData.find(item => item.investment.ticker === ticker)
    setSelectedFundInvestment(fundInvestment || null)
  }
  const handleRequestedAmountChange = (value: number) => {
    if (selectedFundInvestment?.investment?.marketPrice) {
      const units = value / selectedFundInvestment?.investment?.marketPrice
      handleGrantRequestChange("information",
        {
          ...grantRequest.information,
          requestedAmount: value,
          units: units
        })
      const availableBalance = selectedFundInvestment?.investment?.marketPrice * selectedFundInvestment?.totalApprovedUnits
      setInvestmentAmountError(value > availableBalance)
    }
  };
  const handleRequestedUnitsChange = (value: number) => {
    if (selectedFundInvestment?.investment?.marketPrice) {
      const amount = value * selectedFundInvestment?.investment?.marketPrice;
      handleGrantRequestChange("information",
        {
          ...grantRequest.information,
          requestedAmount: amount,
          units: value
        })
      const availableBalance = selectedFundInvestment?.investment?.marketPrice * selectedFundInvestment?.totalApprovedUnits
      setInvestmentAmountError(amount > availableBalance)
      setError(amount > availableBalance)
    }
  };
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]}>Use</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ToggleButtonGroup
          fullWidth
          size="medium"
          value={grantRequest.transactionType}
          onChange={handleTransactionType}
          exclusive
          color="primary"
        >
          <ToggleButton
            fullWidth
            className="add-money-toggle"
            sx={{ paddingY: 2 }}
            value={GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH}
            aria-label="left aligned"
          >
            Cash
          </ToggleButton>
          <ToggleButton
            fullWidth
            className="add-money-toggle"
            value={GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT}
            sx={{ paddingY: 2 }}
            aria-label="centered"
          >
            Investment
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      {
        grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH &&
        <Grid sx={{ mt: 1 }} item xs={12} sm={11}>
          <FormControl fullWidth>
            <InputLabel shrink={false}>Enter amount </InputLabel>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={1} textAlign={"center"}>
                <Typography
                  sx={{ color: "neutral.main" }}
                  variant="subtitle3"
                  gutterBottom
                >
                  $
                </Typography>
              </Grid>
              <Grid item xs={11} sm={5}>
                <TextField
                  size='small'
                  sx={{ width: '100%' }} name="requestedAmount"
                  value={(grantRequest.information as CashInfo).requestedAmount}
                  onChange={(event) => handleInformationChange('requestedAmount', event.target.value)}
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ color: neutral[500], mb: 0 }}
                  className="title"
                  variant="smallCopy"
                  paragraph
                >
                  Available balance
                </Typography>
                <Typography
                  variant="bodyCopyBold"
                  sx={{ color: "neutral.main" }}
                >
                  $
                  {moneyFormat(
                    selectedAccount?.availableBalance || 0
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                {(grantRequest.information as CashInfo).requestedAmount > selectedAccount?.availableBalance! && <FormHelperText sx={{ m: 0 }}>  <ErrorIcon /> Not enough funds available. Try paying with cash and liquid investments.  </FormHelperText>}

              </Grid>

            </Grid>

          </FormControl>
        </Grid>
      }
      {
        grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT &&
        <>
          <Grid sx={{ mt: 2 }} item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel shrink={false} id="title">
                Select asset
              </InputLabel>
              <Select
                required
                fullWidth
                id="fund-investments-select"
                size="small"
                name="ticker"
                value={(grantRequest.information as InvestmentInfo).ticker}
                onChange={(event) => selectFundInvestment(event.target.value)}
              >
                {isSuccess && investmentsRequestsData.length > 0 && (
                  investmentsRequestsData.map((request) => (
                    <MenuItem key={request.fundInvestmentId} value={request.investment.ticker}>
                      {request.investment.name}
                    </MenuItem>
                  ))
                )}
                <MenuItem sx={{ p: 0 }} ref={lastElementRef as any} value="loading">
                  {isFetchingNextPage && (
                    <Box>
                      <CircularProgress size={10} sx={{ display: 'flex', width: '100%', justifyContent: 'center' }} color="primary" /></Box>

                  )}
                </MenuItem>




              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle3" color={neutral[900]}>Enter amount</Typography>
            <Box sx={{ display: 'flex' }}>
              <ExclamationIcon />
              <Typography sx={{ ml: 1 }} variant='smallCopyBold' color={neutral[900]} paragraph>All unit quantities and/or dollar values are
                estimations, and will be confirmed once executed</Typography>

            </Box>
          </Grid>
          {
            selectedFundInvestment &&
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}
                sx={{
                  pt: 1,
                  pb: 2,
                  border: `1px solid ${neutral[400]}`,
                  borderRadius: '8px'
                }}>
                <Grid sx={{ display: 'grid', justifyContent: 'start' }} item xs={12} sm={4}>
                  <Typography variant='smallCopyBold' color={neutral[600]}>{selectedFundInvestment.investment.ticker}</Typography>
                  <Typography variant='subtitle3' color={neutral[900]}>{selectedFundInvestment.investment.name}</Typography>
                  <Typography variant='smallCopyBold' color={neutral[600]}>Available ${moneyFormat(selectedFundInvestment.totalApprovedUnits * selectedFundInvestment.investment.marketPrice)}</Typography>
                </Grid>
                {(grantRequest.information as InvestmentInfo).interestedIn === "UNITS" ? (
                  <>
                    <Grid item xs={11} sm={3}>
                      <TextField
                        required
                        helperText={investmentAmountError && <><ErrorIcon /> Not enough units available</>}
                        error={investmentAmountError}
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Units"
                        value={((grantRequest.information as InvestmentInfo).units) ? moneyFormat((grantRequest.information as InvestmentInfo).units, 4, 0) : ""}
                        onChange={(e) =>
                          handleRequestedUnitsChange(
                            parseFloat(e.target.value)
                          )
                        }
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <Typography
                        sx={{ color: "neutral.main" }}
                        variant="subtitle3"
                        gutterBottom
                      >
                        units
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton
                        onClick={(event) => handleInformationChange('interestedIn', "AMOUNT")}
                        sx={{
                          backgroundColor: primary[500],
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: primary[800],
                          },
                        }}
                      >
                        <SyncAltIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} sm={3} textAlign={"start"}>
                      <Typography
                        variant="subtitle3"
                        color={neutral[900]}
                        gutterBottom
                      >
                        ${moneyFormat((grantRequest.information as InvestmentInfo).requestedAmount, 2)}*
                      </Typography>
                    </Grid>

                  </>
                ) : (
                  <>
                    <Grid item xs={1} textAlign={"center"}>
                      <Typography
                        sx={{ color: "neutral.main" }}
                        variant="subtitle3"
                        gutterBottom
                      >
                        $
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={3}>
                      <TextField
                        required
                        error={investmentAmountError}
                        helperText={investmentAmountError && <><ErrorIcon /> Not enough units available</>}

                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder={"Amount"}
                        value={((grantRequest.information as InvestmentInfo).requestedAmount) ? moneyFormat((grantRequest.information as InvestmentInfo).requestedAmount, 2, 0) : ""}
                        onChange={(e) =>
                          handleRequestedAmountChange(
                            parseFloat(e.target.value)
                          )
                        }
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                      />
                    </Grid>

                    <Grid item xs={2} sm={1}>
                      <IconButton
                        onClick={(event) => handleInformationChange('interestedIn', "UNITS")}
                        sx={{
                          backgroundColor: primary[500],
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: primary[800],
                          },
                        }}
                      >
                        <SyncAltIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} sm={3} textAlign={"start"}>
                      <Typography
                        variant="subtitle3"
                        color={neutral[900]}
                        gutterBottom
                      >
                        {moneyFormat((grantRequest.information as InvestmentInfo).units, 4) || 0} * units
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          }

        </>

      }
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]}>Grant type</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            row
            name="row-radio-buttons-group"
            value={grantRequest.type}
            onChange={(event) => handleType(event.target.value as GRANT_REQUEST_TYPE_ENUM)}
          >
            <FormControlLabel value={GRANT_REQUEST_TYPE_ENUM.ONETIME} control={<Radio />} label={
              <Typography variant='bodyCopyRegular' >One time</Typography>
            } />
            <FormControlLabel value={GRANT_REQUEST_TYPE_ENUM.RECURRENT} control={<Radio disabled={grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT} />} label={
              <Typography variant='bodyCopyRegular' >Recurrent</Typography>
            }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {
        grantRequest.type === GRANT_REQUEST_TYPE_ENUM.ONETIME &&
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle3" color={neutral[900]}>Choose when to send your one time grant</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={(grantRequest.scheduleInformation as OnTimeSchedule).now}
                onChange={(event) => handleScheduleInformationChange("now", event.target.value === 'true')}
              >
                <FormControlLabel value={true} control={<Radio />} label={
                  <Typography variant='bodyCopyRegular' >Send now</Typography>
                } />
                <FormControlLabel value={false} control={<Radio />} label={
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Typography variant='bodyCopyRegular' >Schedule for</Typography>
                    <FormControl>
                      <TextField
                        sx={{ width: '100%', ml: 1 }} name="executionDate"
                        value={(grantRequest.scheduleInformation as OnTimeSchedule).executionDate}
                        onChange={(event) => handleScheduleInformationChange("executionDate", event.target.value)}
                        required
                        variant="outlined"
                        type="Date"
                        size='small'
                        inputProps={{ min: new Date().toISOString().slice(0, 10) }}
                        disabled={(grantRequest.scheduleInformation as OnTimeSchedule).now}
                      />
                    </FormControl>
                  </Box>

                }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </>

      }
      {
        grantRequest.type === GRANT_REQUEST_TYPE_ENUM.RECURRENT &&
        <>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>Starting on</InputLabel>
              <OutlinedInput
                fullWidth name="startingOn"
                value={(grantRequest.scheduleInformation as RecurrentScheduleInput).startingOn}
                onChange={(event) => handleScheduleInformationChange("startingOn", event.target.value)}
                required
                type="Date"
                inputProps={{ min: new Date().toISOString().slice(0, 10) }}
                size='small'
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>Ending on</InputLabel>
              <OutlinedInput
                fullWidth name="endingOn"
                value={(grantRequest.scheduleInformation as RecurrentScheduleInput).endingOn}
                onChange={(event) => handleScheduleInformationChange("endingOn", event.target.value)}
                required
                inputProps={{ min: new Date((grantRequest.scheduleInformation as RecurrentScheduleInput).startingOn).toISOString().slice(0, 10) }}
                type="Date"
                size='small'
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel shrink={false} id="title">
                Frequency
              </InputLabel>
              <Select
                id="frequency-select"
                size="small"
                name="frequency"
                value={(grantRequest.scheduleInformation as RecurrentScheduleInput).frequency}
                onChange={(event) => handleScheduleInformationChange("frequency", event.target.value)}
              >
                <MenuItem value={GRANT_FREQUENCY_ENUM.YEARLY}>
                  {'Yearly'}
                </MenuItem>
                <MenuItem value={GRANT_FREQUENCY_ENUM.QUARTERLY}>
                  {'Quarterly'}
                </MenuItem>
                <MenuItem value={GRANT_FREQUENCY_ENUM.MONTHLY}>
                  {'Monthly'}
                </MenuItem>

              </Select>
            </FormControl>
          </Grid>
        </>
      }
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]} gutterBottom>Grant purpose*</Typography>
        <Typography variant="bodyCopyRegular" color={neutral[600]} sx={{ mb: 0 }} paragraph>Please specify the purpose of the grant</Typography>

      </Grid>
      <Grid item xs={12} sm={8}>
        <FormControl>
          <RadioGroup
            row
            name="type"
            value={grantRequest.grantPurpose.type}
            onChange={(event) => handleGrantPurposeChange("type", event.target.value)}
          >
            <FormControlLabel value={GRANT_PURPOSE_ENUM.UNRESTRICTED} control={<Radio />} label={
              <Typography variant='bodyCopyRegular' >Unrestricted </Typography>
            } />
            <FormControlLabel value={GRANT_PURPOSE_ENUM.SPECIFIC} control={<Radio />} label={
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <Typography variant='bodyCopyRegular' >Specific</Typography>
                <FormControl>
                  <TextField
                    size='small'
                    sx={{ width: '100%', ml: 1 }} name="note"
                    value={grantRequest.grantPurpose.note}
                    onChange={(event) => handleGrantPurposeChange("note", event.target.value)}
                    variant="outlined"
                    type="text"
                    placeholder="Add note"
                    required
                    disabled={grantRequest.grantPurpose.type === GRANT_PURPOSE_ENUM.UNRESTRICTED}
                  />
                </FormControl>
              </Box>

            }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]} gutterBottom>Person to inform</Typography>
        <Typography variant="bodyCopyRegular" color={neutral[600]} sx={{ mb: 0 }} paragraph>Please indicate who in the organisation should be notified that you made this grant</Typography>

      </Grid>
      <Grid item xs={12} sm={8}>
        <FormControl fullWidth>
          <TextField
            size='small'
            fullWidth
            name="note"
            value={grantRequest.toInform}
            onChange={(event) => handleGrantRequestChange("toInform", event.target.value)}
            variant="outlined"
            type="text"
            placeholder="Enter full name"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
