import { useMutation, useQuery } from "react-query";
import { SUCCESSOR_TYPE, Successor } from "types/successor";
import apiHttpClient from "libs/api-http-client";
export function useSuccessors(
  refresh: boolean,
  fundId: number,
  setSuccessors: (data: any) => void
) {
  const getSuccessors = async () => {
    const response = await apiHttpClient.get(`funds/${fundId}/successors`);
    return response;
  };

  return useQuery({
    queryKey: ["useSuccessors", fundId, refresh],
    queryFn: getSuccessors,
    enabled: fundId !== 0 && fundId !== undefined,
    onSuccess: (data) => setSuccessors(data),
  });
}
const preparePayload = (successor: Successor) => {
  let payload = {
    successorType: successor.successorType,
    successorId: successor.successorId,
  };
  if (successor.successorType === SUCCESSOR_TYPE.CHARITY) {
    return { ...payload, charityInfo: successor.successorInfo };
  } else {
    return { ...payload, individualInfo: successor.successorInfo };
  }
};
export function useAddSuccessor() {
  const AddSuccessorFn = async (data: any) => {
    const response = await apiHttpClient.post(
      `funds/${data.fundId}/successors`,
      preparePayload(data.successor)
    );
    return response;
  };
  return useMutation({
    mutationFn: AddSuccessorFn,
  });
}
export function useUpdateSuccessor() {
  const UpdateSuccessorFn = async (data: any) => {
    const response = await apiHttpClient.patch(
      `funds/${data.fundId}/successors/${data.successor.successorId}`,
      preparePayload(data.successor)
    );
    return response;
  };
  return useMutation({
    mutationFn: UpdateSuccessorFn,
  });
}
export function useDeleteSuccessor() {
  const DeleteSuccessorFn = async (data: any) => {
    const response = await apiHttpClient.delete(
      `funds/${data.fundId}/successors/${data.successorId}`
    );
    return response;
  };
  return useMutation({
    mutationFn: DeleteSuccessorFn,
  });
}
export function useUpdateSuccessionsPlan() {
  const UpdateSuccessionsPlanFn = async (data: any) => {
    const response = await apiHttpClient.patch(
      `funds/${data.fundId}/successors`,
      {
        SuccessionPlan: data.successors,
      }
    );
    return response;
  };
  return useMutation({
    mutationFn: UpdateSuccessionsPlanFn,
  });
}
