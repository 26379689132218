import React, { useContext } from "react";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import Layout from "components/layout/layout";
import { useParams } from "react-router-dom";

import { ReactComponent as Dollar } from "./../../assets/Icons/dollar.svg";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { shortFormat } from "utils/date-format";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useGetFundDividendQuery } from "hooks/use-services/fund-dividend.service";
export const MyDividendDistributionTransactionDetailsPage = () => {
  const { id } = useParams();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const {
    data: dividendData,
    isSuccess,
    isFetching,
    isLoading,
  } = useGetFundDividendQuery({
    fundAccountId: selectedAccount?.fundAccountId || 0,
    dividendId: id ? +id : 0,
  });

  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container>
                {isFetching ||
                  (isLoading && <CircularProgress size={20} color="inherit" />)}
                {isSuccess && dividendData && (
                  <Grid sx={{ m: 5 }} container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <BackToPreviousButton />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TransactionDetailsCard
                        amount={dividendData.amount}
                        type="DIVIDENDS"
                        title={dividendData.investment.name}
                        avatar={<Dollar />}
                        status={"PROCESSED"}
                        dividendType={dividendData.type}
                      >
                        <TransactionDetailsItem
                          label="Type"
                          value={`Distribution/Dividend`}
                        />
                        <TransactionDetailsItem
                          label="DATE"
                          value={shortFormat(dividendData.distributionDate)!}
                        />
                        <TransactionDetailsItem
                          label="INITIATED BY"
                          value={`${dividendData.user.firstName} ${dividendData.user.lastName}`}
                        />
                        <TransactionDetailsItem
                          label="TICKER"
                          value={`${dividendData.investment.ticker}`}
                        />
                        <TransactionDetailsItem
                          label="Fund"
                          value={`${dividendData.fund.fundAccountName}`}
                        />
                      </TransactionDetailsCard>
                    </Grid>
                  </Grid>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
