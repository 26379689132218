import { Account, USER_ROLE } from "./account";
import { User } from "./user";
export enum USER_STATUS {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}
export interface FundAccountInvitation {
  invitationId: number;
  email: string;
  fundId: number;
  fund: Account;
  user: User;
  status: USER_STATUS;
  role: USER_ROLE;
  expiredAt: Date;
}
export interface FundAccountInvitationInput {
  email: string;
  fundId: number;
  role: USER_ROLE;
}
