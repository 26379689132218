import apiHttpClient from "libs/api-http-client";
import PaginatedEntityList from "types/PaginatedEntityList";
import { FundMovementHistory } from "types/fund-movements-history";
import { FundPortfolioStatistics } from "types/fund-portfolio-statistcs";

export interface GetMovementsQuery {
  fundId?: number;
  offset?: number;
  limit?: number;
  filter?: {
    types?: string[];
  };
  dateTimeRange?: {
    startsAt?: Date;
    endsAt?: Date;
  };
}
export const getMovementsAPI = (
  query: GetMovementsQuery
): Promise<PaginatedEntityList<FundMovementHistory>> =>
  apiHttpClient.get(`/fund/movements`, {
    params: {
      fundId: query.fundId,
      limit: query.limit,
      offset: query.offset,
      filter: {
        types: query.filter?.types,
      },
      dateTimeRange: {
        startsAt: query.dateTimeRange?.startsAt,
        endsAt: query.dateTimeRange?.endsAt,
      },
    },
  });
export const getFundPortfolioStatisticsApi = (
  fundId: number
): Promise<FundPortfolioStatistics> =>
  apiHttpClient.get(`/fund/movements/${fundId}`);
