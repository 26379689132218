import { FC, useContext, useState } from "react";
import { Charity, einFormat } from "types/charity";
import { styled } from "@mui/material/styles";
import { warm } from "style/color";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as SaveIcon } from "./../../assets/Icons/save.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  useSaveCharityMutation,
  useUnsaveCharityMutation,
} from "hooks/use-services/charity.service";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
const CustomCard = styled(Card)({
  "& :hover": {
    backgroundColor: warm[500],
  },
});

interface CharityCardProps {
  charity: Charity;
  refresh: () => void;
  handleOpen: (value: boolean) => void;
  selectCharity: (ein: string) => void;
}

const CharityCard: FC<CharityCardProps> = ({
  charity: _charity,
  refresh,
  handleOpen,
  selectCharity
}) => {
  const [charity, setCharity] = useState(_charity);
  const { selectedAccount, userData, userRole } = useContext<AuthData>(AuthContext);
  const { mutate: saveCharity } = useSaveCharityMutation();
  const { mutate: unsaveCharity } = useUnsaveCharityMutation();

  const onSaveUnSaveBtnClicked = () => {
    if (!selectedAccount?.fundAccountId) return;
    if (charity.isSavedByActiveFundAccount) {
      unsaveCharity(
        {
          fundAccountId: selectedAccount?.fundAccountId,
          charityEIN: charity.ein,
        },
        {
          onSuccess: (updated) => {
            refresh();
          },
        }
      );
      setCharity({
        ...charity,
        isSavedByActiveFundAccount: false,
      });
    } else {
      saveCharity({
        fundAccountId: selectedAccount?.fundAccountId,
        charityEIN: charity.ein,
      });
      setCharity({
        ...charity,
        isSavedByActiveFundAccount: true,
      });
    }
  };
  const openRequestPage = (ein: string): void => {
    if (userData?.user) {
      selectCharity(ein);
    } else {
      handleOpen(false);
    }
  };
  return (
    <CustomCard>
      <CardContent sx={{ "&:last-child": { pb: 5 } }}>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              variant="smallCopy"
              sx={{
                color: "neutral.main",
              }}
            >
              {(charity.country === "USA") ? einFormat(charity.ein) : charity.ein}
            </Typography>

            {userData?.user && (
              <IconButton
                className={
                  charity.isSavedByActiveFundAccount
                    ? "charities-saved"
                    : "charities-unsaved"
                }
                size="small"
                onClick={onSaveUnSaveBtnClicked}
              >
                <SaveIcon />
              </IconButton>
            )}
          </Stack>
        </Box>
        <Box sx={{ height: "120px" }}>
          <Typography
            gutterBottom
            sx={{
              color: "neutral.main",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              whiteSpace: "pre-wrap",
            }}
            variant="subtitle1"
          >
            {charity.name}
          </Typography>
          <Typography
            paragraph
            sx={{
              color: "neutral.main",
            }}
            variant="smallCopyBold"
          >
            {charity.street} {charity.street2},<br></br>
            {charity.city}, {charity.state} {charity.zip}, {charity.country}
          </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={userRole === USER_ROLE.VIEWER || userData?.user?.status === USER_STATUS.PENDING}
            onClick={() => openRequestPage(charity.ein)}
          >
            Recommend a grant
          </Button>
          <Button
            target="_blank"
            href={`https://www.charitynavigator.org/ein/${charity.ein}`}
            startIcon={<LaunchIcon />}
            variant="text"
            color="primary"
            className="charites-more-info"
          >
            More info
          </Button>
        </Stack>
      </CardContent>
    </CustomCard>
  );
};

export default CharityCard;
