import React from 'react';
import { Typography } from '@mui/material';
import { neutral, primary } from 'style/color';
import { moneyFormat } from 'utils/money-format';
interface StatisticsDetailsProps {
    title: string;
    amount: number;
    text: string;
}

export const StatisticsDetails: React.FC<StatisticsDetailsProps> = (
    {
        title, amount, text
    }
) => {
    return (

        <>
            <Typography className='title' variant='subtitle2' sx={{ color: neutral[900] }} gutterBottom>{title} </Typography>
            <Typography variant='h3' sx={{ color: primary[500] }} gutterBottom>${moneyFormat(amount)} </Typography>
            {amount !== 0 &&
                <Typography variant='subtitle3' sx={{ color: neutral[900] }} gutterBottom>{text} </Typography>
            }


        </>


    )
}
