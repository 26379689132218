import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface MenuProps {
  PaperProps: {
    style: {
      maxHeight: number;
      width: number;
    };
  };
}
export interface Option {
  key: number;
  label: string;
  value: string;
}
interface SelectMultipleCheckboxDropdownProps {
  setSelected: any;
  options: Option[];
  size?: "small" | "medium";
  selected: Option[];
  menuProps?: MenuProps;
  title: string;
}

const menuPropsInitialValues: MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectMultipleCheckboxDropdown: React.FC<
  SelectMultipleCheckboxDropdownProps
> = ({
  options,
  selected,
  setSelected,
  title,
  size = "small",
  menuProps = menuPropsInitialValues,
}) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved: Option[] = [];

    value.forEach((item: Option) => {
      if (
        duplicateRemoved.findIndex((selected) => selected.key === item.key) >= 0
      ) {
        duplicateRemoved = duplicateRemoved.filter(
          (selected) => selected.key === item.key
        );
      } else {
        duplicateRemoved.push(item);
      }
    });

    setSelected(duplicateRemoved);
  };

  return (
    <FormControl size={size} fullWidth>
      <Select
        id={`multiple-checkbox-select-${title}`}
        labelId={`multiple-checkbox-select-label-${title}`}
        multiple
        value={selected}
        onChange={handleChange}
        displayEmpty
        renderValue={() => title}
        MenuProps={menuProps}
      >
        {options.map((option: Option) => (
          <MenuItem key={option.label} value={option as any}>
            <Checkbox
              checked={
                selected &&
                selected.findIndex((item) => item.key === option.key) >= 0
              }
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
