import { Grid, Skeleton } from "@mui/material";
interface LoadingCardProps {
  nbElement: number;
  height?: number;
  color: string;
}
export const LoadingCard: React.FC<LoadingCardProps> = ({
  nbElement,
  height = 100,
  color,
}) => {
  return (
    <Grid xs={12} sm={4}>
      {[...Array(nbElement)].map(() => (
        <Skeleton
          sx={{
            backgroundColor: color,
            boxShadow: "none",
            my: 3,
          }}
          variant="rounded"
          height={height}
        />
      ))}
    </Grid>
  );
};
