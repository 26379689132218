import React, { useEffect, useMemo, useRef } from "react";
import Layout from "../../components/layout/layout";
import {
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GrantVestmentsCard from "../../components/grant-vestments/grant-vestment-card";
import { useSearchParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { GrantVestment } from "types/grant-vestment";
import { useGetCategoriesQuery } from "hooks/use-services/category.service";
import { useGetGrantVestmentsQueryWithPagination } from "hooks/use-services/grant-vestment.service";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
export default function GrantVestmentsSearch() {
  const initialized = useRef(false);
  const [searchParams] = useSearchParams();
  const [keyword, setKeyword] = React.useState("");
  const [filters, setFilters] = React.useState({
    sort: "Newest",
    show: "All",
    category: 0,
    keyword: "",
  });
  const { data,
    fetchNextPage,
    isSuccess: isGrantVestmentSuccess,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage } = useGetGrantVestmentsQueryWithPagination({
      limit: 6, filters: filters
    });
  const grantVestments = useMemo<GrantVestment[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );
  const { data: categories, isSuccess } = useGetCategoriesQuery({ limit: 6 });
  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (searchParams && searchParams.get("category")) {
        setFilters({
          ...filters,
          category: +searchParams.get("category")!,
        });
      }
      if (searchParams && searchParams.get("keyword")) {
        setFilters({
          ...filters,
          keyword: searchParams.get("keyword")!,
        });
      }
    }
  });
  const handleFilterChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  const handlekeywordChange = (event: any) => {
    const { name, value } = event.target;
    if (event.key === "Enter") {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };
  const reset = () => {
    setKeyword("");
    setFilters({
      ...filters,
      keyword: "",
    });
  };

  return (
    <Layout>
      {() => (
        <Box sx={{ m: 4 }}>
          <Typography sx={{ color: "neutral.main" }} variant="h3" gutterBottom>
            GrantVestments
          </Typography>

          <Grid
            sx={{ my: 2 }}
            container
            justifyContent={{ xs: "start", md: "space-between" }}
            alignItems="center"
            columns={16}
            spacing={{ xs: 1, md: 0 }}
            rowSpacing={2}
          >
            <Grid
              display={{ xs: "block", md: "none" }}
              item
              xs={16}
              md={4}
              textAlign={"end"}
            >
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder="Type keyword or name"
                  size="small"
                  id="keyword"
                  value={keyword}
                  defaultValue={""}
                  name="keyword"
                  onChange={(event) => setKeyword(event.target.value)}
                  onKeyDown={(event) => handlekeywordChange(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={1}>
              <Typography
                sx={{ color: "neutral.main" }}
                variant="subtitle3"
                component={"span"}
                gutterBottom
              >
                Show me
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl size="small" fullWidth>
                <Select
                  fullWidth
                  name="show"
                  id="show-me"
                  value={filters.show}
                  className="search"
                  MenuProps={{ classes: { paper: "search" } }}
                  onChange={(event) => {
                    handleFilterChange(event);
                  }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"RAISING"}>Raising</MenuItem>
                  <MenuItem value={"CLOSED"}>Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {filters.keyword ? (
              <Grid item xs={6} md={2}>
                <FormControl size="small">
                  <OutlinedInput
                    value={filters.keyword}
                    className="search"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={reset} edge="end">
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  ></OutlinedInput>
                </FormControl>
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                sx={{ display: { xs: "block", md: "none" } }}
              ></Grid>
            )}
            <Grid item xs={4} md={1}>
              <Typography
                sx={{ color: "neutral.main" }}
                variant="subtitle3"
                component={"span"}
                gutterBottom
              >
                projects in
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl size="small" fullWidth>
                <Select
                  fullWidth
                  id="show-me"
                  value={filters.category}
                  name="category"
                  className="search"
                  MenuProps={{ classes: { paper: "search" } }}
                  onChange={(event) => {
                    handleFilterChange(event);
                  }}
                >
                  <MenuItem key={0} value={0}>
                    All categories
                  </MenuItem>
                  {isSuccess &&
                    (categories.data).map((category) => {
                      return (
                        <MenuItem
                          key={category.categoryId}
                          value={category.categoryId}
                        >
                          {category.categoryName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: { xs: "block", md: "none" } }}
            ></Grid>
            <Grid item xs={4} md={1}>
              <Typography
                sx={{ color: "neutral.main" }}
                variant="subtitle3"
                component={"span"}
                gutterBottom
              >
                sorted by
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl size="small" fullWidth>
                <Select
                  fullWidth
                  id="sorted"
                  value={filters.sort}
                  name="sort"
                  className="search"
                  MenuProps={{ classes: { paper: "search" } }}
                  onChange={(event) => {
                    handleFilterChange(event);
                  }}
                >
                  <MenuItem value={"Newest"}>Newest</MenuItem>
                  <MenuItem value={"Popularity"}>Popularity</MenuItem>
                  <MenuItem value={"End date"}>End date</MenuItem>
                  <MenuItem value={"Most funded"}>Most funded</MenuItem>
                  <MenuItem value={"Least funded"}>Least funded </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: { xs: "block", md: "none" } }}
            ></Grid>
            <Grid
              display={{ xs: "none", md: "block" }}
              item
              xs={16}
              md={4}
              textAlign={"end"}
            >
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder="Type keyword or name"
                  size="small"
                  id="keyword"
                  value={keyword}
                  defaultValue={""}
                  name="keyword"
                  onChange={(event) => setKeyword(event.target.value)}
                  onKeyDown={(event) => handlekeywordChange(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          {isGrantVestmentSuccess && (
            <>
              <Typography
                sx={{ color: "neutral.main", mt: 4, mb: 2 }}
                className="title"
                variant="subtitle1"
                gutterBottom
              >
                {grantVestments.length} projects found
              </Typography>
              <Grid
                sx={{ my: 2 }}
                container
                justifyContent="start"
                columnSpacing={2}
                rowSpacing={2}
                columns={16}
                alignItems="center"
              >
                {(grantVestments).map(
                  (grantvestment, index) => {
                    return (
                      <Grid key={index} item xs={12} sm={8} md={5}>
                        <GrantVestmentsCard
                          grantvestment={grantvestment}
                          single={false}
                          allContent={false}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </>
          )}
          <Box ref={lastElementRef}>
            {isFetchingNextPage && (
              <Grid
                item
                xs={12}
                sm={10}
                textAlign={"center"}
                sx={{ m: 3 }}
              >
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </Layout>
  );
}
