import React, { useState } from "react";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Box,
    FormHelperText,
    CircularProgress
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { neutral } from "style/color";
import { RecommendedCharityInput } from "types/recommended-charity";
import { useAddRecommendedCharityMutation } from "hooks/use-services/recommended-charity.service";
interface recommendCharityProps {
    open: boolean;
    onClose: () => void;
}
export const RecommendCharity: React.FC<
    recommendCharityProps
> = ({ open, onClose }) => {
    const { mutate, isLoading } = useAddRecommendedCharityMutation();
    const [openSuccessModal, setOpen] =
        useState<boolean>(false);
    const [recommendCharity, setRecommendCharity] = useState<RecommendedCharityInput>({
        ein: '',
        charityName: '',
        contactName: '',
        contactEmail: '',
        websiteLink: '',
        contactPhone: '',
        additionalInformation: ''
    })
    const [error, setError] = React.useState({
        ein: false,
        charityName: false,
        contactName: false,
        contactEmail: false
    })
    const handleChange = (key: string, value: string) => {
        setError({
            ...error,
            [key]: false
        })
        setRecommendCharity({
            ...recommendCharity,
            [key]: value
        })
    };
    const handleError = (key: string) => {
        setError({
            ...error,
            [key]: true
        })
    };
    const onSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        mutate(
            recommendCharity,
            {
                onSuccess: (updated) => {
                    onClose()
                    setOpen(true)
                },
            }
        );

    }
    return (
        <>

            <Dialog sx={{ m: 2 }} fullWidth={true}
                maxWidth={'md'} open={open} onClose={onClose}>

                <DialogTitle sx={{ my: 2, pl: 4 }}>
                    <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                        Recommend a charity
                        <IconButton
                            onClick={onClose}
                            sx={{
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: "transparent",
                                }
                            }}
                        > <CloseIcon />
                        </IconButton>

                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ pl: 4 }}>
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={onSubmit}
                    >
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: neutral[600] }} variant='bodyCopyRegular'>
                                    Please enter the information below to suggest a new charity, and we will review it for potential inclusion in our list

                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>

                                <FormControl error={error.charityName} fullWidth>
                                    <InputLabel shrink={false}>Charity name*</InputLabel>
                                    <OutlinedInput name="charityName"
                                        value={recommendCharity.charityName}
                                        onChange={(event) => handleChange("charityName", event.target.value)}
                                        onInvalid={(event) => handleError('charityName')}
                                        type="text" required />
                                    {error.charityName && <FormHelperText >This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                <FormControl error={error.ein} fullWidth>
                                    <InputLabel shrink={false}>ein*</InputLabel>
                                    <OutlinedInput name="ein"
                                        value={recommendCharity.ein}
                                        onChange={(event) => handleChange("ein", event.target.value)}
                                        onInvalid={(event) => handleError('ein')}
                                        type="text" required />
                                    {error.ein && <FormHelperText >This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>

                                <FormControl fullWidth>
                                    <InputLabel shrink={false}>Website link</InputLabel>
                                    <OutlinedInput name="websiteLink"
                                        value={recommendCharity.websiteLink}
                                        onChange={(event) => handleChange("websiteLink", event.target.value)}
                                        type="text" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: neutral[900] }} variant='subtitle3'>
                                    Contact person at the charity
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl error={error.contactName} fullWidth>
                                    <InputLabel shrink={false}>Name*</InputLabel>
                                    <OutlinedInput name="contactName"
                                        value={recommendCharity.contactName}
                                        onChange={(event) => handleChange("contactName", event.target.value)}
                                        onInvalid={(event) => handleError('contactName')}
                                        type="text" required />
                                    {error.contactName && <FormHelperText >This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel shrink={false}>Phone</InputLabel>
                                    <OutlinedInput name="contactPhone"
                                        value={recommendCharity.contactPhone}
                                        onChange={(event) => handleChange("contactPhone", event.target.value)}
                                        type="text" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={error.contactEmail} fullWidth>
                                    <InputLabel shrink={false}>Email*</InputLabel>
                                    <OutlinedInput name="contactEmail"
                                        value={recommendCharity.contactEmail}
                                        onChange={(event) => handleChange("contactEmail", event.target.value)}
                                        onInvalid={(event) => handleError('contactEmail')}
                                        type="text" required />
                                    {error.contactEmail && <FormHelperText >This field is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <InputLabel shrink={false}>Any additional information</InputLabel>
                                    <OutlinedInput name="additionalInformation"
                                        value={recommendCharity.additionalInformation}
                                        onChange={(event) => handleChange("additionalInformation", event.target.value)}
                                        type="text"
                                        multiline
                                        rows={3} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={'right'}>
                                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose} >
                                    Discard
                                </Button>
                                <Button startIcon={
                                    isLoading && <CircularProgress size={20} color="inherit" />
                                } disabled={isLoading} variant="contained" color='primary' size='large' type='submit'    >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog sx={{ m: 2 }} fullWidth={true}
                maxWidth={'sm'} open={openSuccessModal} onClose={() => setOpen(false)}>

                <DialogTitle sx={{ my: 2, pl: 4 }}>
                    <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                        Thank you
                        <IconButton
                            onClick={() => setOpen(false)}
                            sx={{
                                padding: 0,
                                "&:hover": {
                                    backgroundColor: "transparent",
                                }
                            }}
                        > <CloseIcon />
                        </IconButton>

                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ pl: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ color: neutral[900] }} variant='bodyCopyRegular'>
                                We will take a look at your suggestion and get back to you as soon as possible
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} textAlign={'right'}>

                            <Button variant="contained" color='primary' size='large' onClick={() => setOpen(false)}    >
                                Got it
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


        </>
    );
};
