import {
  Box,
  FilledInput,
  FormControl,
  Grid,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { neutral, warm } from "style/color";
import SearchIcon from "@mui/icons-material/Search";

import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { CharitiesSearchPagePath } from "constants/pages";
import { useNavigate, createSearchParams } from "react-router-dom";
import { SavedCharitiesList } from "../saved-charities-list";
import { GrantedCharitiesList } from "./granted-charities-list";
import { RecommendCharity } from "components/charity-card/recommendCharity";

interface GrantRequestCharityListProps {
  handleOpen: (value: boolean) => void;
  selectCharity: (ein: string) => void;
}

export const GrantRequestCharityList: FC<GrantRequestCharityListProps> = ({
  handleOpen, selectCharity
}) => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const [open, setOpen] =
    useState<boolean>(false);
  const [viewAllSavedCharities, setViewAllSavedCharities] =
    useState<boolean>(false);
  const [viewAllCharities, setViewAllCharities] =
    useState<boolean>(false);
  const [charitieslimit, setLimit] = useState(9);
  const navigate = useNavigate();
  const searchByKeyword = (event: any) => {
    if (event.key === "Enter") {
      navigate({
        pathname: CharitiesSearchPagePath,
        search: `?${createSearchParams({ keyword: event.target.value })}`,
      });
    }
  };
  const handleViewAllSavedCharities = () => {
    setViewAllSavedCharities((prev) => {
      return !prev;
    });
    setViewAllSavedCharities((prev) => {
      return !prev;
    });
  };
  const handleViewAllCharities = () => {
    setViewAllCharities((prev) => {
      if (!prev === true) {
        setLimit(3);
      } else {
        setLimit(9);
      }
      return !prev;
    });
  };
  return (
    <>
      <Box>
        <Typography variant="h3" color={neutral[900]}>
          Select Charities
        </Typography>
      </Box>
      <Box sx={{ marginY: 4 }}>
        <Grid item xs={12} sm={12} sx={{ textAlign: "end" }}>
          <FormControl variant="outlined" fullWidth>
            <FilledInput
              className="search"
              onKeyDown={(event) => {
                searchByKeyword(event);
              }}
              placeholder="Type keyword or name"
              size="small"
              id="outlined-adornment-weight"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Box>
      {selectedAccount && !viewAllSavedCharities && (
        <GrantedCharitiesList
          selectCharity={selectCharity}
          handleOpen={handleOpen}
          handleViewAllSavedCharities={handleViewAllCharities}
          selectedAccount={selectedAccount}
          limit={charitieslimit}
          viewAllSavedCharities={viewAllCharities}
        />
      )}
      {selectedAccount && !viewAllCharities && (
        <SavedCharitiesList
          openRequestPage={selectCharity}
          handleOpen={handleOpen}
          handleViewAllSavedCharities={handleViewAllSavedCharities}
          selectedAccount={selectedAccount}
          limit={9}
          viewAllSavedCharities={viewAllSavedCharities}
        />
      )}
      <Box sx={{ backgroundColor: warm[500], py: 6, px: 8, borderRadius: '8px' }}>
        <Typography variant="h3" color={neutral[900]} paragraph>
          Your favorite charity isn’t appearing?
        </Typography>
        <Typography variant='bodyCopyRegular' color={neutral[900]} paragraph>
          Click below to suggest a new charity and our team will review it to have it added to our list.
        </Typography>
        <Button variant='contained' color='primary' onClick={() => setOpen(true)} >Suggest a charity</Button>
      </Box>
      <RecommendCharity open={open} onClose={() => setOpen(false)}></RecommendCharity>
    </>
  );
};
