import { addFundAccountAPI, updateFundAccountAPI } from "api/fund.api";
import { useMutation } from "react-query"
import { Account, Entity } from "types/account";

const FUND_RQ_KEY = 'fund';



export const useUpdateFundAccountMutation = (fundAccountId: number) => {
  return useMutation<unknown, unknown, {
    fundAccountName?: string;
    entityInformation?: Entity;
    interests?: number[];
  }>({
    mutationKey: [FUND_RQ_KEY, fundAccountId],
    mutationFn: (data) => updateFundAccountAPI(fundAccountId, data),
  })
}
export const useAddFundAccountMutation = () => {
  return useMutation<unknown, unknown, {
    userId: number;
    fund?: Account;
  }>({
    mutationKey: [FUND_RQ_KEY],
    mutationFn: (data) => addFundAccountAPI(data),
  })
}
