import React, { useContext, useMemo } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Layout from "components/layout/layout";
import { useNavigate } from "react-router-dom";
import {
  CharitiesPagePath,
  CharityGrantRequestPagePath,
} from "constants/pages";

import { ReactComponent as GrantIcon } from "./../../../assets/Icons/Grant.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { MyGrantsRequestList } from "./my-grants-list";
import { MyScheduledGrantsRequestList } from "./my-scheduled-grants-list";
import { useGetScheduledGrantRequestsQuery } from "hooks/use-services/grant-request.service";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import {
  SCHEDULED_GRANT_REQUEST_STATUS_ENUM,
  ScheduledGrantRequest,
} from "types/grant-request";
import { MyGrantsRequestListItems } from "./my-grants-list-items";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { USER_ROLE } from "types/account";
import { USER_STATUS } from "types/fund-account-invitation";
import { addWeeksToDate, dateFormat } from "utils/date-format";
import { einFormat } from "types/charity";

export const MyGrantsRequestPage = () => {
  const navigate = useNavigate();
  const { selectedAccount, userRole, userData } = useContext<AuthData>(AuthContext);
  const [yearForPastGrant, ForPastGrant] = React.useState<number>(
    new Date().getFullYear()
  );
  const [startDate] = React.useState<Date>(
    new Date()
  );
  const [endDate] = React.useState<Date>(
    addWeeksToDate(1)
  );
  const {
    data: pastGrantRequestsData,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetScheduledGrantRequestsQuery({
    limit: 10,
    filter: {
      fundAccountId: selectedAccount?.fundAccountId,
      statuses: [
        SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PROCESSED,
        SCHEDULED_GRANT_REQUEST_STATUS_ENUM.REJECTED,
      ],
    },
    dateTimeRange: {
      startsAt: new Date(`${yearForPastGrant}-01-01`),
      endsAt: new Date(`${yearForPastGrant}-12-31`),
    },
  });
  const pastGrantRequests = useMemo<ScheduledGrantRequest[]>(
    () =>
      pastGrantRequestsData
        ? pastGrantRequestsData?.pages?.flatMap((item) => item.data)
        : [],
    [pastGrantRequestsData]
  );
  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  const {
    data: presentGrantRequestsData,
    isFetching: isFetchingPresent,
    isLoading: isLoadingPresent,
    fetchNextPage: fetchNextPagePresent,
    hasNextPage: hasNextPagePresent,
    isFetchingNextPage: isFetchingNextPagePresent,
  } = useGetScheduledGrantRequestsQuery({
    limit: 10,
    filter: {
      fundAccountId: selectedAccount?.fundAccountId,
      statuses: [
        SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PENDING,
        SCHEDULED_GRANT_REQUEST_STATUS_ENUM.APPROVED,
      ],
    },
    dateTimeRange: {
      startsAt: dateFormat(startDate),
      endsAt: dateFormat(endDate)
      ,
    },
  });
  const presentGrantRequests = useMemo<ScheduledGrantRequest[]>(
    () =>
      presentGrantRequestsData
        ? presentGrantRequestsData?.pages?.flatMap((item) => item.data)
        : [],
    [presentGrantRequestsData]
  );
  const lastElementRefPresent = useIntersectionObserver({
    isFetching: isFetchingPresent,
    isLoading: isLoadingPresent,
    fetchNextPage: fetchNextPagePresent,
    hasNextPage: hasNextPagePresent,
  });
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, mx: 3 }}>
            <React.Fragment>
              <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                <Grid item xs={12} sm={2} sx={{ mb: 2 }}>
                  <Typography variant="h3">My Grants</Typography>
                </Grid>
                <Grid item xs={12} sm={2} sx={{ mb: 2 }} textAlign={"right"}>
                  <Button
                    startIcon={<GrantIcon />}
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={userRole === USER_ROLE.VIEWER || userData?.user?.status === USER_STATUS.PENDING}
                    onClick={() => navigate(CharityGrantRequestPagePath)}
                  >
                    Grant
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
                  <Button
                    sx={{}}
                    startIcon={<VisibilityOutlinedIcon />}
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => navigate(CharitiesPagePath)}
                  >
                    Browse charities
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MyScheduledGrantsRequestList title=" Future & Recurring Grants" />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MyGrantsRequestList
                    length={presentGrantRequests.length}
                    title="Upcoming"
                  >
                    {presentGrantRequests.map((scheduledGrantRequest, index) => {
                      const { grantRequest } = scheduledGrantRequest;
                      const { charity, user } = grantRequest;
                      return (
                        <>
                          <MyGrantsRequestListItems
                            charityName={charity.name}
                            ein={(charity.country === "USA") ? einFormat(charity.ein) : charity.ein}
                            userName={`${user.firstName} ${user.lastName}`}
                            executionDate={scheduledGrantRequest.executionDate}
                            amount={scheduledGrantRequest.appliedAmount}
                            status={scheduledGrantRequest.status}
                            lastElement={index === pastGrantRequests.length - 1}
                            id={grantRequest.grantRequestId}
                            transactionId={
                              scheduledGrantRequest.scheduledGrantRequestId
                            }
                          />
                          <Box ref={lastElementRefPresent}>
                            {isFetchingNextPagePresent && (
                              <Grid
                                item
                                xs={12}
                                sm={10}
                                textAlign={"center"}
                                sx={{ m: 3 }}
                              >
                                {" "}
                                <CircularProgress color="primary" />
                              </Grid>
                            )}
                          </Box>
                        </>
                      );
                    })}
                  </MyGrantsRequestList>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MyGrantsRequestList
                    length={pastGrantRequests.length}
                    title="Past Grants"
                    updateYear={ForPastGrant}
                  >
                    {pastGrantRequests.map((scheduledGrantRequest, index) => {
                      const { grantRequest } = scheduledGrantRequest;
                      const { charity, user } = grantRequest;
                      return (
                        <>
                          <MyGrantsRequestListItems
                            charityName={charity.name}
                            ein={(charity.country === "USA") ? einFormat(charity.ein) : charity.ein}
                            userName={`${user.firstName} ${user.lastName}`}
                            executionDate={scheduledGrantRequest.executionDate}
                            amount={scheduledGrantRequest.appliedAmount}
                            status={scheduledGrantRequest.status}
                            lastElement={index === pastGrantRequests.length - 1}
                            id={grantRequest.grantRequestId}
                            transactionId={
                              scheduledGrantRequest.scheduledGrantRequestId
                            }
                          />
                          <Box ref={lastElementRef}>
                            {isFetchingNextPage && (
                              <Grid
                                item
                                xs={12}
                                sm={10}
                                textAlign={"center"}
                                sx={{ m: 3 }}
                              >
                                {" "}
                                <CircularProgress color="primary" />
                              </Grid>
                            )}
                          </Box>
                        </>
                      );
                    })}
                  </MyGrantsRequestList>
                </Grid>
              </Grid>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
