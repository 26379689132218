import { useCallback, useRef } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";

interface Props {
    isLoading: boolean,
    isFetching: boolean,
    hasNextPage: boolean | undefined,
    fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<any, any>>,
}
export default function useIntersectionObserver({
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,

}: Props) {
    const observer = useRef<IntersectionObserver>();
    return useCallback(
        (node: HTMLDivElement) => {
            //Return if already fetching
            if (isLoading || isFetching) return;

            // Disconnect if already observer exists
            if (observer.current) observer.current.disconnect();

            //Create new observer for the last element, and call fetchNextPage if visible(isIntersecting)
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, isFetching, hasNextPage, fetchNextPage]
    );
}
