import {
  getFundPortfolioStatisticsApi,
  getMovementsAPI,
  GetMovementsQuery,
} from "api/movements.api";
import { useInfiniteQuery, useQuery } from "react-query";
import { FundMovementHistory } from "types/fund-movements-history";
import { FundPortfolioStatistics } from "types/fund-portfolio-statistcs";
import PaginatedEntityList from "types/PaginatedEntityList";

const FUND_MOVEMENTS_HISTORY_KEY = "FundMovementsHistory";

export const useGetMovementsQuery = (query: GetMovementsQuery) => {
  return useInfiniteQuery<PaginatedEntityList<FundMovementHistory>>({
    queryKey: [FUND_MOVEMENTS_HISTORY_KEY, query],
    enabled: query.fundId !== 0,
    queryFn: ({ pageParam = 0 }) =>
      getMovementsAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.nextOffset;
    },
  });
};
export const useGetFundPortfolioStatisticsQuery = (fundId: number) => {
  return useQuery<FundPortfolioStatistics>({
    queryKey: [FUND_MOVEMENTS_HISTORY_KEY, fundId],
    enabled: fundId !== 0,
    queryFn: () => getFundPortfolioStatisticsApi(fundId),
  });
};
