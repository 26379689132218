import { Box } from "@mui/material";
import Layout from "components/layout/layout";
import { FundDashboardPage } from "constants/pages";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomStepper } from "components/shared/custom-stepper";
import { InvestmentRequestSelectAsset } from "components/investment/investment-request/investment-request-select-asset";
import { InvestmentRequestEnterData } from "components/investment/investment-request/investment-request-enter-data";
import { InvestmentRequestSuccess } from "components/investment/investment-request/investment-request-sucess";
import { INVESTMENT_REQUEST_INTEREST_ENUM, INVESTMENT_REQUEST_TYPE_ENUM } from "types/investment-request";
import { CustomBreadcrumb } from "components/shared/custom-breadcrumb";

const InvestmentBuyRequestPage = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [ticker, setTicker] = useState<string>("");
  const [requestInfo, setRequestInfo] = useState<{
    name: string;
    amount: number;
    units: number;
    interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM
  }>({
    name: "",
    amount: 0,
    units: 0,
    interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM.AMOUNT
  });
  const [searchParams] = useSearchParams();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (searchParams && searchParams.get("ticker")) {
        setTicker(searchParams.get("ticker") || "");
        setActiveStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  const handleSuccess = (name: string, amount: number, units: number, interestedIn: INVESTMENT_REQUEST_INTEREST_ENUM) => {
    setRequestInfo({ name, amount, units, interestedIn });
    setActiveStep(2);
  };
  const selectAsset = (ticker: string): void => {
    setTicker(ticker);
    setActiveStep(1);
  };

  return (
    <Layout>
      {() => (
        <Box sx={{ m: 5 }}>
          <CustomBreadcrumb
            title={"Back to fund dashboard "}
            navigateTo={FundDashboardPage}
          />
          <CustomStepper
            stepsTitles={[
              "Select asset",
              "Enter amount and confirm",
              "Confirmation",
            ]}
            activeStep={activeStep}
          />
          {activeStep === 0 && (
            <InvestmentRequestSelectAsset selectAsset={selectAsset} />
          )}
          {activeStep === 1 && ticker !== "" && (
            <InvestmentRequestEnterData
              ticker={ticker}
              handleSuccess={handleSuccess}
              type={INVESTMENT_REQUEST_TYPE_ENUM.BUY}
            />
          )}
          {activeStep === 2 && requestInfo.name !== "" && (
            <InvestmentRequestSuccess
              investmentName={requestInfo.name}
              requestedAmount={requestInfo.amount}
              requestUnits={requestInfo.units}
              interestedIn={requestInfo.interestedIn}
              type={INVESTMENT_REQUEST_TYPE_ENUM.BUY}
            />
          )}
        </Box>
      )}
    </Layout>
  );
};

export default InvestmentBuyRequestPage;
