import React from 'react';
import { Alert } from '@mui/material';
interface SuccessAlertProps {
    text: string;
    setOpen: (value: boolean) => void;
}
export const SuccessAlert: React.FC<SuccessAlertProps> = ({
    text, setOpen
}) => {

    const handleClose = () => {
        setOpen(false);
    };

    setTimeout(handleClose, 5000);
    return (

        <Alert className='settings' action={false} icon={false} onClose={handleClose} sx={{ py: 2 }}>
            {text}
        </Alert>


    )
}
