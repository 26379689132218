export interface Charity {
  ein: string;
  name: string;
  mission: string;
  organizationUrl: string;
  cause: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  isBlocked: boolean;
  lastFetchedAt: Date;
  isSavedByActiveFundAccount: boolean;
}

export const einFormat = (ein: string) => {
  return ein.slice(0, 2) + "-" + ein.slice(2);
};
