import React, { useContext, useState } from "react";
import {
    IconButton,
    Divider,
    Menu,
    MenuItem
} from "@mui/material";
import { ReactComponent as EditIcon } from './../../assets/Icons/edit.svg'
import { ReactComponent as DeleteIcon } from './../../assets/Icons/Delete.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { neutral } from "style/color";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { USER_ROLE } from "types/account";
interface DetailsButtonProps {
    item: any;
    grant?: boolean;
    handleOpenDetails: (item: any) => void;
    handleOpenCancelDialog: (item: any) => void;
    handleOpenEditPage: (item: any) => void;
}
export const DetailsButton: React.FC<
    DetailsButtonProps
> = ({ item, handleOpenDetails, handleOpenCancelDialog, handleOpenEditPage, grant }) => {
    const { userRole } = useContext<AuthData>(AuthContext)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const openEditPage = (event: any) => {
        event.stopPropagation()
        setAnchorEl(null);
        handleOpenEditPage(item)
    };
    const viewDetails = (event: any) => {
        event.stopPropagation()
        setAnchorEl(null);
        handleOpenDetails(item)
    };
    const cancel = (event: any) => {
        event.stopPropagation()
        setAnchorEl(null);
        handleOpenCancelDialog(item)
    };
    return (
        <>
            <IconButton
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <MoreVertIcon sx={{ color: 'neutral.light' }} />
            </IconButton>
            {anchorEl && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        "& .MuiMenu-paper": { px: 1 },
                    }}
                >
                    <MenuItem
                        className="contribution-dropdown"
                        onClick={viewDetails}
                    >
                        <VisibilityOutlinedIcon /> {(grant) ? 'View grant details' : 'View payment details'}
                    </MenuItem>
                    <Divider sx={{ backgroundColor: neutral[400] }} />
                    <MenuItem
                        className="contribution-dropdown"
                        onClick={openEditPage}
                        disabled={userRole === USER_ROLE.VIEWER}
                    >
                        <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem
                        className="contribution-dropdown"
                        onClick={cancel}
                        disabled={userRole === USER_ROLE.VIEWER}
                    >
                        <DeleteIcon /> Delete
                    </MenuItem>
                </Menu>
            )}

        </>
    );
};
