import { getCharitiesAPI, GetCharitiesQuery, getCharityAPI, getGrantedCharitiesAPI, getSavedCharitiesAPI, saveCharityAPI, unsaveCharityAPI } from "api/charities.api";
import { useMutation, useInfiniteQuery, useQuery } from "react-query"
import { Charity } from "types/charity";
import PaginatedEntityList from "types/PaginatedEntityList";

const CHARITY_RQ_KEY = 'charities';
const SAVED_CHARITY_RQ_KEY = 'saved-charities';
const GRANTED_CHARITY_RQ_KEY = 'granted-charities';

export const useGetCharitiesQuery = (query: GetCharitiesQuery) => {
  return useInfiniteQuery<PaginatedEntityList<Charity>>({
    queryKey: [CHARITY_RQ_KEY, query],
    queryFn: ({ pageParam = 0 }) => getCharitiesAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetCharityQuery = (ein: string) => {
  return useQuery<Charity>({
    queryKey: [CHARITY_RQ_KEY, ein],
    enabled: ein !== "",
    queryFn: () => getCharityAPI(ein),
  })
}
export const useGetSavedCharitiesQuery = (query: GetCharitiesQuery) => {
  return useInfiniteQuery<PaginatedEntityList<Charity>>({
    queryKey: [SAVED_CHARITY_RQ_KEY, query],
    enabled: query?.activeFundAccountId !== undefined,
    queryFn: ({ pageParam = 0 }) => getSavedCharitiesAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}
export const useGetGrantedCharitiesQuery = (query: GetCharitiesQuery) => {
  return useInfiniteQuery<PaginatedEntityList<Charity>>({
    queryKey: [GRANTED_CHARITY_RQ_KEY, query],
    enabled: query?.activeFundAccountId !== undefined,
    queryFn: ({ pageParam = 0 }) => getGrantedCharitiesAPI({ ...query, offset: pageParam }),
    getNextPageParam: (lastPage) => { return lastPage.pagination.nextOffset },
  })
}

export const useSaveCharityMutation = () => {
  return useMutation({
    mutationKey: [CHARITY_RQ_KEY],
    mutationFn: (data: {
      fundAccountId: number, charityEIN: string
    }) => saveCharityAPI(data.fundAccountId, data.charityEIN),
  })
}


export const useUnsaveCharityMutation = () => {
  return useMutation({
    mutationKey: [CHARITY_RQ_KEY],
    mutationFn: (data: {
      fundAccountId: number, charityEIN: string
    }) => unsaveCharityAPI(data.fundAccountId, data.charityEIN),
  })
}



