import React, { useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { Account } from "types/account";
import { AuthContext } from "providers/userProvider";
import { neutral, warm } from "style/color";
import { AuthData } from "types/auth";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as ArrowRightIcon } from "./../assets/Icons/arrow-right.svg";

import { MyScheduledGrantsRequestList } from "pages/charities/my-grants/my-scheduled-grants-list";
import { MyPendingContributionList } from "pages/contribution-transaction/contribution";
import { MovementsCard } from "pages/my-cash/movements-card";
import BalanceBox from "components/shared/balance-box";
import { StatisticsDetails } from "components/shared/statistics-details";
import { useGetFundPortfolioStatisticsQuery } from "hooks/use-services/movements.service";
import { useNavigate } from "react-router-dom";
import { MyCashPageUrl, MyGrantVestmentRequestPage, MyInvestmentsRequestsPage } from "constants/pages";
import { useGetUsersInvitationsQuery, useManageUserInvitationMutation } from "hooks/use-services/fund-account-invitations.service";
import { USER_STATUS } from "types/fund-account-invitation";

export const Dashboard = () => {
  const { selectedAccount, userData } = useContext<AuthData>(AuthContext);
  const [account, setAccount] = React.useState<Account>();
  const { data: fundPortfolioStatists } = useGetFundPortfolioStatisticsQuery(
    (selectedAccount?.fundAccountId as number) || 0
  );
  const { data: invites, refetch } = useGetUsersInvitationsQuery({
    filter: {
      email: userData?.user?.email!,
      status: USER_STATUS.PENDING

    }
  })
  const { mutate } = useManageUserInvitationMutation()
  const navigate = useNavigate()
  const CustomCard = (text: string, value: number, path: string) => {
    return (
      <Card sx={{
        backgroundColor: warm[500], boxShadow: "none", "&:hover": {
          cursor: "pointer",
        },
      }}
        onClick={() => navigate(path)}
      >
        <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
          <Stack direction="row" spacing={2}>
            <Box>
              <Typography
                variant="subtitle3"
                sx={{
                  color: neutral[700],
                  display: "flex",
                  alignItems: "center",
                }}
                gutterBottom
              >
                <Box sx={{ mr: 3 }}> {text}</Box> <ArrowRightIcon />
              </Typography>
              <Typography variant="subtitle1" component={"div"}>
                ${moneyFormat(value, 2)}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  };
  useEffect(() => {
    if (selectedAccount) {
      setAccount(selectedAccount);
    }
  }, [selectedAccount]);
  const manageInvite = (invitationId: number, status: USER_STATUS) => {
    mutate({
      invitationId, status
    }, {
      onSuccess: () => refetch()
    });

  }
  return (
    <Layout>
      {() => (
        <>
          {invites && invites.data.length !== 0 &&
            <Alert className='invite' icon={false} sx={{}}>
              You have been invited by {invites.data[0].user.firstName} {invites.data[0].user.lastName} to manage the {invites.data[0].fund.fundAccountName}.
              <Box><Button color="inherit" size="large" onClick={() => manageInvite(invites.data[0].invitationId, USER_STATUS.REJECTED)}>
                Decline request
              </Button>
                <Button variant='outlined' color="inherit" size="large" onClick={() => manageInvite(invites.data[0].invitationId, USER_STATUS.ACCEPTED)}>
                  Accept
                </Button>
              </Box>
            </Alert>}

          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Box sx={{ mt: 3, mx: 3 }}>
                <BalanceBox
                  dashboardStyle={true}
                  showRequestsButtons={true}
                  name={account?.fundAccountName as string}
                  total={fundPortfolioStatists?.totalValue as number}
                  available={fundPortfolioStatists?.availableBalance as number}
                />

                <Grid sx={{ my: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    {CustomCard(
                      "My cash",
                      fundPortfolioStatists?.myCashTotalValue || 0,
                      MyCashPageUrl
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {CustomCard(
                      "My investments",
                      fundPortfolioStatists?.myInvestmentsTotalValue || 0,
                      MyInvestmentsRequestsPage
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {CustomCard(
                      "My GrantVestments",
                      fundPortfolioStatists?.myGrantVestmentTotalValue || 0,
                      MyGrantVestmentRequestPage
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: warm[600] }} />
                <Grid container gap={1} sx={{ my: 2 }}>
                  <Grid item xs={12}>
                    {" "}
                    <MyPendingContributionList
                      dashboardStyle={true}
                      limit={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyScheduledGrantsRequestList
                      title="Future Grants"
                      dashboard={true}
                      limit={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MovementsCard
                      title="Recent activity"
                      limit={8}
                      dashboard={true}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    py: 6,
                    px: 8,
                    my: 3,
                    backgroundImage:
                      "linear-gradient(to right, #EDE5E1 , #F5F5F5);",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} md={4} sx={{ ml: 2 }}>
                      <StatisticsDetails
                        title="Granted this year"
                        amount={
                          fundPortfolioStatists
                            ?.myGrantRequestsStatisticByCurrentYear
                            .totalAmount || 0
                        }
                        text={`In ${fundPortfolioStatists
                          ?.myGrantRequestsStatisticByCurrentYear
                          .transactionsCount || 0
                          } ${(fundPortfolioStatists
                            ?.myGrantRequestsStatisticByCurrentYear
                            .transactionsCount === 1) ? 'grant' : 'grants'} to ${fundPortfolioStatists
                              ?.myGrantRequestsStatisticByCurrentYear
                              .charitiesCount || 0
                          } ${(fundPortfolioStatists
                            ?.myGrantRequestsStatisticByCurrentYear
                            .charitiesCount === 1) ? 'charity' : 'charities'}`}
                      ></StatisticsDetails>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={1}
                      sx={{ borderLeft: `solid 1px ${neutral[900]}` }}
                    ></Grid>
                    <Grid item xs={12} md={4}>
                      <StatisticsDetails
                        title="Grantvestments this year "
                        amount={
                          fundPortfolioStatists
                            ?.myGrantVestmentStatisticByCurrentYear
                            .totalAmount || 0
                        }
                        text={`into ${fundPortfolioStatists
                          ?.myGrantVestmentStatisticByCurrentYear
                          .grantvestmentCount || 0
                          } ${(fundPortfolioStatists
                            ?.myGrantVestmentStatisticByCurrentYear
                            .grantvestmentCount === 1) ? 'opportunity' : 'opportunities'}`}
                      ></StatisticsDetails>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
