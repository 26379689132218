import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Successor } from "types/successor";
import Layout from "components/layout/layout";
import { AddSuccessorContent } from "components/successor-information/add-successor-content";
import { useLookup } from "api/lookupService";
import { useLocation, useNavigate } from "react-router-dom";
import { FundSettingsPage, SuccessionPlanPage } from "constants/pages";
import { ReactComponent as DeleteIcon } from "./../../../assets/Icons/Delete.svg";
import { RemoveSuccessor } from "components/successor-information/remove-successor";
import { useUpdateSuccessor } from "api/SuccessorService";

export const EditSuccessor = () => {
  const updateSuccessorMutation = useUpdateSuccessor();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openRemoveDialog, setOpenRemoveDialog] =
    React.useState<boolean>(false);
  const [successor, setSuccessor] = React.useState<Successor>(state?.successor);
  const [titles, setTitles] = React.useState([]);
  const setLookupData = (data: any) => {
    setTitles(data.titles);
  };
  useLookup(setLookupData);
  const submit = (successor: Successor) => {
    updateSuccessorMutation.mutate(
      { fundId: state.id, successor: successor },
      {
        onSuccess: (updated) => {
          navigate(SuccessionPlanPage);
        },
      }
    );
  };
  const close = () => {
    setSuccessor({ ...state?.successor });
    navigate(SuccessionPlanPage);
  };
  const removeSuccessors = () => {
    navigate(SuccessionPlanPage);
  };
  return (
    <Layout>
      {() => (
        <>
          <RemoveSuccessor
            successor={successor}
            last={state?.length === 1}
            open={openRemoveDialog}
            cancel={() => setOpenRemoveDialog(false)}
            remove={removeSuccessors}
          ></RemoveSuccessor>

          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container sx={{ my: 3, width: "100%" }}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(FundSettingsPage)}
                  >
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      Fund settings
                    </Typography>
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(SuccessionPlanPage)}
                  >
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      Succession
                    </Typography>
                  </Link>
                  <Typography
                    variant="bodyCopyBold"
                    sx={{ color: "neutral.main" }}
                  >
                    Edit successor
                  </Typography>
                </Breadcrumbs>
                <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="h3">Edit Successor</Typography>
                  </Grid>
                  <Grid textAlign={"right"} item xs={12} sm={4}>
                    <Button
                      className="icon"
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => setOpenRemoveDialog(true)}
                    >
                      Delete Successor
                    </Button>
                  </Grid>
                </Grid>
                <AddSuccessorContent
                  edit={true}
                  selectedSuccessor={successor}
                  saveSuccessor={submit}
                  titles={titles}
                  close={close}
                />
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
