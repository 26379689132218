import React from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";

import {
  CashInformation,
  ContributionTransactionInput,
  SecuritiesInformation,
  TRANSACTION_TYPE_ENUM,
} from "types/contribution-transaction";
import { moneyFormat } from "utils/money-format";

interface SuccessStepProps {
  contributionTransaction: ContributionTransactionInput;
}
export const SuccessStep: React.FC<SuccessStepProps> = ({
  contributionTransaction,
}) => {
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid sx={{ m: 5 }} container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h3">Thank you!</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                sx={{ mt: 2, color: `neutral.main` }}
                variant="subtitle3"
              >
                {contributionTransaction.type ===
                  TRANSACTION_TYPE_ENUM.CASH && (
                  <>
                    We have received the details of your $
                    {moneyFormat(
                      +(
                        contributionTransaction.contributionInformation as CashInformation
                      ).requestedAmount
                    )}{" "}
                    contribution into your Fund.
                  </>
                )}
                {contributionTransaction.type ===
                  TRANSACTION_TYPE_ENUM.SECURITIES && (
                  <>
                    We received your request to contribute{" "}
                    {
                      (
                        contributionTransaction.contributionInformation as SecuritiesInformation
                      ).quantity
                    }{" "}
                    units of{" "}
                    {
                      (
                        contributionTransaction.contributionInformation as SecuritiesInformation
                      ).securityName
                    }{" "}
                    (
                    {
                      (
                        contributionTransaction.contributionInformation as SecuritiesInformation
                      ).ticker
                    }
                    ).
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ mt: 2 }} variant="bodyCopyRegular">
                {contributionTransaction.type ===
                  TRANSACTION_TYPE_ENUM.CASH && (
                  <>
                    Once we receive your contribution, your Fund balance will be
                    updated and we will send the tax acknowledgement letter (if
                    applicable). You can modify or cancel this contribution in
                    the dashboard, under "Pending Contributions"
                  </>
                )}
                {contributionTransaction.type ===
                  TRANSACTION_TYPE_ENUM.SECURITIES && (
                  <>
                    Your donation report will be sent once we receive the
                    transfer, and your balance will be updated once we sell the
                    securities. If needed, you can modify or cancel this
                    transaction in the dashboard.
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
