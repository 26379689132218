import {
  Typography,
  Grid,
  Divider,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { errorAlert, neutral, warm } from "style/color";
import { SCHEDULED_GRANT_REQUEST_STATUS_ENUM } from "types/grant-request";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
interface MyGrantsRequestListProps {
  charityName: string;
  userName: string;
  ein: string;
  executionDate: Date;
  amount: number;
  lastElement: boolean;
  id: number;
  transactionId?: number;
  status: string;
}
export const MyGrantsRequestListItems: React.FC<MyGrantsRequestListProps> = ({
  charityName,
  ein,
  userName,
  executionDate,
  amount,
  lastElement,
  transactionId,
  status,
}) => {
  const navigate = useNavigate();
  const openDetailPage = () => {
    navigate({
      pathname: `/my-grants/request/${transactionId}`
    });
  };
  return (
    <>
      {" "}
      <ListItem onClick={() => openDetailPage()}>
        <Grid alignItems="center" container>
          <Grid item xs={12} sm={3} textAlign={"start"}>
            <ListItemText
              sx={{ display: "inline-grid", width: "auto", flex: "none" }}
              primary={
                <Typography
                  sx={{ color: `neutral.main` }}
                  variant="bodyCopyBold"
                  gutterBottom
                >
                  {charityName}
                </Typography>
              }
              secondary={
                <Typography
                  sx={{ color: neutral[600] }}
                  variant="smallCopyBold"
                >{`By ${userName}`}</Typography>
              }
            />
          </Grid>
          <Grid item xs={12} sm={2} textAlign={"center"}>
            <Typography variant="bodyCopyRegular" sx={{ color: neutral[600] }}>
              {ein}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} textAlign={"center"}>
            <Typography variant="bodyCopyRegular" sx={{ color: neutral[600] }}>
              {shortFormat(executionDate)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} textAlign={"center"}>
            {status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.REJECTED && (
              <Button
                sx={{
                  borderRadius: "4px",
                  p: 1,
                  backgroundColor: warm[200],
                  color: errorAlert[500],
                  paddingX: 2,
                  paddingY: 1.5,
                  fontFamily: "Quattrocento Sans",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "13.3px",
                }}
              >
                DECLINED
              </Button>
            )}
            {status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PENDING && (
              <Button
                sx={{
                  borderRadius: "4px",
                  p: 1,
                  backgroundColor: warm[200],
                  color: neutral[500],
                  paddingX: 2,
                  paddingY: 1.5,
                  fontFamily: "Quattrocento Sans",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "13.3px",
                }}
              >
                PENDING
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={2} textAlign={"end"}>
            <Typography
              sx={{ color: neutral[900] }}
              variant="bodyCopyBold"
              gutterBottom
            >
              <>-${moneyFormat(amount)}</>
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      {!lastElement && <Divider />}
    </>
  );
};
