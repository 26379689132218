import React, { useContext, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Successor, getPercentageTotal } from "types/successor";
import Layout from "components/layout/layout";
import { useNavigate } from "react-router-dom";
import {
  AddSuccessorPage,
  EditSuccessorPage,
  FundSettingsPage,
} from "constants/pages";
import AddIcon from "@mui/icons-material/Add";
import { SuccessorList } from "components/successor-information/successor-list";
import { SaveButton } from "components/shared/save-button";
import { RemoveSuccessor } from "components/successor-information/remove-successor";
import { AuthContext } from "providers/userProvider";
import {
  useDeleteSuccessor,
  useSuccessors,
  useUpdateSuccessionsPlan,
} from "api/SuccessorService";
import { AuthData } from "types/auth";
import { USER_ROLE } from "types/account";
export const SuccessionPlan = () => {
  const updateSuccessionsPlanMutation = useUpdateSuccessionsPlan();
  const { selectedAccount, userRole } = useContext<AuthData>(AuthContext);
  const [change, setChange] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [id, setID] = React.useState<any>(0);

  const [toBeRemoved, setToBeRemoved] = React.useState<Successor>();
  const [openRemoveDialog, setOpenRemoveDialog] =
    React.useState<boolean>(false);
  const [successors, setSuccessors] = React.useState<Successor[]>([]);
  const data = useSuccessors(refresh, id, setSuccessors);
  const deleteSuccessorMutation = useDeleteSuccessor();
  useEffect(() => {
    if (selectedAccount) {
      if (selectedAccount) {
        setID(selectedAccount.fundAccountId);
      }
    }
  }, [selectedAccount]);
  const navigate = useNavigate();
  const getTotal = () => {
    return getPercentageTotal(successors);
  };
  const openEditPage = (successor: Successor, index: number) => {
    navigate(EditSuccessorPage, {
      state: { successor: successor, id: id, length: successors.length },
    });
  };
  const updateSuccessorPercentage = (successors: Successor[]) => {
    setChange(true);
    setSuccessors([...successors]);
  };
  const openRemove = (index: number) => {
    setToBeRemoved(successors[index]);
    setOpenRemoveDialog(true);
  };
  const removeSuccessors = () => {
    setOpenRemoveDialog(false);
    setChange(true);
    deleteSuccessorMutation.mutate(
      { fundId: id, successorId: toBeRemoved?.successorId },
      {
        onSuccess: (updated) => {
          setRefresh((prev) => {
            return !prev;
          });
        },
      }
    );
  };
  const reset = () => {
    setChange(false);
    setRefresh((prev) => {
      return !prev;
    });
  };
  const submit = () => {
    setChange(false);
    const payload = successors.map((item) => {
      return { successorId: item.successorId, percentage: item.percentage };
    });
    updateSuccessionsPlanMutation.mutate(
      { fundId: selectedAccount?.fundAccountId, successors: payload },
      {
        onSuccess: () => {},
      }
    );
  };
  return (
    <Layout>
      {() => (
        <>
          <RemoveSuccessor
            successor={toBeRemoved}
            last={successors.length === 1}
            open={openRemoveDialog}
            cancel={() => setOpenRemoveDialog(false)}
            remove={removeSuccessors}
          ></RemoveSuccessor>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container sx={{ my: 3, width: "100%" }}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate(FundSettingsPage)}
                  >
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      Fund settings
                    </Typography>
                  </Link>
                  <Typography
                    variant="bodyCopyBold"
                    sx={{ color: "neutral.main" }}
                  >
                    Succession plan
                  </Typography>
                </Breadcrumbs>
                <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="h3">Succession planning</Typography>
                  </Grid>
                  <Grid textAlign={"right"} item xs={12} sm={4}>
                    <Button
                      className="icon"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => navigate(AddSuccessorPage)}
                      disabled={userRole !== USER_ROLE.ADMIN}
                    >
                      Add successor
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="bodyCopyRegular">
                      We recommend you to name one or more successors of the
                      fund to carry over your charity legacy.
                    </Typography>
                  </Grid>
                </Grid>

                <Card sx={{ boxShadow: "none", p: 4 }}>
                  <CardContent>
                    <Typography
                      sx={{ color: `neutral.main` }}
                      variant="subtitle1"
                      gutterBottom
                    >
                      Succession planning{" "}
                    </Typography>
                    {data.isLoading && <CircularProgress color="primary" />}
                    {successors.length !== 0 ? (
                      <>
                        <Typography
                          sx={{ color: `neutral.dark` }}
                          variant="bodyCopyRegular"
                        >
                          The total succession allocation has to be 100%.
                        </Typography>
                        <Grid
                          sx={{ mt: 2 }}
                          container
                          rowSpacing={2}
                          justifyContent={"center"}
                        >
                          <SuccessorList
                            role={userRole!}
                            successors={successors}
                            getTotal={getTotal}
                            updateSuccessorPercentage={
                              updateSuccessorPercentage
                            }
                            openEdit={openEditPage}
                            removeSuccessors={openRemove}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Typography
                        sx={{ color: `neutral.dark` }}
                        variant="bodyCopyRegular"
                      >
                        If you don’t add beneficiaries to your succession
                        planning, in the event of death your fund would be given
                        to the Neta Discretionary Fund.
                      </Typography>
                    )}
                  </CardContent>
                </Card>
                {successors.length !== 0 && (
                  <Box sx={{ my: 3, width: "100%" }}>
                    {change && userRole === USER_ROLE.ADMIN && (
                      <SaveButton
                        reset={reset}
                        submit={submit}
                        disabled={getTotal() !== 100}
                      />
                    )}
                  </Box>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
