import { Box, Grid, Typography, Container, Divider } from "@mui/material";
import { neutral } from "../style/color";
import Layout from "components/layout/layout";
import pdf from "../assets/DAFProgramGuidelines.pdf";
export const AgreementPolicyPage = () => {
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ display: "flex" }}>
            <Container>
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Neta Foundation’s DAF Program Guidelines & Policies
                    </Typography>
                    <Typography
                      variant="smallCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      {" "}
                      <a href={pdf} rel="noreferrer" target="_blank">
                        Click here for a PDF version
                      </a>{" "}
                    </Typography>
                  </Box>

                  <Typography
                    variant="smallCopyBold"
                    sx={{ color: neutral[900], fontWeight: "700" }}
                    gutterBottom
                  >
                    Effective January 1, 2024
                  </Typography>
                  <Typography
                    variant="bodyCopyRegular"
                    sx={{ color: neutral[900], pt: 3 }}
                    gutterBottom
                    paragraph
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      INTRODUCTION
                    </Typography>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      About Neta Foundation
                    </Typography>
                    <p>
                      {" "}
                      Neta Foundation, Inc. (“Neta”) is an independent
                      non-profit public charity<sup>1</sup>, operating
                      exclusively for charitable purposes. Neta’s mission
                      (“Mission”) is to distribute charitable funds and make
                      impactful investments that further human prospering and
                      wellbeing, primarily (but not exclusively) focusing on
                      scientific research efforts and early- stage technologies.
                    </p>

                    <p>
                      One of the ways that Neta pursues its Mission is by
                      administering a donor-advised fund (“DAF”) program, under
                      which donors can make tax deductible contributions (to the
                      extent permitted by law) to Neta and subsequently make
                      general grants and investments recommendations, and also
                      further the Mission via GrantVestments™ (as defined
                      below).
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      About This Document
                    </Typography>
                    <p>
                      This document (“DAF Agreement”) sets forth all the
                      policies, terms and conditions that apply to Neta’s DAF
                      program (“DAF Program”). Neta DAF users must attest that
                      they have read and understood this DAF Agreement at the
                      time their account is created or at the time they first
                      register with a Neta, and agree to be bound by this DAF
                      Agreement.
                    </p>
                    <p>
                      Neta reserves the right to amend this DAF Agreement and
                      the terms and conditions of its DAF Program at any time.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Governance
                    </Typography>
                    <p>
                      Once contributions (which are irrevocable) are accepted to
                      any DAF, they shall become assets of Neta and not be a
                      separate trust.
                    </p>
                    <p>
                      Neta is governed by a Board of Directors (“BoD”), which is
                      responsible for its governance and operations, including
                      all aspects of this DAF Program.
                    </p>
                    <p>
                      The DAF Program and all activities under this DAF
                      Agreement are subject to Neta’s articles of incorporation,
                      by- laws, policies, and IRS regulations. Neta’s articles
                      of incorporation, by-laws and policies are available upon
                      request. Neta reserves the right to, at any time, amend
                      this DAF Agreement, and to amend or adopt policies, as
                      long as they remain consistent with the articles of
                      incorporation and the by-laws. The BoD may amend the
                      articles of incorporation and the by-laws at any time,
                      provided they remain consistent with the Mission and IRS
                      regulations in a way that Neta continues to retain its
                      status as an organization that is described in sections
                      501(c)(3) and 509(a)(1) of the Internal Revenue Code.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      ACCOUNTS
                    </Typography>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Donor Advised Fund Accounts
                    </Typography>
                    <p>
                      Pursuant to the terms and conditions of this DAF
                      Agreement, DAF accounts (“Funds”) can be opened on behalf
                      of individuals or entities (which include companies,
                      private or public foundations, and trusts). The Funds are
                      designed for individuals and entities domiciled within the
                      United States; Neta does not intend to offer Funds or
                      related products and services outside of the United
                      States.
                    </p>
                    <p>
                      Each Fund will be identified by a unique number assigned
                      by Neta, and a name recommended by the individual or
                      entity establishing the Fund. The Fund name will be used
                      for internal communications and also externally as an
                      option for recognizing grants, therefore naming usually
                      makes reference to a family name, and/or charitable
                      values. While there are no set rules for naming of Funds,
                      Neta reserves the right to reject a name, in which case
                      Neta will work with the individual or entity to identify a
                      suitable alternative. Names can include words such as
                      “Fund”, “Trust” and “Foundation” if desired. Names can be
                      changed from time to time via the Portal (as defined
                      below).
                    </p>
                    <p>
                      Individuals and entities can set up, at any time,
                      additional Funds, and be responsible for multiple Funds at
                      a time.
                    </p>
                    <p>
                      Once a Fund is established, in accordance with the terms
                      of this DAF Agreement, donors will be able to make
                      charitable contributions into the Fund, and advisors (who
                      may or not be the donor) can recommend grants and
                      investments from the Fund.
                    </p>
                    <p>
                      By establishing a Fund or agreeing to become an advisor to
                      any Fund, donors and advisors agree to be bound by the
                      terms of this Agreement. Importantly, after a contribution
                      into a Fund is accepted, the donor has no further legal
                      control over the assets which are ultimately owned and
                      controlled by Neta. All contributions to Fund are
                      considered irrevocable contributions to Neta, and
                      therefore Fund assets are not legally segregated from each
                      other or from other Neta assets, despite being
                      managerially isolated into Funds.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Web Portal
                    </Typography>
                    <p>
                      Establishing Funds and all other activities and
                      communications concerning the Funds are to be performed
                      primarily through Neta’s web interface (“Portal”).
                      Nevertheless, Neta will also accept instructions in
                      writing, including email, for activities that could
                      otherwise be done through the Portal.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Donors and Advisors
                    </Typography>
                    <p>
                      Each Fund account has one or more donors (each a “Donor”)
                      and one or more advisors (each an “Advisor”).
                    </p>
                    <p>
                      Donors: they are the prior owner of the assets that are
                      contributed to Funds. In the case of Funds set up on
                      behalf of individuals (personal use), the Donor will be
                      the person listed during the account opening process via
                      the Portal. In the case of Funds set up on behalf of
                      entities, the Donor will be the entity itself (represented
                      by the individual setting up the Fund, who shall be duly
                      authorized to do so). Each Fund has initially a single
                      Donor associated with it; however, additional ones can be
                      added on a case by case basis for each contribution via
                      the Portal.
                    </p>
                    <p>
                      Advisors: individuals (in their own capacity or as
                      authorized representatives for entities) who are appointed
                      by the Donor (or other approved Advisors) that have access
                      to information related to the Fund and, subject to
                      specific permissions, can recommend grants and/or
                      investments and be responsible for other administrative
                      matters. When setting up a Fund, the Donor (or
                      representative of the entity for which the Fund is being
                      set up for) will be the primary Advisor (“Primary
                      Advisor”). Additional Advisors may be added and removed
                      from time to time via the Portal.
                    </p>
                    <p>
                      Communications to and from the Advisors will be through
                      those Advisors designated via the Portal. If no Advisors
                      have been designated to receive communications, Neta will
                      consider the Primary Advisor to be the designee.
                    </p>
                    <p>
                      In the case of death or incapacity of the Primary Advisor,
                      the provision of Succession Planning below will apply.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      CONTRIBUTIONS
                    </Typography>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Overview
                    </Typography>
                    <p>
                      Subject to a case by case exception, an initial
                      contribution of at least $100,000 is required to establish
                      a Fund, and additional contributions of at least $1,000
                      can be made at any time. Neta also imposes Minimum Balance
                      requirements for Funds (as described below).
                    </p>
                    <p>
                      All contributions other than cash (see Types of
                      Contributions below) must be reviewed and approved by Neta
                      prior to acceptance. This review process will include a
                      determination as to whether the assets will be in
                      compliance with the excess business holding rules under
                      Internal Revenue Code (“IRC”) 4943(e). Contributions that
                      are accepted represent an irrevocable contribution to
                      Neta, are not refundable and become subject to Neta’s
                      control and discretion. Contributions that are not
                      accepted will be returned.
                    </p>
                    <p>
                      Donors should consult their tax and/or legal advisor prior
                      to making any gift.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Types of Contributions
                    </Typography>
                    <p>
                      Contributions may be made using cash, or publicly traded
                      securities or other property, upon approval by Neta.
                      Contributions of property that may not have immediate
                      liquidity are accepted at the discretion of Neta, and
                      subject to completion of its due diligence procedures.
                      Donors considering a gift in any form other than cash or
                      publicly traded securities should contact Neta to discuss
                      its appropriateness and delivery/assignment instructions.
                    </p>
                    <Typography
                      variant="bodyCopyRegular"
                      sx={{ color: neutral[900], textDecoration: "underline" }}
                      gutterBottom
                    >
                      Cash
                    </Typography>
                    <p>
                      Cash donations (in US dollars) may be made in the form of
                      a check, wire, or ACH. Mailing, wire and ACH instructions
                      are available in the Portal or upon request. The necessary
                      information must be provided to Neta via the Portal prior
                      to initiating the transaction to ensure the funds are
                      properly credited. All checks should be made payable to
                      Neta Foundation, Inc.
                    </p>
                    <Typography
                      variant="bodyCopyRegular"
                      sx={{ color: neutral[900], textDecoration: "underline" }}
                      gutterBottom
                    >
                      Mutual Funds and Publicly Traded Securities
                    </Typography>
                    <p>
                      A Donor may contribute mutual fund shares, stocks, bonds,
                      and other publicly traded securities via DTC or
                      certificate. In the event of a donation of publicly traded
                      securities, Neta will seek to sell them promptly. For
                      securities for which no readily liquid market exists, if
                      accepted, Neta will exercise discretion as to the time of
                      sale (subject to consultation with Donor if deemed
                      appropriate). Any costs incurred to dispose of the
                      securities, such as transaction or legal fees, will reduce
                      the net proceeds available to the Fund.
                    </p>
                    <p>
                      The value available to the Fund (which may differ from the
                      amount of the charitable deduction available to the Donor)
                      is determined on the date on which Neta receives the net
                      proceeds from the sale of contributed securities. Thus,
                      the net proceeds and the corresponding value of the Fund
                      may differ from the charitable deduction amount.
                    </p>
                    <p>
                      When transferring securities or mutual funds,
                      Donor/Advisors are required to fill out the details of the
                      transfer in the Portal and obtain approval prior to
                      sending instructions to the holding firm.
                    </p>
                    <Typography
                      variant="bodyCopyRegular"
                      sx={{ color: neutral[900], textDecoration: "underline" }}
                      gutterBottom
                    >
                      Other assets
                    </Typography>
                    <p>
                      Neta can consider other types of assets, including
                      securities that are non-publicly traded. Please contact
                      Neta to discuss such type of assets on a case by case
                      basis.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Third Party Contributions
                    </Typography>
                    <p>
                      Neta allows entities and individuals other than the Donor
                      (each a “Third Party Donor”) to make contributions into a
                      Fund. A Fund’s Advisor must inform Neta, via the Portal,
                      that a specific contribution will be made from a Third
                      Party Donor, and complete the necessary Third Party Donor
                      information.
                    </p>
                    <p>
                      Third Party Donor contributions are subject to all terms
                      and conditions of this DAF Agreement. Third Party Donors
                      will have no access to or control of the Fund, and will
                      not be considered Advisors of the Fund.
                    </p>
                    <p>
                      No person not expressly authorized by Neta – including
                      Donors and Advisors – may actively solicit third party
                      contributions to Neta or to a Fund on behalf of any
                      specific charitable organization (including Neta), or
                      represent to any Third Party Donors what grant
                      recommendations will be made or that any such grant
                      recommendations will be approved by Neta.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Tax Forms
                    </Typography>
                    <p>
                      For tax reporting purposes, Neta will send confirmation of
                      each contribution to the Donor or Third Party Donor, as
                      the case may be. Confirmations should be read carefully,
                      and any error must be reported promptly (but in any event
                      no later than 30 days after receipt) to Neta.
                    </p>
                    <p>
                      Confirmations do not include the value of the asset
                      contributed, except when the contribution is in the form
                      of cash (via check, wire or ACH). When a non-cash
                      contribution is made, Neta will acknowledge the receipt of
                      specified assets, but will not provide or agree to any
                      valuation of such assets. Gift valuation for tax purposes
                      is solely the responsibility of the Donor. The IRS may
                      require the Donor to file other forms (such as Form 8283)
                      for certain non- cash contributions and Neta will
                      cooperate with the Donor in completion of any required
                      forms. Donors should consult their own tax advisors with
                      respect to any additional information regarding taxpayer
                      filing and substantiation requirements.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      GRANTS
                    </Typography>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Overview
                    </Typography>
                    <p>
                      At any time, Advisors may recommend grant distributions to
                      eligible recipients, as detailed below, and subject to the
                      other terms, limitations and requirements. Both realized
                      income and principal are available for grant distribution.
                    </p>
                    <p>
                      From time to time, Neta may bring to an Advisor’s
                      attention grant making opportunities in which the Advisor
                      may have an interest. Distributions may also be to
                      specific funds within Neta in which DAF funds are pooled
                      for greater impact.
                    </p>
                    <p>
                      Neta conducts a review of each grant recommendation and
                      has sole discretion on all grant making decisions. Neta
                      shall not be bound by any recommendation, and retains
                      ultimate legal authority and control with respect to grant
                      making. Neta may decline to make a recommended grant to
                      any organization for any reason, including if such a grant
                      is inconsistent with its policies and procedures or with
                      the laws and regulations governing public charities and
                      donor advised funds.
                    </p>
                    <p>
                      Please keep in mind that grant distributions from a Fund
                      do not result in additional charitable deduction;
                      charitable deductions are only available at the time when
                      assets have been contributed to a Fund.
                    </p>
                    <p>
                      Generally, advisors will recommend grants from their Fund
                      through the Portal. All grant recommendations will require
                      the following certification:<br></br>
                      “I hereby certify that neither I, nor anyone else, will
                      receive any impermissible benefit from a recommended grant
                      to this charitable organization. This includes, for
                      example, admission to charitable events, memberships that
                      have a non-deductible portion, tuition, raffle tickets,
                      and goods or services bought at a charitable auction. In
                      addition, this grant recommendation is not made for
                      lobbying purposes or to support a political campaign.”
                    </p>
                    <p>Additional certifications may be required.</p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Minimum Grant Amounts
                    </Typography>
                    <p>Neta accepts grant recommendations of $250 or more.</p>
                    <p>
                      Funds with less than $250 in combined balance (after
                      taking into account any accrued but yet unpaid fees) are
                      permitted to recommend one final grant below the
                      established minimum in order to close the Fund.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Eligible Grant Recipients
                    </Typography>
                    <p>
                      Distributions via grants will be limited to organizations
                      that qualify under section 170(b)(1)(A) of the Internal
                      Revenue Code, except distributions may not be made to
                      private non-operating foundations or non-functionally
                      integrated Type III supporting organizations.
                    </p>
                    <p>
                      For recommendations of grants to qualified section
                      501(c)(3) organizations. Neta will confirm tax status
                      through the IRS database and review to ensure they further
                      the mission of Neta.
                    </p>
                    <p>
                      For recommendations of grants to other eligible
                      organizations under IRC section 170(b)(1)(A), such as
                      colleges and universities, medical research organizations,
                      or governments, Neta will confirm qualification of such
                      organizations and review to ensure they further the
                      mission of Neta.
                    </p>
                    <p>
                      Neta may, on a case by case basis, consider grant
                      recommendations to foreign charitable organizations,
                      although this may incur additional costs to the Fund.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Ineligible Grant Recipients and Grant Purposes
                    </Typography>
                    <p>
                      The Internal Revenue Code prohibits certain grants from
                      DAFs; Neta does not make grants to individuals, private
                      non-operating foundations, or Type III supporting
                      organizations that are not functionally integrated. Also
                      prohibited are grants for political contributions or to
                      support political campaigns.
                    </p>
                    <p>
                      No grants shall be made that would result in a penalty tax
                      under Sections 4958, 4966 or 4967 of the Internal Revenue
                      Code.
                    </p>
                    <p>
                      Grants may not result in benefits, goods, or services to
                      the Donor, the Advisors, members of their families, and
                      businesses they control. Benefits include the payment of
                      pledges, event tickets, meals, sponsorships, registration
                      fees, discounted merchandise, preferred parking and/or
                      seating, and memberships unless the membership confers
                      nothing of value. Expense reimbursements, loans,
                      compensation, and other similar payments also are not
                      permitted from a DAF to a Donor, Advisor, or related
                      party, including a member of the Donor or Advisor’s family
                      or a 35% controlled entity of the aforementioned. Failure
                      to observe these restrictions can subject the Advisor to
                      tax penalties.
                    </p>
                    <p>
                      Please contact Neta if you have specific questions about
                      the eligibility or not of a specific grant proposal.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Grant Acknowledgment
                    </Typography>
                    <p>
                      The Portal will offer the ability for the Advisors to
                      select how any grant acknowledgement should be composed,
                      including anonymity, name of the Fund, name of the Fund
                      and the Donor, or in honor of a third party. The recipient
                      organization will be encouraged to acknowledge (and
                      publish via news release or list of donors) the gift in
                      accordance with the acknowledgement instructions, however,
                      Donor and Advisors hereby recognize that Neta has no
                      control over how recipient organization will do so, and
                      hereby release Neta from any errors or omissions related
                      to acknowledgements. Additional language will confirm that
                      no benefits have been offered or provided to Neta or the
                      Donor/Advisor in exchange for the accompanying grant.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Variance Power
                    </Typography>
                    <p>
                      Consistent with Treas. Reg. 1.170A-9(f)(11)(v) Neta has
                      the power “to modify any restriction or condition on the
                      distribution of funds for any specified charitable purpose
                      or to specified organizations if in the sole judgment of
                      the governing body (without the necessity of the approval
                      of any participating trustee, custodian or agent), such
                      restriction or condition becomes in effect, unnecessary,
                      incapable of fulfillment or inconsistent with the
                      charitable needs of the community or area served.”
                      Consistent with this requirement, any restrictions placed
                      on Funds by Donors are subject to modification by Neta if
                      it determines, in its sole discretion, that the
                      restriction or condition is unnecessary, incapable of
                      fulfillment, or inconsistent with the charitable needs of
                      the organization.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      INVESTMENTS
                    </Typography>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Overview
                    </Typography>
                    <p>
                      Neta offers Advisors the ability to recommend how assets
                      in a Fund are invested while grant opportunities are being
                      contemplated.
                    </p>
                    <p>
                      Neta will allow investments with varying risk and return
                      objectives. Investment options available from time to time
                      are described in the Portal. All investment options are
                      reviewed and approved by the BoD or an Investment
                      Committee, as applicable, and may change from time to time
                      as determined by Neta.
                    </p>
                    <p>
                      Advisors may make recommendations on how to invest assets
                      in their Funds in one or more of these options via the
                      Portal, and even recommend new investment alternatives.
                      Advisors may also recommend divestment (sale) and
                      reinvestment of assets. It is, nevertheless, important to
                      note that Neta has the sole responsibility and authority
                      for investment of the assets of each Fund. Therefore Neta
                      will have to approve each investment recommendation, and
                      may reject them if deemed inconsistent with its policies
                      and procedures or with the laws and regulations governing
                      public charities and donor advised funds. Decisions with
                      respect to the retention, investment, or reinvestment of
                      assets and with respect to commingling of assets are the
                      purview of the BoD and/or the Investment Committee, as
                      applicable. Funds are customarily invested and commingled
                      with assets of other Funds.
                    </p>
                    <p>
                      Neta may appoint professional investment managers from
                      time to time to carry out some of its investment
                      management responsibilities with respect to certain
                      investment options. The BoD/Investment Committee, as
                      applicable, is solely responsible for the selection and
                      oversight of investment managers. To the extent used, Neta
                      will ensure that all terms of compensation of investment
                      managers are commercially reasonable and do not result in
                      excess benefit to any party involved.
                    </p>
                    <p>
                      Neta will also offer certain investments specifically
                      linked to the Mission, as more fully described in
                      GrantVestments™ below.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Fees on Investments
                    </Typography>
                    <p>
                      Except as described in GrantVestments™, Neta does not
                      itself charge investment fees.
                    </p>
                    <p>
                      Different investment options may incur third-party
                      management or administration fees (i.e., which are not
                      paid to Neta). Any underlying fees (expense charges)
                      existing under any investment option are charged by and
                      paid to the investment manager / administrator of such
                      investment option. These fees will not appear in a Fund’s
                      statement, as they are included in the price / net asset
                      value of each investment option.
                    </p>
                    <p>
                      The responsibility to research each investment option and
                      their fees before making an investment recommendation lies
                      solely with the Advisors. Advisors may contact Neta to
                      discuss investment options.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      GRANTVESTMENTS™
                    </Typography>
                    <p>
                      Neta created this DAF Program to enable Donors to deploy
                      capital from their Funds to advance human prospering and
                      wellbeing (including via scientific research efforts and
                      early-stage technologies), the heart of Neta’s Mission.
                    </p>
                    <p>
                      The way Neta and Donors deploy this type of capital is
                      through GrantVestments™. GrantVestments™ are, in essence,
                      similar to grants as they are used for our Mission; yet,
                      they are similar to investments, as they keep economic
                      upside should the technology or research prove to be
                      commercially successful.
                    </p>
                    <p>
                      GrantVestments™ can take different forms. If the
                      technology is still in a lab at a research institution,
                      the best way may be to distribute a grant (with revenue
                      sharing language) to the institution. If the technology
                      has been spun out into a company, the best way may be an
                      investment into the company (equity or notes).
                      Irrespective of the format they take, GrantVestments™ are
                      monitored separately from other investments and from
                      standards grants<sup>2</sup>.
                    </p>
                    <p>
                      GrantVestments™ are illiquid and bear a higher risk /
                      lower return profile versus other traditional investments,
                      as the main objective of such GrantVestments™ will not be
                      of obtaining financial returns, but rather furthering the
                      Mission. By recommending GrantVestments™ from their Funds,
                      Donors/Advisors represent that it is their charitable
                      intention to support the Mission and not to preserve
                      capital or obtain financial returns.
                    </p>
                    <p>
                      GrantVestments™ will be revalued (marked-to-market)
                      periodically according to best practices set by the BoD.
                      The aggregate market value of all GrantVestments™ will be
                      included in the overall value of a Fund (the Total Asset
                      Value, as defined below) for the purposes of calculating
                      fees.
                    </p>
                    <p>
                      Certain GrantVestments™ will incur an additional fee to
                      support the necessary sourcing, diligence and monitoring.
                      The standard fee for each GrantVestment™ is currently set
                      at 1% pa of the balance (market value) of such
                      GrantVestment™. To the extent that a certain
                      GrantVestment™ will carry a fee that is higher or lower
                      than the standard fee, such nonstandard fee will be
                      disclosed in the Portal section for such GrantVestment™.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      SUCCESSION PLANNING
                    </Typography>
                    <p>
                      In the case of death or incapacity of the Primary Advisor,
                      the Fund’s assets will initially be set to revert to Neta
                      as general unrestricted assets. At any time, via the
                      Portal, the Primary Advisor may change the succession
                      instructions so that funds are distributed in accordance
                      with the Donor’s wishes to a combination of:
                    </p>
                    <ol type="a">
                      <li>
                        Individual(s) as successors: The successor(s) must
                        provide Neta with written notification and proof of the
                        Primary Advisor(s)’s death or incapacity, at which point
                        the Advisors in the Fund will be updated or, as needed,
                        new Fund(s) will be created where the successor(s)
                        is(are) the Primary Advisors;
                      </li>
                      <li>
                        Charitable organization(s): Once Neta is notified (or
                        becomes aware) of the death or incapacity of the Primary
                        Advisor, Fund assets will be distributed to one or more
                        charitable organizations (pre-selected by the Primary
                        Advisor), which may include Neta Foundation, subject to
                        the review and approval by Neta at the time of
                        distribution.
                      </li>
                    </ol>
                    <p>
                      At the time of distributions (to new Funds or charitable
                      organizations) pursuant to succession, if (a) any named
                      individual successor is not living, willing and able to
                      become a successor, or cannot be reached, or (b) any named
                      charitable organization is not a valid recipient for a
                      distribution (as determine by Neta in its discretion),
                      then distributions to such Funds (on behalf of individual
                      successors) or charitable organizations will not be made,
                      and such distribution will be pro-rated across the
                      remaining individual successors and charitable
                      organizations.
                    </p>
                    <p>
                      If at any time there are no valid successor or charitable
                      institutions named, then upon death or incapacity of the
                      Primary Advisory, the Fund’s assets will revert to Neta as
                      general unrestricted assets.
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      FEES
                    </Typography>
                    <p>
                      Neta assesses fees against all Funds to cover the cost of
                      administration and to continue Neta’s Mission. Fees
                      provide the necessary resources to operate efficiently and
                      effectively, ensuring fiscal responsibility in grant due
                      diligence, donor and nonprofit education, research, and
                      other activities.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Administrative Fee
                    </Typography>
                    <p>
                      An administrative fee is charged to each Fund based on the
                      Total Asset Value of such Fund. Total Asset Value, as of
                      any date, is the sum of (a) the cash available in the Fund
                      on such date, (b) the market value of the investment
                      portfolio on such date, and (c) the market value of the
                      GrantVestment™ portfolio on such date.
                    </p>
                    <p>
                      The current fee (unless otherwise agreed) is set at 1%pa
                      of the Total Asset Value, calculated and accrued daily
                      based on each day’s Total Asset Value, and payable
                      monthly, subject to a minimum administrative fee of USD
                      25.00 per month.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      GrantVestment™ Fee
                    </Typography>
                    <p>
                      Certain GrantVestments™ may be subject to specific fees,
                      as described fully in GrantVestments™.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Other Fees
                    </Typography>
                    <p>
                      Neta does not have any other standard fees. Nevertheless,
                      on a case by case basis, certain Advisor requests may
                      generate an extra burden on Neta from a diligence or
                      administrative perspective. Neta may require additional
                      fees in such cases, which will be negotiated with Advisors
                      on a case by case basis, and subject to Advisor approval.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Fee Payment
                    </Typography>
                    <p>
                      Any fees that are payable will be automatically deducted
                      from the Funds’ cash account.
                    </p>
                    <p>
                      To the extent a Fund does not have enough cash to pay the
                      fees, Neta may, at its sole discretion, (a) leave the
                      Fund’s cash account with a negative balance until cash
                      flows through the account, (b) partially liquidate
                      investments to generate enough cash, or (c) request that
                      the Donor make an additional cash contribution into the
                      Fund.
                    </p>
                    <p>
                      If the value of the fees is higher than the Total Asset
                      Value of a Fund, then all assets (cash, investments,
                      GrantVestments™)
                    </p>
                    <Typography
                      variant="h6"
                      sx={{ color: neutral[900], fontWeight: "700" }}
                      gutterBottom
                    >
                      OTHER POLICIES AND PROCEDURES
                    </Typography>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Minimum Distributions
                    </Typography>
                    <p>
                      Neta requires that minimum annual grants in aggregate be
                      greater than 5% of the DAF Program’s average net assets on
                      a fiscal three-year rolling basis. If this requirement is
                      not met in a fiscal year, Neta will ask for grant
                      recommendations from Funds that have not had grant
                      activity of at least 5% of their average net assets over
                      the same three-year period. If Donors/Advisors on these
                      accounts do not make grant recommendations within 90 days,
                      Neta will transfer the required amounts to a Neta
                      Foundation discretionary fund, from where Neta will make
                      grants at its sole discretion.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Minimum Balance
                    </Typography>
                    <p>
                      Subject to case by case exceptions, Donor/Advisors are
                      required to maintain a minimum Fund Total Asset Value of
                      $25,000. Should the balance fall below such level, grant
                      activity will be suspended until an additional
                      contribution is made or the Advisor notifies Neta that the
                      Fund will be closed and recommends grants for the
                      remaining assets.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Inactivity
                    </Typography>
                    <p>
                      If a Fund has made no grant or GrantVestmentTM
                      recommendations over a three (3) year period, Neta will
                      send written notification to the Advisors. If no response
                      is received after 90 days and Neta is otherwise
                      unsuccessful in contacting the Advisors, the Fund will be
                      deemed inactive.
                    </p>
                    <p>
                      A Fund will also be deemed inactive if:
                      <ul>
                        <li>
                          The Primary Advisor dies or resigns or evidence of his
                          or her incapacity is provided to Neta, and if no
                          successor advisor has been named; or
                        </li>
                        <li>
                          All named individual successors are unable or
                          unwilling to serve as such.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Please be advised that even if a fund is inactive, fees
                      will continue to be deducted until the Fund is closed.
                    </p>
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: neutral[900] }}
                      gutterBottom
                    >
                      Termination
                    </Typography>
                    <p>
                      Upon the death, resignation or incapacity of the last
                      Advisor to a Fund, or if the Fund is determined to be
                      inactive, Neta will deem the advisory period to have ended
                      and will initiate distributions from the fund in
                      accordance with the provisions herein, and the Fund will
                      be closed. Any assets not distributed pursuant to
                      Succession Planning will become a part of the Neta’s
                      unrestricted assets.
                    </p>
                    <Divider></Divider>
                    <Typography
                      variant="smallCopy"
                      sx={{ color: neutral[900], fontWeight: "400" }}
                      paragraph
                    >
                      <sup>1</sup>Neta Foundation, Inc. was incorporated in
                      Delaware in 2023, and is recognized by the Internal
                      Revenue Service (“IRS”) as a tax-exempt charitable
                      organization and classified as a 509(a)(1) public charity
                      under section 501(c)(3), of the Internal Revenue Code.
                      <br></br> <br></br>
                      <sup>2</sup>In standard grants, money leaves the Fund, and
                      once they are done, there is no further ongoing monitoring
                      or tracking. In the case of GrantVestments, given the
                      economic upside clauses, they are tracked and monitored,
                      as money may return to a Fund.
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </Layout>
  );
};
