import { Grid, Typography, Button, Box } from "@mui/material";
import { MyInvestmentsRequestTransactionDetailPage } from "constants/pages";
import { useNavigate } from "react-router-dom";
import { errorAlert, neutral, primary, warm } from "style/color";
import {
  INVESTMENT_REQUEST_STATUS_ENUM,
  INVESTMENT_REQUEST_TYPE_ENUM,
} from "types/investment-request";
import { dateFormat, moneyFormat } from "utils/money-format";

interface InvestmentRequestActivityCardProps {
  date: Date;
  status: INVESTMENT_REQUEST_STATUS_ENUM;
  user: { firstName: string; lastName: string };
  quantity: number;
  amount: number;
  type: INVESTMENT_REQUEST_TYPE_ENUM;
  ticker: string;
  id: number;
}

export const InvestmentRequestActivityCard: React.FC<
  InvestmentRequestActivityCardProps
> = ({ status, user, quantity, date, amount, type: action, ticker, id }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        "& :hover": {
          backgroundColor: warm[200],
          cursor: "pointer",
        },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "white",
          width: "100%",
          boxShadow: "none",
          borderRadius: 1,
          p: 5,
          my: 2,
        }}
        onClick={() =>
          navigate(
            MyInvestmentsRequestTransactionDetailPage.replace(
              ":id",
              id.toString()
            )
          )
        }
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Typography variant="bodyCopyBold" sx={{ color: neutral[900] }}>
            {dateFormat(date)}
          </Typography>
          <Typography
            variant="smallCopyBold"
            sx={{
              color: neutral[600],
            }}
          >
            {`By ${user.firstName}  ${user.lastName}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
            }}
          >
            <Typography variant="smallCopyBold" sx={{ color: neutral[600] }}>
              Quantity
            </Typography>
            <Typography variant="bodyCopyBold" sx={{ color: neutral[900] }}>
              {action === INVESTMENT_REQUEST_TYPE_ENUM.SELL && "-"}
              {moneyFormat(quantity, 4)}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              borderRadius: "4px",
              p: 1,
              backgroundColor: warm[200],
              color:
                status === INVESTMENT_REQUEST_STATUS_ENUM.PENDING
                  ? neutral[500]
                  : status === INVESTMENT_REQUEST_STATUS_ENUM.REJECTED
                    ? errorAlert[500]
                    : primary[500],
              paddingX: 2,
              paddingY: 1.5,
              fontFamily: "Quattrocento Sans",
              fontSize: "12px",
              fontWeight: "700",
              lineHeight: "13.3px",
            }}
          >
            {status === INVESTMENT_REQUEST_STATUS_ENUM.REJECTED
              ? "DECLINED"
              : status === INVESTMENT_REQUEST_STATUS_ENUM.APPROVED
                ? "PROCESSED"
                : status}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            variant="bodyCopyBold"
            sx={{
              color: neutral[900],
              textDecoration:
                status === INVESTMENT_REQUEST_STATUS_ENUM.REJECTED
                  ? "line-through"
                  : "none",
            }}
          >
            {action === INVESTMENT_REQUEST_TYPE_ENUM.SELL && "-"}$
            {moneyFormat(amount)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
