import React, { useContext, useEffect } from 'react';
import { Box, Card, CardContent, Container, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import Layout from 'components/layout/layout';
import { useNavigate } from 'react-router-dom';
import { Account, USER_ROLE } from 'types/account';
import { AuthContext } from 'providers/userProvider';
import { ReactComponent as EditIcon } from './../../assets/Icons/edit.svg'
import { primary, warm } from 'style/color';
import { ReactComponent as UnitesIcon } from './../../assets/Icons/units.svg';

import { ReactComponent as DonationIcon } from './../../assets/Icons/Donation.svg';
import { ReactComponent as PeopleIcon } from './../../assets/Icons/joint.svg';
import { ReactComponent as FormPersonIcon } from './../../assets/Icons/form-person.svg';
import { ReactComponent as NotifcationIcon } from './../../assets/Icons/notification-black.svg';
import { ReactComponent as ThumbsUpIcon } from './../../assets/Icons/thumbs-up-black.svg';
import { ReactComponent as InfoIcon } from './../../assets/Icons/info.svg';
import { styled } from "@mui/material/styles";
import { UpdateFundName } from './update-fund-name';
import { ContributionReceiptsPage, EmailSettingsPage, EntitySettingsPage, InterestsSettingsPage, RolesPermissionsPagePath, SuccessionPlanPage } from 'constants/pages';
import { AuthData } from 'types/auth';
import { useUpdateFundAccountMutation } from 'hooks/use-services/fund.service';

const CustomCard = styled(Card)({
  '& :hover': {
    backgroundColor: warm[500],
    '& .title': {
      color: primary[500]
    },
    '& svg  path': {
      fill: primary[500]

    },
    '& svg  circle': {
      stroke: primary[500]
    },
  }
});
export const FundSettings = () => {
  const navigate = useNavigate()
  const { selectedAccount, setSelectedAccount, refresh, setRefresh, userRole } = useContext<AuthData>(AuthContext)
  const [account, setAccount] = React.useState<Account>();
  const [open, setOpen] = React.useState<boolean>(false);
  const {
    mutate: updateFundAccount
  } = useUpdateFundAccountMutation(selectedAccount?.fundAccountId || 0);
  const cards = [
    { title: 'Entity information ', text: 'Edit your the entity address, name and information', show: account?.entityInformation, icon: <UnitesIcon style={{ flex: 'none' }} />, page: EntitySettingsPage },
    { title: 'Contribution receipts', text: 'Download contribution receipts for tax purposes', show: true, icon: <DonationIcon style={{ flex: 'none' }} />, page: ContributionReceiptsPage },
    { title: 'Roles & permissions', text: 'Invite other Advisors to this Fund and set their permissions', show: userRole === USER_ROLE.ADMIN, icon: <PeopleIcon style={{ flex: 'none' }} />, page: RolesPermissionsPagePath },
    { title: 'Succession', text: 'Establish a succession plan for your Fund', show: true, icon: <FormPersonIcon style={{ flex: 'none' }} />, page: SuccessionPlanPage },
    { title: 'Email notifications', text: 'Choose which emails  notifications you want to receive. ', show: true, icon: <NotifcationIcon style={{ flex: 'none' }} />, page: EmailSettingsPage },
    { title: 'Interests', text: 'Get personalised recommendations.', show: true, icon: <ThumbsUpIcon style={{ flex: 'none' }} />, page: InterestsSettingsPage },
    { title: 'Terms & conditions ', text: 'Review Neta\'s terms & conditions for Donor Advised Funds', show: true, icon: <InfoIcon style={{ flex: 'none' }} />, page: '/agreement-policies' },

  ];
  useEffect(() => {
    if (selectedAccount) {
      setAccount(selectedAccount)
    }
  }, [selectedAccount])
  const updateFundAccountName = (name: string) => {
    updateFundAccount({ fundAccountName: name }, {
      onSuccess: (newData) => {
        setSelectedAccount(newData as Account)
        setRefresh(!refresh)
        setOpen(false)
      }
    })
  }
  return (
    <Layout>
      {() => (
        <>
          <UpdateFundName account={account} open={open} cancel={() => setOpen(false)} submit={updateFundAccountName} />
          <Box sx={{ my: 4, width: '100%' }}>
            <React.Fragment>

              <Container sx={{ mt: 3, width: '100%' }}>
                <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h3'>
                      Fund settings
                    </Typography>

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant='smallCopy'>
                      FUND NAME
                    </Typography>
                    <Typography variant='subtitle1'>
                      {account?.fundAccountName}
                      <IconButton
                        onClick={() => setOpen(true)}
                        sx={{ float: 'right' }}
                        aria-label="edit name"
                        edge="end"
                        disabled={userRole !== USER_ROLE.ADMIN}
                      >
                        <EditIcon />
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ backgroundColor: warm[600], borderWidth: '1px' }} />
                <Grid sx={{ mt: 2 }} container spacing={2}>
                  {
                    cards.map((card, index) => {
                      return (
                        card.show &&
                        <Grid key={index} item xs={12} sm={4}>
                          <CustomCard onClick={() => navigate(card.page)} >
                            <CardContent sx={{ p: 5, '&:last-child': { pb: 5 } }}>
                              <Stack direction="row" spacing={2}>
                                {card.icon}
                                <Box>
                                  <Typography className='title' variant='subtitle3' gutterBottom>
                                    {card.title}
                                  </Typography>
                                  <Typography variant='bodyCopyRegular' component={'div'}>
                                    {card.text}
                                  </Typography>
                                </Box>
                              </Stack>
                            </CardContent>
                          </CustomCard>
                        </Grid>
                      )
                    })
                  }


                </Grid>
              </Container>

            </React.Fragment>

          </Box>
        </>)}
    </Layout>



  )
}
