import React, { useContext, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../providers/userProvider";
import { PersonalInformation } from "../../components/user-information/personal-information";
import { Address } from "../../components/user-information/address";
import { ContactInformation } from "../../components/user-information/contact-information";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { SaveButton } from "../../components/shared/save-button";
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { SuccessAlert } from "../../components/shared/success-alert";
import { EmailSettings } from "./email-settings";
import { USER, USER_ERROR } from "../../constants/user";
import Layout from "../../components/layout/layout";
import { useLookup } from "../../api/lookupService";
import {
  setUpTOTP,
  fetchMFAPreference,
  updateMFAPreference,
} from "aws-amplify/auth";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import { AddFundPage } from "constants/pages";
import { User, UserError } from "types/user";
import { AuthData, UserData } from "types/auth";
import { MFASettings } from "./mfa-settings";
import { PasswordSettings } from "./password-settings";
import { useUpdateUserMutation } from "hooks/use-services/user.service";
export const PersonalSettings = () => {
  const updateUserMutation = useUpdateUserMutation();
  const navigate = useNavigate();
  const { userData, setUserData } = useContext<AuthData>(AuthContext);
  const [user, setUser] = React.useState<User>(USER);
  const [open, setOpen] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openMFA, setOpenMFA] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");
  const [changes, setChanges] = React.useState(false);
  const [error, setError] = React.useState<UserError>(USER_ERROR);
  const [MFAenbaled, setMFAEnabled] = React.useState(false);
  const [qrCode, setQrCode] = React.useState({
    secret: "",
    url: "",
  });
  const [titles, setTitles] = React.useState([]);
  const setLookupData = (data: any) => {
    setTitles(data.titles);
  };
  useLookup(setLookupData);
  useEffect(() => {
    if (userData?.user) {
      setUser({
        ...userData?.user,
        dateOfBirth: dayjs(userData?.user?.dateOfBirth as any),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user]);
  const getMFAData = async () => {
    try {
      const output = await fetchMFAPreference();
      setMFAEnabled(output.enabled !== undefined);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getMFAData();
  }, []);
  const handleChange = (key: string, value: any) => {
    setChanges(true);
    setUser({
      ...user,
      [key]: value,
    });
  };
  const handleInvalid = (event: any) => {
    setError({
      ...error,
      [event.target.name]: true,
    });
  };
  const reset = () => {
    setChanges(false);
    setUser(userData?.user as User);
  };
  const submit = async (event: any) => {
    event.preventDefault();
    const fundAccounts = user.fundAccounts;
    delete user.fundAccounts;
    updateUserMutation.mutate(user as any, {
      onSuccess: (newUser: any) => {
        newUser.fundAccounts = fundAccounts;
        setUserData({
          ...(userData as UserData),
          user: newUser,
        });
        setChanges(false);
        openSuccessMessage(
          "Your account infromation was successfully updated!"
        );
      },
    });
  };
  const updateEmail = async (email: string) => {
    const fundAccounts = user.fundAccounts;
    delete user.fundAccounts;
    const newUser = {
      ...user,
      email: email,
    };
    setUser(newUser);
    updateUserMutation.mutate(user as any, {
      onSuccess: (updated: any) => {
        updated.fundAccounts = fundAccounts;
        setUserData({
          ...(userData as UserData),
          user: updated,
          email: updated.email,
        });
      },
    });
  };
  const openSuccessMessage = (text: string) => {
    setOpen(true);
    setAlertText(text);
  };
  const updateMFA = async () => {
    try {
      const totpSetupDetails = await setUpTOTP();
      const setupUri = totpSetupDetails.getSetupUri("neta");
      setQrCode({
        secret: totpSetupDetails.sharedSecret,
        url: setupUri.toString(),
      });
      if (totpSetupDetails.sharedSecret) {
        setOpenMFA(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const disableMFA = async () => {
    try {
      await updateMFAPreference({ totp: "DISABLED" });
      getMFAData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      {() => (
        <>
          {open && <SuccessAlert setOpen={setOpen} text={alertText} />}
          <Container>
            <Box sx={{ my: 4, width: "100%" }}>
              <React.Fragment>
                <Container sx={{ my: 8, width: "100%" }}>
                  <Grid sx={{ mb: 5 }} container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography variant="h3">
                        Your personal settings
                      </Typography>
                      <Typography variant="bodyCopyRegular">
                        Please complete all mandatory fields (*).
                      </Typography>
                    </Grid>
                    <Grid textAlign={"right"} item xs={12} sm={4}>
                      <Button
                        onClick={() => navigate(AddFundPage)}
                        startIcon={<AddIcon />}
                        variant="outlined"
                        color="primary"
                        size="large"
                      >
                        Add another fund
                      </Button>
                    </Grid>
                  </Grid>
                  <Box component="form" autoComplete="off" onSubmit={submit}>
                    {changes && <SaveButton reset={reset} />}

                    <div>
                      <Accordion>
                        <AccordionSummary
                          tabIndex={-1}
                          expandIcon={<ExpandMore />}
                          aria-controls="login"
                          id="login"
                        >
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="subtitle1"
                          >
                            Login Information{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid sx={{ mt: 2 }} container spacing={2}>
                            <Grid item xs={12} sm={8}>
                              <FormControl error={error.email} fullWidth>
                                <InputLabel shrink={false}>Email*</InputLabel>
                                <OutlinedInput
                                  name="email"
                                  value={user.email}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="edit email"
                                        edge="end"
                                        onClick={() => setOpenEmail(true)}
                                      >
                                        <EditIcon
                                          sx={{ color: "neutral.light" }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  type="text"
                                  required
                                  disabled
                                />
                                <EmailSettings
                                  oldEmail={user.email}
                                  openSuccessMessage={openSuccessMessage}
                                  open={openEmail}
                                  submit={(email) => updateEmail(email)}
                                  handleClose={() => setOpenEmail(false)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <FormControl fullWidth>
                                <InputLabel shrink={false}>
                                  Password*
                                </InputLabel>
                                <OutlinedInput
                                  name="password"
                                  type="password"
                                  value={"12345678"}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="edit password"
                                        edge="end"
                                        onClick={() => setOpenPassword(true)}
                                      >
                                        <EditIcon
                                          sx={{ color: "neutral.light" }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  disabled
                                />
                              </FormControl>
                              <PasswordSettings
                                openSuccessMessage={openSuccessMessage}
                                open={openPassword}
                                handleClose={() => setOpenPassword(false)}
                              />
                            </Grid>
                          </Grid>

                          <Grid sx={{ mt: 3 }} container>
                            {MFAenbaled ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={disableMFA}
                              >
                                Remove two-Factor Authentication
                              </Button>
                            ) : (
                              <>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="large"
                                  onClick={updateMFA}
                                >
                                  Add two-Factor Authentication
                                </Button>
                                <MFASettings
                                  getMFAData={getMFAData}
                                  qrCode={qrCode}
                                  openSuccessMessage={openSuccessMessage}
                                  open={openMFA}
                                  handleClose={() => setOpenMFA(false)}
                                />
                              </>
                            )}
                          </Grid>

                          <></>
                        </AccordionDetails>
                      </Accordion>
                      <PersonalInformation
                        titles={titles}
                        settings={true}
                        user={user}
                        handleChange={handleChange}
                        error={error}
                        handleInvalid={handleInvalid}
                      ></PersonalInformation>
                      <Address
                        settings={true}
                        user={user}
                        handleChange={handleChange}
                        error={error}
                        handleInvalid={handleInvalid}
                      ></Address>
                      <ContactInformation
                        settings={true}
                        user={user}
                        handleChange={handleChange}
                        error={error}
                        handleInvalid={handleInvalid}
                      ></ContactInformation>
                      <Accordion>
                        <AccordionSummary
                          tabIndex={-1}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="delete"
                          id="delete"
                        >
                          <Typography
                            sx={{ color: `neutral.main` }}
                            variant="subtitle1"
                          >
                            Delete account{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{ color: `neutral.dark` }}
                            variant="bodyCopyRegular"
                          >
                            We are working on this feature, this is coming soon.
                            In the meantime, if you want to delete your account,
                            please contact us.
                          </Typography>
                          <Grid sx={{ mt: 2 }} container>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="large"
                            >
                              I want to delete this account
                            </Button>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {changes && <SaveButton reset={reset} />}
                  </Box>
                </Container>
              </React.Fragment>
            </Box>
          </Container>
        </>
      )}
    </Layout>
  );
};
