import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Account } from 'types/account';
interface SuccsessAlertProps {
    account?: Account;
    open: boolean;
    cancel: () => void;
    submit: (name: string) => void;
}
export const UpdateFundName: React.FC<SuccsessAlertProps> = ({
    account, open, cancel, submit
}) => {
    const [error, setError] = React.useState<boolean>(false);
    const [fundName, setFundName] = React.useState<string>('');
    useEffect(() => {
        if (account) {
            setFundName(account?.fundAccountName)
        }
    }, [account])
    const update = () => {
        submit(fundName)
    }

    const handleChange = (value: string) => {
        setError(false);
        setFundName(value)
    }

    return (

        <Dialog sx={{ m: 2 }} fullWidth={true}
            maxWidth={'sm'} open={open} onClose={cancel}>

            <DialogTitle sx={{ my: 2, pl: 4 }}> <Typography sx={{ color: "neutral" }} variant='subtitle1'>Change your Fund name</Typography> </DialogTitle>
            <DialogContent sx={{ pl: 4 }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                        <FormControl error={error} fullWidth>
                            <InputLabel shrink={false}>Enter new name</InputLabel>
                            <OutlinedInput name="entityName"
                                value={fundName}
                                onInvalid={(event) => { setError(true) }}
                                onChange={(event) => handleChange(event.target.value)} type="text" required />
                            {error && <FormHelperText color={'red'}>Fund name is required</FormHelperText>}
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={cancel} >
                                Discard
                            </Button>
                            <Button variant="contained" color='primary' size='large' onClick={update}  >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>


        </Dialog>


    )
}
