import { Successor } from "./successor";

export enum ACCOUNT_TYPE {
  PERSONAL = "PERSONAL",
  ENTITY = "ENTITY",
}
export enum USER_ROLE {
  ADMIN = "ADMIN",
  VIEWER = "VIEWER",
  ADVISOR = "ADVISOR",
  USER = "USER",
}
export interface Entity {
  entityName: string;
  zip: string;
  city: string;
  state: string;
  firstAddressLine: string;
  secondAddressLine?: string;
  type: string;
  role: string;
}
export interface Account {
  fundAccountId?: number;
  fundAccountName: string;
  fundAccountType: ACCOUNT_TYPE;
  role: USER_ROLE;
  entityInformation?: Entity;
  total?: number;
  availableBalance?: number;
  successors?: Successor[];
  interests?: any[];
}
export interface AccountInput {
  fundAccountId?: number;
  fundAccountName: string;
  fundAccountType: ACCOUNT_TYPE;
  role: USER_ROLE;
  entityInformation?: Entity;
  total?: number;
  availableBalance?: number;
  successors?: Successor[];
  interests?: any[];
  invitations?: any[];
}
export interface FundError {
  fundAccountName?: boolean;
  entityName?: boolean;
  zip?: boolean;
  city?: boolean;
  state?: boolean;
  firstAddressLine?: boolean;
  secondAddressLine?: boolean;
  type?: boolean;
  role?: boolean;
}
