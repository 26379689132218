import React from "react";
import Header from "./header";
import Footer from "./footer/footer";
import { Sidebar } from "./sidebar";
import { Box } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import PublicFooter from "./footer/public-footer";
import AmplifyAuthenticator from "./authenticator";
import { useLocation } from "react-router-dom";
import { HomePage } from "../../constants/pages";

interface Props {
  children?: any;
  // any props that come into the component
}

function Layout({ children }: Props) {
  const location = useLocation();
  const [openSignIn, setOpenSignIn] = React.useState({
    open: false,
    signup: false,
  });

  const { route, signOut } = useAuthenticator((context) => [context.route]);
  const handleOpen = (signup: boolean) => {
    if (route !== "authenticated") {
      setOpenSignIn({
        open: true,
        signup: signup,
      });
    }
  };
  const handleClose = () => {
    setOpenSignIn({
      open: false,
      signup: false,
    });
  };
  return (
    <>
      <Header route={route} signOut={signOut} openSignIn={handleOpen} />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        {![HomePage, '/policy', '/agreement-policies'].includes(location.pathname) && <Sidebar route={route} />}

        <Box sx={{ flex: 1 }}>{children(handleOpen)}</Box>
      </Box>

      {route === "authenticated" || ['/policy', '/agreement-policies'].includes(location.pathname) ? <Footer /> : <PublicFooter />}
      <AmplifyAuthenticator
        open={openSignIn.open}
        goToSignUp={openSignIn.signup}
        handleClose={handleClose}
      />
    </>
  );
}
export default Layout;
