import React, { } from "react";
import {
    Box, Button, Container, Grid,
} from "@mui/material";
import Layout from "components/layout/layout";
import { useParams } from "react-router-dom";

import { ReactComponent as EditIcon } from './../../assets/Icons/edit.svg'
import { ReactComponent as GrantVestmentsIcon } from "./../../assets/Icons/Gantvestments.svg";
import { ReactComponent as ExtendedIcon } from './../../assets/Icons/extended.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import CloseIcon from '@mui/icons-material/Close';
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { shortFormat } from "utils/date-format";
import { useGetGrantVestmentRequestQuery } from "hooks/use-services/grant-vestment.service";
import { GRANT_VESTMENT_REQUEST_STATUS_ENUM } from "types/grant-vestment";
import { primary } from "style/color";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
export const GrantVestmentDetailsPage = () => {
    const { id } = useParams();
    const { data: grantVestmentRequest, isSuccess } = useGetGrantVestmentRequestQuery((id) ? +id : 0)
    const getStatusCard = () => {
        if (grantVestmentRequest?.status === GRANT_VESTMENT_REQUEST_STATUS_ENUM.PENDING) {
            return {
                icon: <ExtendedIcon />, title: 'Pending', text: 'Transactions appear pending until they are approved and executed.'
            }
        }
        if (grantVestmentRequest?.status === GRANT_VESTMENT_REQUEST_STATUS_ENUM.REJECTED) {
            return {
                icon: <HighlightOffIcon sx={{ color: primary[500] }} />, title: 'Rejected', text: `Our team didn’t approve this transaction due to ${grantVestmentRequest.rejectionReason}`
            }
        }
        return undefined;
    };

    return (
        <Layout>
            {() => (
                <>
                    <Box sx={{ my: 4, width: "100%" }}>
                        <React.Fragment>
                            <Container >
                                {
                                    isSuccess && grantVestmentRequest &&
                                    <Grid sx={{ m: 5 }} container spacing={2}>
                                        <Grid item xs={12} sm={9}>
                                            <BackToPreviousButton />
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <TransactionDetailsCard
                                                amount={grantVestmentRequest.appliedAmount}
                                                type="GrantVestment"
                                                title={grantVestmentRequest.grantVestment.name}
                                                avatar={<GrantVestmentsIcon />}
                                                status={grantVestmentRequest.status}
                                                statusCard={getStatusCard()}
                                            >

                                                <TransactionDetailsItem label="Type" value="GrantVestment" />
                                                <TransactionDetailsItem label="Date" value={shortFormat(grantVestmentRequest.createdAt)!} />
                                                <TransactionDetailsItem label="Initiated by" value={`${grantVestmentRequest.user.firstName} ${grantVestmentRequest.user.lastName}`} />
                                                <TransactionDetailsItem label="Fund" value={grantVestmentRequest.fund.fundAccountName} />

                                            </TransactionDetailsCard>

                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            {
                                                grantVestmentRequest.status === GRANT_VESTMENT_REQUEST_STATUS_ENUM.PENDING &&
                                                <>

                                                    <Button fullWidth startIcon={<EditIcon />} variant='contained' >Edit</Button>
                                                    <br></br>
                                                    <Button sx={{ mt: 1 }} fullWidth size='large' startIcon={<CloseIcon />} variant='contained'>Delete</Button>
                                                </>

                                            }
                                        </Grid>
                                    </Grid>
                                }


                            </Container>
                        </React.Fragment>
                    </Box>

                </>
            )}
        </Layout>
    );
};
