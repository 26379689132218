import React, { useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  SnackbarCloseReason,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Layout from "components/layout/layout";
import { useLookup } from "api/lookupService";
import { useNavigate } from "react-router-dom";
import {
  ACCOUNT_TYPE,
  Account,
  AccountInput,
  Entity,
  USER_ROLE,
} from "types/account";
import { USER, USER_ERROR } from "constants/user";
import { FundDetails } from "components/fund-information/fund-details";
import { EntityInformation } from "components/fund-information/entity-information";
import { SignUpSuccessionPlan } from "components/successor-information/signup-succession-plan";
import { InviteAdvisors } from "components/fund-information/invite-advisors";
import { AddInterests } from "components/fund-information/add-intrestes";
import { AuthContext } from "providers/userProvider";
import { User, UserError } from "types/user";
import { getPercentageTotal } from "types/successor";
import { UserSettingsPage } from "constants/pages";
import { AuthData } from "types/auth";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { useAddFundAccountMutation } from "hooks/use-services/fund.service";
import { errorAlert } from "style/color";

export const AddFund = () => {
  const navigate = useNavigate();
  const { userData, setRefresh, refresh } = useContext<AuthData>(AuthContext);
  const [titles, setTitles] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("Oops! Something went wrong, please try again later ");

  const [types, setTypes] = React.useState([]);
  const setLookupData = (data: any) => {
    setTitles(data.titles);
    setCategories(data.categories.data);
    setTypes(data.types);
  };
  useLookup(setLookupData);
  const { mutate, isLoading } = useAddFundAccountMutation();
  const [user, setUser] = React.useState<User>(USER);
  const [error, setError] = React.useState<UserError>(USER_ERROR);
  const [account, setAccount] = React.useState<AccountInput>({
    fundAccountName: "",
    fundAccountType: ACCOUNT_TYPE.ENTITY,
    role: USER_ROLE.ADMIN,
    successors: [],
    invitations: [],
  });
  const [entity, setEntity] = React.useState<Entity>({
    entityName: "",
    zip: "",
    city: "",
    state: "",
    firstAddressLine: "",
    secondAddressLine: "",
    type: "",
    role: "",
  });
  useEffect(() => {
    if (userData?.user) {
      setUser(userData?.user);
    }
  }, [userData?.user]);
  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value !== null) {
      handleAccountChange("fundAccountType", value);
    }
  };
  const change = (
    key: string,
    value: any,
    object: Account | Entity,
    setObject: any
  ) => {
    setError({ ...error, [key]: false });
    setObject({ ...object, [key]: value });
  };
  const handleAccountChange = (key: string, value: any) => {
    change(key, value, account, setAccount);
  };
  const handleEntityChange = (key: string, value: any) => {
    change(key, value, entity, setEntity);
  };
  const handleAddressChange = () => {
    setError({
      ...error,
      firstAddressLine: false,
      secondAddressLine: false,
      city: false,
      state: false,
      zip: false,
    });
    setEntity({
      ...entity,
      firstAddressLine: user.addressLine1,
      secondAddressLine: user.addressLine2,
      city: user.city,
      state: user.state,
      zip: user.zip,
    });
  };
  const handleInvalid = (event: any) => {
    setError({
      ...error,
      [event.target.name]: true,
    });
  };
  const submit = (event: any) => {
    event.preventDefault();
    const fundAccount =
      account.fundAccountType === ACCOUNT_TYPE.ENTITY
        ? { ...account, entityInformation: entity }
        : account;
    mutate(
      { userId: user.userId!, fund: fundAccount },
      {
        onSuccess: () => {
          setRefresh(!refresh);
          navigate(UserSettingsPage);
        },
        onError: (error: any) => {

          const message = error.response.data.message
          if (message.includes("duplicate key")) {
            setErrorMessage("The fund name is already used")
            setOpen(true)
          }
          else {
            setErrorMessage("Oops! Something went wrong, please try again later ");
            setOpen(true)
          }

        }
      }
    );
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container sx={{ my: 3, width: "100%" }}>
                <BackToPreviousButton />
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000} open={open} onClose={handleClose}>
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', backgroundColor: errorAlert[300] }}
                  >
                    {errorMessage}
                  </Alert>
                </Snackbar>
                <Box component="form" autoComplete="off" onSubmit={submit}>
                  <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography variant="h3">Add Fund</Typography>
                      <Typography variant="bodyCopyRegular">
                        Please complete all mandatory fields (*).
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          disabled={
                            (account.successors!.length !== 0 &&
                              getPercentageTotal(account.successors!) !== 100) || isLoading
                          }
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box sx={{ display: "flex", alignItems: "baseline", mb: 4 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ mr: 3 }}>
                      This fund is
                    </Typography>
                    <ToggleButtonGroup
                      value={account.fundAccountType}
                      exclusive
                      onChange={handleTypeChange}
                      className="successor-toggle"
                      size="large"
                    >
                      <ToggleButton
                        className="successor-toggle-button"
                        value={ACCOUNT_TYPE.ENTITY}
                      >
                        <div> Of an entity</div>
                      </ToggleButton>
                      <ToggleButton
                        className="successor-toggle-button"
                        value={ACCOUNT_TYPE.PERSONAL}
                      >
                        <div>For personal use</div>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <FundDetails
                    account={account}
                    handleAccountChange={handleAccountChange}
                    handleInvalid={handleInvalid}
                    error={error}
                  />
                  {account.fundAccountType === "ENTITY" && (
                    <EntityInformation
                      entity={entity}
                      types={types}
                      error={error}
                      handleEntityChange={handleEntityChange}
                      handleInvalid={handleInvalid}
                      handleAddressChange={handleAddressChange}
                    ></EntityInformation>
                  )}
                  <SignUpSuccessionPlan
                    successorsList={account?.successors!}
                    titles={titles}
                    handleAccountChange={handleAccountChange}
                  />
                  <InviteAdvisors
                    invitations={account?.invitations!}
                    handleAccountChange={handleAccountChange}
                  />
                  <AddInterests
                    categories={categories}
                    handleAccountChange={handleAccountChange}
                  />
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={
                        (account.successors!.length !== 0 &&
                          getPercentageTotal(account.successors!) !== 100) || isLoading
                      }
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
