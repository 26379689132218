import { getNetaSettingsAPI } from "api/neta-settings.api";
import { NetaSetting } from "constants/neta-settings";
import { useQuery } from "react-query";

const NETA_SETTINGS_KEY = "neta-setting";
export const useGetNetaSettingsQuery = () => {
  return useQuery<NetaSetting>({
    queryKey: [NETA_SETTINGS_KEY],
    queryFn: () => getNetaSettingsAPI(),
  });
};
