import apiHttpClient from "libs/api-http-client";
import PaginatedEntityList from "types/PaginatedEntityList";
import { Charity } from "types/charity";

export interface GetCharitiesQuery {
  activeFundAccountId?: number;
  searchTerm?: string;
  offset?: number;
  limit?: number;
}
export const getCharitiesAPI = (
  query: GetCharitiesQuery
): Promise<PaginatedEntityList<Charity>> =>
  apiHttpClient.get(`/charities`, {
    params: {
      activeFundAccountId: query.activeFundAccountId,
      limit: query.limit,
      offset: query.offset,
      searchTerm: query.searchTerm,
    },
  });

export const saveCharityAPI = (fundAccountId: number, charityEIN: string) =>
  apiHttpClient.post(
    `/funds/${fundAccountId}/saved-charities/${encodeURIComponent(charityEIN)}`
  );

export const unsaveCharityAPI = (fundAccountId: number, charityEIN: string) =>
  apiHttpClient.delete(
    `/funds/${fundAccountId}/saved-charities/${encodeURIComponent(charityEIN)}`
  );

export const getSavedCharitiesAPI = (
  query: GetCharitiesQuery
): Promise<PaginatedEntityList<Charity>> =>
  apiHttpClient.get(`/funds/${query.activeFundAccountId}/saved-charities`, {
    params: {
      limit: query.limit,
      offset: query.offset,
    },
  });

export const getCharityAPI = (ein: string): Promise<Charity> =>
  apiHttpClient.get(`/charities/${encodeURIComponent(ein)}`);

export const getGrantedCharitiesAPI = (
  query: GetCharitiesQuery
): Promise<PaginatedEntityList<Charity>> =>
  apiHttpClient.get(`/funds/${query.activeFundAccountId}/granted-charities`, {
    params: {
      limit: query.limit,
      offset: query.offset,
    },
  });
