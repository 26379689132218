import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    SvgIcon,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { ReactComponent as CheckIcon } from './../../assets/Icons/cheque.svg';
import { ReactComponent as TransferOutgoingIcon } from './../../assets/Icons/transfer-outgoing.svg';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CashInformation, PAYMENT_METHOD_ENUM, TRANSACTION_TYPE_ENUM } from "types/contribution-transaction";
import { OtherPaymentDetails } from "./payment-details/other-payment-details";
import { SecuritiesPaymentDetails } from "./payment-details/securities-payment-details";
import { CheckCashPaymentDetails } from "./payment-details/check-cash-payment-details";
import { TransferCashPaymentDetails } from "./payment-details/transfer-cash-payment-details";
import { DafCashPaymentDetails } from "./payment-details/daf-cash-payment-details";


interface PaymentMethodDetailsProps {
    information?: CashInformation | null;
    transactionType: TRANSACTION_TYPE_ENUM;
    handleChange: (key: string, value: any) => void;
}
export const PaymentMethodDetails: React.FC<
    PaymentMethodDetailsProps
> = ({ information, transactionType, handleChange }) => {
    const [paymentMethod, setpaymentMethod] = React.useState<PAYMENT_METHOD_ENUM>(PAYMENT_METHOD_ENUM.TRANSFER);
    useEffect(() => {
        if (information) {
            setpaymentMethod(information.paymentMethod)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleType = (
        event: React.MouseEvent<HTMLElement>,
        newPaymentMethod: PAYMENT_METHOD_ENUM | null,
    ) => {
        if (newPaymentMethod !== null) {
            setpaymentMethod(newPaymentMethod)
            if (transactionType === TRANSACTION_TYPE_ENUM.CASH) {
                handleChange('contributionInformation', {
                    ...information,
                    paymentMethod: newPaymentMethod
                })
            }
        }
    };
    const getCashPaymentMethod = () => {
        return (
            <>
                <Grid item xs={12} sm={10} >
                    <ToggleButtonGroup
                        fullWidth
                        size='medium'
                        value={paymentMethod}
                        onChange={handleType}
                        exclusive
                        color="primary"
                    >
                        <ToggleButton fullWidth className="add-money-toggle" value={PAYMENT_METHOD_ENUM.TRANSFER} aria-label="left aligned">
                            <SvgIcon sx={{ mr: 2 }}> <TransferOutgoingIcon /> </SvgIcon>  Transfer
                        </ToggleButton>
                        <ToggleButton fullWidth className="add-money-toggle" value={PAYMENT_METHOD_ENUM.CHECK} aria-label="centered">
                            <SvgIcon sx={{ mr: 2 }}> <CheckIcon /> </SvgIcon>   Check
                        </ToggleButton>
                        <ToggleButton fullWidth className="add-money-toggle" value={PAYMENT_METHOD_ENUM.DAF} aria-label="centered">
                            <SvgIcon sx={{ mr: 2 }}> <TransferOutgoingIcon /> </SvgIcon>   From another DAF
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                {
                    paymentMethod === PAYMENT_METHOD_ENUM.TRANSFER && (
                        <>
                            <Grid item xs={12} sm={10} sx={{ mt: 5 }}>
                                <Typography variant="subtitle3" sx={{ color: `neutral.main` }}>
                                    Use the information below for wire or ACH transfers
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} sx={{ mb: 2 }}>
                                <Typography variant='smallCopyBold' sx={{ color: `neutral.lighter` }}>
                                    Important: if possible, please indicate Fund’s name in a comment or reference field
                                </Typography>
                            </Grid>
                            <TransferCashPaymentDetails /></>

                    )
                }
                {
                    paymentMethod === PAYMENT_METHOD_ENUM.CHECK && (
                        <>
                            <Grid item xs={12} sm={10} sx={{ mt: 5, mb: 2 }}>
                                <Typography variant="subtitle3" sx={{ color: `neutral.main` }}>
                                    Send a check to
                                </Typography>
                                <Grid item xs={12} sm={10} sx={{ mb: 2 }}>
                                    <Typography variant='smallCopyBold' sx={{ color: `neutral.lighter` }}>
                                        Important: please add Fund’s name in the memo line of the check
                                    </Typography>
                                </Grid>
                            </Grid>
                            <CheckCashPaymentDetails /></>

                    )
                }
                {
                    paymentMethod === PAYMENT_METHOD_ENUM.DAF && (
                        <>
                            <Grid item xs={12} sm={10} sx={{ mt: 5, mb: 2 }}>
                                <Typography variant="smallCopyBold" sx={{ color: `neutral.main` }}>
                                    To transfer money from another DAF into your Fund, just recommend a grant to Neta Foundation to your other DAF. If you cannot find us in their system, then you should be able to recommend a new charity (please let us know if they ask for any additional information)
                                </Typography>

                            </Grid>
                            <DafCashPaymentDetails /></>

                    )
                }
            </>

        )
    };
    const getSecuritiesPaymentMethod = () => {
        return (
            <>
                <Grid item xs={12} sm={10} sx={{ mb: 2 }}>
                    <Typography variant="subtitle3" sx={{ color: `neutral.main` }}>
                        Please instruct your broker to send the securities via DTC to:
                    </Typography>
                </Grid>
                <SecuritiesPaymentDetails />
            </>


        )
    };
    const getOtherAssetsPaymentMethod = () => {
        return (
            <>
                <Grid item xs={12} sm={10} sx={{ mb: 2 }}>
                    <Typography variant="subtitle3" sx={{ color: `neutral.main` }}>
                        To contribute other assets, please contact us to discuss details
                    </Typography>
                </Grid>
                <OtherPaymentDetails />
            </>


        )
    };
    return (
        <>
            <Accordion defaultExpanded>
                <AccordionSummary tabIndex={-1}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Details"
                    id="Details"
                >
                    <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
                        {(transactionType === TRANSACTION_TYPE_ENUM.SECURITIES) ? " Delivery instructions" : "Payment method"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid sx={{ my: 3 }} container spacing={0}>
                        {
                            transactionType === TRANSACTION_TYPE_ENUM.CASH &&
                            getCashPaymentMethod()
                        }
                        {
                            transactionType === TRANSACTION_TYPE_ENUM.SECURITIES &&
                            getSecuritiesPaymentMethod()
                        }
                        {
                            transactionType === TRANSACTION_TYPE_ENUM.OTHER &&
                            getOtherAssetsPaymentMethod()
                        }




                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
