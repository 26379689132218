import { Investment } from "./Investment";
import { Account } from "./account";
import { InvestmentRequest } from "./investment-request";
import { User } from "./user";

export enum DIVIDEND_DISTRIBUTION_TYPE {
  CASH = "CASH",
  UNIT = "UNIT",
}
export interface FundDividendDistribution {
  dividendDistributionRequestId: number;
  amount: number;
  type: DIVIDEND_DISTRIBUTION_TYPE;
  distributionDate: Date;
  reinvest: boolean;
  investment: Investment;
  fund: Account;
  user: User;
  investmentRequest?: InvestmentRequest;
}
