import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { CustomSwitch } from "components/shared/custom-switch";
import { FC, useContext, useState } from "react";
import { neutral, primary } from "style/color";
import { ReactComponent as EyeOpen } from "./../../../assets/Icons/eye-open.svg";
import {
  CashInfo,
  GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
  GrantRequestInput,
  InvestmentInfo,
} from "types/grant-request";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Charity } from "types/charity";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import {
  compileHtmlToPDF,
  generateAcknowledgementLetterHtml,
} from "utils/create-pdf";
interface GrantAcknowledgementDetailsProps {
  grantRequest: GrantRequestInput;
  charity: Charity;
  handleGrantRequestChange: (key: string, value: any) => void;
}

export const GrantAcknowledgementDetails: FC<
  GrantAcknowledgementDetailsProps
> = ({ grantRequest, charity, handleGrantRequestChange }) => {
  const { selectedAccount, userData } = useContext<AuthData>(AuthContext);

  const [inHonorOf, setInHonorOf] = useState<boolean>(false);
  const [inMemoryOf, setInMemoryOf] = useState<boolean>(false);
  const [newAddress, setNewAddress] = useState<boolean>(false);

  const openPdf = () => {
    const amount =
      grantRequest.transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH
        ? (grantRequest.information as CashInfo).requestedAmount
        : (grantRequest.information as InvestmentInfo).requestedAmount;

    compileHtmlToPDF(
      generateAcknowledgementLetterHtml({
        acknowledgement: grantRequest.acknowledgement,
        fund: selectedAccount!,
        user: userData?.user!,
        charity: charity,
        amount: amount,
        purpose: grantRequest.grantPurpose,
        personToInform: grantRequest.toInform,
      })
    );
  };
  const handleAcknowledgementChange = (key: string, value: any) => {
    handleGrantRequestChange("acknowledgement", {
      ...grantRequest.acknowledgement,
      [key]: value,
    });
  };
  const handleChange = (index: number, value: string) => {
    const emails = grantRequest.acknowledgement.thirdPartiesEmails;
    emails[index] = value;
    handleGrantRequestChange("acknowledgement", {
      ...grantRequest.acknowledgement,
      thirdPartiesEmails: emails,
    });
  };
  const removeEmail = (index: number) => {
    const emails = grantRequest.acknowledgement.thirdPartiesEmails;
    emails.splice(index, 1);
    handleGrantRequestChange("acknowledgement", {
      ...grantRequest.acknowledgement,
      thirdPartiesEmails: emails,
    });
  };
  console.log(grantRequest.acknowledgement);
  return (
    <Grid container rowSpacing={0} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]}>
          Acknowledgement details to show in the grant letter*
        </Typography>
        <Typography
          variant="bodyCopyRegular"
          color={neutral[600]}
          sx={{ mb: 0 }}
          paragraph
        >
          Please use the below to customize the grant letter we send out with
          the grant
        </Typography>
      </Grid>
      <Grid sx={{ mt: 1, mb: 2 }} item xs={12}>
        <FormControlLabel
          control={
            <CustomSwitch
              onChange={(event) => {
                handleAcknowledgementChange("anonymous", event.target.checked);
              }}
              checked={grantRequest.acknowledgement.anonymous}
            />
          }
          label="I want the grant to be anonymous"
        />
      </Grid>
      {!grantRequest.acknowledgement.anonymous && (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-no-undef
                <Checkbox
                  onChange={(event) => {
                    handleAcknowledgementChange(
                      "showFundName",
                      event.target.checked
                    );
                  }}
                  checked={grantRequest.acknowledgement.showFundName}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Show your fund’s name"
              name="reuse"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-no-undef
                <Checkbox
                  onChange={(event) =>
                    handleAcknowledgementChange(
                      "showDonorAddress",
                      event.target.checked
                    )
                  }
                  checked={grantRequest.acknowledgement.showDonorAddress}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Show donor name & address "
              name="reuse"
            />
          </Grid>
          {grantRequest.acknowledgement.showDonorAddress && (
            <Grid sx={{ ml: 3 }} item xs={8}>
              <RadioGroup
                value={newAddress}
                name="radio-buttons-group"
                onChange={(event) => {
                  setNewAddress(event.target.value === "true");
                  if (event.target.value === "false") {
                    handleAcknowledgementChange("donorAddress", "");
                    handleAcknowledgementChange("thirdPartNameAndAddress", "");
                  }
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Show my name & address "
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Show a third party name & address  "
                />
              </RadioGroup>
              <FormControl sx={{ ml: 3 }} fullWidth>
                <TextField
                  disabled={!newAddress}
                  name="thirdPartNameAndAddress"
                  multiline
                  rows={2}
                  value={grantRequest.acknowledgement.thirdPartNameAndAddress}
                  onChange={(event) => {
                    handleAcknowledgementChange(
                      "thirdPartNameAndAddress",
                      event.target.value
                    );
                  }}
                  placeholder="Enter name"
                />
              </FormControl>
              <FormControl sx={{ ml: 3, mt: 2 }} fullWidth>
                <TextField
                  disabled={!newAddress}
                  name="address"
                  multiline
                  rows={2}
                  value={grantRequest.acknowledgement.donorAddress}
                  onChange={(event) => {
                    handleAcknowledgementChange(
                      "donorAddress",
                      event.target.value
                    );
                  }}
                  placeholder="Enter address"
                />
              </FormControl>
            </Grid>
          )}
        </>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-no-undef
            <Checkbox
              checked={inHonorOf}
              onChange={(event) => {
                setInHonorOf(event.target.checked);
                if (!event.target.checked) {
                  handleAcknowledgementChange("inHonorOf", "");
                }
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Add “In honour of”"
          name="reuse"
        />
      </Grid>
      <Grid sx={{ ml: 3 }} item xs={8}>
        <FormControl fullWidth>
          <TextField
            disabled={!inHonorOf}
            required={inHonorOf}
            multiline
            rows={4}
            value={grantRequest.acknowledgement.inHonorOf}
            onChange={(event) =>
              handleAcknowledgementChange("inHonorOf", event.target.value)
            }
            placeholder="Enter name and/or address"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-no-undef
            <Checkbox
              onChange={(event) => {
                setInMemoryOf(event.target.checked);
                if (!event.target.checked) {
                  handleAcknowledgementChange("inMemoryOf", "");
                }
              }}
              checked={inMemoryOf}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Add “In memory of”"
          name="addInMemoryOf"
        />
      </Grid>
      <Grid sx={{ ml: 3 }} item xs={8}>
        <FormControl fullWidth>
          <TextField
            disabled={!inMemoryOf}
            required={inMemoryOf}
            multiline
            rows={4}
            value={grantRequest.acknowledgement.inMemoryOf}
            onChange={(event) =>
              handleAcknowledgementChange("inMemoryOf", event.target.value)
            }
            placeholder="Enter name and/or address"
          />
        </FormControl>
      </Grid>
      <Grid sx={{ my: 4 }} item xs={8}>
        <Button
          size="medium"
          variant="outlined"
          startIcon={<EyeOpen />}
          onClick={openPdf}
        >
          See letter preview
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]}>
          Send to third parties
        </Typography>
        <Typography
          variant="bodyCopyRegular"
          color={neutral[600]}
          sx={{ mb: 0 }}
          paragraph
        >
          The charity receiving the grant will be notified by a standard
          communication. <br></br>If you wish to notify other parties of this
          grant, add their email(s) below and they will get a notification.{" "}
        </Typography>
      </Grid>
      {grantRequest.acknowledgement.thirdPartiesEmails.map((email, index) => (
        <>
          <Grid sx={{ mt: 2 }} item xs={8} sm={8}>
            <FormControl fullWidth>
              <TextField
                size="small"
                fullWidth
                name="email"
                value={email}
                onChange={(event) => handleChange(index, event.target.value)}
                variant="outlined"
                type="text"
                placeholder="Enter email"
              />
            </FormControl>
          </Grid>
          {grantRequest.acknowledgement.thirdPartiesEmails.length !== 1 && (
            <Grid sx={{ mt: 2 }} item xs={1} sm={1}>
              <IconButton
                onClick={(event) => removeEmail(index)}
                sx={{
                  backgroundColor: "#fff",
                  color: primary[500],
                  border: "1px solid #0B3A81",
                  "&:hover": {
                    backgroundColor: primary[500],
                    color: "#fff",
                  },
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          )}
        </>
      ))}
      <Grid sx={{ mt: 2 }} item xs={1} sm={1}>
        <IconButton
          onClick={(event) =>
            handleChange(
              grantRequest.acknowledgement.thirdPartiesEmails.length,
              ""
            )
          }
          sx={{
            backgroundColor: "#fff",
            color: primary[500],
            border: "1px solid #0B3A81",
            "&:hover": {
              backgroundColor: primary[500],
              color: "#fff",
            },
          }}
          disabled={
            grantRequest.acknowledgement.thirdPartiesEmails[
              grantRequest.acknowledgement.thirdPartiesEmails.length - 1
            ] === ""
          }
        >
          <AddIcon />
        </IconButton>
      </Grid>
      {!grantRequest.acknowledgement.anonymous && (
        <>
          <Grid sx={{ mt: 2 }} item xs={12}>
            <Typography variant="subtitle3" color={neutral[900]}>
              Visibility*
            </Typography>
            <Typography
              variant="bodyCopyRegular"
              color={neutral[600]}
              sx={{ mb: 0 }}
              paragraph
            >
              We do not control how the recipient of the grant will publicly
              recognize you (or not), but we can tell them your preference{" "}
            </Typography>
          </Grid>
          <Grid sx={{ mt: 1 }} item xs={12}>
            <RadioGroup
              onChange={(event) =>
                handleAcknowledgementChange(
                  "visibility",
                  event.target.value === "true"
                )
              }
              value={grantRequest.acknowledgement.visibility}
              name="visibility"
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Yes, I agree to have the acknowledgement details indicated above shared publicly by the recipient"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="No, I prefer to not have my details shared publicly"
              />
            </RadioGroup>
          </Grid>
        </>
      )}
    </Grid>
  );
};
