import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  listItemClasses,
} from "@mui/material";
import React, { useState } from "react";
import ContributionTransaction, {
  CashInformation,
  OtherAssetsInformation,
  SecuritiesInformation,
  TRANSACTION_TYPE_ENUM,
} from "types/contribution-transaction";
import { moneyFormat } from "utils/money-format";
import { shortFormat } from "utils/date-format";
import { ViewPaymentDetails } from "./view-payment-details";
import { DetailsButton } from "../../shared/custom-details-button";
import { CancelContribution } from "./cancel-contribution";
import {
  ContributionTransactionDetailPageUrl,
  EditContributionReceiptsPage,
} from "constants/pages";
import { useNavigate } from "react-router-dom";
import { neutral, warm } from "style/color";
interface ContributionReceiptsListProps {
  contributionTransactions: ContributionTransaction[];
  pending: boolean;
  cancelContribution?: (id: number) => void;
  dashboardStyle?: boolean;
}
export const ContributionReceiptsList: React.FC<
  ContributionReceiptsListProps
> = ({
  contributionTransactions,
  pending,
  cancelContribution,
  dashboardStyle: DashboardStyle = false,
}) => {
  const navigate = useNavigate();
  const [openPaymentDetails, setOpenPaymentDetails] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const [selectedContribution, setSelectedContribution] =
    useState<ContributionTransaction | null>();
  const handleOpenPaymentDetails = (contribution: ContributionTransaction) => {
    setOpenPaymentDetails(true);
    setSelectedContribution(contribution);
  };
  const handleClosePaymentDetails = () => {
    setOpenPaymentDetails(false);
    setSelectedContribution(null);
  };
  const openEditPage = (contribution: ContributionTransaction) => {
    const url = EditContributionReceiptsPage.replace(
      ":id",
      contribution.contributionId.toString()
    );
    navigate(url);
  };
  const openDetailsPage = (contribution: ContributionTransaction) => {
    const url = ContributionTransactionDetailPageUrl.replace(
      ":id",
      contribution.contributionId.toString()
    );
    navigate(url);
  };
  const handleOpenCancelDialog = (contribution: ContributionTransaction) => {
    setOpenCancelDialog(true);
    setSelectedContribution(contribution);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setSelectedContribution(null);
  };
  return (
    <>
      <ViewPaymentDetails
        open={openPaymentDetails}
        onClose={handleClosePaymentDetails}
        contributionTransaction={selectedContribution!}
      />
      <CancelContribution
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        contributionTransaction={selectedContribution!}
        cancelContribution={cancelContribution!}
      />

      <List
        className="successor"
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          mt: 2,
          [` & .${listItemClasses.root}:hover`]: {
            backgroundColor: warm[200],
            cursor: "pointer",
          },
        }}
      >
        {contributionTransactions.map((contribution, index) => (
          <>
            {" "}
            <ListItem
              sx={{ display: "-webkit-inline-box" }}
              key={index}
              onClick={() => openDetailsPage(contribution)}
            >
              <Grid alignItems="center" container>
                <Grid item xs={12} sm={4} textAlign={"start"}>
                  <ListItemText
                    sx={{ display: "inline-grid", width: "auto", flex: "none" }}
                    primary={
                      <Typography
                        sx={{ color: `primary.main` }}
                        variant="bodyCopyBold"
                        gutterBottom
                      >
                        {pending
                          ? `Initiated on ${shortFormat(
                              contribution.createdAt
                            )}`
                          : `Received on ${shortFormat(
                              contribution.updatedAt
                            )}`}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        sx={{ color: `neutral.dark` }}
                        variant="smallCopyBold"
                      >{`By ${contribution.user.firstName} ${contribution.user.lastName}`}</Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} textAlign={"center"}>
                  <Typography
                    variant="bodyCopyRegular"
                    sx={{ color: "neutral.main", textTransform: "capitalize" }}
                  >
                    {contribution.type.toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={pending ? 3 : 4} textAlign={"end"}>
                  <Typography
                    sx={{
                      color: DashboardStyle ? neutral[900] : `primary.main`,
                    }}
                    variant="bodyCopyBold"
                    gutterBottom
                  >
                    {contribution.type === TRANSACTION_TYPE_ENUM.CASH && (
                      <>
                        {" "}
                        $
                        {moneyFormat(
                          +(
                            contribution.contributionInformation as CashInformation
                          ).requestedAmount
                        )}
                      </>
                    )}
                    {contribution.type === TRANSACTION_TYPE_ENUM.SECURITIES && (
                      <>
                        {" "}
                        {
                          (
                            contribution.contributionInformation as SecuritiesInformation
                          ).quantity
                        }{" "}
                        {
                          (
                            contribution.contributionInformation as SecuritiesInformation
                          ).securityName
                        }
                      </>
                    )}
                    {contribution.type === TRANSACTION_TYPE_ENUM.OTHER && (
                      <>
                        {" "}
                        {
                          (
                            contribution.contributionInformation as OtherAssetsInformation
                          )?.description
                        }
                      </>
                    )}
                  </Typography>
                </Grid>
                {pending && (
                  <>
                    <Grid item xs={12} sm={1} textAlign={"end"}>
                      <DetailsButton
                        handleOpenEditPage={openEditPage}
                        item={contribution}
                        handleOpenDetails={handleOpenPaymentDetails}
                        handleOpenCancelDialog={handleOpenCancelDialog}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </ListItem>
            {index !== contributionTransactions.length - 1 && (
              <Divider key={index + 100} />
            )}
          </>
        ))}
      </List>
    </>
  );
};
