import { Category } from "types/category";
import apiHttpClient from "libs/api-http-client";
import { PaginatedEntityList } from "types/pagination";

export interface GetCategoriesQuery {
  searchTerm?: string;
  offset?: number;
  limit?: number;
}
export const getCategoriesAPI = (
  query: GetCategoriesQuery
): Promise<PaginatedEntityList<Category>> =>
  apiHttpClient.get(`/categories`, {
    params: {
      limit: query.limit,
      offset: query.offset,
      searchTerm: query.searchTerm,
    },
  });
