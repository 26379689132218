import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Layout from "components/layout/layout";
import { useParams } from "react-router-dom";

import { ReactComponent as InvestmentsIcon } from "./../../../assets/Icons/fluctuations.svg";
import { ReactComponent as ExtendedIcon } from "./../../../assets/Icons/extended.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { useGetFundInvestmentRequestQuery } from "hooks/use-services/investment-request.service";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import {
  INVESTMENT_REQUEST_STATUS_ENUM,
  INVESTMENT_REQUEST_TYPE_ENUM,
} from "types/investment-request";
import { shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
import { primary } from "style/color";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
export const InvestmentTransactionDetailsPage = () => {
  const { id } = useParams();
  const { data: investmentRequest, isSuccess } =
    useGetFundInvestmentRequestQuery(id ? +id : 0);
  const getStatusCard = () => {
    if (investmentRequest?.status === INVESTMENT_REQUEST_STATUS_ENUM.PENDING) {
      return {
        icon: <ExtendedIcon />,
        title: "Pending",
        text: "Transactions appear as pending until the payment is received. .",
      };
    }
    if (investmentRequest?.status === INVESTMENT_REQUEST_STATUS_ENUM.REJECTED) {
      return {
        icon: <HighlightOffIcon sx={{ color: primary[500] }} />,
        title: "Rejected",
        text: `Our team didn’t approve this transaction due to ${investmentRequest.rejectionReason}`,
      };
    }
    return undefined;
  };
  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container>
                {isSuccess && investmentRequest && (
                  <Grid sx={{ m: 5 }} container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <BackToPreviousButton />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TransactionDetailsCard
                        amount={investmentRequest.appliedAmount}
                        type="investment"
                        title={investmentRequest.investment.name}
                        avatar={<InvestmentsIcon />}
                        status={investmentRequest.status}
                        statusCard={getStatusCard()}
                      >
                        <TransactionDetailsItem
                          label="Type"
                          value={`Investment ${
                            investmentRequest.type ===
                            INVESTMENT_REQUEST_TYPE_ENUM.BUY
                              ? "Purchase"
                              : "Sale"
                          }`}
                        />
                        <TransactionDetailsItem
                          label="Date"
                          value={
                            (investmentRequest.status &&
                              (investmentRequest?.status as INVESTMENT_REQUEST_STATUS_ENUM)) ===
                            INVESTMENT_REQUEST_STATUS_ENUM.APPROVED
                              ? shortFormat(investmentRequest.executionDate)!
                              : shortFormat(investmentRequest.createdAt)!
                          }
                        />
                        <TransactionDetailsItem
                          label="Initiated by"
                          value={`${investmentRequest.user.firstName} ${investmentRequest.user.lastName}`}
                        />

                        <TransactionDetailsItem
                          label="Units"
                          value={moneyFormat(
                            investmentRequest.appliedUnits,
                            6
                          ).toString()}
                        />
                        <TransactionDetailsItem
                          label="Ticker"
                          value={investmentRequest.investment.ticker}
                        />
                        <TransactionDetailsItem
                          label="Fund"
                          value={investmentRequest.fund.fundAccountName}
                        />
                      </TransactionDetailsCard>
                    </Grid>
                  </Grid>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
