import React from "react";
import { NumericFormat } from "react-number-format";
import { NumericFormatProps } from "react-number-format/types/types";
interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
export const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values: any) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
            />
        );
    },
);
