import { Box, Grid, Container } from "@mui/material";
import logo from "../assets/logo-black/logo-black.svg";

import {
  Authenticator,
  CheckboxField,
  View,
  useAuthenticator,
  useTheme,
  Text,
} from "@aws-amplify/ui-react";
import { signIn, confirmSignUp, SignInInput } from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import { AuthContext } from "providers/userProvider";
import { useContext, useEffect } from "react";
import { AuthData } from "types/auth";
import { useNavigate } from "react-router-dom";
import { FundDashboardPage, MyFundsPageUrl } from "constants/pages";
I18n.putVocabularies(translations);
I18n.setLanguage("en");
I18n.putVocabulariesForLanguage("en", {
  "Password must have lower case letters":
    "Password must have at least one lower case letter",
  "Password must have upper case letters":
    "Password must have at least one upper case letter",
  "Password must have numbers": "Password must have at least one number",
  "Password must have special characters":
    "Password must have at least one special character",
  "An account with the given email already exists.":
    "An account with the given email already exists. Please try to sign in, or click the Forgot Your Password link to re-set your password",
  "We Emailed You": "We emailed you",
  "Your code is on the way. To log in, enter the code we emailed to":
    "We want to verify you have access to the email you entered. Please check",
  "It may take a minute to arrive":
    "for the code we sent you, and enter it below. Please note it may take a few minutes to arrive, or may end up in your spam folder",
  "Confirm TOTP Code": "Confirm two-Factor Authentication Code",
});
export const AuthPage = () => {
  const passwordSettings = {
    minLength: 8,
    requireLowercase: true,
    requireUppercase: true,
    requireNumbers: true,
    requireSpecialCharacters: true,
  };
  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
        isRequired: true,
        label: "Email:",
      },
    },
    signUp: {
      username: {
        placeholder: "Enter your email",
        isRequired: true,
        label: "Email:",
      },
    },
    forgotPassword: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
    },
  };
  const components = {
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();
        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms & Conditions field to sign up  */}
            <CheckboxField
              value="yes"
              errorMessage={validationErrors.acknowledgement as any}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              isRequired={false}
              label={
                <>
                  Accepting{" "}
                  <a href="/agreement-policies" target="_blank">
                    program guidelines
                  </a>{" "}
                  and{" "}
                  <a href="/policy" target="_blank">
                    privacy policy
                  </a>{" "}
                </>
              }
            />
          </>
        );
      },
      Footer() {
        const { tokens } = useTheme();

        return (
          <View
            textAlign="center"
            paddingInlineEnd={tokens.space.small}
            paddingInlineStart={tokens.space.small}
          >
            <Text color={tokens.colors.neutral[80]}>
              We're using an external service to keep your email and password
              secure
            </Text>
          </View>
        );
      },
    },
  };
  const services = {
    async validateCustomSignUp(formData: any) {
      if (
        "acknowledgement" in formData &&
        formData?.acknowledgement === undefined
      ) {
        return {
          acknowledgement: "You must agree to the Terms & Conditions",
        };
      }
      if (
        ["username", "password", "confirm_password"].every(
          (t) => t in formData
        ) &&
        !("acknowledgement" in formData)
      ) {
        return {
          acknowledgement: "You must agree to the Terms & Conditions",
        };
      }
    },
    async handleSignIn(formData: { username: string; password: string }) {
      const { username, password } = formData;
      const result = await signIn({ username, password });
      if (result.nextStep.signInStep === "CONFIRM_SIGN_UP") {
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("password", password);
      }

      return result;
    },
    async handleConfirmSignUp(formData: {
      username: string;
      confirmationCode: string;
    }) {
      const { username, confirmationCode } = formData;
      const result = await confirmSignUp({
        username,
        confirmationCode,
        options: { autoSignIn: true },
      });
      if (
        result.isSignUpComplete &&
        result.nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN"
      ) {
        return result;
      } else {
        const data = {
          username: sessionStorage.getItem("username"),
          password: sessionStorage.getItem("password"),
        } as SignInInput;
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("password");
        await signIn(data);
        window.location.reload();
      }
    },
  };
  const navigate = useNavigate();
  const { userData } = useContext<AuthData>(AuthContext);
  useEffect(() => {
    if (userData?.user) {
      const loggedIn = localStorage.getItem("loggedIn");
      if (loggedIn === "true") {
        if (userData?.user.fundAccounts?.length === 1) {
          navigate(FundDashboardPage);
        } else {
          navigate(MyFundsPageUrl);
        }
      }
    }
  }, [navigate, userData]);
  return (
    <>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Container>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
            <Grid item xs={12} sm={8} md={6} lg={6}>
              <Grid
                container
                direction="row"
                sx={{ my: 4, backgroundColor: "white" }}
                rowSpacing={2}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} textAlign={"center"}>
                  <Box component="img" alt="Logo" src={logo} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} paddingBottom={6}>
                  <Authenticator
                    passwordSettings={passwordSettings}
                    services={services as any}
                    formFields={formFields}
                    components={components}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
