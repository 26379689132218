import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Grid, Link } from "@mui/material";
import Layout from "components/layout/layout";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as DownloadIcon } from "./../../../assets/Icons/download.svg";
import { ReactComponent as ExtendedIcon } from "./../../../assets/Icons/extended.svg";
import { ReactComponent as GrantIcon } from "./../../../assets/Icons/Grant.svg";
import { ReactComponent as EditIcon } from "./../../../assets/Icons/edit.svg";
import CloseIcon from "@mui/icons-material/Close";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { shortFormat } from "utils/date-format";
import { useGetScheduledGrantRequestQuery } from "hooks/use-services/grant-request.service";
import {
  GRANT_REQUEST_STATUS_ENUM,
  GRANT_REQUEST_TYPE_ENUM,
  GrantRequest,
  RecurrentSchedule,
  SCHEDULED_GRANT_REQUEST_STATUS_ENUM,
  getExecutionDate,
} from "types/grant-request";
import { primary } from "style/color";

import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { AuthContext } from "providers/userProvider";
import { USER_ROLE } from "types/account";
import { AuthData } from "types/auth";
import { MyGrantsRequestsPagePath } from "constants/pages";
import { CancelGrant } from "./cancel-grant";
import { einFormat } from "types/charity";

export const GrantTransactionDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: transaction, isSuccess } = useGetScheduledGrantRequestQuery(
    id ? +id : 0
  );
  const [grantRequest, setGrantRequest] = useState<GrantRequest | undefined>(
    undefined
  );
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const { userRole } = useContext<AuthData>(AuthContext);
  useEffect(() => {
    if (transaction) {
      setGrantRequest(transaction.grantRequest);
    }
  }, [transaction]);
  const getStatusCard = () => {
    if (transaction?.status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PENDING) {
      return {
        icon: <ExtendedIcon />,
        title: "Pending",
        text: "Grants appear as pending until the payment is received. .",
      };
    }
    if (
      transaction?.status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.REJECTED &&
      grantRequest
    ) {
      return {
        icon: <HighlightOffIcon sx={{ color: primary[500] }} />,
        title: "Rejected",
        text: `Our team didn’t approve this transaction due to ${grantRequest?.rejectionReason}`,
      };
    }
    if (
      transaction?.status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.CANCELED &&
      grantRequest
    ) {
      return {
        icon: <HighlightOffIcon sx={{ color: primary[500] }} />,
        title: "Canceled",
        text: `This Transaction was canceled`,
      };
    }
    if (
      transaction &&
      transaction.status === SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PROCESSED
    ) {
      return {
        icon: <CheckCircleOutlineIcon sx={{ color: primary[500] }} />,
        title: "Grant sent",
        text: `The grant has been sent to the charity`,
      };
    }
    return undefined;
  };
  const handleCloseCancelDialog = (redirect: boolean) => {
    setOpenCancelDialog(false);
    if (redirect) {
      navigate(MyGrantsRequestsPagePath);
    }
  };

  return (
    <Layout>
      {() => (
        <>
          <Box sx={{ my: 4, width: "100%" }}>
            <React.Fragment>
              <Container>
                {isSuccess && grantRequest && (
                  <>
                    <CancelGrant
                      open={openCancelDialog}
                      onClose={handleCloseCancelDialog}
                      grantRequest={grantRequest!}
                    />

                    <Grid sx={{ m: 5 }} container spacing={2}>
                      <Grid item xs={12} sm={9}>
                        <BackToPreviousButton />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TransactionDetailsCard
                          amount={transaction.appliedAmount}
                          ein={(grantRequest.charity.country === "USA") ? einFormat(grantRequest.charity.ein) : grantRequest.charity.ein}
                          type="Grant"
                          title={grantRequest.charity.name}
                          avatar={<GrantIcon />}
                          status={grantRequest.status}
                          statusCard={getStatusCard()}
                        >
                          <TransactionDetailsItem
                            label="Type"
                            value={"Grant"}
                          />
                          {grantRequest.type ===
                            GRANT_REQUEST_TYPE_ENUM.RECURRENT && (
                              <TransactionDetailsItem
                                label="recurrency"
                                value={
                                  (
                                    grantRequest?.scheduleInformation as RecurrentSchedule
                                  ).frequency.charAt(0) +
                                  (
                                    grantRequest?.scheduleInformation as RecurrentSchedule
                                  ).frequency
                                    .substr(1)
                                    .toLowerCase()
                                }
                              />
                            )}
                          <TransactionDetailsItem
                            label="Date"
                            value={
                              shortFormat(
                                transaction
                                  ? transaction.executionDate
                                  : getExecutionDate(grantRequest)
                              )!
                            }
                          />
                          <TransactionDetailsItem
                            label="Initiated by"
                            value={`${grantRequest.user.firstName} ${grantRequest.user.lastName}`}
                          />
                          <TransactionDetailsItem
                            label="Fund"
                            value={grantRequest.fundAccount.fundAccountName}
                          />
                        </TransactionDetailsCard>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        {(grantRequest.status ===
                          GRANT_REQUEST_STATUS_ENUM.PROCESSED ||
                          transaction?.status ===
                          SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PROCESSED) && (
                            <Link
                              href={transaction.receipt?.url}
                              target="__blank"
                              underline="none"
                              color="inherit"
                            >
                              <Button
                                fullWidth
                                startIcon={<DownloadIcon />}
                                variant="contained"
                                disabled={
                                  transaction.status !==
                                  SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PROCESSED ||
                                  !transaction.receipt
                                }
                              >
                                Download
                              </Button>
                            </Link>
                          )}
                        {(grantRequest.status ===
                          GRANT_REQUEST_STATUS_ENUM.SCHEDULED ||
                          grantRequest.status ===
                          GRANT_REQUEST_STATUS_ENUM.PENDING) &&
                          transaction.status ===
                          SCHEDULED_GRANT_REQUEST_STATUS_ENUM.PENDING && (
                            <>
                              <Button
                                disabled={userRole === USER_ROLE.VIEWER}
                                fullWidth
                                startIcon={<EditIcon />}
                                variant="contained"
                                onClick={() => {
                                  navigate({
                                    pathname: `/my-grants/edit/${grantRequest.grantRequestId}`,
                                  });
                                }}
                              >
                                Edit
                              </Button>
                              <br></br>
                              <Button
                                disabled={userRole === USER_ROLE.VIEWER}
                                sx={{ mt: 1 }}
                                fullWidth
                                size="large"
                                startIcon={<CloseIcon />}
                                variant="contained"
                                onClick={() => {
                                  setOpenCancelDialog(true);
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Container>
            </React.Fragment>
          </Box>
        </>
      )}
    </Layout>
  );
};
