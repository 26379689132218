import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddSuccessorContent } from "./add-successor-content";
import { SUCCESSOR } from "constants/successor";
import { Successor, getPercentageTotal } from "types/successor";
import AddIcon from "@mui/icons-material/Add";
import { SuccessorList } from "./successor-list";

interface SignUpSuccessionPlanProps {
  successorsList: Successor[];
  titles: string[];
  handleAccountChange: (key: string, value: any) => void;
}
export const SignUpSuccessionPlan: React.FC<SignUpSuccessionPlanProps> = ({
  successorsList,
  titles,
  handleAccountChange,
}) => {
  const [openAddSuccessor, setOpenSuccessor] = React.useState(false);
  const [selectedSuccessor, setSelectedSuccessor] = React.useState({
    successor: SUCCESSOR as Successor,
    index: -1,
  });
  const openAddSuccessorDialog = (
    successor?: Successor,
    index: number = -1
  ) => {
    if (successor) {
      setSelectedSuccessor({ successor: successor, index: index });
    }
    setOpenSuccessor(true);
  };
  const closeAddSuccessorDialog = () => {
    setSelectedSuccessor({ successor: SUCCESSOR, index: -1 });
    setOpenSuccessor(false);
  };
  const setSuccessors = (successor: Successor) => {
    let successors = successorsList;
    if (selectedSuccessor.index !== -1) {
      successors[selectedSuccessor.index] = successor;
    } else {
      successors = [...successors, successor];
    }
    handleAccountChange("successors", successors);
    closeAddSuccessorDialog();
  };
  const removeSuccessors = (index: number) => {
    let successors = successorsList;
    successors.splice(index, 1);
    handleAccountChange("successors", successors);
  };
  const updateSuccessorPercentage = (successors: Successor[]) => {
    handleAccountChange("successors", successors);
  };

  const getTotal = () => {
    return getPercentageTotal(successorsList);
  };
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        tabIndex={-1}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="succession-planning"
        id="succession-planning"
        className="hasText"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          Succession planning{" "}
          <Typography
            sx={{ color: `neutral.lighter`, mx: 3 }}
            variant="bodyCopyBold"
          >
            Optional{" "}
          </Typography>{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="hasText">
        <Dialog
          sx={{
            my: 2,
            "& .MuiDialog-paper": { backgroundColor: "warm.light" },
          }}
          slotProps={{ backdrop: { className: "Dialog" } }}
          fullWidth={true}
          maxWidth={"lg"}
          open={openAddSuccessor}
          onClose={closeAddSuccessorDialog}
        >
          <DialogTitle sx={{ my: 2 }}>
            {" "}
            <Typography sx={{ color: "neutral" }} variant="subtitle1">
              Add successor
            </Typography>{" "}
          </DialogTitle>
          <DialogContent>
            <AddSuccessorContent
              edit={selectedSuccessor.index !== -1}
              selectedSuccessor={selectedSuccessor.successor}
              saveSuccessor={setSuccessors}
              titles={titles}
              close={() => closeAddSuccessorDialog()}
            />
          </DialogContent>
        </Dialog>
        <Typography sx={{ color: `neutral.dark` }} variant="bodyCopyRegular">
          {successorsList.length === 0
            ? "We recommend you to name one or more successors of the fund to carry over your charity legacy. You can add or modify your fund settings later from your fund settings."
            : "The total succession allocation has to be 100%."}
        </Typography>
        <Grid sx={{ mt: 2 }} container rowSpacing={2}>
          {successorsList.length !== 0 && (
            <SuccessorList
              successors={successorsList}
              getTotal={getTotal}
              updateSuccessorPercentage={updateSuccessorPercentage}
              openEdit={openAddSuccessorDialog}
              removeSuccessors={removeSuccessors}
            />
          )}
          <Grid item xs={12} sm={12}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => openAddSuccessorDialog()}
            >
              Add successor
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
