import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { FC } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

export const CustomAccordion: FC<Props> = ({
  defaultExpanded = false,
  title,
  children,
}) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        tabIndex={-1}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="donor-info"
        id="donor-info"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}

      </AccordionDetails>
    </Accordion>
  );
};
